<template>
    <dl class="myo_search_list">
        <dt>Maintenance status</dt>
        <dd class="flex_box">
            <select id="end_type" v-model="end_type" class="select1 wide">
                <option value="" >ALL</option>
                <option value="Y" >Y</option>
                <option value="N" >N</option>
            </select>
        </dd>
    </dl>

</template>

<script>
    import datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: "endDateForm",
    components: {
        datepicker
    },
    data: () => ({
        i18n: window.i18n,
        locale :"en",
        end_date: "",
        format : "yyyy-MM-dd",
        end_type : "",
    }),
    watch : {
        end_type(end_type) {
            this.set_end_type(end_type);
        },
    },
    methods : {
        set_end_type(end_type) {
            this.$emit("set_end_type",end_type);
        },
    }
}
</script>

<style scoped>

</style>
