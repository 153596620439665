<template>
    <dl class="myo_search_list">
        <dt>{{ i18n.myoffice.Order.OrderStatus }}</dt>
        <dd>
            <vSelect :options="order_cds" :reduce="value => value.id" v-on="ord_kind"   ></vSelect>
        </dd>
    </dl>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "orderClassification",
    components : {
        vSelect
    },
    data: () => ({
        i18n: window.i18n,
        order_cds : [ { "id": 9, "label": i18n.myoffice.all }, { "id": 1, "label": i18n.myoffice.Order.orderNomal }, { "id": 3, "label": i18n.myoffice.Order.Cancel } ]
    }),
    props : {
        ord_kind : ""
    },
    watch : {
        ord_kind(ord_kind){
            this.set_ord_kind(ord_kind);
        }
    },
    methods : {
        set_ord_kind(ord_kind){
            this.$emit("set_ord_kind",ord_kind);
        }
    }
}
</script>

<style scoped>

</style>
