<template>
    <div class="flex flex-row w-full mb-6">
        <div class="w-full border bg-white rounded-xl p-5">
            <div class="overflow-x-scroll w-full block full:scrollbar-hide full:overflow-hidden">
                <table class="border-separate border-spacing-2 table-auto w-full">
                    <thead>
                    <tr>
                        <th colspan="7" class="py-2 bg-[#2e5db3] text-white">{{  i18n.myoffice.Commission.performanceState }}</th><!--본인 실적 현황-->
                        <th colspan="6" class="py-2 bg-[#2e5db3] text-white">{{  i18n.myoffice.Commission.subordinateMembershipPerformance }}</th><!--직추천 1대 실적 현황-->
                    </tr>
                    <tr class="bg-[#172e59]">
                        <th class="p-2"></th>
                        <th class="p-2 text-white break-keep w-20">{{ i18n.myoffice.MyBusiness.amount}}</th>
                        <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.MyBusiness.amountRefunded}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.orderPV}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.refundedPv}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountTotal}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.totalPv}}</th>

                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amount}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountRefunded}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.orderPV}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.refundedPv}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountTotal}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.totalPv}}</th>


                    </tr>
                    </thead>
                    <tbody>
                    <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                        <th class="p-2 text-white bg-[#172e59] min-w-[100px]">{{ i18n.myoffice.MyBusiness.thisMonth}}</th>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT_A1 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT_R1 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV_A1 ?? 0,1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV_R1 ?? 0,1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT1 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV1 ?? 0,1) }}</td>

                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT_A1 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT_R1 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV_A1 ?? 0,1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV_R1 ?? 0,1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT1 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV1 ?? 0,1) }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center border-gray-500 py-3 flex justify-center mt-3" v-if="false">
                <a href="" class="border border-gray-500 px-8 py-2 bg-[#172e59] text-white text-base inline-block w-auto rounded-xl flex items-center justify-between hover:bg-[#254a8f]">
                    {{  i18n.myoffice.Commission.seeMoreOf }}
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white inline-block" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path class="text-red-500" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                </a>
            </div>
        </div>
    </div><!-- //나와 -->

    <div class="flex-col w-full gap-5 mb-6 bg-white border rounded-xl p-5">
        <div class="w-full overflow-x-scroll sm:overflow-hidden block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
            <table class="border-separate border-spacing-2 w-full table-auto">
                <thead>
                    <tr>
                        <th colspan="4" class="text-center text-base py-2 bg-[#2e5db3] text-white">{{i18n.myoffice.Commission.downlineMemberRankStatus}}</th><!--직 추천 하선정보-->
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.Qualifier  }}</th>
                        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.memberOfNumber}}</th>
                        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.Ranker  }}</th>
                        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.memberOfNumber}}</th>
                    </tr>
                    <tr class="bg-[#f0f4ff] text-center text-slate-800">
                        <td class="text-center p-2 min-w-[100px]">Member</td>
                        <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                            <button v-if="dash_d_code.RANK01 != '0' && dash_d_code.RANK01 !=''" type="button" @click="getBonusLeglist('01',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">
                                {{ (dash_d_code.RANK01) ?? "" }}
                            </button>
                            <button v-else class="cursor-default">
                                -
                            </button>
                        </td>

<!--                        <td class="text-center p-2 min-w-[100px]">Branch Manager</td>-->
                        <td class="text-center p-2 min-w-[100px]">Manager</td>
                        <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                            <button v-if="dash_d_code.RANK42 != '0' && dash_d_code.RANK42 !=''" type="button" @click="getBonusLeglist('42',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">
                                {{ (dash_d_code.RANK42) ?? "" }}
                            </button>
                            <button v-else class="cursor-default">
                                -
                            </button>
                        </td>
                    </tr>
                    <tr class="bg-[#f0f4ff] text-center text-slate-800">
                        <td class="text-center p-2 min-w-[100px]">Bronze</td>
                        <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                            <button v-if="dash_d_code.RANK10 != '0' && dash_d_code.RANK10 !=''" type="button" @click="getBonusLeglist('10',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">
                                {{ (dash_d_code.RANK10) ?? "" }}
                            </button>
                            <button v-else class="cursor-default">
                                -
                            </button>
                        </td>

                        <td class="text-center p-2 min-w-[100px]">General Manager</td>
                        <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                            <button v-if="dash_d_code.RANK45 != '0' && dash_d_code.RANK45 !=''" type="button" @click="getBonusLeglist('45',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">
                                {{ (dash_d_code.RANK45) ?? "" }}
                            </button>
                            <button v-else class="cursor-default">
                                -
                            </button>
                        </td>

                    </tr>
                    <tr class="bg-[#f0f4ff] text-center text-slate-800">
                        <td class="text-center p-2 min-w-[100px]">Silver</td>
                        <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                            <button v-if="dash_d_code.RANK20 != '0' && dash_d_code.RANK20 !=''" type="button" @click="getBonusLeglist('20',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">
                                {{ (dash_d_code.RANK20) ?? "" }}
                            </button>
                            <button v-else class="cursor-default">
                                -
                            </button>
                        </td>

                        <td class="text-center p-2 min-w-[100px]">Director</td>
                        <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                            <button v-if="dash_d_code.RANK50 != '0' && dash_d_code.RANK50 !=''" type="button" @click="getBonusLeglist('50',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">
                                {{ (dash_d_code.RANK50) ?? "" }}
                            </button>
                            <button v-else class="cursor-default">
                                -
                            </button>
                        </td>

                    </tr>
                    <tr class="bg-[#f0f4ff] text-center text-slate-800">
                        <td class="text-center p-2 min-w-[100px]">Gold</td>
                        <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                            <button v-if="dash_d_code.RANK30 != '0' && dash_d_code.RANK30 !=''" type="button" @click="getBonusLeglist('30',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">
                                {{ (dash_d_code.RANK30) ?? "" }}
                            </button>
                            <button v-else class="cursor-default">
                                -
                            </button>
                        </td>

                        <td class="text-center p-2 min-w-[100px]">Vice President</td>
                        <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                            <button v-if="dash_d_code.RANK60 != '0' && dash_d_code.RANK60 !=''" type="button" @click="getBonusLeglist('60',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">
                                {{ (dash_d_code.RANK60) ?? "" }}
                            </button>
                            <button v-else class="cursor-default">
                                -
                            </button>
                        </td>
                    </tr>

                    <tr class="bg-[#f0f4ff] text-center text-slate-800">
                        <td colspan="2" class="text-center p-2 min-w-[100px]"></td>
                        <td colspan="1" class="text-center p-2 min-w-[100px]">President</td>
                        <td colspan="1" class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                            <button v-if="dash_d_code.RANK70 != '0' && dash_d_code.RANK70 !=''" type="button" @click="getBonusLeglist('70',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">
                                {{ (dash_d_code.RANK70) ?? "" }}
                            </button>
                            <button v-else class="cursor-default">
                                -
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div  class="w-full pt-4 overflow-x-scroll block sm:overflow-hidden"> <!--xl:scrollbar-hide lg:scrollbar-hide-->
            <table class="border-separate border-spacing-2 w-full table-auto">
                <thead>
                <tr>
                    <th colspan="5" class="text-center text-base py-2 bg-[#2e5db3] text-white">{{  i18n.myoffice.Commission.statusOfJobClass }}</th> <!--나의 직급현황-->
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{  i18n.myoffice.Commission.leg }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{  i18n.myoffice.Commission.member }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{  i18n.myoffice.Commission.totalByLeg }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{  i18n.myoffice.Rank }}</th>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">1</td>
                    <td class="text-center p-2 min-w-[100px]">{{dash_d_code.LEG_N1 ? dash_d_code.LEG_N1: ""}}</td>
                    <td class="text-center p-2 min-w-[100px]">
                        {{ formatPrice(dash_d_val.LEG_N1 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px]" rowspan="7">{{ dash_d_val.RANK_NEW ?? 0}}</td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">2 </td>
                    <td class="text-center p-2 min-w-[100px]]">{{dash_d_code.LEG_N2 ?dash_d_code.LEG_N2: ""}}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.LEG_N2 ?? 0,1)}}</td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">3 </td>
                    <td class="text-center p-2 min-w-[100px]">{{dash_d_code.LEG_N3 ?dash_d_code.LEG_N3: ""}}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.LEG_N3 ?? 0,1)}}</td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">4 </td>
                    <td class="text-center p-2 min-w-[100px]">{{dash_d_code.LEG_N4 ?dash_d_code.LEG_N4: ""}}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.LEG_N4 ?? 0,1)}}</td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">5 </td>
                    <td class="text-center p-2 min-w-[100px]">{{dash_d_code.LEG_N5 ?dash_d_code.LEG_N5: ""}}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.LEG_N5 ?? 0,1)}}</td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]" colspan="2">
                        {{  i18n.myoffice.dashboard.otherLeg }}
                    </td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.LEG_E ?? 0,1)}}</td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]" colspan="2">{{  i18n.myoffice.dashboard.totalDownlineSalesPV }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.LEG_T ?? 0,1)}}</td>
                </tr>
                <tr class="text-slate-800">
                    <td rowspan="2" class="min-w-[100px] pt-4" colspan="4">
                        <p>{{  i18n.myoffice.dashboard.statusOfJobClassMsg1 }}</p>
                        <p>{{  i18n.myoffice.dashboard.statusOfJobClassMsg2 }}</p>
                    </td>
                </tr>

                </tbody>
            </table>
        </div>

        <div v-if="false" class="w-full pt-4 overflow-x-scroll block sm:overflow-hidden"> <!--xl:scrollbar-hide lg:scrollbar-hide-->
            <table class="border-separate border-spacing-2 w-full table-auto">
                <thead>
                <tr>
                    <th colspan="5" class="text-center text-base py-2 bg-[#2e5db3] text-white">{{  i18n.myoffice.Commission.affiliatedSalesInformation }}</th> <!--산하 매출정보-->
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.monthly}}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.orderPV}}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.refundedPV}}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.totalPv }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.Commission.totalByLeg }}</th> <!--총 레그 수-->
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.thisMonth}}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_A1 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_R1 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV1 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500"><button type="button" @click="getLegList(0)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.D_LEG_CNT1 ?? 0 }}</button></td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.prevMonth}}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_A2 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_R2 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV2 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px]"><button type="button" @click="getLegList(-1)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.D_LEG_CNT2 ?? 0 }}</button></td>
                </tr>
                <!--                <tr class="text-left">
                                  <td colspan="5" class="text-[#e1253f] p-2 text-sm">* 총 레그 수 클릭 시 해당 레그의 회원 확인 가능</td>
                                </tr>-->
                </tbody>
            </table>
            <p class="text-[#e1253f] p-2 text-sm">{{  i18n.myoffice.Commission.message2 }}</p>
            <!--* 총 레그 수 클릭 시 해당 레그의 회원 확인 가능-->

        </div>
    </div>

</template>
<script>
import numeral from "numeral";

export default {
    name: "BusinessStatus",
    props: ['dash_d_val', 'dash_d_code'],
    components: {},
    data: () => ({
        i18n: window.i18n,
    }),
    created() {

        // console.group("============= AAA ================")
        // console.log("dash_d_val")
        // console.dir(this.dash_d_val)
        //
        // console.log(this.dash_d_val.LEG_N1)
        // console.log(this.dash_d_val['LEG_N1'])
        //
        // console.log(typeof this.dash_d_val)
        // console.log("dash_d_code")
        // console.dir(this.dash_d_code)
        // console.log(typeof this.dash_d_code)
        // console.groupEnd("============= AAA ================")
    },
    methods: {

        formatPrice(value, digit) {
            let val = (value / 1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        getLegList(month){//본인 산하 직추천 명단
            this.$emit('getLegList', month);
        },
        getBonusLeglist(rank,type){//본인 산하 직추천 명단
            this.$emit('getBonusLeglist', rank, type);
        },
        goUrl(sid){
            location.href='/MyOrderLegs/'+sid;
        },
        numberFormat(val, type) {

            if ( val === ''){
                return '';
            }
            if ( val === null || val === undefined ){
                return 0;
            }
            if (type === 'dollar') {
                return numeral(val).format('$0,0.00')
            }
            return String(val).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        },
    }
}
</script>

<style scoped>
.cursor-default {
    cursor: default !important;
}
</style>
