<template>
    <loading :active="false"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <div class="member-search">

        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
            <section class="myo_condition_box">
                <dl class="myo_search_list">
                    <dd class="myo_search_list">
                        <input type="hidden" name="order" id="order">
                        <input type="hidden" name="sort" id="sort">
                        <section class="myo_condition_box">
                            <dateForm @set_s_date="set_s_date" @set_e_date="set_e_date" :start_plusday="1"></dateForm>
                        </section>
                    </dd>
                </dl>
                <div class="myo_condition_search">
                    <button type="reset" onclick="" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                    <button type="submit" id="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
                </div>

            </section>
        </form>
    </div>

    <div class="summary" style="clear: both;"><strong>Total</strong> <em>{{ total_count }}</em></div>

<!--    <p class="p_alert">{{ i18n.myoffice.myofficeAlert.ClickStarButtonNotice }}</p>-->


    <input type=hidden name=exec_mode value=''>

    <div class="w-full flex mt-2 mb-2">
      <button @click="pointHistory('')" class="border-r border-gray-400 focus:rounded-t-md hover:rounded-t-md focus:bg-[#172e59] hover:bg-[#172e59] text-[#0e1b35] focus:text-white hover:text-white xl:text-base lg:text-base text-sm xl:px-6 lg:px-6 px-2 xl:py-4 lg:py-4 py-2 xl:w-1/4 lg:w-1/4 w-1/3 break-keep">{{ i18n.myoffice.Mypoint.totalDetails }}</button>
      <button @click="pointHistory('1')" class="border-r border-gray-400 focus:rounded-t-md hover:rounded-t-md focus:bg-[#172e59] hover:bg-[#172e59] text-[#0e1b35] focus:text-white hover:text-white xl:text-base lg:text-base text-sm xl:px-6 lg:px-6 px-2 xl:py-4 lg:py-4 py-2 xl:w-1/4 lg:w-1/4 w-1/3 break-keep">{{ i18n.myoffice.Mypoint.balanceDetails }}</button>
      <button @click="pointHistory('2')" class="focus:rounded-t-md hover:rounded-t-md focus:bg-[#172e59] hover:bg-[#172e59] text-[#0e1b35] focus:text-white hover:text-white xl:text-base lg:text-base text-sm xl:px-6 lg:px-6 px-2 xl:py-4 lg:py-4 py-2 xl:w-1/4 lg:w-1/4 w-1/3 break-keep">{{ i18n.myoffice.Mypoint.redeemedDetails }}</button>
    </div>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table id="point_list" class="border-separate border-spacing-2 table-auto w-full" >
            <thead>
              <tr class="bg-[#172e59] text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px] whitespace-nowrap">{{ i18n.myoffice.Mypoint.no }}</th>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px] whitespace-nowrap">{{ i18n.myoffice.Mypoint.date }}</th>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px] whitespace-nowrap">{{ i18n.myoffice.Mypoint.type }}</th>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px] whitespace-nowrap">{{ i18n.myoffice.Mypoint.points }}</th>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px] whitespace-nowrap">{{ i18n.myoffice.Mypoint.remark }}</th>
              </tr>
            </thead>
            <tbody>

              <tr v-for="(Value,index) in List" class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                  <th class="text-center p-2 min-w-[100px] whitespace-nowrap" >{{ total_count - ((page-1)*20 + index) }}</th>
                  <!--<th class="text-center p-2 min-w-[100px] whitespace-nowrap">{{ moment(Value.reg_date).format('MM/DD/YY') }}</th>-->
                  <th class="text-center p-2 min-w-[100px] whitespace-nowrap">{{ Value.reg_date.substring(4,6)+"/"+Value.reg_date.substring(6)+"/"+Value.reg_date.substring(0,4) }}</th>
                  <th class="text-center p-2 min-w-[100px] whitespace-nowrap">{{ Value.kind =="1" ? "Accumulated Points" : "Points Redeemed" }}</th>
                  <th class="text-center p-2 min-w-[100px] whitespace-nowrap">{{ formatPrice(Value.pv,2) }}</th>
                  <th class="text-center p-2 min-w-[100px] whitespace-nowrap">{{ Value.remark}}</th>
              </tr>
              <!-- 검색 결과 없을 경우 -->
              <tr class="h-2" v-if="total_count == 0">
                <td class="text-left md:text-center pl-5 py-5" colspan="13">{{ this.list_msg }}</td>
              </tr>
            </tbody>
        </table>
    </div>
    <div style="display: flex; align-items: center; justify-content: center;" class="mt-4">
        <v-pagination
            v-model="page"
            :pages=total_page
            :range-size="1"
            active-color="#DCEDFF">
        </v-pagination>
    </div>
</template>

<script>

import dateForm from '../../searchForm/dateForm.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";
import moment from 'moment';
export default {
    name: "MyPointHistory",
    components: {
        dateForm,
        VPagination,
        Loading
    },setup() {
        //
    },
    el: '#tabs',
    data: () => ({
        activetab: '1',
        i18n: window.i18n,
        title: [],
        products: [],
        page : 1,
        total_page : 1,
        total_count : 0,
        s_date: date_half_month,
        e_date: date_today,
        checkCondition : false,
        List : [],
        cate : "",
        loading : false,
        list_msg : "Click 'Search' to view the data",
    }),
    created() {

        this.loading = true;
      //하트 내역
        axios.post(window.Laravel.back_url+'/api/getMyPointHistory',{
          s_date : this.s_date,
          e_date : this.e_date,
          userid:window.Laravel.user
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {

            this.total_count = response.data.length;
            this.List = response.data;
            this.loading = false;
        });
    },
    mounted() {
         this.userid = window.Laravel.user;
    },
    methods:{
        submitForm : function (){
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/getMyPointHistory',{
                s_date : this.s_date,
                e_date : this.e_date,
                userid:window.Laravel.user,
                type:this.cate,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
              if(response.data.length == 0){
                  VueSimpleAlert.alert("There are no search results","","",{confirmButtonColor: '#0abab5'});
                  this.list_msg = "No data";
                }

                this.total_count = response.data.length;
                this.List = response.data;
                this.loading = false;
            });
        },
        set_s_date(s_date){
            this.s_date = s_date;
        },
        set_e_date(e_date){
            this.e_date = e_date;
        },
        notCheck(id){
            return this.order != id ? true : false;
        },
        asconCheck(id){
            return this.order == id && this.sort == "asc" ? true : false;
        },
        descCheck(id){
            return this.order == id && this.sort == "desc" ? true : false;
        },
        formatPrice(value,digit) {
          let val = (value/1).toFixed(digit).replace('.', '.')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        pointHistory(type){
          //'' : 전체 , 1: 누적, 2: 사용
           this.cate = type;
            this.submitForm();

        },
        moment: function () {
          return moment();
        },

    }
}
</script>

<style scoped>

</style>
