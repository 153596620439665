<template>
    <div v-for="(popup, index) in popup_list" :key="index">
        <div class="modal fixed w-full h-full inset-0 z-[902]" v-if="popup.current">
            <div :class=" (index === 0) ? 'top-[55%] left-2/4 -translate-x-1/2 -translate-y-1/2': 'top-[45%] left-2/4 sm:left-[60%] -translate-x-1/2 -translate-y-1/2' " class="relative max-w-[720px] w-full max-h-[800px] p-4 opacity-100 overflow-y-auto z-[902]">
                <a :href="popup.link">
                    <img :src="popup.href" alt="badge" style="z-index: 1;"/>
                </a>
                <div class="flex justify-between w-auto p-1 bg-gray-50">
                    <div class="text-xs mt-1 ml-1">
                        <input type="checkbox" v-model="popup.selected"> Do not show again
                    </div>
                    <div class="cursor-pointer inline-block text-xs px-2 py-0.5 border border-gray-500" @click="set_modal_hide(popup.no, index)">close</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {useMobileDetection} from "vue3-mobile-detection";

let numeral = require("numeral");

export default {
    name: "etcPopup",
    setup() {
      const { isMobile } = useMobileDetection();   // 모바일 여부 체크
      // if( isMobile())  itemsToShow  = 1;
      // return { itemsToShow };
    },
    data: () => ({
        popup_list : [],
        popup_checked : []
    }),
    created() {
      /*
      axios.post(process.env.MIX_BACK_URL + '/api/getPopupEtcController', {
      }).then(response => {
        if(response.data.length != 0){
          this.set_modal_flag(true);//1
          response.data.forEach((value, index) => {
            this.popup_list.push({ no:value.no, name: value.orgname, href: 'https://cdnus.gcoop.me/storage/popup/data/'+value.filename, current: false, link: value.link });
          });
        }else if(response.data.length == 0){
          this.set_modal_flag(false);//1

        }
      });
      */
        // if(localStorage.getItem('20240408_1') !=='N') this.popup_list.push({ no:'20240408_1', name: 'autoship', href: 'https://cdnus.gcoop.me/us/etc_popup/start_autoship.png', current: true, selected: false, link: 'https://usa.gcoop.com/MyNotice/28387'});
        // if(localStorage.getItem('20240408_2') !=='N')  this.popup_list.push({ no:'20240408_2', name: 'new_business', href: 'https://cdnus.gcoop.me/us/etc_popup/start_new_business.png', current: true, selected: false, link: 'https://usa.gcoop.com/MyNotice/28272'});
    },

    methods : {
        set_modal_hide(no, index){
            if(this.popup_list[index].selected) {
                localStorage.setItem(no, 'N'); // 로컬 스토리지에 저장
            }
            this.popup_list[index].current = false;
        },
    },
}
</script>

<style scoped>

</style>
