<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true">
    </loading>

    <article class="myo_search_area">
      <section class="myo_condition_box">
          <section class="flex_box_quad">
              <nameForm></nameForm>
              <stepForm></stepForm>
          </section>
          <section class="" style="width: 100%">
            <rankCompressForm  @setSelected="setSelected"></rankCompressForm>
          </section>
          </section><!-- // myo_condition_box -->
          <div class="myo_condition_search">
            <button type="reset" class="page_reset_btn" onclick="location.replace('/MyMemberLegBoxCompression')">{{ i18n.myoffice.Refresh }}</button>
            <button type="submit" v-on:click="searchLeg" class="search_btn">{{ i18n.myoffice.Search }}</button>
          </div>
          </article>

          <div id="search_member-wrap" v-show="search_member_div">
            <i class="fa fa-times-circle btnCloseLayer" aria-hidden="true"></i>
            <div id="member-list">
            <table class="list_members">
                <thead>
                <tr>
                    <th>{{ i18n.myoffice.jsALert.tablecon1 }}</th>
                    <th>{{ i18n.myoffice.jsALert.tablecon2 }}</th>
                    <th>{{ i18n.myoffice.jsALert.tablecon3 }}</th>
                    <th>{{ i18n.myoffice.jsALert.tablecon5 }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="Value in legMember" class='list_of_members sel_user' data-id='{{ Value.user_id }}' v-on:click="sel_user( Value.user_id )">
                    <td>{{ Value.level }}</td>
                    <td>{{ Value.user_id }}({{ Value.user_name }})</td>
                    <td>{{ Value.rank }}</td>
                    <td>{{ Value.r_info }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div style="text-align: right;">
        <button class="reg_notice_btn" href="#reg_notice_box">[How to Use the Box Chart]</button>
    </div>
    <!-- 계보도 노티스 팝업 -->
    <div id="reg_notice_box" class="reg_notice">
        <div id="reg_notice_dim"></div>
        <div class="reg_notice_cont">
            <div class="reg_notice_head">
                <span class="reg_close_btn">×</span>
                <h2>[How to Use the Box Chart]</h2>
            </div>
            <div class="reg_notice_body">
                <div class="red_cont_box">
                    <p>· The Box Chart generally shows up to two (2) generations, including your own, by default.<br></p>
                    <p>· Change settings and click ‘Search’ to view additional information.<br></p>
                    <p>· If you select ‘0’ and click ‘Search’, you will be able to view the whole Box Chart.<br></p>
                    <p>· For PC users, you can zoom in and out of the Box Chart by using the wheel on your mouse (For those using mobile devices, click the + button on the bottom of the page/window).<br></p>
                    <p>· You can view up to 2,000 people on the Box Chart. You can view additional information/GCOOPERS after changing your settings.<br></p>
                    <br><br>
                </div>
            </div>
        </div>
    </div><!-- // reg_notice -->

    <div id="tree_wrap">
        <div style="position:absolute;  z-index: 999999 ">
            <LegDetail @goTree="goTree" ref="showDetailRequest"></LegDetail>
        </div>
        <div id="tree_viewer">
            <div id="chart_div" ref="chart_div"></div>
        </div>
    </div>
</template>

<script>
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import nameForm from '../../searchForm/nameForm.vue'
import stepForm from '../../searchForm/stepForm.vue'
import legForm from '../../searchForm/legForm.vue'
import OrgChart from '@balkangraph/orgchart.js'
import LegDetail from './LegDetail.vue'
import rankCompressForm from '../../searchForm/rankCompressForm.vue'

export default {
    name: "MyMemberLegBoxCompress",
    components : {
        nameForm,
        stepForm,
        Loading,
        legForm,
        LegDetail,
        rankCompressForm,
    },
    data: () => ({
        i18n: window.i18n,
        nodes : [],
        legMember : [],
        search_member_div : false,
        loading : false,
        search_tag : "",
        node_id : "",
        chart : "",
        rank_cd : "40,50,60,70",
        tree_node : "UNI"
    }),
    props:['level','down_name'],
    methods: {
        searchLeg(){
            this.loading = true;
            this.search_member_div = false;
            axios.post(window.Laravel.back_url+'/api/leg/search',{
                level : level.value,
                node : this.tree_node,
                keyword : down_name.value,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if(response.data.count > 0){
                    this.search_member_div = true;
                    this.legMember = response.data.data;
                    this.loading = false;

                }else{
                    this.submitForm();
                }
            });
        },
        goTree : function (userid){
            down_name.value = userid;
            this.submitForm();
        },
        setSelected(rank){
            this.rank_cd = "";
            rank.forEach((value, index) => {
                this.rank_cd += value.id
                if(rank.length != index+1){
                    this.rank_cd += ",";
                }
            });
        },
        submitForm : function (){
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/box',{
                level : level.value,
                tree_node : this.tree_node,
                uid : down_name.value,
                mode : "boxCompress",
                rank_cd : this.rank_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {

                if(response.data.ret == "1") {
                    this.nodes = [];

                    response.data.tree_list.forEach((value, index) => {
                        var tags = value.consumer_t == "Y" ? "consumer_t" : "ori";
                        this.nodes.push({
                            id : value.userid,
                            pid : value.up_id,
                            tags : [tags],
                            이름: value.username,
                            아이디: value.userid,
                            직급 : value.rank_cd,
                            rank_cd: value.rank_cd,
                            위치: value.ab_pos,
                            img: value.bz_img,
                            favor: value.status_fd,
                            가입일: value.reg_date,
                            gp_statuss: value.reg_date,
                            maintain: value.labels,

                        });

                    });

                    this.mytree(this.$refs.chart_div, this.nodes,this.$refs.showDetailRequest)
                    this.loading = false;

                }
            });

        },
        mytree: function(domEl, x, showDetailRequest) {
            OrgChart.templates.consumer_t = Object.assign({}, OrgChart.templates.ana);
            OrgChart.templates.consumer_t.size = [200, 130];
            OrgChart.templates.consumer_t.node = '<rect x="0" y="0" width="200" height="120" fill="#ff6a6a" stroke-width="1" stroke="#1C1C1C"></rect>';


            OrgChart.templates.consumer_t.ripple = {
                radius: 100,
                color: "#0890D3",
                rect: null
            };

            OrgChart.templates.consumer_t.favor = '<text style="font-size: 12px;" fill="yellow" x="50" y="14" text-anchor="middle">{val}</text>';
            OrgChart.templates.consumer_t.field_3 = '<text style="font-size: 12px;" fill="#ffffff" x="105" y="15" text-anchor="middle">{val}</text>'; //1
            OrgChart.templates.consumer_t.field_1 = '<text style="font-size: 12px;" fill="#ffffff" x="105" y="30" text-anchor="middle">{val}</text>';//2
            OrgChart.templates.consumer_t.field_0 = '<text style="font-size: 12px;" fill="#ffffff" x="105" y="60" text-anchor="middle">{val}</text>';//4
            OrgChart.templates.consumer_t.field_2 = '<text style="font-size: 12px;" fill="#ffffff" x="105" y="45" text-anchor="middle">{val}</text>';//3
            OrgChart.templates.consumer_t.field_4 = '<text style="font-size: 12px;" fill="#ffffff" x="105" y="90" text-anchor="middle">{val}</text>';//5
            OrgChart.templates.consumer_t.field_5 = '<text style="font-size: 12px;" fill="#DC143C" x="105" y="60" text-anchor="middle">{val}</text>';//3
            OrgChart.templates.consumer_t.field_6 = '<text style="font-size: 12px;" fill="#87CEEB" x="105" y="90" text-anchor="middle">{val}</text>';//5
            OrgChart.templates.consumer_t.field_7 = '<text style="font-size: 12px;" fill="#ffffff" x="105" y="75" text-anchor="middle">{val}</text>';//6
            OrgChart.templates.consumer_t.field_8 = '<text style="font-size: 12px;" fill="#32CD32" x="105" y="105" text-anchor="middle">{val}</text>';//7

            //OrgChart.templates.consumer_t.img_0 = '<image preserveAspectRatio="" clip-path="url(#ulaImg)" xlink:href="{val}" x="175" y="3" width="16" height="16"></image>';

            OrgChart.templates.myTemplate = Object.assign({}, OrgChart.templates.ana);
            OrgChart.templates.myTemplate.size = [200, 130];
            OrgChart.templates.myTemplate.node = '<rect x="0" y="0" width="200" height="120" fill="#4D4D4D" stroke-width="1" stroke="#1C1C1C"></rect>';


            OrgChart.templates.myTemplate.ripple = {
                radius: 100,
                color: "#0890D3",
                rect: null
            };

            OrgChart.templates.myTemplate.favor = '<text style="font-size: 12px;" fill="yellow" x="50" y="14" text-anchor="middle">{val}</text>';
            OrgChart.templates.myTemplate.field_3 = '<text style="font-size: 12px;" fill="#ffffff" x="105" y="15" text-anchor="middle">{val}</text>'; //1
            OrgChart.templates.myTemplate.field_1 = '<text style="font-size: 12px;" fill="#ffffff" x="105" y="30" text-anchor="middle">{val}</text>';//2
            OrgChart.templates.myTemplate.field_0 = '<text style="font-size: 12px;" fill="#ffffff" x="105" y="60" text-anchor="middle">{val}</text>';//4
            OrgChart.templates.myTemplate.field_2 = '<text style="font-size: 12px;" fill="#ffffff" x="105" y="45" text-anchor="middle">{val}</text>';//3
            OrgChart.templates.myTemplate.field_4 = '<text style="font-size: 12px;" fill="#ffffff" x="105" y="90" text-anchor="middle">{val}</text>';//5
            OrgChart.templates.myTemplate.field_5 = '<text style="font-size: 12px;" fill="#DC143C" x="105" y="60" text-anchor="middle">{val}</text>';//3
            OrgChart.templates.myTemplate.field_6 = '<text style="font-size: 12px;" fill="#87CEEB" x="105" y="90" text-anchor="middle">{val}</text>';//5
            OrgChart.templates.myTemplate.field_7 = '<text style="font-size: 12px;" fill="#ffffff" x="105" y="75" text-anchor="middle">{val}</text>';//6
            OrgChart.templates.myTemplate.field_8 = '<text style="font-size: 12px;" fill="#32CD32" x="105" y="105" text-anchor="middle">{val}</text>';//7

            //OrgChart.templates.myTemplate.img_0 = '<image preserveAspectRatio="" clip-path="url(#ulaImg)" xlink:href="{val}" x="175" y="3" width="16" height="16"></image>';


            OrgChart.templates.searchTemplate = Object.assign({}, OrgChart.templates.ana);
            OrgChart.templates.searchTemplate.size = [200, 120];
            OrgChart.templates.searchTemplate.node = '<rect x="0" y="0" width="200" height="120"  fill="#dcf4dc" stroke-width="1" stroke="#aaa"></rect>';


            OrgChart.templates.searchTemplate.ripple = {
                radius: 100,
                color: "#0890D3",
                rect: null
            };

            OrgChart.templates.searchTemplate.favor = '<text style="font-size: 12px;" fill="yellow" x="10" y="14" text-anchor="middle">{val}</text>';
            OrgChart.templates.searchTemplate.field_3 = '<text style="font-size: 12px;" fill="black" x="105" y="15" text-anchor="middle">{val}</text>'; //1
            OrgChart.templates.searchTemplate.field_1 = '<text style="font-size: 12px;" fill="black" x="105" y="30" text-anchor="middle">{val}</text>';//2
            OrgChart.templates.searchTemplate.field_0 = '<text style="font-size: 12px;" fill="black" x="105" y="60" text-anchor="middle">{val}</text>';//4
            OrgChart.templates.searchTemplate.field_2 = '<text style="font-size: 12px;" fill="black" x="105" y="45" text-anchor="middle">{val}</text>';//3
            OrgChart.templates.searchTemplate.field_4 = '<text style="font-size: 12px;" fill="black" x="105" y="90" text-anchor="middle">{val}</text>';//5
            OrgChart.templates.searchTemplate.field_5 = '<text style="font-size: 12px;" fill="#DC143C" x="105" y="60" text-anchor="middle">{val}</text>';//3
            OrgChart.templates.searchTemplate.field_6 = '<text style="font-size: 12px;" fill="#87CEEB" x="105" y="90" text-anchor="middle">{val}</text>';//5
            OrgChart.templates.searchTemplate.field_7 = '<text style="font-size: 12px;" fill="black" x="105" y="75" text-anchor="middle">{val}</text>';//7
            OrgChart.templates.searchTemplate.field_8 = '<text style="font-size: 12px;" fill="#32CD32" x="105" y="105" text-anchor="middle">{val}</text>';//7
            //OrgChart.templates.searchTemplate.img_0 = '<image preserveAspectRatio="" clip-path="url(#ulaImg)" xlink:href="{val}" x="175" y="3" width="16" height="16"></image>';
            this.chart = new OrgChart (domEl, {
                nodes: x,
                nodeBinding: {
                    field_0: "이름",
                    field_1: "아이디",
                    phone: "name",
                    field_2: "직급",
                    field_3: "위치",
                    field_4: "유지",
                    field_5: "탈퇴",
                    field_6: "GP유지",
                    field_8: "maintain",
                    field_7: "가입일",
                    //img_0: "img",
                    favor: "favor"
                },
                tags: {
                    "search": {
                        template: "searchTemplate"
                    },
                    "ori": {
                        template: "myTemplate"
                    },
                    "consumer_t": {
                        template: "consumer_t"
                    },
                },
                nodeMouseClick: OrgChart.action.init,
                enableSearch: false,
                showXScroll: OrgChart.scroll.visible,
                showYScroll: OrgChart.scroll.visible,
                toolbar: {
                    zoom: true,
                    fit: true,
                },
            });
            this.chart.onNodeClick((node) => {
                showDetailRequest.setDetailData(node.node.id);
                this.loading = false;
            });

        },
        sel_user(user_id){
            down_name.value = user_id;
            this.submitForm();

        }
    },

    mounted(){
        this.submitForm();
    }
}

</script>

<style scoped>

</style>
