<template>

    <div class="member-search">
        <loading :active="loading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage"></loading>
        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
            <input type="hidden" name="order" id="order">
            <input type="hidden" name="sort" id="sort">
            <section class="myo_condition_box">

                <section class="flex_box_quad">
                    <productNameForm></productNameForm>
                </section>

            </section>

            <div class="myo_condition_search">
                <button type="button" v-on:click="reset" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                <button type="submit" id="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
            </div>
        </form>
    </div>

    <p class="p_alert">You cannot enter a sales quantity that exceeds the inventory quantity. After entering the sales quantity, please select the consumer and register.</p>
    <!-- 재고 수량을 초과한 판매 수량 입력은 불가합니다. 판매 수량을 입력하신 뒤, 소비자를 선택하여 등록해 주시기 바랍니다. -->

    <section>
        <form name="custom_order" id="custom_order" class="justify-center w-full mx-auto" method="post" v-on:submit.prevent="submitOrder">
        <div class="w-full overflow-x-scroll sm:overflow-hidden min-h-[300] block">
            <table class="border-separate w-full table-auto text-slate-800">
                <tbody>
                <!-- 제품명 소비자가 재고수량 판매수량 판매금액 -->
                <tr>
<!--                    <th class="bg-[#172e59] text-center text-white p-3">선택</th>-->
                    <th class="bg-[#172e59] text-center text-white p-3">{{i18n.shop.mypage.ProductName}}</th>
                  <th class="bg-[#172e59] text-center text-white p-3">{{i18n.shop.shopping.RetailPrice}}</th>
                    <th class="bg-[#172e59] text-center text-white p-3">Inventory Quantity</th>
                    <th class="bg-[#172e59] text-center text-white p-3">Input Sales Quantity</th>
                  <th class="bg-[#172e59] text-center text-white p-3">{{i18n.shop.shopping.SalePrice}}</th>
                </tr>
                <tr v-for="(Value,index) in productList" :key="Value.pdt_cd" class="bg-[#f0f4ff] text-center">
<!--                    <th class="text-center p-2 min-w-[100px] max-w-[100px]"><input type="hidden" name="pdt_cd" value="{{ Value.pdt_cd }}">{{ Value.pdt_cd }}</th>-->
                    <td class="text-center p-2 min-w-[100px]">{{ Value.pdt_name }}</td>
                    <td class="text-center p-2 min-w-[100px]">${{ Value.retail_amt }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ Value.now_qty }}</td>
                    <td class="text-center p-2 min-w-[100px]"><input type="number" name="qty" v-model="order_qty[index]" @input="changeQty($event,index)" class="border w-20 h-[35px] border-[#bfbfbf] rounded-lg text-center"></td>
                    <td class="text-center p-2 min-w-[100px]">
                        <span v-if="!isNaN(parseInt(order_qty[index]))">
                            ${{ Value.retail_amt*order_qty[index] }}
                        </span>
                    </td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center">
                    <th class="bg-[#172e59] text-center text-white p-2 min-w-[100px]" colspan="3">
                        <span v-if="order_qty_length !== 0">
                            {{ order_qty_length }} Qty
                        </span>
                      {{i18n.myoffice.total}}</th>
                    <td class="text-center p-2 min-w-[100px]">
                        <span v-if="order_qty.reduce((partialSum, a) => {return parseInt(partialSum += a) }, 0) >= 0">
                            {{ order_qty.reduce((partialSum, a) => {return parseInt(partialSum += a) }, 0) }}
                        </span>
                    </td>
                    <td class="text-center p-2 min-w-[100px]">
                        ${{order_info.total_amt}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="w-full overflow-x-scroll sm:overflow-hidden block">
            <table class="border-separate w-full table-auto text-slate-800">
                <tbody>
                <!-- 소비자명 휴대폰번호 이메일 배송방법 -->
                <tr class="bg-[#f0f4ff] text-center">
                    <th class="text-center p-2 bg-[#172e59] text-white min-w-[100px]">{{i18n.myoffice.dashboard.consumerName}}</th>
                    <td class="text-left p-2">
                        <!-- 등록 또는 검색 가능하도록 적용 -->
                        <div class="myo_search_box">
                            <input type="hidden" id="cs_id" v-model="order_info.cs_id">
                            <input type="text" id="cs_name" v-model="order_info.cs_name" class="myo_search_input" v-on:keyup.enter="openForm" />
                            <button type="button" class="myo_search_btn sid-wrap" @click="openForm"><i class="fas fa-search"></i></button>
                        </div>
                    </td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center">
                    <th class="text-center p-2 bg-[#172e59] text-white min-w-[100px]">{{i18n.myoffice.autoShip.CellPhoneNumber}}</th>
                    <td class="text-left p-2"><input type="text" v-model="order_info.mobile" name="mobile" class="border w-100 h-[35px] border-[#bfbfbf] rounded-lg text-left" readonly></td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center">
                    <th class="text-center p-2 bg-[#172e59] text-white min-w-[100px]">Email</th>
                    <td class="text-left p-2"><input type="text" v-model="order_info.email" name="email" class="border w-100 h-[35px] border-[#bfbfbf] rounded-lg text-left" readonly></td>
                </tr>
                <!-- 배송방법 직접수령 - 택배수령 -->
                <tr class="bg-[#f0f4ff] text-center">
                    <th class="text-center p-2 bg-[#172e59] text-white min-w-[100px]">Shipping Method</th>
                    <td class="text-left p-2">
                      <div><input type="radio" value="now" name="sendType" id="sendType1" v-model="toggleSendType" />&nbsp;<label for="sendType1">In-person drop off</label></div>
                      <div><input type="radio" value="reserve" name="sendType" id="sendType2" v-model="toggleSendType" />&nbsp;<label for="sendType2">Shipping delivery</label></div>
                    </td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center" v-if="sendType2">
                  <th class="text-center p-2 bg-[#172e59] text-white min-w-[100px]">Address</th>
                  <td class="text-left p-2">
                    <input type="text" class="border xl:w-2/3 lg:w-2/3 w-full h-[35px] border-[#bfbfbf] rounded-lg text-left" v-model="order_info.address">
                  </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center border-gray-500 py-3 flex justify-center mt-3">
            <button type="submit" id="register_submit" class="border border-gray-500 px-8 py-2 bg-[#172e59] text-white text-base inline-block w-auto rounded-xl flex items-center justify-between hover:bg-[#254a8f]">Click to Register</button>
        </div>
        </form>
    </section>

  <!--소비자 정보 모달 팝업 -->
    <div class="fixed w-full h-full bg-black/80 z-[10] top-0 left-0 cursor-pointer" v-if="showModal" @click="closeForm"></div>
    <div class="fixed top-2/4 left-2/4 z-[11] translate-x-[-50%] translate-y-[-50%] bg-white p-5 max-w-[750px] w-10/12 h-96 rounded-md shadow-zinc-500/40 shadow-xl overflow-scroll" v-if="showModal">
      <div>
        <div class="flex flex-row justify-between items-center mb-3 px-2 py-3">
          <h3 class="xl:text-lg text-lg text-[#0e1b35]">Consumer Search</h3>
          <button class="ml-4" @click="closeForm">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 inline-block cursor-pointer fill-[#091223]" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
          </button>
        </div>
        <div class="overflow-x-scroll sm:overflow-hidden block xl:scrollbar-hide lg:scrollbar-hide">
          <table class="w-full border-separate break-keep">
            <thead>
            <tr class="">
              <th class="bg-[#172e59] text-center text-white p-3 break-keep min-w-[150px]">Consumer Name</th>
              <th class="bg-[#172e59] text-center text-white p-3 break-keep min-w-[150px]">Mobile Number</th>
              <th class="bg-[#172e59] text-center text-white p-3 break-keep min-w-[250px]">Address</th>
              <th class="bg-[#172e59] text-center text-white p-3 break-keep min-w-[150px]">Select</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(Value,index) in customerList" class="bg-[#f0f4ff] text-center">
              <td class="text-center p-2 ">{{ Value.cs_name }}</td>
              <td class="text-center p-2">{{ Value.mobile }}</td>
              <td class="text-center p-2">{{ Value.addr1 }}{{ Value.addr2 }}</td>
              <td class="text-center p-2"><button type="submit" @click="selectCustomer(Value)" class="border bg-[#3d79ea] text-white py-2 px-3 rounded-md">select</button></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</template>

<script>

import numeral from "numeral";
import productNameForm from '../../searchForm/productNameForm.vue'
import VueSimpleAlert from "vue3-simple-alert";
import Loading from "vue3-loading-overlay";
import {isReactive, toRaw} from "vue";


export default {
    name: "MyCustomerOrderRegister",
    components : {
        productNameForm,
        Loading,
        VueSimpleAlert,
    },
    data: () => ({
        sendType2: false,
        toggleSendType: 'now',
        showModal: false,
        working_nation : working_nation,
        loading : false,
        fullPage : true,
        i18n: window.i18n,
        total_count : 0,
        page : 1,
        total_page : 1,
        productList : [],
        customerList : [],
        order_qty: [],
        order_qty_length: 0,
        order_info: [],
        order_item: [],
    }),
    watch : {
        page: function(){
            $("#submit").click();
            $("#register_submit").click();
        },
        toggleSendType: function(data){
          if(data == "now")
            this.sendType2 = false
          else
            this.sendType2 = true
        }
    },
    mounted() {
        $("#submit").click();
    },
    methods:{
        submitForm : function (event){
            event.preventDefault();
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/MyCustomerOrderCreate',{
                down_name : down_name.value,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if(response.data.total_count == 0){
                    VueSimpleAlert.alert("There are no search results","","",{confirmButtonColor: '#0abab5'});
                    this.list_msg = "No data";
                }
                console.log(response.data);
                // Inventory Qty 가 '0' 인 경우 제외
                response.data.forEach((data, index, array) => {
                    if(data.now_qty !== '0') this.productList.push(data);
                });
                console.log(this.productList);
                this.total_count = this.productList.length;
                this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
                this.loading = false;
            }).catch(error => console.error(error));
        },
        openForm() {
            this.loading = true;
            console.log(cs_name.value);
 /*           if(cs_name.value === '') {
                VueSimpleAlert.alert("소비자명을 입력해 주세요.","","",{confirmButtonColor: '#0abab5'});
                this.list_msg = "No data";
                this.loading = false;
                return false;
            }*/
                axios.post(window.Laravel.back_url+'/api/MyCustomer',{
                    down_name : cs_name.value,
                },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                    if(response.data.total_count == 0){
                        VueSimpleAlert.alert("There are no search results","","",{confirmButtonColor: '#0abab5'});
                        this.list_msg = "No data";
                    }
                    console.log(response.data);
                    this.customerList = response.data;
                    this.disabled = 1;
                }).catch(error => console.error(error));

            this.showModal = true;
            this.loading = false;
        },
        closeForm(){
            this.customerList = [];
            this.disabled = 0;
            this.showModal = false;
            this.loading = false;
        },
        selectCustomer(value){
            console.log(value);
            this.order_info.cs_id = value.cs_id;
            this.order_info.cs_name = value.cs_name;
            this.order_info.mobile = value.mobile;
            this.order_info.email = value.email;
            this.order_info.deli_kind = '0';
            this.order_info.address = value.addr2 + " " + value.addr1;
            this.closeForm();
        },
        isObject(value) {
            return value !== null && !Array.isArray(value) && typeof value === 'object'
        },
        getRawData (data) {
            return isReactive(data) ? toRaw(data) : data
        },
        toDeepRaw (data) {
            const rawData = this.getRawData(data)

            for (const key in rawData) {
                const value = rawData[key]

                if (!this.isObject(value) && !Array.isArray(value)) {
                    continue
                }

                rawData[key] = this.toDeepRaw(value)
            }

            return rawData // much better: structuredClone(rawData)
        },
        submitOrder : function (event){
            event.preventDefault();
            console.log(this.order_item);
            console.log(this.order_info);
            if(this.order_item.length === 0) {
                alert("No items have been selected."); //선택된 제품이 없습니다.
                return false;
            }
            if(this.order_info.total_amt === 0) {
                alert("The order amount is $0."); //주문 금액이 0 입니다.
                return false;
            }
            if(this.order_info.cs_id === '' || cs_id.value === '') {
                alert("No consumers have been selected."); //선택된 소비자가 없습니다.
                return false;
            }
            this.loading = true;
            let data = this.order_info;
            if(!this.isObject(this.order_info)) {
                // data = this.toDeepRaw(this.customer);
                data = Object.assign({}, this.toDeepRaw(this.order_info));
            }
            console.log(data);
            axios.post(window.Laravel.back_url+'/api/MyCustomerOrderStore',{
                order_item : this.order_item,
                order_info : data,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if(response.data === 0){
                    VueSimpleAlert.alert("Failed to create order, please contact customer service.","","",{confirmButtonColor: '#0abab5'}); //주문 생성에 실패하였습니다. 운영자에게 문의 부탁드립니다.
                    this.list_msg = "No data";
                    return false;
                }
                VueSimpleAlert.alert("Order has been created.","","",{confirmButtonColor: '#0abab5'}); //주문 생성하였습니다.
                this.list_msg = "OK";
                console.log(response.data);
                location.href="/MyCustomerOrder";
                return false;
                this.productList = response.data;
                this.total_count = response.data.length;
                this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
                this.loading = false;
            }).catch(error => console.error(error));
        },
        changeQty(e,i){
            // console.log(e);
            // console.log(i);
            // console.log(this.order_qty);
            if(e.data == null) {
                // this.order_item.slice(i);
                delete this.order_item[i];
                delete this.order_qty[i];
            }else{
                if (parseInt(this.order_qty[i]) < 1) {
                    alert("Quantity less than 0 cannot be added."); //0개 미만은 담을 수 없습니다.
                    // this.order_item.slice(i);
                    // this.order_qty.slice(i);
                    delete this.order_item[i];
                    delete this.order_qty[i];
                    //this.totalCost(this.products);
                } else if (parseInt(this.order_qty[i]) > this.productList[i].now_qty) {
                    // alert("Cannot exceed" + this.productList[i].now_qty + "quantity.");
                    alert("Incorrect quantity entered");
                    // this.order_item.slice(i);
                    // this.order_qty.slice(i);
                    delete this.order_item[i];
                    delete this.order_qty[i];
                    //this.totalCost(this.products);
                } else {
                    this.order_item[i] = this.productList[i];
                    this.order_item[i].sale_qty = this.order_qty[i];
                }
            }
            this.order_qty_length = this.arrayLength(this.order_qty);
            this.order_info.total_amt = this.order_item.reduce((partialSum, a, i) => {return parseFloat(partialSum += (a.sale_qty * a.retail_amt)) }, 0);
            // console.log(this.order_item);
            // this.order_info[i].push()
            // this.setChangeQty(this.products[i].seq_pd,this.products[i].qty);
            // this.getWeight();
        },
        arrayLength(a){
            let length = 0;
            a.forEach((data) => {
                if(data !== undefined || data !== '')  length++;
            });
            return length;
        }
    },
}

</script>

<style scoped>
.board-inner{background:#f0f2f4 !important;}

/*.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}*/

</style>
