<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>
    <modalView class="z-50" v-if="weachat_open" @close-modal="weachat_open = false" :harf="true" :request="request" :MIX_BACK_URL="MIX_BACK_URL">
        <iframe id="resultIframe" name="resultIframe"></iframe>
    </modalView>

    <section class="bg-[#f0f2f4]">
        <div class="container mx-auto py-6">
            <div class="max-w-8xl mx-auto px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8">
                <div class="w-full">
                    <div class="flex justify-start item-start space-y-2 flex-col">
                        <h1 class="text-2xl  font-semibold leading-7 lg:leading-9 text-gray-800 mb-2 lg:mb-6">Order Summary</h1>
                    </div>

                    <div class="w-full lg:flex lg:justify-start">

                        <div class="w-full lg:w-2/3 ">
                            <!-- 주문자 정보 -->
                            <div class="flex flex-col justify-start items-start w-full mb-6 shadow-zinc-500/40 shadow-md rounded-md xl:mt-0 lg:mt-0 mt-4">
                                <p class="text-lg xl:text-xl lg:text-xl  font-semibold leading-6 xl:leading-5 text-gray-800 flex items-center justify-between w-full cursor-pointer xl:px-6 lg:px-6 px-4 xl:py-6 lg:py-6 py-4 bg-white rounded-md" v-on:click="showMessage2 = !showMessage2">
                                    <span class="border-l-[10px] border-[#4493d5] pl-3 xl:pt-2 lg:pt-2 xl:pb-2 lg:pb-2">Information</span>
                                    <svg class="w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path></svg>
                                </p>
                                <div class="w-full p-4 xl:grid lg:grid xl:grid-cols-2 lg:grid-cols-2 block bg-white rounded-bl-md rounded-br-md" v-if="showMessage2">
                                    <div class="flex justify-start items-start flex-col space-y-4 mt-2 border-b lg:border-0 xl:border-0 p-2">
                                        <p class="text-base  font-semibold leading-4 text-left text-[#2c7fc3] lg:text-lg">Recipient Information</p>
                                        <p class="w-full dark:text-gray-300 text-left text-sm leading-5 text-[#0e293e]">{{ order_info_list.d_firstname }} {{ order_info_list.d_lastname }}<br> {{ order_info_list.d_mobile }}</p>
                                    </div>
                                    <div class="flex justify-start items-start flex-col space-y-4 mt-2 border-b lg:border-0 xl:border-0 p-2">
                                        <p class="text-base  font-semibold leading-4 text-left text-[#2c7fc3] lg:text-lg">Shipping Address</p>
                                        <p class="w-full dark:text-gray-300 text-left text-sm leading-5 text-[#0e293e]">
                                            {{ request["address2_1"]}} {{ request["address2_2"]}}, {{ request["city"]}}, {{ request["state"]}}, {{ request["postcode"]}}</p>
                                    </div>
                                    <div class="flex justify-start items-start flex-col space-y-4 mt-2 border-b lg:border-0 xl:border-0 p-2">
                                        <p class="text-base  font-semibold leading-4 text-left text-[#2c7fc3] lg:text-lg">Billing Address</p>
                                        <p class="w-full dark:text-gray-300 text-left text-sm leading-5 text-[#0e293e]">
                                            {{ order_info_list.o_firstname }} {{ order_info_list.o_lastname }}<br>{{ order_info_list.o_mobile }}<br>
                                            {{ order_info_list.o_post }} {{ order_info_list.o_addr2 }} {{ order_info_list.o_addr1 }}
                                        </p>
                                    </div>
                                    <div v-show="gift_card == 1" class="flex justify-start items-start flex-col space-y-4 mt-2 p-2">
                                        <p class="text-base  font-semibold leading-4 text-left text-[#2c7fc3] lg:text-lg">E-mail</p>
                                        <p class="w-full dark:text-gray-300 text-left text-sm leading-5 text-[#0e293e]">{{ mem_e_mail}}</p>
                                    </div>
                                    <div v-show="false" class="flex justify-start items-start flex-col space-y-4 mt-2 p-2">
                                        <p class="text-base  font-semibold leading-4 text-left text-[#2c7fc3] lg:text-lg">Contact Help</p>
                                        <p class="w-full dark:text-gray-300 text-left text-sm leading-5 text-[#0e293e]">info.us@gcoop.com</p>
                                    </div>
                                </div>
                            </div>
                            <!-- 제품 리스트 -->
                            <div class="flex flex-col justify-start items-start shadow-zinc-500/40 shadow-md rounded-md">
                                <div class="flex flex-col justify-start items-start w-full rounded-md">
                                    <p class="text-lg xl:text-xl lg:text-xl  font-semibold leading-6 xl:leading-5 text-gray-800 flex items-center justify-between w-full cursor-pointer xl:px-6 lg:px-6 px-4 xl:py-6 lg:py-6 py-4 bg-white rounded-md" v-on:click="showMessage = !showMessage">
                                        <span class="border-l-[10px] border-[#4493d5] pl-3 xl:pt-2 lg:pt-2 xl:pb-2 lg:pb-2">Customer’s Cart <span class="">({{ order_product_list.length }})</span></span>
                                        <svg class="w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                    </p>
                                    <div class="bg-white xl:px-4 lg:px-4 xl:py-4 lg:py-4 rounded-bl-md rounded-br-md w-full" v-if="showMessage">
                                        <div v-for="(product, i) in order_product_list" :key="product.seq_pd" class="flex w-full border-b border-dotted border-gray-300 mb-2 p-3 last:border-b-0">
                                            <div class="relative xl:w-1/5 lg:w-1/5 w-1/3">
                                                <div class="aspect-w-1 aspect-h-1 rounded-md overflow-hidden aspect-square border">
                                                    <img class="w-full hidden md:block" v-bind:src="'https://cdnus.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename "  alt="{{ product.product.pdt_name }}" />
                                                    <img class="w-full md:hidden" v-bind:src="'https://cdnus.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename " alt="{{ product.product.pdt_name }}" />
                                                    <div class="sold-out-box2" v-if="product.product.status == 2">
                                                        <img src="/images/badge/us/pc_temp_sold_out.png" alt="sold out" />
                                                    </div>
                                                    <div class="sold-out-box2" v-if="product.product.status == 3">
                                                        <img src="/images/badge/us/pc_sold_out.png" alt="sold out" />
                                                    </div>
                                                    <div class="coming_box" v-if="product.product.status == 4">
                                                        <img src="/images/badge/us/pc_coming_soon.png" alt="sold out" />
                                                    </div>
                                                    <div class="badge_box" v-if="product.product.badges">
                                                        <img v-bind:src="'https://cdnus.gcoop.me/' + product.product.badges.image_url" alt="sold out" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-col flex justify-between items-start w-full pt-3 pb-2 space-y-4 md:space-y-0 pl-4">
                                                <div class="w-full flex flex-col justify-start items-start space-y-8 mb-2">
                                                    <h3 class=" xl:text-lg lg:text-lg text-md font-semibold leading-6 text-[#0e293e]">{{ product.product.pdt_name }}</h3>
                                                </div>
                                                <div class="text-red-500 text-md" v-if="product.product.deli_date2" v-html="product.product.deli_date2"></div>
                                                <div class=" w-full">
                                                    <p class="text-xs ">USD ${{ formatPrice(product.price,2) }} / <span class="text-blue-500">PV {{ formatPrice(product.pv,2) }}</span></p>
                                                    <div class="flex justify-end flex-col">
                                                        <p class="xl:text-md lg:text-md  font-semibold text-gray-800 xl:text-right lg:text-right mb-1">Qty : {{ product.qty }}</p>
                                                        <p class="xl:text-md lg:text-md  font-semibold text-gray-800 xl:text-right lg:text-right">USD ${{ formatPrice(product.amt * product.qty,2) }} / <span class="text-blue-500">PV {{ formatPrice(product.pv * product.qty,2) }} </span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- // 제품 리스트 -->
                        </div>

                        <!-- payment 영역 -->
                        <div class="w-full lg:w-1/3 xl:mt-0 lg:mt-0 mt-8">
                            <!-- 결제유형 선택 -->
                            <div class="w-full" id="app">
                                <div class="flex justify-start w-full xl:px-4 lg:px-4 xl:pb-4 lg:pb-4 xl:mt-0 lg:mt-0 mt-4">
                                    <div class="flex flex-col justify-center px-4 py-6 md:p-6 xl:p-6 w-full bg-white dark:bg-gray-800 space-y-6 shadow-zinc-500/40 shadow-md rounded-md">
                                        <h3 class="text-lg xl:text-xl lg:text-xl  font-semibold leading-5 text-gray-800 border-l-[10px] border-[#4493d5] pl-3">Payment Method<br>
                                            <p class="text-[#2c7fc3] text-xs font-semibold">( Choose your payment method )</p>
                                        </h3>
                                        <h3>My Points : {{ formatPrice(cur_point,2) }}</h3>

                                        <!-- 결제수단  -->
                                        <div class="w-full pay_box">
                                            <div class="w-full text-lg flex items-center" @click="key = 1">
                                                <input type="radio" id="paytype1" name="paytype" class="hidden" :checked="key == '1'">
                                                <label for="paytype1" class="bg-white text-gray-800 border border-gray-300 font-semibold px-4 py-1 cursor-pointer rounded-md block text-center h-12 flex items-center justify-center w-full">
                                                <span class="flex">
                                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-6"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M168 336C181.3 336 192 346.7 192 360C192 373.3 181.3 384 168 384H120C106.7 384 96 373.3 96 360C96 346.7 106.7 336 120 336H168zM360 336C373.3 336 384 346.7 384 360C384 373.3 373.3 384 360 384H248C234.7 384 224 373.3 224 360C224 346.7 234.7 336 248 336H360zM512 32C547.3 32 576 60.65 576 96V416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H512zM512 80H64C55.16 80 48 87.16 48 96V128H528V96C528 87.16 520.8 80 512 80zM528 224H48V416C48 424.8 55.16 432 64 432H512C520.8 432 528 424.8 528 416V224z"/></svg>
                                                  &nbsp;Card
                                                </span>
                                                </label>
                                            </div>
                                            <div class="grid grid-cols-2 gap-2 mt-2">
                                                <div class="w-full xl:text-base lg:text-base text-sm" v-show="request['bz_cd'] == 'CAN'" @click="key = 2">
                                                    <input type="radio" id="paytype2" name="paytype" class="hidden">
                                                    <label for="paytype2" class="bg-white text-gray-800 border border-gray-300 font-semibold px-4 py-1 cursor-pointer rounded-md block text-center h-12 flex items-center justify-center"><img src="/images/payment3/paypal.png" alt="alipay_logo" class="w-[90px]" /></label>
                                                </div>
                                                <div v-show="gift_card == 0" class="w-full xl:text-base lg:text-base text-sm" @click="key = 3">
                                                    <input type="radio" id="paytype5" name="paytype" class="hidden">
                                                    <label for="paytype5" class="bg-white text-gray-800 border border-gray-300 font-semibold px-4 py-1 cursor-pointer rounded-md block text-center h-12 flex items-center justify-center">Points</label>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 결제수단 콘텐츠 영역 -->
                                        <div v-show="visible_payment_area"> <!--v-if="key == 1"-->
                                            <h5 class="font-bold before_cont text-[#2c7fc3]">Card</h5>
                                            <div class="mt-4 flex space-x-4 mt-4 ">
                                                <div class="md-input-box relative z-[10] bg-white w-1/2">
                                                    <input id="cardOwnerNameF" type="text" placeholder=" "
                                                           class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="40"
                                                           v-model="first_name">
                                                    <label for="cardOwnerNameF" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">First Name</label>
                                                </div>
                                                <div class="md-input-box relative z-[10] bg-white w-1/2 ">
                                                    <input id="cardOwnerNameL" type="text" placeholder=" "
                                                           class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="40"
                                                           v-model="last_name">
                                                    <label for="cardOwnerNameL" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">Last Name</label>
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <div class="md-input-box relative z-[10] w-full bg-white w-full">
                                                    <input name="card_no" id="card_no" ref="card_no" type="text" placeholder=" "
                                                           class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="16"
                                                           v-model="card_no">
                                                    <label for="card_no" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">Card Number</label>
                                                </div>
                                            </div>
                                            <div class="mt-4 flex space-x-4 mt-4 ">
                                                <div class="md-input-box relative z-[10] bg-white w-1/2">
                                                    <input name="valid_date" id="valid_date" ref="valid_date" type="text" placeholder=" "
                                                           class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="4"
                                                           v-model="valid_date">
                                                    <label for="valid_date" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">MM/YY</label>
                                                </div>
                                                <div class="md-input-box relative z-[10] bg-white w-1/2">
                                                    <input name="cvv" id="cvv" ref="cvv" type="text" placeholder=" "
                                                           class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="4"
                                                           v-model="cvv">
                                                    <label for="cvv" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">CVV</label>
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <div class="md-input-box relative z-[10] w-full bg-white w-full">
                                                    <input name="e_mail" id="e_mail" ref="e_mail" type="text" placeholder=" "
                                                           class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer"
                                                           v-model="e_mail">
                                                    <label for="e_mail" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">E-mail</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- 총합계 -->
                            <div class="flex justify-center flex-col md:flex-row flex-col items-stretch w-full mt-4 xl:px-4 lg:px-4 xl:pb-4 lg:pb-4 ">
                                <div class="flex flex-col px-4 py-6 md:p-6 xl:p-6 w-full bg-white dark:bg-gray-800 space-y-6 shadow-zinc-500/40 shadow-md rounded-md">

                                    <h3 class="text-lg xl:text-xl lg:text-xl  font-semibold leading-5 text-gray-800 border-l-[10px] border-[#4493d5] pl-3">Payment Amount</h3>

                                    <div class="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                                        <div class="flex justify-between w-full">
                                            <p class="text-base  leading-4 text-gray-800">Subtotal</p>
                                            <p class="text-base dark:text-gray-300 leading-4 text-gray-800">
                                                USD ${{ formatPrice(sum_item.price,2) }}
                                                <p class="text-blue-500 text-right mt-2">PV {{ formatPrice(sum_item.bv,2) }} </p>
                                            </p>
                                        </div>
                                        <div class="flex justify-between w-full">
                                            <p class="text-base  leading-4 text-gray-800">Shipping</p>
                                            <p class="text-base dark:text-gray-300 leading-4 text-gray-800 text-right">
                                                USD ${{ formatPrice(parseFloat(shipping_selected) + parseFloat(insurance_fee) ,2) }}
                                                <p class="text-blue-500 text-sm text-right mt-2">{{ deli_kinds }} {{ formatPrice(parseFloat(shipping_selected) - formatPrice(gel_price,2),2) }}</p>
                                                <p  v-if="insurance_fee > 0" class="text-blue-500 text-sm text-right ">Insurance {{ formatPrice(insurance_fee,2) }}</p>
                                                <p  v-if="gel_price > 0" class="text-blue-500 text-sm text-right ">Gel {{ formatPrice(gel_price,2) }}</p>
                                            </p>
                                        </div>
                                        <div class="flex justify-between items-center w-full">
                                            <p class="text-base  leading-4 text-gray-800">Tax</p>
                                            <p class="text-base dark:text-gray-300 leading-4 text-gray-800">USD ${{ formatPrice(this.tax_selected,2) }}</p>
                                        </div>
                                        <div class="flex justify-between w-full" v-if="key == '3'">
                                            <p class="text-base  leading-4 text-gray-800">Point</p>
                                            <p class="text-base dark:text-gray-300 leading-4 text-gray-800 text-right">
                                                - {{ formatPrice(cur_point,2) }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="flex justify-between items-center w-full pb-4">
                                        <p class="text-base  font-semibold leading-4 text-gray-800">Total</p>
                                        <div class="text-base dark:text-gray-300 font-semibold leading-4 text-gray-600">
                                            <p class="xl:text-2xl lg:text-2xl text-xl font-bold font-bold text-gray-900">USD ${{  formatPrice(total_amt,2) }}</p>
                                            <p class="xl:text-2xl lg:text-2xl text-xl text-blue-500 text-right font-bold mt-2">PV {{ formatPrice(sum_item.bv,2) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="flex flex-row mt-4 mb-2">
                                    <h3 class="w-full text-lg xl:text-xl lg:text-xl  font-semibold leading-5 text-gray-800 border-l-[10px] border-[#4493d5] pl-3">Price in Invoice</h3>
                                </div>
                                <div class="flex flex-row mt-2 mb-2">
                                    <select name="price_open" id="price_open" class="mt-2 mb-2 w-full text-sm md:text-base" v-model=price_open>
                                      <option value="" selected>Show</option>
                                      <option value="3">Hide</option>
                                    </select>
                                </div>
                              <div class="flex flex-row">
                                    <input type="checkbox" class="mt-0.5" v-model="pay_agree" true-value="Y" false_value="N" id="pay_agree">
                                    <label for="pay_agree" class="block ml-3 mb-3 text-sm font-semibold text-gray-500 cursor-pointer">Confirm the purchase terms and give consent to payment processing for the above products.</label>
                                </div>
                                <div class="flex flex-row" v-if="pay_agree2_show">
                                    <input type="checkbox" class="mt-0.5" v-model="pay_agree2" true-value="Y" false_value="N" id="pay_agree2">
                                    <label for="pay_agree2" class="block ml-3 mb-3 text-sm font-semibold text-gray-500 cursor-pointer">Returns are not accepted due to change of mind other than defective or damaged products.</label>
                                </div>
                            </div>

                            <div class="w-full xl:px-4 lg:px-4 xl:py-4 lg:py-4 mt-2">
                                <button @click="go_pay" class="bg-[#2c80c4] font-semibold hover:bg-[#2468a0] py-3 text-sm text-white uppercase w-full">Checkout</button>
                            </div>

                        </div>
                        <!--// payment 영역 -->

                    </div>
                </div>
            </div>
        </div>
    </section>
    <form name="frmPayment">
        <input type="hidden" name="_token">
        <input type="hidden" name="TransactionType" value="SALE">
        <input type="hidden" name="PymtMethod" value="ANY"> <!-- 정기구매 첫결제 : CC , 일반결제 any -->
        <input type="hidden" name="ServiceID" value="GPO">
        <input type="hidden" name="PaymentID">
        <input type="hidden" name="OrderNumber">
        <input type="hidden" name="PaymentDesc" value="GCOOP Malaysia">
        <input type="hidden" name="MerchantName" value="GCOOP Malaysia Sdn. Bhd.">
        <input type="hidden" name="MerchantReturnURL">
        <input type="hidden" name="MerchantCallbackURL">
        <input type="hidden" name="Amount">
        <input type="hidden" name="CurrencyCode" value="MYR">
        <input type="hidden" name="CustIP">
        <input type="hidden" name="CustName">
        <input type="hidden" name="CustEmail">
        <input type="hidden" name="CustPhone">
        <input type="hidden" name="MerchantTermsURL" value="https://cdn.gcoop.me/shop/storage/pdf/my/terms_mal.pdf">
        <input type="hidden" name="LanguageCode" value="en">
        <input type="hidden" name="PageTimeout" value="780">
        <input type="hidden" name="first_name">
        <input type="hidden" name="last_name">
        <input type="hidden" name="valid_date">
        <input type="hidden" name="cvv">
        <input type="hidden" name="card_no">
        <input type="hidden" name="tax">
        <input type="hidden" name="seq_os">
        <input type="hidden" name="userid">
    </form>
</template>

<script>
/* javascript numberFormat library */
import numeral from 'numeral';
import 'numeral/locales';
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import modalView from "../modalViewWechat";
import { useMobileDetection } from "vue3-mobile-detection";
import alert from "vue3-simple-alert";
import VueSimpleAlert from "vue3-simple-alert";

let itemsToShow  = 1;

export default {
    name: "Order",
    props: ['request','hashdata'],
    setup() {
        const { isMobile } = useMobileDetection();   // 모바일 여부 체크
        if( isMobile())  itemsToShow  = 2;
        return { itemsToShow };
    },
    components : {
        Loading,
        modalView
    },

    data: () => ({
        //show hide
        showMessage: true,
        showMessage2: true,
        title: [],
        order_list: [],
        order_info_list: [],
        order_pay_list: [],
        order_product_list: [],
        sum_item: [],
        // fee_shipping: [],
        shipping_selected: 0,
        csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
        is_order_possible : window.Laravel.is_order_possible,
        key : 1,
        loading : false,
        weachat_open :false,
        is_mobile : itemsToShow,
        card_no : "",
        valid_date : "",
        cvv : "",
        first_name : "",
        last_name : "",
        e_mail : "",
        deli_kind : "",
        deli_kinds : "",
        insurance_fee : "",
        gel_price : "",
        price_open : "",
        pay_agree : "",
        pay_agree2 : "",
        pay_agree2_show : true,
        cur_point : 0,
        usable_point : 0,
        visible_payment_area : true,
        gift_card : 0,
        mem_e_mail : "",
    }),
    watch : {
        key : function(){

            if(this.cur_point <= 0 && this.key == "3"){
                VueSimpleAlert.alert("No points available","","","");
                this.key = "1";
                $("#paytype1").prop("checked", true);
                return;
            }else{
                this.calcTotal();
            }

        },
        card_no : function(val){
            return this.card_no = this.card_no.replace(/[^0-9]/g, '');
        },
        valid_date : function(val){
            return this.valid_date = this.valid_date.replace(/[^0-9]/g, '');
        },
        cvv : function(val){
            return this.cvv = this.cvv.replace(/[^0-9]/g, '');
        },
    },
    created() {
        this.loading = true;
        this.order = this.request["order_seq"];
        this.sum_item.price = 0;
        this.sum_item.vat = 0;
        this.sum_item.amt = 0;
        this.sum_item.bv = 0;
        this.shipping_selected = 0;
        this.getOrder(window.Laravel.bz_cd);
        this.MIX_BACK_URL = process.env.MIX_BACK_URL;
    },
    methods:{

        /**
         * 결제 진행 전에 제품 정보 및 결제 금액에 대한 검증
         * @param response
         * @returns {boolean}
         */
        isPaymentValidation(response) {
            if ( response.data.result === '-100'){
                return false;
            }
            return true;

        },
        getOrder(bz_cd) {
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL + '/api/getOrder', {
                order: this.order,
                bz_cd: bz_cd,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {

                    if( this.isPaymentValidation(response) === false)
                    {
                        this.loading = false;
                        VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => { //회원가입이 완료 되었습니다.
                            if(result){
                                console.log("window.history.back")
                                history.back();
                            }
                        });
                        return ;
                    }

                    this.order_list = response.data;
                    this.order_info_list= response.data.info;
                    this.order_info_list.d_addr2 = this.order_info_list.d_addr2.replaceAll("|@|", ", ");
                    this.order_info_list.d_addr1 = this.order_info_list.d_addr1.replaceAll("|@|", ", ");
                    this.order_info_list.d_firstname = this.order_info_list.d_firstname;
                    this.order_info_list.d_lastname = this.order_info_list.d_lastname;
                    this.order_info_list.d_mobile = this.order_info_list.d_mobile;
                    this.order_info_list.o_firstname = this.order_info_list.o_firstname;
                    this.order_info_list.o_lastname = this.order_info_list.o_lastname;
                    this.order_info_list.o_addr1 = this.order_info_list.o_addr1.replaceAll("|@|", ", ");
                    this.order_info_list.o_addr2 = this.order_info_list.o_addr2.replaceAll("|@|", ", ");
                    this.order_info_list.o_name = this.order_info_list.o_name;
                    this.order_pay_list= response.data.pay;
                    this.order_product_list= response.data.product;
                    this.sum_item.price = this.order_list.ord_price;
                    this.sum_item.vat = this.order_list.ord_vat;
                    this.sum_item.amt = this.order_list.ord_amt;
                    this.sum_item.bv = this.order_list.ord_pv;
                    this.shipping_selected = this.order_list.ord_deli_amt;
                    this.insurance_fee = this.order_list.insurance_amt;
                    this.tax_selected = this.order_list.ord_vat;
                    this.gel_price = this.order_list.gel_price;
                    this.deli_kind = this.order_list.info.deli_kind;
                    if(this.deli_kind == '3'){
                        this.deli_kinds = "Signature";
                    }else if(this.deli_kind == '1'){
                        this.deli_kinds = "Pickup";
                    }else{
                        this.deli_kinds = "Ground";
                    }

                    if(this.order_list.ord_nation == "USA"){
                        this.pay_agree2_show = false;
                    }
                    this.total_amt = this.sum_item.amt;
                    this.gift_card = response.data.gift_card;
                    this.mem_e_mail = response.data.mem_e_mail;
                    //포인트
                    axios.post(process.env.MIX_BACK_URL + '/api/getPoint', {
                        bz_cd: bz_cd,
                    }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                        .then(response => {
                            this.cur_point = response.data;
                            if(parseFloat(this.total_amt) <= parseFloat(this.cur_point)){
                                this.usable_point = this.total_amt;
                            }else{
                                this.usable_point = this.cur_point;
                            }

                            this.loading = false;
                        })
                        .catch(e => {
                        });

                })
                .catch(e => {
                });

        },
        prevUrl(){ // 쇼핑 계속하기
            window.history.go(-1);//
        },
        calcTotal(){
            if(this.key == "3"){
                this.total_amt = this.sum_item.amt - this.usable_point;

                if(parseFloat(this.cur_point) >= parseFloat(this.sum_item.amt)){
                    this.visible_payment_area = false;
                }

            }else{
                this.total_amt = this.sum_item.amt;
                this.visible_payment_area = true;
            }
        },
        formatPrice(value,digit) { // 숫자 포멧
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        checkCardInvalid(){
            if(this.first_name == ""){
                VueSimpleAlert.alert("Please enter first name","","","");
                return false;
            }

            if(this.last_name == ""){
                VueSimpleAlert.alert("Please enter Last name","","","");
                return false;
            }
            if(this.card_no == ""){
                VueSimpleAlert.alert("Please enter card number","","","");
                return false;
            }
            if(this.valid_date == ""){
                VueSimpleAlert.alert("Please enter Valid date","","","");
                return false;
            }

            if(this.cvv == ""){
                VueSimpleAlert.alert("Please enter cvv","","","");
                return false;
            }
            return true;
        },
        go_pay(){
            // console.log(this.key);
            if(!this.pay_agree){
                VueSimpleAlert.alert("Please accept the purchase terms.","","","");
                return;
            }


            if(this.key == "3"){
                if(parseFloat(this.cur_point) < parseFloat(this.sum_item.amt)){
                    if(this.checkCardInvalid() == false){
                        return;
                    }
                }
            }else{
                if(this.checkCardInvalid() == false){
                    return;
                }
            }

            this.loading = true;
            if(this.key == "1"){
                axios.post(process.env.MIX_BACK_URL+'/api/pay_card',
                    {
                        seq_os : this.request["order_seq"],
                        amount : this.request["pay_total_amt"],
                        card_no : this.card_no,
                        first_name : this.first_name,
                        last_name : this.last_name,
                        e_mail : this.e_mail,
                        valid_date : this.valid_date,
                        cvv : this.cvv,
                        price_open : this.price_open,
                    },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                    .then(response => {
                        if(response.data.ord_no && response.data.result == "1"){
                            location.href="/payResult?kind=success&order_no=" + response.data.ord_no;
                        }else{
                            this.loading = false;
                            if(response.data.msg != ""){
                                this.loading = false;
                                VueSimpleAlert.alert(response.data.msg,"","",{});
                            }else{
                                alert("결제 실패");
                                this.loading = false;
                            }
                        }
                    });
            }

            if(this.key == "2"){
                axios.post(process.env.MIX_BACK_URL+'/api/pay_paypal',
                    {
                        seq_os : this.request["order_seq"],
                        amount : this.request["pay_total_amt"],
                        card_no : this.card_no,
                        first_name : this.first_name,
                        last_name : this.last_name,
                        e_mail : this.e_mail,
                        valid_date : this.valid_date,
                        cvv : this.cvv,
                        price_open : this.price_open,
                    },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                    .then(response => {
                        if(response.data.ord_no && response.data.result == "1"){
                            location.href="/payResult?kind=success&order_no=" + response.data.ord_no;
                        }else{
                            this.loading = false;
                            if(response.data.msg != ""){
                                alert(response.data.msg);
                                this.loading = false;
                            }else{
                                alert("결제 실패");
                                this.loading = false;
                            }
                        }
                    });
            }

            if(this.key == "3"){
                axios.post(process.env.MIX_BACK_URL+'/api/pay_point',
                    {
                        seq_os : this.request["order_seq"],
                        amount : this.request["pay_total_amt"],
                        card_no : this.card_no,
                        first_name : this.first_name,
                        last_name : this.last_name,
                        e_mail : this.e_mail,
                        valid_date : this.valid_date,
                        cvv : this.cvv,
                        point : this.usable_point,
                        price_open : this.price_open,
                    },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                    .then(response => {
                        if(response.data.ord_no && response.data.result == "1"){
                            location.href="/payResult?kind=success&order_no=" + response.data.ord_no;
                        }else{
                            this.loading = false;
                            if(response.data.msg != ""){
                                this.loading = false;
                                VueSimpleAlert.alert(response.data.msg,"","",{});
                            }else{
                                alert("결제 실패");
                                this.loading = false;
                            }
                        }
                    });
            }
        },
    }
}
</script>

<style>
.pay_box input[type="radio"]:checked + span {
    display: block;
}

.pay_box input:checked + label {
    background-color: rgb(182 197 210 / 30%);
    border:2px solid #2c80c4;
    color:#0e293e;
}
.pay_box input:checked + label>span>svg {
    color:#184468;
}

.before_cont::before {
    content: "■ ";
    color:#dce0ef;
}

</style>


