<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="true"></loading>

  <div class="member-search">

    <form name='form_search' id="form_search" v-on:submit.prevent="submitForm(1)">
      <section class="myo_condition_box">
        <dl class="myo_search_list">
          <dd class="myo_search_list">
            <input type="hidden" name="order" id="order">
            <input type="hidden" name="sort" id="sort">
            <section class="myo_condition_box">
              <dateForm @set_s_date="set_s_date" @set_e_date="set_e_date"></dateForm>
              <span class="mini_notice2 pl-0">
                  <p>Based on order date.</p>
              </span>
              <dl class="myo_search_list">
                <dt>Activate Status</dt>
                <dd>
                  <div class="myo-mem-check">
                      <span class="radio-box">
                          <input type="radio" id="us-myflag1" v-model="act_yn" value="" :checked="!act_yn" >
                          <label for="us-myflag1" class="">
                              <i class="far fa-dot-circle"></i>
                              All
                          </label>
                      </span>
                      <span class="radio-box">
                          <input type="radio" id="us-myflag2" v-model="act_yn" value="Y" :checked="act_yn == 'Y'" >
                          <label for="us-myflag2" class="">
                              <i class="far fa-dot-circle"></i>
                              Activated
                          </label>
                      </span>
                      <span class="radio-box">
                          <input type="radio" id="us-myflag3" v-model="act_yn" value="N"  :checked="act_yn == 'N'" >
                          <label for="us-myflag3" class="">
                              <i class="far fa-dot-circle"></i>
                              Not Activated
                          </label>
                      </span>
                  </div>
                </dd>
              </dl>
            </section>
          </dd>
        </dl>
        <div class="myo_condition_search">
          <button type="reset" @click="reset" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
          <button type="submit" id="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
        </div>

      </section>
    </form>
  </div>

  <div class="summary" style="clear: both;"><strong>Total</strong> <em>{{ total_count }}</em></div>

  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table id="point_list" class="border-separate border-spacing-2 table-auto w-full" >
      <thead>
      <tr class="bg-[#172e59] text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px] whitespace-nowrap">{{ i18n.myoffice.GiftCard.no }}</th>
        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px] whitespace-nowrap">{{ i18n.myoffice.GiftCard.ord_no }}</th>
        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px] whitespace-nowrap">{{ i18n.myoffice.GiftCard.ord_date }}</th>
        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px] whitespace-nowrap">{{ i18n.myoffice.GiftCard.amt }}</th>
        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px] whitespace-nowrap">{{ i18n.myoffice.GiftCard.act_status }}</th>
        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px] whitespace-nowrap">{{ i18n.myoffice.GiftCard.act_date }}</th>
      </tr>
      </thead>
      <tbody>

      <tr v-for="(Value,index) in List" class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
        <th class="text-center p-2 min-w-[100px] whitespace-nowrap" >{{ total_count - ((page-1)*page_size + index) }}</th>
        <th class="text-center p-2 min-w-[100px] whitespace-nowrap">{{ Value.ord_no }}</th>
        <th class="text-center p-2 min-w-[100px] whitespace-nowrap">{{ Value.reg_date }}</th>
        <th class="text-center p-2 min-w-[100px] whitespace-nowrap">{{ formatPrice(Value.amt, 2) }}</th>
        <th class="text-center p-2 min-w-[100px] whitespace-nowrap" :class="'text-' + Value.activate_color + '-600'">{{ Value.activate_status }}</th>
        <th class="text-center p-2 min-w-[100px] whitespace-nowrap">{{ Value.use_date }}</th>
      </tr>
      <!-- 검색 결과 없을 경우 -->
      <tr class="h-2" v-if="total_count == 0">
        <td class="text-left md:text-center pl-5 py-5" colspan="13">{{ this.list_msg }}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div style="display: flex; align-items: center; justify-content: center;" class="mt-4">
    <v-pagination
        v-model="page"
        :pages=total_page
        :range-size="1"
        active-color="#DCEDFF">
    </v-pagination>
  </div>
</template>

<script>

import dateForm from '../../searchForm/dateForm.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";
import moment from 'moment';
export default {
  name: "",
  components: {
    dateForm,
    VPagination,
    Loading
  },
  data: () => ({
    i18n        : window.i18n,
    page        : 1,
    page_size   : 20,
    total_page  : 1,
    total_count : 0,
    act_yn      : '',
    s_date      : date_month,
    e_date      : date_today,
    List        : [],
    loading     : false,
    list_msg    : "No data",
  }),
  created() {
    this.loading = true;
    this.submitForm();
  },
  mounted() {
    this.userid = window.Laravel.user;
  },
  watch : {
      page : function(){
        this.submitForm();
      }
  },
  methods:{
    submitForm : function (page = ''){
      this.loading = true;

      axios.post(window.Laravel.back_url+'/api/giftCardHistory',{
        s_date : this.s_date,
        e_date : this.e_date,
        act_yn : this.act_yn,
        page   : (!page) ? this.page : page
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        if(response.data.list.length == 0){
          VueSimpleAlert.alert("There are no search results","","",{confirmButtonColor: '#0abab5'});
          this.list_msg = "No data";
        }

        this.total_count = response.data.total_count;
        this.total_page  = parseInt(this.total_count/this.page_size)+parseInt(this.total_count%this.page_size > 0 ? 1 : 0);
        this.List        = response.data.list;
        this.loading     = false;
      });
    },

    set_s_date(s_date){
      this.s_date = s_date;
    },
    set_e_date(e_date){
      this.e_date = e_date;
    },
    formatPrice(value,digit) {
      let val = (value/1).toFixed(digit).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    moment: function () {
      return moment();
    },
    reset: function () {
      location.replace('/GiftCardHistory');
    },
  }
}
</script>

<style scoped>

</style>
