<template>
    <loading :active="loading" :can-cancel="true"></loading>
    <!-- 마이오피스 추가 부분 임시 -->
    <div class="xl:p-6 lg:p-6 md:p-0 sm:p-0"> <!-- bg-[#f0f2f4]  -->
        <div>
            <div class="xl:flex lg:flex block flex-row items-center justify-between w-full rounded-md mb-6 shadow-zinc-500/40 shadow-md p-5 bg-[#272c33]">

                <div class="flex flex-row items-center xl:gap-10 lg:gap-5 gap-2 xl:mb-0 lg:mb-0 mb-4 w-full max-w-[460px]">
                    <div class="xl:w-28 xl:h-28 lg:w-28 lg:h-28 w-20 h-20 rounded-full overflow-hidden">
                        <img v-show="rank_cd == '00'" src="https://cdn.gcoop.me/us2/images/us_badge/us_badge_basic.png?ver=1.0" class="object-scale-down bg-cover" alt="" />
                        <img v-show="rank_cd == '01'" src="https://cdn.gcoop.me/us2/images/us_badge/us_badge_basic.png?ver=1.0" class="object-scale-down bg-cover" alt="" />
                        <img v-show="rank_cd == '10'"  src="https://cdn.gcoop.me/us2/images/us_badge/us_badge_bronze.png?ver=1.0" class="object-scale-down bg-cover" alt="" />

                        <img v-show="rank_cd == '20'"  src="https://cdn.gcoop.me/us2/images/us_badge/us_badge_silver.png?ver=1.0" class="object-scale-down bg-cover" alt="" />
                        <img v-show="rank_cd == '30'"  src="https://cdn.gcoop.me/us2/images/us_badge/us_badge_gold.png?ver=1.0" class="object-scale-down bg-cover" alt="" />

                        <img v-show="rank_cd == '40'"  src="https://cdn.gcoop.me/us2/images/us_badge/us_badge_BM.png?ver=1.0" class="object-scale-down bg-cover" alt="" />

                        <img v-show="rank_cd == '42'"  src="https://cdn.gcoop.me/us2/images/us_badge/badge_m.png?ver=1.0" class="object-scale-down bg-cover" alt="" />
                        <img v-show="rank_cd == '45'"  src="https://cdn.gcoop.me/us2/images/us_badge/badge_gm.png?ver=1.0" class="object-scale-down bg-cover" alt="" />

                        <img v-show="rank_cd == '50'"  src="https://cdn.gcoop.me/us2/images/us_badge/us_badge_DR.png?ver=1.0" class="object-scale-down bg-cover" alt="" />
                        <img v-show="rank_cd == '60'"  src="https://cdn.gcoop.me/us2/images/us_badge/us_badge_VP.png?ver=1.0" class="object-scale-down bg-cover" alt="" />
                        <img v-show="rank_cd == '70'"  src="https://cdn.gcoop.me/us2/images/us_badge/us_badge_P.png?ver=1.0" class="object-scale-down bg-cover" alt="" />

                    </div>
                    <div class="flex flex-col justify-items-center items-start p-2">
                        <div class="text-left text-white font-semibold text-xl py-2">{{ username }} {{ bb_username}}</div>
                        <div class="text-left text-white text-base">{{ userid }} </div>
<!--                        <div class="text-left text-white text-base">Attainment Rank : {{ rank_max_name??"" }}</div>-->
<!--                        <div class="text-left text-white text-base">Previous Month’s Rank : {{ rank_name??""}}</div>-->
                        <div class="text-left text-white text-base">{{  i18n.myoffice.dashboard.myRank }} : {{ rank_name??"" }}</div>
                        <div class="text-left text-white text-base">{{  i18n.myoffice.dashboard.highestRankAchieved }} : {{ rank_max_name??"" }} </div>
                    </div>


                </div>

                <!-- 사용 가능 포인트 / 적립 예정 포인트가 실시간으로 적용 되는지 확인 -->
                <!-- 버추얼 회원은 확인 불가 //-->
                <div v-if="isVcMember===false" class="flex flex-col items-center justify-items-center">
                    <div class="flex xl:flex-row lg:flex-row  flex-col gap-4 w-full flex-wrap justify-end">

                        <div v-if="this.rank_cd != '00'" class="py-2 px-3 border rounded-md flex flex-col bg-white items-center justify-items-center justify-between xl:w-48 lg:w-40 w-full">
                            <div class="w-full text-center flex items-center">
                                <div class="rounded-full p-2 bg-[#ffe3e7]">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-[#FF4560]" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M352 128c0 70.7-57.3 128-128 128s-128-57.3-128-128S153.3 0 224 0s128 57.3 128 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                                </div>
                                <!--유지 상태-->
                                <div class="w-full text-left pl-2 text-slate-800">
                                    My Eligibility Status<br>This Month
                                </div>
                            </div>
                            <div class="text-xl font-semibold xl:mt-3 lg:mt-3 mt-1 text-[#0d6efd] text-right w-full" v-show="this.act_yn =='Y'">Yes</div>
                            <div class="text-xl font-black xl:mt-3 lg:mt-3 mt-1 text-[#e1253f] text-right w-full" v-show="this.act_yn =='N'">No</div>
                        </div>

                        <!-- -------------------------------------------------------------- //-->

                        <div class="py-2 px-3 border rounded-md flex flex-col bg-white items-center justify-items-center justify-between xl:w-48 lg:w-40 w-full">
                            <div class="w-full text-center flex items-center">
                                <div class="rounded-full p-2 bg-[#B0C1DD]">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-[#ff0000]" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"/></svg>
                                </div>
                                <!-- 사용 가능 포인트-->
                                <div class="w-full text-left pl-2 text-slate-800">
                                    Gift Card Points
                                </div>
                            </div>
                            <div class="text-xl font-semibold xl:mt-3 lg:mt-3 mt-1 text-[#0d6efd] text-right w-full">{{ formatPrice(mem_stamp_h_giftCard.now_pv,2) }}</div>
                        </div>
                        <!-- -------------------------------------------------------------- //-->

                        <div v-if="false" class="py-2 px-3 border rounded-md flex flex-col bg-white items-center justify-items-center justify-between xl:w-48 lg:w-40 w-full">
                            <div class="w-full text-left flex items-center">
                                <div class="rounded-full p-2 bg-[#f7efd5]">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-[#f7bc0c]" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM128 256a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM80 432c0-44.2 35.8-80 80-80h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H96c-8.8 0-16-7.2-16-16z"/></svg>
                                </div>
                                <!-- 수당 적용 직급-->
                                <div class="w-full text-left pl-2 text-slate-800">
                                    Applicable Commission Rank
                                </div>
                            </div>
                            <div class="text-xl font-semibold xl:mt-3 lg:mt-3 mt-1 text-[#0d6efd] text-right w-full">{{ rank_name??"" }}</div>
                        </div>

                        <div class="py-2 px-3 border rounded-md flex flex-col bg-white items-center justify-items-center justify-between xl:w-48 lg:w-40 w-full">
                            <div class="w-full text-center flex items-center">
                                <div class="rounded-full p-2 bg-[#c5edec]">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-[#0abab5]" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"/></svg>
                                </div>
                                <!-- 사용 가능 포인트-->
                                <div class="w-full text-left pl-2 text-slate-800">
                                    Usable Points
                                </div>
                            </div>
                            <div class="text-xl font-semibold xl:mt-3 lg:mt-3 mt-1 text-[#0d6efd] text-right w-full">{{ formatPrice(mem_stamp_h_user.pv_now,2) }}</div>
                        </div>

                        <div class="py-2 px-3 border rounded-md flex flex-col bg-white items-center justify-items-center justify-between xl:w-48 lg:w-40 w-full">
                            <div class="w-full text-center flex items-center">
                                <div class="rounded-full p-2 bg-[#dfe8f8]">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-[#6ba0f8]" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"/></svg>
                                </div>
                                <!--적립 예정 포인트-->
                                <div class="w-full text-left pl-2 text-slate-800">
                                    Pending Points
                                </div>
                            </div>
                            <div class="text-xl font-semibold xl:mt-3 lg:mt-3 mt-1 text-[#0d6efd] text-right w-full">{{ formatPrice(mem_stamp_h_user.pv_ready,2) }}</div>
                        </div>

                    </div>
                    <!--표시되는 데이터는 일 마감 기준 데이터입니다.-->
                    <p class="text-xs xl:text-right lg:text-right text-left p-1.5 pb-0 w-full text-white mt-2 word-">
                        * The data displayed is based on the end of the day before.
                    </p>
                </div>
            </div>

            <article v-if="this.rank_cd != '00'" class="relative">

                <div v-show="true">
                    <search_date v-if="isEmptyObject(dash_d_val_ori) === false" :getYear="currentYear" :getMonth="currentMonth" :dash_d_val="dash_d_val_ori" @searchMonthDashBoard="searchMonthDashBoard" :isDashboard="true"></search_date>
                    <search_date v-else :getYear="currentYear" :getMonth="currentMonth" :dash_d_val="dash_d_val_ori" @searchMonthDashBoard="searchMonthDashBoard" :isDashboard="true"></search_date>
                </div>

                <section class="flex xl:flex-row lg:flex-row flex-col gap-4">
                    <!-- 직급 현황 -->
                    <promotion_status v-if="isEmptyObject(dash_d_val_ori) === false" :dash_d_val="dash_d_val_ori" :rank_name="rank_name" :rank_ord_pv="rank_ord_pv"></promotion_status>
                    <promotion_status v-else :dash_d_val="dash_d_val_ori" :rank_name="rank_name" :rank_ord_pv="rank_ord_pv"></promotion_status>
                    <!-- 옴니트리션 달성현황 -->
                    <consumer_sales_status :cs_omni_list="cs_omni_list" :series="series"></consumer_sales_status>
                </section>
                <section class="month-change-section">
                    <h2 class="text-xl text-[] font-semibold mb-3">Business Status</h2> <!--비즈니스 현황-->
                </section>

<!--                <search_date v-if="Object.keys(dash_d_val_ori).length > 0" :year_month="year_month" :dash_d_val="dash_d_val_ori" @searchMonthDashBoard="searchMonthDashBoard"></search_date>-->


                <!-- 변경된 Business Status //-->
                <div v-if="isNewBusinessStatus">
                    <business_status v-if="isEmptyObject(dash_d_val) === false" :legs_type="legs_type" :showModal="showModal" :dash_d_val="dash_d_val" :dash_d_code="dash_d_code" @getBonusLeglist="getBonusLeglist" @getLegList="getLegList"></business_status>
                    <business_status v-else :legs_type="legs_type" :showModal="showModal" :dash_d_val="dash_d_val" :dash_d_code="dash_d_code" @getBonusLeglist="getBonusLeglist" @getLegList="getLegList"></business_status>
                </div>
                <!-- 변경전 Business Status //-->
                <div v-else>
                    <business_status_v1 v-if="isEmptyObject(dash_d_val) === false" :mrArr="mrArr" :newArr="newArr" :legs="legs" :legs_type="legs_type" :showModal="showModal" :dash_d_val="dash_d_val" :dash_d_code="dash_d_code" :dash_d_name="dash_d_name" :currentYear="currentYear" :currentMonth="currentMonth"></business_status_v1>
                    <business_status_v1 v-else mrArr="mrArr" :newArr="newArr" :legs="legs" :legs_type="legs_type" :showModal="showModal" :dash_d_val="dash_d_val" :dash_d_code="dash_d_code" :dash_d_name="dash_d_name" :currentYear="currentYear" :currentMonth="currentMonth"></business_status_v1>
                </div>
                <!-- 모달 팝업 -->
                <div class="fixed w-full h-full bg-black/80 z-[10] top-0 left-0 " v-if="showModal"></div>
                <!-- 모달 팝업 콘텐츠 -->
                <div class="fixed top-2/4 left-2/4 z-[11] translate-x-[-50%] translate-y-[-50%] bg-white p-5 w-full max-w-xl h-2/3 rounded-md shadow-zinc-500/40 shadow-xl overflow-x-scroll sm:overflow-hidden" v-if="showModal">
                    <div class="text-right mb-2">
                        <button class="close" @click="showModal = false">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 inline-block  fill-[#091223]" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
                        </button>
                    </div>
                    <div class="h-72">
                        <h4 v-if='legs_type=="sales"' class="text-center font-semibold text-[#172e59] text-lg mb-3 break-words">{{  i18n.myoffice.Commission.numberOfFirstGenerationMembers }}</h4> <!-- 산하 1대 레그 별 회원 현황 -->
                        <h4 v-if='legs_type=="bonus"' class="text-center font-semibold text-[#172e59] text-lg mb-3 break-words">{{  i18n.myoffice.Commission.salesUnderTheUmbrellaOfTheCurrentMonth }}</h4> <!-- 당월 산하매출 표기-->
                        <div class="text-center mb-3 h-80 overflow-scroll tailwind-scrollbar-hide scrollbar-hide">

                            <!--Total amount of legs-->
                            <table class="w-full border-separate sm:overflow-hidden">
                                <thead>
                                <tr>
                                    <th v-if='legs_type=="bonus"' class="bg-[#172e59] text-center text-white p-3" >{{  i18n.myoffice.Commission.stage }}</th>
                                    <th class="bg-[#172e59] text-center text-white p-3 w-24">{{  i18n.myoffice.Commission.MemberID }}</th>
                                    <th class="bg-[#172e59] text-center text-white p-3" >{{  i18n.myoffice.Commission.membershipName }}</th>
                                    <th class="bg-[#172e59] text-center text-white p-3">{{  i18n.myoffice.Commission.theHighestRank }}</th><!--최고직급-->
                                    <th v-if='legs_type=="bonus"' class="bg-[#172e59] text-center text-white p-3" >{{  i18n.myoffice.Commission.OrderPV }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="leg in legs" class="bg-[#f0f4ff] text-center cursor-pointer">
                                    <td v-if='legs_type=="bonus"' class="text-center p-2"><router-link  @click="goUrl(leg.sid);" class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{ leg.lv }}</router-link></td>
                                    <td class="text-center p-2"> <router-link  @click="goUrl(leg.sid);" class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{ leg.userid }}</router-link></td>
                                    <td class="text-center p-2"><router-link  @click="goUrl(leg.sid);" class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{ leg.username }}</router-link></td>
<!--                                    <td class="text-center p-2"><router-link  @click="goUrl(leg.sid);" class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{ leg.rank_name_max }}</router-link></td>-->
<!--                                    <td v-if='legs_type=="bonus"' class="text-center p-2"><router-link  @click="goUrl(leg.sid);" class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{formatPrice( leg.r_pv_grp,1) }}</router-link></td>-->
                                    <td class="text-center p-2"><router-link  @click="goUrl(leg.sid);" class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{ leg.rank_name }}</router-link></td>
                                    <td v-if='legs_type=="bonus"' class="text-center p-2"><router-link  @click="goUrl(leg.sid);" class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{formatPrice( leg.ord_pv,1) }}</router-link></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p class="text-[#e1253f] text-sm break-words mb-2">{{  i18n.myoffice.Commission.message3 }}</p>
                        <!--  *회원명을 클릭 하시면 해당 회원 기준의 레그 별 매출 집계를 확인하실 수 있습니다.-->
                    </div>
                </div>
            </article>
        </div>
    </div>
    <!-- 메인페이지 진입시 수당 여부 팝업 -->
    <!--    <modalView v-if="isUfPayModal" @close-modal="isUfPayModal = false" class="relative">-->
    <!--        <IsUfPay v-if="isUfPayModal" @close-modal="isUfPayModal = false" class="z-[999] bg-white min-w-[360px] max-w-[650px] m-auto mt-[50px] p-[20px] lg:min-h-[450px] min-h-[650px] max-h-[700px] rounded-2xl"></IsUfPay>-->
    <!--    </modalView>-->

</template>

<script>

import numeral from "numeral";
import TabItem from './TabItem.vue'
import VueApexCharts from 'vue3-apexcharts'
import 'floating-vue/dist/style.css';
import Loading from 'vue3-loading-overlay';
import VPagination from "@hennge/vue3-pagination";
import modalView from "../modalView";
import IsUfPay from "./IsUfPay";


export default {
    name: "MyofficeNewMain",
    props: ['year_month'],
    components : {
        apexcharts: VueApexCharts,
        Loading,
        modalView,
        VPagination,
        IsUfPay,

    },
    data: () => ({
        loading : false,
        isCommissionBlur : false,
        today : window.Laravel.today,
        showModal: false,
        isUfPayModal: false,
        isVcMember : (window.Laravel.isVcMember === true),
        title : "",
        notice_no : "",
        board_title : "",
        username : "",
        bb_username : "",
        rank_name : "",
        act_yn : "",
        user_kinds : "",
        rank_max_cd : "",
        rank_max_name : "",
        rank_old_name : "",
        rank_cd : "",
        cnt_name : "",
        e_mail : "",
        mobile : "",
        week4Data : [],
        weekATotal : "",
        weekBTotal : "",
        local_edate : "",
        uji_date : "",
        global_edate : "",
        ujiG_date : "",
        autoship_admits_cnt : "",
        autoship_admits_day : "",
        favorite_total_count : "",
        pay_date : "",
        total_amt : "",
        boardlist1 : [],
        boardlist2 : [],
        boardlist3 : [],
        boardlist4 : [],
        Tablist: [
            { id: 1, label: i18n.menu.MyNotice, content: 'board-list1', href:'MyNotice' },
            { id: 2, label: i18n.menu.MyOrderList, content: 'board-list2', href:'MyOrderList' },
            { id: 3, label: i18n.menu.MyInQuriy, content: 'board-list3', href:'MyInQuriy' },
            { id: 4, label: i18n.menu.MyData, content: 'board-list4', href:'MyData' }

        ],
        currentId: 1,
        working_nation : working_nation,
        i18n: window.i18n,
        userid : window.Laravel.user,
        Autoship : false,
        view_pay : true,
        myconfig : "",
        series: [0],
        series2: [10, 20, 30, 40],
        cs_omni_list : {ord_amt : 0,cs_amt : 0, o_rate : 0,ord_mon : ""},
        mem_stamp_h_user : {pv_now: 0 , pv_ready:0},
        mem_stamp_h_giftCard : {now_pv : 0, pv_org : 0, gift_Cnt : 0},
        dash_info : [],

        /** S : getDashBoard*/
        dash_d_val : [],
        dash_d_code : [],
        dash_d_name : [],
        dash_d_val_ori : [],

        //My Rank Information
        bm1 : 0,
        bm2 : 0,
        bm3 : 0,
        bm_total : 0,
        g1 : 0,
        g2 : 0,
        g3 : 0,
        g_total : 0,
        d1 : 0,
        d2 : 0,
        d3 : 0,
        d_total : 0,
        vp1 : 0,
        vp2 : 0,
        vp3 : 0,
        vp_total : 0,
        p1 : 0,
        p2 : 0,
        p3 : 0,
        p_total : 0,
        rank_need : "",
        rank_need1 : 0,
        rank_need2 : 0,
        rank_remark : '',
        rank_ord_pv : 0,
        legs : [],
        mrArr : {},
        newArr : {},
        legs_type : "", //sales , bonus
        currentYear : '',
        currentMonth : '',

        isNewBusinessStatus : true,
    }),
    created() {

        const currentDate = new Date();
        this.currentYear = currentDate.getFullYear();
        this.currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');

        axios.post(window.Laravel.back_url+'/api/memberInfo',{
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            // 수당 발생여부 팝업
            if(response.data.isUfPay === 'N') {
                this.isUfPayModal = true;
            }

            this.username = response.data.username;

            if(response.data.bb_name != "" && response.data.bb_name != null){
                this.bb_username = " / "+response.data.bb_name;
            }

            this.rank_name = response.data.rank_name;
            this.cnt_name = response.data.cnt_name;
            this.e_mail = response.data.e_mail;
            this.mobile = response.data.mobile;
            this.user_kinds = response.data.user_kinds;
            this.rank_max_cd = response.data.rank_max_cd;
            this.rank_max_name = response.data.rank_max_name;
            this.rank_cd = response.data.rank_cd;

        });
    },
    mounted() {

        // // 현재 날짜를 가져옵니다.
        // const currentDate = new Date();
        // // 월을 가져옵니다. (0부터 시작하므로 1을 더해줍니다.)
        // const year = currentDate.getFullYear();
        // const month = currentDate.getMonth() + 1;
        // // 현재 월을 데이터에 할당합니다.
        // this.currentMonth = month;
        // this.currentYear = year;


        // console.log(this.currentYear + " <--- this.currentYear A")
        // console.log(this.currentMonth + " <--- this.currentMonth A")

        this.searchMonthDashBoard('', '');
    },
    methods:{

        getBonusLeglist(rank,type){//본인 산하 직추천 명단

            this.showModal = true;
            this.legs_type = "bonus";
            this.legs = [];
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/getBonusLeglist',{rank : rank, type : type,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.loading = false;
                this.legs = response.data;
            });
        },


        // 객체가 비어 있는지 확인하는 함수
        isEmptyObject(obj) {
            return Object.keys(obj).length === 0;
        },
        /**
         * deep copy
         * @param obj
         * @param hash
         * @returns {*[]|{}|RegExp|*|Date|null}
         */
        deepCopy(obj, hash = new WeakMap()) {
            if (obj === null) return null;
            if (typeof obj !== 'object') return obj;
            if (obj instanceof Date) return new Date(obj);
            if (obj instanceof RegExp) return new RegExp(obj.source, obj.flags);
            if (hash.has(obj)) return hash.get(obj);

            const clone = Array.isArray(obj) ? [] : {};
            hash.set(obj, clone);

            Object.keys(obj).forEach(key => {
                clone[key] = this.deepCopy(obj[key], hash);
            });

            return clone;
        },

        searchMonthDashBoard(year, month){

            this.loading = true;
            this.currentYear = (year !== '' ) ? year : this.currentYear;
            this.currentMonth = (month !== '' ) ? month : this.currentMonth;
            this.isNewBusinessStatus = ( Number(this.currentYear + this.currentMonth) >= 202404 || this.currentYear === '') ? true : false;

            // console.log(year + " <-- year");
            // console.log(month + " <-- month");
            // console.log(this.currentYear + " <-- this.currentYear");
            // console.log(this.currentMonth + " <-- this.currentMonth");

            this.apiDashboard().then((data) => {
                this.apiGetDashBoard().then((data) => {
                    this.loading = false;
                });
            });


        },
        apiDashboard(){

            return new Promise((resolve, reject) => {
                axios.post(window.Laravel.back_url+'/api/dashboard',{
                    s_year : this.currentYear,
                    s_month : this.currentMonth,
                },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {


                    this.cs_omni_list = response.data.cs_omni_list;
                    this.cs_omni_list.ord_mon = this.cs_omni_list.ord_mon.substring(4, 6) + "/" + this.cs_omni_list.ord_mon.substring(0, 4)
                    this.series[0] = this.cs_omni_list.o_rate;
                    this.mem_stamp_h_user = response.data.mem_stamp_h_user;
                    this.mem_stamp_h_giftCard = response.data.mem_stamp_h_giftCard;


                    resolve(1);
                })
            });
        },

        applyBusinessStatus(response){

            response.data.forEach((value, index) => {

                this.dash_d_val[value.d_col] = value.d_val??"";
                this.dash_d_name[value.d_col] = value.d_name??"";
                this.dash_d_code[value.d_col] = value.d_code??"";

                if(value.d_col == "ACT_YN"){
                    this.act_yn = value.d_code;
                }else if (value.d_col === 'PAY_DATE'){
                    this.currentYear = value.d_code.substring(0, 4);
                    this.currentMonth = value.d_code.substring(4, 6);
                }


                /* 해당월, 전월, 전전월 구분은 D_CODE 컬럼을 이용하여 구분함
                  따라서, D_COL 컬럼과 D_CODE 컬럼을 합처서 변수명으로 사용하여 월을 구분함
                */
                if(value.d_col == "LV_1" || value.d_col == "LV_2"){
                    this.dash_d_val[value.d_col + value.d_name] = value.d_val;
                    this.dash_d_code[value.d_col + value.d_name] = value.d_code;
                }
                /*주문합계*/
                if(
                    value.d_col == "ORD_AMT_A" || value.d_col == "ORD_AMT_R" || value.d_col == "ORD_AMT"
                    || value.d_col == "ORD_PV_A" || value.d_col == "ORD_PV_R" || value.d_col == "ORD_PV"
                    || value.d_col == "R_ORD_AMT_A" || value.d_col == "R_ORD_AMT_R"  || value.d_col == "R_ORD_AMT"
                    || value.d_col == "R_ORD_PV_A" || value.d_col == "R_ORD_PV_R" || value.d_col == "R_ORD_PV"

                ){
                    this.dash_d_val[value.d_col + value.d_code] = value.d_val??0;

                }
                if(value.d_col == "D_ORD_PV_A" || value.d_col == "D_ORD_PV_R" || value.d_col == "D_ORD_PV" || value.d_col == "D_LEG_CNT"){
                    this.dash_d_val[value.d_col + value.d_code] = value.d_val??0;
                }
                //산하 PV
                if(value.d_col == "LEG_N"){
                    this.dash_d_code[value.d_col + value.d_code] = value.d_name;
                    this.dash_d_val[value.d_col + value.d_code] = value.d_val??0;
                }


                // 미국에서만 사용하는 currently pv 값
                if(value.d_col == "ORD_PV"){
                    this.rank_ord_pv = (value.d_val === null ) ? "0" : value.d_val
                }

                if(value.d_col == "RANK_NEED"){
                    this.rank_need = value.d_name; //달성인원직급
                    this.rank_need1 = value.d_code; //필요회원수
                    this.rank_need2 = value.d_val; //달성인원수
                }

                if(value.d_col == "RANK_OLD"){
                    this.rank_old_name = value.d_val; //달성인원수
                }

                if(value.d_col == "RANK01"){//MEMBER
                    this.g1 = value.d_name; //나의 하선 당월
                    this.g2 = value.d_code; //나의 하선 전월
                    this.g3 = value.d_val; //나의 하선 전전월
                    this.g_total =  parseInt(this.g1) + parseInt(this.g2) + parseInt(this.g3);
                }
                if(value.d_col == "RANK10"){//BRONZE
                    this.g1 = value.d_name; //나의 하선 당월
                    this.g2 = value.d_code; //나의 하선 전월
                    this.g3 = value.d_val; //나의 하선 전전월
                    this.g_total =  parseInt(this.g1) + parseInt(this.g2) + parseInt(this.g3);
                }
                if(value.d_col == "RANK20"){//SILVER
                    this.g1 = value.d_name; //나의 하선 당월
                    this.g2 = value.d_code; //나의 하선 전월
                    this.g3 = value.d_val; //나의 하선 전전월
                    this.g_total =  parseInt(this.g1) + parseInt(this.g2) + parseInt(this.g3);
                }
                if(value.d_col == "RANK30"){//GOLD
                    this.g1 = value.d_name; //나의 하선 당월
                    this.g2 = value.d_code; //나의 하선 전월
                    this.g3 = value.d_val; //나의 하선 전전월
                    this.g_total =  parseInt(this.g1) + parseInt(this.g2) + parseInt(this.g3);
                }
                // if(value.d_col == "RANK40"){//BRANCH MANAGER
                //     this.bm1 = value.d_name; //나의 하선 당월
                //     this.bm2 = value.d_code; //나의 하선 전월
                //     this.bm3 = value.d_val; //나의 하선 전전월
                //     this.bm_total =  parseInt(this.bm1) + parseInt(this.bm2) + parseInt(this.bm3);
                // }

                if(value.d_col == "RANK42"){//MANAGER
                    this.bm1 = value.d_name; //나의 하선 당월
                    this.bm2 = value.d_code; //나의 하선 전월
                    this.bm3 = value.d_val; //나의 하선 전전월
                    this.bm_total =  parseInt(this.bm1) + parseInt(this.bm2) + parseInt(this.bm3);
                }

                if(value.d_col == "RANK45"){//GENERAL_MANAGER
                    this.bm1 = value.d_name; //나의 하선 당월
                    this.bm2 = value.d_code; //나의 하선 전월
                    this.bm3 = value.d_val; //나의 하선 전전월
                    this.bm_total =  parseInt(this.bm1) + parseInt(this.bm2) + parseInt(this.bm3);
                }
                if(value.d_col == "RANK50"){//DIRECTOR
                    this.d1 = value.d_name; //나의 하선 당월
                    this.d2 = value.d_code; //나의 하선 전월
                    this.d3 = value.d_val; //나의 하선 전전월
                    this.d_total = parseInt(this.d1) + parseInt(this.d2) + parseInt(this.d3);
                }

                if(value.d_col == "RANK60"){//VICE PRESIDENT
                    this.vp1 = value.d_name; //나의 하선 당월
                    this.vp2 = value.d_code; //나의 하선 전월
                    this.vp3 = value.d_val; //나의 하선 전전월
                    this.vp_total = parseInt(this.vp1) + parseInt(this.vp2) + parseInt(this.vp3);
                }

                if(value.d_col == "RANK70"){//PRESIDENT
                    this.p1 = value.d_name; //나의 하선 당월
                    this.p2 = value.d_code; //나의 하선 전월
                    this.p3 = value.d_val; //나의 하선 전전월
                    this.p_total = parseInt(this.p1) + parseInt(this.p2) + parseInt(this.p3);
                }
            });
        },
        /**
         * 202404 이전 마이오피스 데이터 내역
         * @param response
         */
        applyLegacyBusinessStatus(response){

            response.data.forEach((value, index) => {

                if(value.d_col =="RANK_CURR"){

                    this.rank_curr_code = value.d_code;
                    this.rank_curr_name = value.d_val;
                }

                this.dash_d_val[value.d_col] = value.d_val??"";
                this.dash_d_name[value.d_col] = value.d_name??"";
                this.dash_d_code[value.d_col] = value.d_code??"";

                if ((value.d_col).substring(0, 2) == "MR") {
                    for (var i = 0; i < 4; i++) {
                        var currentMonthValue = this.currentMonth - i;
                        if (value.d_col.slice(-2).replace(/\b0*(\d+)\b/g, '$1') == currentMonthValue) {
                            if (!this.mrArr[value.d_val]) {
                                this.mrArr[value.d_val] = []; // 배열로 초기화
                            }
                            // 배열에 값을 추가
                            if(value.d_val == "MY_RANK" || value.d_val == "RANK_KIND"  ){
                                this.mrArr[value.d_val].push(value.d_name);
                            }else{
                                this.mrArr[value.d_val].push(value.d_code);
                            }
                        }
                    }
                }
                if ((value.d_col).substring(0, 3) == "NEW") {
                    for (var i = 0; i < 4; i++) {
                        var currentMonthValue = this.currentMonth - i;
                        if (value.d_col.slice(-2).replace(/0/g, '') == currentMonthValue) {
                            if (!this.newArr[value.d_val]) {
                                this.newArr[value.d_val] = []; // 배열로 초기화
                            }
                            // 배열에 값을 추가
                            this.newArr[value.d_val].push(value.d_code);
                        }
                    }
                }




                /* 해당월, 전월, 전전월 구분은 D_CODE 컬럼을 이용하여 구분함
                  따라서, D_COL 컬럼과 D_CODE 컬럼을 합처서 변수명으로 사용하여 월을 구분함
                */
                // 230714 dylee 미주 요청으로 이번달 직급 유지 여부를 ufPAY_DASH 에 추가요청함
                if(value.d_col == "ACT_YN"){
                    this.act_yn = value.d_val;
                }

                if(value.d_col == "LV_1" || value.d_col == "LV_2"){
                    this.dash_d_val[value.d_col + value.d_name] = value.d_val;
                    this.dash_d_code[value.d_col + value.d_name] = value.d_code;
                }
                /*주문합계*/
                if(
                    value.d_col == "ORD_AMT_A" || value.d_col == "ORD_AMT_R" || value.d_col == "ORD_AMT"
                    || value.d_col == "ORD_PV_A" || value.d_col == "ORD_PV_R" || value.d_col == "ORD_PV"
                    || value.d_col == "R_ORD_AMT_A" || value.d_col == "R_ORD_AMT_R"  || value.d_col == "R_ORD_AMT"
                    || value.d_col == "R_ORD_PV_A" || value.d_col == "R_ORD_PV_R" || value.d_col == "R_ORD_PV"

                ){
                    this.dash_d_val[value.d_col + value.d_code] = value.d_val??0;

                }
                if(value.d_col == "D_ORD_PV_A" || value.d_col == "D_ORD_PV_R" || value.d_col == "D_ORD_PV" || value.d_col == "D_LEG_CNT"){
                    this.dash_d_val[value.d_col + value.d_code] = value.d_val??0;
                }

                if(value.d_col == "RANK_NEED"){
                    this.rank_need = value.d_name; //달성인원직급
                    this.rank_need1 = value.d_code; //필요회원수
                    this.rank_need2 = value.d_val; //달성인원수

                }


                if(value.d_col == "D2_RANK_30"){//GOLD
                    this.g1 = value.d_name; //나의 하선 당월
                    this.g2 = value.d_code; //나의 하선 전월
                    this.g3 = value.d_val; //나의 하선 전전월
                    this.g_total =  value.d_code;
                }
                if(value.d_col == "D2_RANK_40"){//BRANCH MANAGER
                    this.bm1 = value.d_name; //나의 하선 당월
                    this.bm2 = value.d_code; //나의 하선 전월
                    this.bm3 = value.d_val; //나의 하선 전전월
                    this.bm_total =  value.d_code;
                }
                if(value.d_col == "D2_RANK_50"){//DIRECTOR
                    this.d1 = value.d_name; //나의 하선 당월
                    this.d2 = value.d_code; //나의 하선 전월
                    this.d3 = value.d_val; //나의 하선 전전월
                    this.d_total = value.d_code;
                }

                if(value.d_col == "D2_RANK_60"){//VICE PRESIDENT
                    this.vp1 = value.d_name; //나의 하선 당월
                    this.vp2 = value.d_code; //나의 하선 전월
                    this.vp3 = value.d_val; //나의 하선 전전월
                    this.vp_total = value.d_code;
                }

                if(value.d_col == "D2_RANK_70"){//PRESIDENT
                    this.p1 = value.d_name; //나의 하선 당월
                    this.p2 = value.d_code; //나의 하선 전월
                    this.p3 = value.d_val; //나의 하선 전전월
                    this.p_total = value.d_code;
                }
            })
        },
        apiGetDashBoard(){
            //this.loading = true;
            return new Promise((resolve, reject) => {
                axios.post(window.Laravel.back_url+'/api/getDashBoard',{
                    s_year : this.currentYear,
                    s_month : this.currentMonth,
                },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {

                    if ( this.isNewBusinessStatus === true ){
                        this.applyBusinessStatus(response)
                    }else{
                        this.applyLegacyBusinessStatus(response)
                    }

                    // deep copy
                    if ( this.isEmptyObject(this.dash_d_val_ori) === true ) {
                        this.dash_d_val_ori = this.deepCopy(this.dash_d_val);
                    }

                    // console.group("==== dash_d_val ====");
                    // console.dir(this.dash_d_val)
                    // console.dir(typeof this.dash_d_val)
                    // console.log(this.isEmptyObject(this.dash_d_val))
                    // console.groupEnd("==== dash_d_val ====");

                    resolve(1);
                })
            });
        },
        /**
         * 숫자 포맷
         * @param val 숫자 값
         * @param type dollar 의 값은 0.00 으로 표기, undefined 는 천단위로 콤마 표기
         * @returns string
         */
        numberFormat(val, type){
            if ( type === 'dollar') {
                return numeral(val).format('$0,0.00')
            }
            return String(val).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        },
        goUrl(sid){
            location.href='/MyOrderLegs/'+sid;
        },
        getLegList(month){//본인 산하 직추천 명단
            this.showModal = true;
            this.legs_type = "sales";
            this.legs = [];
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/getLeglist',{month : month,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.legs = response.data;
                this.loading = false;
            });
        },

        get4WeekData($list) {
            var i = 3 ;
            for( i ; i >= 0 ; i--){
                if( i == 3){
                    var a_week_tot_data = $list.A_WEEK_TOT4.d_val;
                    var b_week_tot_data = $list.B_WEEK_TOT4.d_val;
                    var d_remark_data = $list.A_WEEK_TOT4.d_remark;
                }
                if( i == 2){
                    var a_week_tot_data = $list.A_WEEK_TOT3.d_val;
                    var b_week_tot_data = $list.B_WEEK_TOT3.d_val;
                    var d_remark_data = $list.A_WEEK_TOT3.d_remark;
                }
                if( i == 1){
                    var a_week_tot_data = $list.A_WEEK_TOT2.d_val;
                    var b_week_tot_data = $list.B_WEEK_TOT2.d_val;
                    var d_remark_data = $list.A_WEEK_TOT2.d_remark;
                }
                if( i == 0){
                    var a_week_tot_data = $list.A_WEEK_TOT1.d_val;
                    var b_week_tot_data = $list.B_WEEK_TOT1.d_val;
                    var d_remark_data = $list.A_WEEK_TOT1.d_remark;
                }
                this.week4Data.push({
                    pay_date : $list.PAY_DATE.d_code - i ,
                    remark : d_remark_data ,
                    a_week_tot : a_week_tot_data ,
                    b_week_tot : b_week_tot_data ,

                });
            }

            this.weekATotal = $list.A_WEEK_TOT.d_val;
            this.weekBTotal = $list.B_WEEK_TOT.d_val;

        },
        drawChart($list) {
            var chartwidth = $('#piechart').width();
            if($list)
            {

                var chartchl = $list.chartchl.split("|");
                var ctx = document.getElementById('myChart2').getContext('2d');

                var cartchd = $list.chartchd;
                var txtArray = new Array();
                cartchd = cartchd.split(",");
                for(var i = 0 ; i < cartchd.length; i++){
                    var subArray = new Array(cartchd[i]);
                    txtArray.push(cartchd[i]);

                }

                var myChart = new Chart(ctx, {
                    type: 'doughnut',
                    data: {
                        labels: chartchl,
                        datasets: [{
                            label: i18n.myoffice.Commission.BonusRate+'(%)',  /* 수당비율 */
                            data: txtArray,
                            backgroundColor: [
                                '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
                            ],
                            borderColor: [
                                '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
                            ],
                            borderWidth: 1
                        }]
                    },

                    options: {
                        title: {
                            display: true,
                            text: ''
                        },
                        maintainAspectRatio: false,
                    }
                });

            }
        },
        drawChart2($list) {
            var chartwidth = $('#piechart').width();
            if($list)
            {

                var chartchl = new Array(i18n.myoffice.jan,i18n.myoffice.feb,i18n.myoffice.mar,i18n.myoffice.apr,i18n.myoffice.may,i18n.myoffice.jun,
                    i18n.myoffice.jul,i18n.myoffice.aug,i18n.myoffice.sep,i18n.myoffice.oct,i18n.myoffice.nov,i18n.myoffice.dec);

                var ctx = document.getElementById('myChart1').getContext('2d');

                var cartchd = $list.chartchd;
                var txtArray = new Array();
                cartchd = cartchd.split(",");
                for(var i = 0 ; i < cartchd.length; i++){
                    var subArray = new Array(cartchd[i]);
                    txtArray.push(cartchd[i]);

                }
                var labels  = Array(i18n.myoffice.jan,i18n.myoffice.feb,i18n.myoffice.mar,i18n.myoffice.apr,i18n.myoffice.may,i18n.myoffice.jun,
                    i18n.myoffice.jul,i18n.myoffice.aug,i18n.myoffice.sep,i18n.myoffice.oct,i18n.myoffice.nov,i18n.myoffice.dec);

                var myChart = new Chart(ctx, {
                    type: 'horizontalBar',
                    data: {
                        labels: Array(i18n.myoffice.jan,i18n.myoffice.feb,i18n.myoffice.mar,i18n.myoffice.apr,i18n.myoffice.may,i18n.myoffice.jun,
                            i18n.myoffice.jul,i18n.myoffice.aug,i18n.myoffice.sep,i18n.myoffice.oct,i18n.myoffice.nov,i18n.myoffice.dec),
                        datasets: [{
                            label: i18n.myoffice.Commission.BonusRate+'(%)',  /* 수당비율 */
                            data: txtArray,
                            backgroundColor: [
                                '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
                            ],
                            borderColor: [
                                '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
                            ],
                            borderWidth: 1
                        }]
                    },
                    options: {
                        title: {
                            display: true,
                            text: i18n.myoffice.dashboard.yearmonthbonus /* 년 월별 수당금액 */
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    userCallback: function(value, index, values) {
                                        if(parseInt(value) >= 1000){
                                            value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                        }
                                        return value;
                                    }
                                }
                            }]
                        },
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    var value = $list.data[tooltipItem.index];
                                    if(parseInt(value) >= 1000){
                                        value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                    }
                                    return value;
                                }
                            } // end callbacks:
                        },
                    },
                });

            }
        },
        mainBoard1($list){
            $list.forEach((value, index) => {
                numeral.defaultFormat('$0,0[.]00');
                // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                this.boardlist1.push({
                    no: value.no,
                    title: value.title,
                    date: value.reg_date,
                    url: "MyNotice/"+value.no,

                });
            });
        },
        mainBoard2($list){
            $list.forEach((value, index) => {
                numeral.defaultFormat('$0,0[.]00');
                // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                this.boardlist2.push({
                    ord_no: value.ord_no,
                    title: value.title,
                    date: value.reg_date,
                    ord_date : value.ord_date,
                    ord_amt : value.ord_amt,
                    ord_pv : value.ord_pv,
                    kind_name : value.kind_name,
                    cnt_name : value.cnt_name,
                    deli_finish : value.deli_finish,
                    ord_kind_name : value.ord_kind_name,
                    //url: value.i_list ? "",

                });
            });
        },
        mainBoard3($list){
            $list.forEach((value, index) => {
                numeral.defaultFormat('$0,0[.]00');
                // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                this.boardlist3.push({
                    flag: value.flag,
                    title: value.title,
                    reg_date: value.reg_date,
                    reply_cnt: value.reply_cnt,
                    reply_reg_date: value.reply.reg_date,
                    reply_user_name: value.reply.username,
                    url: "MyInQuiryView/"+value.no,

                });
            });
        },
        mainBoard4($list){
            $list.forEach((value, index) => {
                numeral.defaultFormat('$0,0[.]00');
                // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                this.boardlist4.push({
                    notice_yn : value.notice_yn == 'Y' ? "<span class='notice'>Notice</span>" : "",
                    attach_cnt : value.attach_cnt ? "<i class=\"fas fa-file-text-o attach\"></i>" : "",
                    no: value.no,
                    title: value.title,
                    date: value.reg_date,
                    url: "MyData/"+value.no,

                });
            });
        },
        tabChange(id){
            this.Tablist.id;
            this.currentId = id;
        },
        formatPrice(value,digit) {
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        goAutoLogin(mode, url) {
            window.open("https://register-global.gcoop.com/AutoLogin?xid=" + window.Laravel.xid_info + "&token=" + window.Laravel.xid_info);

            // let config = {
            //     headers: {
            //         Authorization: 'Bearer ' + window.Laravel.access_token
            //     }
            // };
            //
            // axios.post(window.Laravel.back_url +'/api/goAutoLogin',{
            //     mode: mode,
            //     url: url,
            //     bz_cd: window.Laravel.bz_cd,
            // },config).then(response => {
            //     if(response.data.href_url != ""){
            //         window.open(response.data.href_url);
            //     }
            // }).catch(e => {
            // });
        },
        isUfPayModalClose() {
            this.isUfPayModal = false;
        }

    },
}

</script>

<style scoped>
.board-inner{background:#f0f2f4 !important;}

/*.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}*/

.break-keep{word-break: keep-all;}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.month-change-section{
}

</style>
