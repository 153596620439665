<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>
  <section class="container mx-auto lg:py-6 md:p-0" >
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 px-6 lg:max-w-7xl lg:px-8 bg-white">
        <div class="w-full lg:flex lg:justify-start">
          <div class="w-full">
            <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start flex-col">
                  <div class="w-full divide-y bg-white lg:p-2" id="infomodify" >

                    <div class="xl:text-2xl lg:text-xl text-lg mt-3 mb-6">Registration Inquiry</div>
                      <div class="w-full content-center overflow-x-scroll xl:scrollbar-hide lg:scrollbar-hide">
                          <div class="w-full p-4">
                              <span class="block text-sm lg:text-base pb-4">
                              In order to sign up for a business or personal account,
                              sponsor’s information is required to complete the registration.<br>
                              Please fill out all the fields and include your address in the memo field.<br>
                              A sponsor will be selected based on your home address and
                              we will contact you regarding this information.<br>
                              Thank you so much for your inquiry!
                              </span>
                              <span class="block text-sm lg:text-base">
                              사업 및 회원 가입을 위해서는 추천인 정보가 필요합니다.<br>
                              입력란을 빠짐없이 작성해 주시고 Memo 에
                              현재 사시는 곳(City 까지만)을 작성해 주시면
                              가까운 곳에 계신 분을 선별하여 연락 드리도록 하겠습니다.<br>
                              항상 즐거운 일만 가득하시길 바랍니다.<br>
                              감사합니다.
                              </span>
                          </div>
                          <table class="w-full table-auto border-collapse border border-slate-400">
                            <tbody>
                                <tr>
                                  <td class="border border-slate-300 bg-[#f9f9f9] p-2 xl:text-base lg:text-base text-sm">Name</td>
                                  <td class="border border-slate-300 p-2"><input type="text" name="user_name" class=" border-[#BBB]" v-model="user_name"></td>
                                </tr>
                                <tr>
                                  <td class="border border-slate-300 bg-[#f9f9f9] px-2 xl:text-base lg:text-base text-sm">E-mail</td>
                                  <td class="border border-slate-300 p-2"><input type="text" name="user_email" class=" border-[#BBB]" v-model="user_email"></td>
                                </tr>
                                <tr>
                                  <td class="border border-slate-300 bg-[#f9f9f9] px-2 xl:text-base lg:text-base text-sm">Mobile</td>
                                  <td class="border border-slate-300 p-2"><input type="text" name="user_mobile" class=" border-[#BBB]" v-model="user_mobile" maxlength="10" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"></td>
                                </tr>
                                <tr>
                                  <td class="border border-slate-300 bg-[#f9f9f9] px-2 xl:text-base lg:text-base text-sm">Title</td>
                                  <td class="border border-slate-300 p-2"><input                                                                                                                                                      type="text" name="title" class=" border-[#BBB]" v-model="title"></td>
                                </tr>
                                <tr>
                                  <td class="border border-slate-300 bg-[#f9f9f9] px-2 xl:text-base lg:text-base text-sm">Memo</td>
                                  <td class="border border-slate-300 p-2 "><textarea name="contents" id="contents" class="w-full h-40 border-[#BBB]" type="text" v-model="contents"></textarea></td>
                                </tr>
                            </tbody>
                          </table>
                      </div>
                      <div class="xl:text-left lg:text-left text-center xl:py-3 lg:py-3 py-2">
                        <div><vue-recaptcha ref="recaptcha" sitekey="6Lfrz3wkAAAAAIZkkULRXj9qyUHLO_eTYZJKHtn9"  @verify="verifyMethod"/></div>
                      </div>
                      <button type="button" v-on:click="send"  class="mt-6 my-2 py-2 px-4 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] w-full md:w-1/6  rounded-md">Submit</button> <!-- 동의하기 -->
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>
    </div>

  </section>



</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import MypageTop from './mypage/MypageTop.vue';
import SideMenu from './mypage/SideMenu.vue';
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import {createPopper} from "@popperjs/core";
import modalView from "./modalView";
import vSelect from "vue-select";
import dayjs from "dayjs";
import MyRegister_agree from "./MyRegister_agree";
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: "MyRegister_inquiry",
  components : {
    MyRegister_agree,
    VueSimpleAlert,
    ShoppingCartIcon,
    MypageTop,
    SideMenu,
    Loading,
    modalView,
    vSelect,
    VueRecaptcha
  },
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
    no_search_messsage: '',
    isLoggedIn: false,
    loading : false,
    recaptchaRes : "",

    user_name :"",
    user_mobile :"",
    user_email :"",
    title :"",
    contents :"",

  }),

  beforeCreate() {
    this.loading = true;
    if (window.Laravel.isLoggedin) {
      VueSimpleAlert.alert("This page is only for guests","","",{allowOutsideClick: false}).then((result) => {
        location.replace("/");
      });
    }else{
      this.loading = false;
    }

  },
  created() {
  },
  watch : {

  },
  mounted() {

  },
  methods:{

    verifyMethod(response) {//구글 리캡챠
      console.log(response);
      this.recaptchaRes = response;
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute();
    },
    send(){
        this.loading = true;
        if(this.recaptchaRes == ""){
          VueSimpleAlert.alert("Please check recaptcha","","",{allowOutsideClick: false}).then((result) => {})
          this.loading = false;
          return;
        }
        axios.post(window.Laravel.back_url+'/api/insertSaveInquiryUs', {
          user_name : this.user_name,
          user_mobile : this.user_mobile,
          user_email : this.user_email,
          title : this.title,
          contents : this.contents,
        }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        if (response.data.ret == "1") {
          VueSimpleAlert.alert(i18n.inquriy.inquriy.inquriy_notice_ok,"","",{allowOutsideClick: false}).then((result) => { //문의 접수가 완료 되었습니다.
            if (result) {
              location.replace("/");
            }
          });
        }else{
          VueSimpleAlert.alert("Please check again","","",{allowOutsideClick: false}).then((result) => {}) //
          this.loading = false;
          return;
        }

      })


    },
  },
}


</script>

<style >
.v-select{ min-width: 100px; z-index:0 }
.m_menu{display:none;}
</style>

