<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>

  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 px-4 sm:px-6 lg:max-w-7xl lg:px-8 bg-slate-100 lg:bg-white">
        <div class="w-full lg:flex lg:justify-between">
          <div class="flex justify-start items-start flex-col space-y-4 mt-4 lg:mt-8">
            <div>
              <div class="inline-block pl-2">
                <p class="text-2xl p-0 m-0 leading-5">Online Academy</p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full pt-10 lg:flex lg:justify-start">
          <AcademySide :no="this.no" :laravel="this.laravel"></AcademySide>
          <div class="w-full">
            <div class="max-w-8xl mx-auto py-8 px-4 sm:py-6 sm:pl-6 lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="bg-white">
                <nav class="flex flex-col sm:flex-row border">
                  <button class="text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none"
                          :class ="this.cate_3_no == cate_3.id ? 'ext-blue-500 border-b-2 font-medium border-blue-500' : ''"
                          @click="getOnlineAcademyContentsList(cate_3.id)" v-for="cate_3 in this.cate_top_list" :key="cate_3.id">{{ cate_3.category_title }}</button>
                  <button class="text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none"
                          :class ="this.all_yn == 'Y' ? 'ext-blue-500 border-b-2 font-medium border-blue-500' : ''"
                          @click="getOnlineAcademyContentsList('all')">See All</button>
                </nav>
              </div>
              <div class="text-right py-1">
                <input type="text" v-model='this.search_text' placeholder="Search" class="w-4/6 md:w-64 px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                <i class="fas fa-search pl-1 cursor-pointer" @click="getOnlineAcademyContentsList(this.cate_3_no)" aria-hidden="true"></i>
              </div>
              <div class="w-full pb-10">
                <div class="flex justify-start item-start space-y-2 flex-col">
                  <div>
                    <span class="text-sm" v-html="no_search_messsage"></span>
                    <div v-if="contents.length" class="mt-6 grid grid-cols-2 gap-x-1 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                      <div v-for="ctnts in contents" :key="ctnts.id" class="group relative bg-white border-black m-1.5 rounded-lg p-2 border-slate-900">
                        <div class="w-full bg-gray-100 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:aspect-none">
                          <img :src="'https://cdn.gcoop.me/public/video_contents/MYS/' + ctnts.thumbnail" class="w-full object-center object-cover max-w-none	" />
                        </div>
                        <div class="mt-4 justify-between ">
                          <div class="h-12 lg:h-13 md:h-13 sm:h-12 ">
                            <h3 class="text-gray-700 mb-2.5 text-sm lg:text-base sm:text-sm text-ellipsis overflow-hidden line-clamp-2 lg:h-13 md:h-13 h-11 font-light">
                              <a :href="'/MypageAcademyView/' + ctnts.id" class="cursor-pointer">
                                <span aria-hidden="true" class="absolute inset-0" style="bottom: 100px;" />
                                {{ ctnts.title }}
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <v-pagination
                    v-model="page"
                    :pages=total_page
                    :range-size="1"
                    active-color="#DCEDFF" class="text-center">
                  </v-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import AcademySide from './AcademySide.vue'
import Loading from 'vue3-loading-overlay';
import VPagination from "@hennge/vue3-pagination";

export default {
  name: "mypage",
  props:['no'],
  watch : {
    page: function(){
      if(this.cate_3_no == ""){
        this.cate_3_no = "all";
      }
      this.getOnlineAcademyContentsList(this.cate_3_no)
    }
  },
  components : {
    VueSimpleAlert,
    ShoppingCartIcon,
    AcademySide,
    Loading,
    VPagination
  },
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
    limit: 4,
    no_search_messsage: '',
    isLoggedIn: false,
    max_qty : 99,
    loading : false,
    cate_top_list : [],
    contents : [],
    cate_3_no : '',
    all_yn : 'N',
    VPagination: [1],
    search_text : '',
    total_count : 0,
    page : 1,
    total_page : 1,
    fullPage : true,
  }),
  created() {
  },
  mounted() {

    this.getOnlineAcademyCateogry();
    this.getOnlineAcademyContentsList('all');
    if (window.Laravel.isLoggedin) {
      this.isLoggedIn = true;
      this.user = window.Laravel.user;
    }
  },
  methods:{
    getOnlineAcademyCateogry(){
      let config = {
        headers: {
          Authorization: 'Bearer ' + this.laravel.access_token
        }
      };

      axios.post(window.Laravel.back_url+'/api/getOnlineAcademyCateogry',{
        no : this.no,
        bz_cd : window.Laravel.bz_cd,
      },config)
        .then(response => {
          if(response.data.depth == "1" && response.data.no != ""){
            location.replace('/MypageAcademy/' + response.data.no);
          }
          this.cate_top_list =response.data.list;
        });
    },
    list_url(link){
      location.href="/product/list/"+link;
    },
    getOnlineAcademyContentsList(cate_no){
      this.loading = true;
      this.all_yn = (cate_no == 'all') ? 'Y' : 'N';
      if(this.cate_3_no != cate_no){
        this.page = 1;
      }
      if(cate_no == 'all'){
        this.cate_3_no = '';
        this.all_yn = 'Y';
      }else{
        this.cate_3_no = cate_no;
        this.all_yn = 'N';
      }

      let config = {
        headers: {
          Authorization: 'Bearer ' + window.Laravel.access_token
        }
      };
      axios.post(window.Laravel.back_url+'/api/getOnlineAcademyContentsList',{
        cate_3_no : this.cate_3_no,
        no : this.no,
        page : this.page,
        search_text : this.search_text,
        bz_cd: window.Laravel.bz_cd
      },config)
      .then(response => {
        this.total_count = response.data.total_count;
        this.total_page = parseInt(this.total_count/12)+parseInt(this.total_count%12 > 0 ? 1 : 0);
        this.loading = false;
        if(this.total_count == 0){
          VueSimpleAlert.alert("There are no search results","","",{});
          this.list_msg = "No data";
        }
        this.contents =response.data.list.data.data ?? response.data.list.data;
      });
    },
  }
}
</script>

<style scoped>

</style>
