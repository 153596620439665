<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
      <address_search @set_address="set_address" @close_address_search="close_address_search" :nt_cd="this.nt_cd" />
    </modalView>

    <div id="mem_info" class="myo-modi-mem">

        <section class="accordion_notice_box">
            <div class="accordion_notice_item">
                <a class="accordion_notice_tit" href="#n">
                    <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                    {{ i18n.myoffice.myoSearchForm.notice }}
                    <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></div>
                </a>
                <div class="accordion_notice_cont">
                    <div class="myo_search_notice">
                        <p><span class="signiture remark1">&#42;</span>&nbsp;<span>{{ i18n.join.join.required }}</span></p>
                    </div>
                </div>
            </div>
        </section>

        <form name="uni_form" method="post" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
            <input type=hidden name=exec_mode value='modify'>

            <!--회원정보 시작-->
            <h2 class="myo_table_tit">{{ i18n.myoffice.MemberInfomation }}</h2> <!--회원정보 -->
            <ul id="myo_form" class="mgb_30">
                <li class="item table_form top-li">
                    <span class="item_title">{{ i18n.myoffice.memberName }}</span>
                    <span class="item_content">{{ mem.username }}</span>
                </li><!-- 회원명-->

                <li class="item table_form">
                    <span class="item_title">{{ i18n.myoffice.MemberId }}</span>
                    <span class="item_content">{{ mem.userid }}</span>
                </li><!-- 회원아이디-->

                <li class="item table_form">
                    <span class="item_title title_4">{{ i18n.myoffice.birthday }}</span>
                    <span class="item_content m_contents">{{ mem.birthday }}</span>
                </li>

                <li class="item table_form" v-if="false">
                    <span class="item_title title_4">{{ i18n.myoffice.tel }}</span>
                    <span class="item_content m_contents">
                      <input name="tel" id="tel" v-model="tel" class="type_tel tel" style="ime-mode:disabled;" type="text">
                    </span>
                </li>

                <li class="item table_form position-box">
                    <span class="item_title title_4_2"><span class="signiture remark1">&#42;</span>{{ i18n.myoffice.handphone }}</span>
                    <span class="item_content m_contents">
                        <input type="text" v-model="mobile" id="mobile" maxlength="11" class="type_number">
						<span class="bk_submit_btn btn_mobileChk btn-hide">
							<input type="button" class="btn smschBtn osMem_btn ml-2" id="mobileCheck" v-on:click="checkMobile"  value="Check"/><br>
						</span>
						<div id="mobileCheckTxt" class="mgt_8 mgb_8"></div>
						<input type="hidden" id="mobileCheckYn" name="mobileCheckYn" value="N"/>
						<input type="hidden" id="vertifyMobile" name="vertifyMobile" value="N">
					</span>
                </li>

                <li class="item table_form position-box">
                    <span class="item_title title_4_2"><span class="signiture remark1">&#42;</span>{{ i18n.myoffice.email }}</span>
                    <span class="item_content m_contents">
						<span class="">
							<input name="e_mail" id="e_mail" class="type_email" v-model="e_mail" style="ime-mode:disabled;" type="text">
						</span>
						<span class="bk_submit_btn btn_emailChk">
							<input type="button" class="btn smschBtn osMem_btn ml-2" id="emailCheck" v-on:click="checkEmail" value="Check"  >
						</span>
						<input type="hidden" id="emailCheckYn" name="emailCheckYn" value="N"/>

						<div id="emailCheckTxt" class="mgt_8 mgb_8"></div>

						<span class="myo-checkbox">
							<input id="rec_mail" name="rec_mail" style="border:0px; padding:0; line-height:0; height:15px;" v-model="rec_mail" value="Y" type="checkbox">
							<label for="rec_mail" name="rec_mail">
								<i class="far fa-check-square checked" aria-hidden="true"></i>
								<span class="gray01">{{ i18n.myoffice.E_mailReception }}</span><!-- 이메일 수신합니다. -->
							</label>
						</span>
					</span>
                </li>
                <li class="item table_form">
                    <span class="item_title"><span class="signiture remark1">&#42;</span>{{ i18n.myoffice.Password }}</span>
                    <span class="item_content m_contents">
                      <div class="flex xl:flex-row lg:flex-row flex-col">
                        <input type='password'  v-if="!show" v-model="pass1" class="w-3/5" autocomplete="new-password">
                        <input type='text' v-if="show" v-model="pass1" class="w-3/5" autocomplete="new-password">
                        <span class="bk_submit_btn w-2/5">
                            <input type="button" v-if='!show' @click="showPwd('show')"  class="btn smschBtn osMem_btn relative z-1 ml-2 " value="View"/>
                            <input type="button" v-if='show' @click="showPwd('show')"  class="btn smschBtn osMem_btn relative z-1 ml-2 " value="View"/>
                        </span>
                        </div>
                    </span>
                </li>
                <li class="item table_form">
                    <span class="item_title"><span class="signiture remark1">&#42;</span>{{ i18n.myoffice.Passwordcheck }}</span>
                    <span class="item_content m_contents">
                        <input type='password' v-if="!show_confirm" class="w-3/5" v-model="pass2" autocomplete="off">
                        <input type='text' v-if="show_confirm"  class="w-3/5" v-model="pass2" autocomplete="off">
                         <span class="bk_submit_btn w-2/5">
                           <input type="button" v-if='!show_confirm' @click="showPwd('show_confirm')"  class="btn smschBtn osMem_btn relative z-1 ml-2 " value="View"/>
                           <input type="button" v-if='show_confirm' @click="showPwd('show_confirm')"  class="btn smschBtn osMem_btn relative z-1 ml-2 " value="View"/>
                        </span>
                    </span>
                </li>
                <li class="item table_form" v-if="false">
                    <!-- 미국 -->
                    <span class="item_title" v-show="nt_cd == '001'"><span class="signiture remark1">&#42;</span>SSN/ITIN</span>
                    <!-- 캐나다 -->
                    <span class="item_title" v-show="nt_cd == '002'"><span class="signiture remark1">&#42;</span>ITIN</span>
                    <span class="item_content m_contents z-10" v-show="nt_cd == '001'">
                        <div class="z-50 inline-block">
                            <vSelect :options="pin_type_list" label="value" :reduce="value => value.title" v-model="pin_type" :clearable="false" class="w-20   border-zinc-700"  ></vSelect>
                        </div>

                        <div class=" inline-block px-4" v-show="pin_type == 'S'">
                            <input type='number' v-model="jumin_1" maxlength="3" size="3" class="w-10  px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 bg-slate-300"
                                   oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                   autocomplete="off">
                            -
                            <input type='number' v-model="jumin_2" maxlength="2" size="2" class="w-10  px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 bg-slate-300"
                                   oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                   autocomplete="off">
                            -
                            <input type='number' v-model="jumin_3" maxlength="4" size="4" class="w-20  px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 bg-slate-300"
                                   oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                   autocomplete="off">
                        </div>
                        <div class=" inline-block px-4" v-show="pin_type == 'I'">
                            <input type='number' v-model="jumin_1" maxlength="2" size="2" class="w-10  px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 bg-slate-300" autocomplete="off">
                            -
                            <input type='number' v-model="jumin_2" maxlength="7" size="7" class="w-20  px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 bg-slate-300"  autocomplete="off">
                        </div>
                    </span>
                </li>

                <li class="item table_form relative z-1">
                  <span class="item_title title_5">Zip Code</span>
                  <span class="item_content m_contents z-10 relative">
                        <input name="post" id="post" class="type_number relative z-1" @click="search_address" v-model="post" maxlength='5' type="text" readonly>
<!--                        <input type="button" @click="search_address" value="search address" class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-40 cursor-pointer">-->
                        <span class="bk_submit_btn">
                          <input type="button" @click="search_address"  class="btn smschBtn osMem_btn relative z-1 ml-2" value="Search address"/><br>
                        </span>
                      </span>
                </li>
                <li class="item table_form relative z-1">
                    <span class="item_title title_5">State</span>
                    <span class="item_content m_contents z-10 relative">
                      <input name="address" id="state" type="text" placeholder="" v-model='selectState' readonly @click="search_address"
                             class="relative z-1 w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 bg-slate-300" maxlength="40">
					          </span>
                </li>
                <li class="item table_form z-1">
                  <span class="item_title title_5">City</span>
                  <span class="item_content m_contents z-10 relative">
                        <input name="address" id="city" type="text" placeholder="" v-model='city' readonly @click="search_address"
                               class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 bg-slate-300" maxlength="40">
                      </span>
                </li>
                <li class="item table_form">
                  <span class="item_title title_5">Address Line 1</span>
                  <span class="item_content m_contents z-10 relative">
                  <input name="address" id="street" type="text" placeholder="Please enter up to 40 characters." v-model='Street'
                         class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="40">
                  </span>
                </li>

<!--                <li class="item table_form myo-addr-box">
                    <span class="item_title title_5">City</span>
                    <span class="item_content m_contents">
                    <div class="addr_detail_box2 mgb_10 z-20 relative">
                        <vSelect name="city" id="city" ref="d_city" :options="city_list" v-model='city' :clearable="false" @option:selected="onChangeCity" class="block w-full text-gray-600 text-sm"></vSelect>
                    </div>
                  </span>
                </li>-->
<!--                <li class="item table_form myo-addr-box">
                    <span class="item_title title_5">Street</span>
                    <span class="item_content m_contents z-10 relative">
                      <vSelect label="street" name="street" id="street" ref="d_street" :options="street_list" v-model='Street' :clearable="false" @option:selected="onChangeStreet"
                               class="block w-full text-gray-600 text-sm"></vSelect>
					          </span>
                </li>-->
<!--                <li class="item table_form myo-addr-box">
                    <span class="item_title title_5">ZIP Code</span>
                    <span class="item_content m_contents">
                      <div class="addr_box_us mgb_10">
                        <input name="post" id="post" class="type_number us-addr-post addr_post_input" v-model="post" maxlength='5' type="text" readonly>
                      </div>
					          </span>
                </li>-->

                <!-- 주소지 영역-->
                <li class="item table_form myo-addr-box">
                    <span class="item_title title_5">Address Line 2</span>
                    <span class="item_content m_contents">
                      <input name="addr2_1" id="addr2_1" class=" add-input1" v-model="addr2_1" type="text"><span class="item_content_notice"> Apt, Ste, Unit, etc.</span>
                    </span>
                    <span class="item_content m_contents">

                    </span>
                </li>

                <!--//주소지 -->
            </ul>
            <!-- 회원정보 끝 -->

            <!-- 비즈니스 정보 시작 -->
            <h2 class="myo_table_tit" v-show="mem.rank_cd != '00'">{{ i18n.myoffice.BusinessInfomation }}</h2> <!--비즈니스 정보-->
            <ul id="myo_form" v-show="mem.rank_cd != '00'">
                <li class="item table_form top-li">
                    <span class="item_title">{{ i18n.myoffice.RegistrationDate }}</span>
                    <span class="item_content">{{ mem.reg_date }}</span>
                </li>
                <li class="item table_form">
                    <span class="item_title">{{ i18n.myoffice.Rank }}</span>
                    <span class="item_content">{{ mem.rank_name }}</span>
                </li>
                <li class="item table_form">
                    <span class="item_title">{{ i18n.myoffice.EnrollmentSponsor }}</span>
                    <span class="item_content info_content">{{(mem.r_id == '00000001' ? __("myoffice.company") : mem.r_names)}}</span> <!-- 회사 -->
                </li>


                <li class="item table_form ">
                    <span class="item_title item_etc">{{ i18n.myoffice.setUp }}</span>

                    <span class="item_content m_contents">
                        <p class="m_config">{{ i18n.myoffice.LookallowuplineList }}</p> <!-- 업라인에게 본인의 4주실적 보이기 -->

                        <div class="myo-mem-check">
                            <span class="radio-box">
                                <input type="radio" id="us-flag1" v-model="flag_4week" value="Y" onclick="" :checked="myconfig.flag_4week == 'Y'" >
                                <label for="us-flag1" class="">
                                    <i class="far fa-dot-circle"></i>
                                    {{ i18n.myoffice.MyMember.allow }}
                                </label>
                            </span>
                            <span class="radio-box">
                                <input type="radio" id="us-flag2" v-model="flag_4week" value="N" onclick="" :checked="myconfig.flag_4week == 'N'" >
                                <label for="us-flag2" class="">
                                    <i class="far fa-dot-circle"></i>
                                    {{ i18n.myoffice.MyMember.block }}
                                </label>
                            </span><!--차단-->
                        </div>

                        <p class="m_config">{{ i18n.myoffice.LookmyofficeList }}</p><!-- 마이오피스 실적 보기 (본인) -->

                        <!--인풋 라디오 샘플-->
                        <div class="myo-mem-check">
                            <span class="radio-box">
                                <input type="radio" id="us-myflag1" v-model="flag_profit" value="Y" onclick="" :checked="myconfig.flag_profit == 'Y'" >
                                <label for="us-myflag1" class="">
                                    <i class="far fa-dot-circle"></i>
                                    {{ i18n.myoffice.MyMember.allow }}
                                </label>
                            </span><!--허용-->
                            <span class="radio-box">
                                <input type="radio" id="us-myflag2" v-model="flag_profit" value="N" onclick=""  :checked="myconfig.flag_profit == 'N'" >
                                <label for="us-myflag2" class="">
                                    <i class="far fa-dot-circle"></i>
                                    {{ i18n.myoffice.MyMember.block }}
                                </label>
                            </span><!--차단-->
                        </div>
                    </span>
                </li>
            </ul>

            <h2 class="myo_table_tit" v-if="false">{{ i18n.myoffice.AccountInfomation }}</h2>
            <ul id="myo_form" v-if="false">
                <li class="item table_form top-li">
                    <span class="item_title">Account Holder</span>
                    <span class="item_content m_contents">
                        <input type='text' v-model="depositor" :readonly="readonly == true"  >
                    </span>
                </li>
                <li class="item table_form">
                    <span class="item_title">Name of Financial Institution</span>
                    <span class="item_content m_contents">
                        <!--인풋 라디오 샘플-->
                        <vSelect :options="bank_key_list" label="bank_name" :reduce="value => value.bank_cd" v-model="bank" :clearable="false" ></vSelect>

                        <div class="myo-mem-check">
                            <span class="radio-box">
                                <input type="radio" id="us-account1" v-model="accInfoCk" value="C" onclick="" :checked="mem.acc_type == 'C'" >
                                <label for="us-account1" class="">
                                    <i class="far fa-dot-circle"></i>
                                    Checking Account
                                </label>
                            </span><!--허용-->
                            <span class="radio-box">
                                <input type="radio" id="us-account2" v-model="accInfoCk" value="S" onclick="" :checked="mem.acc_type == 'S'" >
                                <label for="us-account2" class="">
                                    <i class="far fa-dot-circle"></i>
                                    Savings Account
                                </label>
                            </span><!--차단-->
                        </div>
                    </span>
                </li>

                <li class="item table_form">
                    <span class="item_title">Bank account No</span>
                    <span class="item_content m_contents">
                        <input type="text" v-model="account" class="type_number" :readonly="doc_void.check_yn == 'Y'">
                    </span>
                </li>
                <li class="item table_form">
                    <span class="item_title">Routing No</span>
                    <span class="item_content m_contents">
                        <input type="text" v-model="routingNo" class="type_number" maxlength='20' :readonly="doc_void.check_yn == 'Y'">
                    </span>
                </li>
                <li class="item table_form">
                    <span class="item_title">W-9 Upload</span>
                    <span class="item_content m_contents">
                        <div v-if="doc_w9.check_yn == 'Y'">
							{{ doc_w9.ori_file_name }}&nbsp;&nbsp;
							<a :href="doc_w9.url">[View]</a>
                        </div>
                        <div v-else>
                            <div v-if="doc_w9.file_name">
                                {{ doc_w9.ori_file_name }}&nbsp;&nbsp;
                                <a :href="doc_w9.url">[View]</a>
                            </div>

							<div style="width: 25%; margin-top: 10px">
                                <input type="file" name="W9File" id="W9File">
                            </div>
                        </div>
                    </span>
                </li>
                <li class="item table_form">
                    <span class="item_title">Void Check Upload</span>
                    <span class="item_content m_contents">
                        <div v-if="doc_void.check_yn == 'Y'">
							{{ doc_void.ori_file_name }}&nbsp;&nbsp;
							<a :href="doc_void.url" >[View]</a>
                        </div>
                        <div v-else>
                            <div v-if="doc_void.file_name">
                                {{ doc_void.ori_file_name }}&nbsp;&nbsp;
                                <a :href="doc_void.url" >[View]</a>
                            </div>

							<div style="width: 25%; margin-top: 10px">
                                <input type="file" name="VOIDFile" id="VOIDFile">
                            </div>
                        </div>
				    </span>
                </li>

                <li class="item table_form" v-if="mem.sa_kind == '1' || mem.sa_kind == '2'">
                    <span class="item_title">Resale Certificate</span>
                    <span class="item_content m_contents">
                        <div v-if="mem.rc_yn == 'Y'">
							{{ doc_rc.ori_file_name }}&nbsp;&nbsp;
							<a href="https://cdn3.gcooperp.com/{{ doc_rc.file_path }}/{{ doc_rc.file_name }}">[View]</a>
                        </div>
                        <div v-else>
                            <div v-if="mem.rc_yn == 'Y'">
                                {{ doc_rc.ori_file_name }}&nbsp;&nbsp;
                                <a href="https://cdn3.gcooperp.com/{{ doc_rc.file_path }}/{{ doc_rc.file_name }}">[View]</a>
                            </div>

							<div style="width: 25%; margin-top: 10px">
                                <input type="file" name="RCFile" id="RCFile">
                            </div>
                        </div>
				    </span>
                </li>
                <li class="item table_form" v-if="mem.sa_kind == '1' || mem.sa_kind == '2'">
                    <span class="item_title">Sales Tax License</span>
                    <span class="item_content m_contents">
                        <div v-if="mem.st_yn == 'Y'">
							{{ doc_st.ori_file_name }}&nbsp;&nbsp;
							<a href="https://cdn3.gcooperp.com/{{ doc_st.file_path }}/{{ doc_st.file_name }}">[View]</a>
                        </div>
                        <div v-else>
                            <div v-if="mem.st_yn == 'Y'">
                                {{ doc_st.ori_file_name }}&nbsp;&nbsp;
                                <a href="https://cdn3.gcooperp.com/{{ doc_st.file_path }}/{{ doc_st.file_name }}">[View]</a>
                            </div>

							<div style="width: 25%; margin-top: 10px">
                                <input type="file" name="STFile" id="STFile">
                            </div>
                        </div>
    				</span>
                </li>
                <li class="item table_form" v-if="mem.sa_kind == '1' || mem.sa_kind == '2'">
                    <span class="item_title">Business Entity Addendum<br>(Signed by all participants)</span>
                    <span class="item_content m_contents">
                        <div v-if="mem.ls_yn == 'Y'">
							{{ doc_ls.ori_file_name }}&nbsp;&nbsp;
							<a href="https://cdn3.gcooperp.com/{{ doc_ls.file_path }}/{{ doc_ls.file_name }}">[View]</a>
                        </div>
                        <div v-else>
                            <div v-if="mem.ls_yn == 'Y'">
                                {{ doc_ls.ori_file_name }}&nbsp;&nbsp;
                                <a href="https://cdn3.gcooperp.com/{{ doc_ls.file_path }}/{{ doc_ls.file_name }}">[View]</a>
                            </div>

							<div style="width: 25%; margin-top: 10px">
                                <input type="file" name="LSFile" id="LSFile">
                            </div>
                        </div>

                    </span>
                </li>
            </ul>
            <div class="myo_condition_search2">
                <button type="button" onclick="location.replace('/MyPersonInfo');"  class="reset_btn">{{ i18n.myoffice.Goback }}</button>
                <button type="submit" class="search_btn">{{ i18n.myoffice.save }}</button>
            </div>
        </form>
    </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueSimpleAlert from "vue3-simple-alert";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { createPopper } from '@popperjs/core'
import alert from "vue3-simple-alert";
import modalView from "../../modalView";

export default {
    name: "MyPersonInfoModify",
    components : {
        vSelect,
        VueSimpleAlert,
        modalView,
        Loading
    },
    data: () => ({
        i18n: window.i18n,
        mem: "",
        myconfig : "",
        state : "",
        flag_4week : "",
        flag_profit : "",
        tel : "",
        mobile : "",
        e_mail : "",
        pass1 : "",
        pass2 : "",
        addr2_1 : "",
        addr2_2 : "",
        Street : "",
        post : "",
        city : "",
        selectState : "",
        depositor : "",
        bank : "",
        bank_key_list : "",
        accInfoCk : "",
        account : "",
        routingNo : "",
        acc_type: "",
        doc_void : "",
        doc_w9 : "",
        mobileCheckYn : "N",
        emailCheckYn : "N",
        selectCity : "",
        loading : false,
        fullPage : true,
        state_list : "",
        city_list : "",
        street_list : "",
        nt_cd : "",
        pin_type : "",
        pin_type_list : [{ title : "" , value : "Select"},{title :"S" , value :"SSN"},{title :"I" , value :"ITIN" }],
        jumin_1 : "",
        jumin_2 : "",
        jumin_3 : "",

        accountCheck : {"check_yn" : "N","memo" : "","file_name" : ""},
        address_search_open:false,
        isMatchStateCity:false,

        show : false,
        show_confirm : false,

    }),
    beforeCreate() {

        axios.post(window.Laravel.back_url + '/api/getBankList',{ bz_cd : window.Laravel.bz_cd }).then(response => {
            this.bank_key_list = response.data;

        });

        axios.post(window.Laravel.back_url + '/api/getjoininfoDocu',{ bz_cd : window.Laravel.bz_cd }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            if(response.data.doc_void != ""){
                response.data.doc_void.url = "https://cdn3.gcooperp.com/"+response.data.doc_void.file_path+"/"+response.data.doc_void.file_name;
            }
            this.doc_void = response.data.doc_void;
            if(response.data.doc_w9 != ""){
                response.data.doc_w9.url = "https://cdn3.gcooperp.com/"+response.data.doc_w9.file_path+"/"+response.data.doc_w9.file_name;
            }
            this.doc_w9 = response.data.doc_w9;
        });

        axios.post(window.Laravel.back_url + '/api/memberInfo', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.mem = response.data;
            this.addr2_1 = response.data.addr2_1;
            this.addr2_2 = response.data.addr2_2;
            this.Street = response.data.street;
            this.city = response.data.city;
            this.selectState = response.data.state;
            this.post = response.data.post;

            this.mobile = response.data.mobile;
            this.tel = response.data.tel != "null" ? response.data.tel : '';
            this.e_mail = response.data.e_mail;

            // this.depositor = response.data.depositor;
            // this.account = response.data.account != "null" ? response.data.account : "";
            // this.bank = response.data.bank_cd;
            //
            // this.acc_type = response.data.acc_type;
            // this.routingNo = response.data.cyber_point;

            this.nt_cd = response.data.nt_cd;
            // if(this.nt_cd == "002"){
            //     this.pin_type_list = [{ title : "" , value : "Select"},{title :"I" , value :"ITIN"}];
            // }
            // this.pin_type = response.data.pin_type;

        });

        axios.post(window.Laravel.back_url + '/api/getMyConfig', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.myconfig = response.data;

        });


    },
    created() {
        this.getPostState();
    },

    watch : {
        selectCity : function (){
            this.city = this.selectCity;
        }
    },
    methods : {
        showPwd : function(select){
          if(select == 'show'){
            this.show = !this.show;
          }else if(select == 'show_confirm'){
            this.show_confirm = !this.show_confirm;
          }

        },
        submitForm: function (event) {
            let formData = new FormData();

            /* address2 없는 경우도 있어 체크 제외
            if(!this.addr2_1 || this.addr2_1.length >= 40){
              alert.alert('You can enter up to 40 characters for the detailed address.');
              $("#addr2_1").focus();
              return false;
            }
            */

          if(!this.Street){
            alert.alert('Please enter Street');
            $("#street").focus();
            return false;
          }
            var W9File = document.getElementById("W9File");
            var VOIDFile = document.getElementById("VOIDFile");
            var RCFile = document.getElementById("RCFile");
            var STFile = document.getElementById("STFile");
            var LSFile = document.getElementById("LSFile");


            formData.append('addr2', this.Street + '|@|' + this.addr2_1);
            formData.append('street', this.Street);
            formData.append('city', this.city);
            formData.append('selectState', this.selectState);
            formData.append('post', this.post);
            formData.append('mobile', this.mobile);
            formData.append('tel', this.tel);
            formData.append('e_mail', this.e_mail);
            formData.append('depositor', this.depositor);
            formData.append('account', this.account);
            formData.append('bank', this.bank);
            formData.append('accInfoCk', this.accInfoCk);
            formData.append('routingNo', this.routingNo);
            formData.append('mobileCheckYn', this.mobileCheckYn);
            formData.append('emailCheckYn', this.emailCheckYn);
            formData.append('pass1', this.pass1);
            formData.append('pass2', this.pass2);
            formData.append('flag_4week', this.flag_4week);
            formData.append('flag_profit', this.flag_profit);

            if(this.doc_w9.check_yn != "Y" && W9File){
                formData.append('W9File',W9File.files[0] );
            }
            if( this.doc_void.check_yn != "Y" && VOIDFile){
                formData.append('VOIDFile',VOIDFile.files[0] );
            }

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + window.Laravel.access_token
                }
            }

            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/memberModify',
                formData
            , config).then(response => {
                if(response.data.result == "1"){
                    VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
                        if(result){
                            location.replace("/MyPersonInfo");
                        }
                    });

                }
                if(response.data.result == "0"){
                    VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
                        if(result){
                        }
                    });
                }

                this.loading = false;
            });

        },
        checkEmail(){
            if(!this.e_mail) {
              VueSimpleAlert.alert("Please enter the correct Email.","","",{allowOutsideClick : false}).then((result) => {});
              return;
            }
            axios.post(window.Laravel.back_url+'/api/getComparisonEmail', {
                email : this.e_mail,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
                if (response.data.chk.checker == "Y") {
                    $("#e_mail").attr("readonly", true);
                    $("#e_mail").css("background", "#d4d4d4");

                    $("#emailCheckYn").val("Y");
                    this.emailCheckYn = "Y";
                    $("#emailCheck").hide();
                    $("#emailCheckTxt").text(i18n.join.join.alert_emailchk4); /* 사용할 수 있는 핸드폰 번호입니다. */
                    $(".btn_emailChk").css("display","none"); // 휴대폰 번호 체크 후에는 버튼 영역도 숨김 처리

                } else {
                    $("#emailCheckYn").val("N");
                    this.emailCheckYn = "N";
                    $("#emailCheck").show();z
                    alert(i18n.join.join.alert_emailchk1);
                    $("#emailCheckTxt").text(i18n.join.join.alert_emailchk1); /* 사용할 수 없는 핸드폰 번호입니다. */
                    $(".btn_emailChk").css("display","none"); // 휴대폰 번호 체크 후에는 버튼 영역도 숨김 처리

                }
            });
        },
        checkMobile(){
            if(this.mobile.length < 10) {
              VueSimpleAlert.alert("Please enter the correct phone number.","","",{allowOutsideClick : false}).then((result) => {});
              return false;
            }
            axios.post(window.Laravel.back_url+'/api/getComparisonMobile', {
                mobile : this.mobile,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
                if (response.data.chk.checker == "0") {
                    $("#mobile").attr("readonly", true);
                    $("#mobile").css("background", "#eaeaea");

                    $("input[id='mobileCheckYn']").val("Y");
                    this.mobileCheckYn = "Y";
                    $("#vertifyMobile").val("Y");
                    $("#mobileCheck").hide();
                    $(".btn_mobileChk").css("display","none"); // 휴대폰 번호 체크 후에는 버튼 영역도 숨김 처리
                    $("#mobileCheckTxt").text(i18n.myoffice.myofficeAlert.alert20); /* 사용할 수 있는 핸드폰 번호입니다. */
                } else {
                    $("input[id='mobileCheckYn']").val("N");
                    this.mobileCheckYn = "N";
                    $("#vertifyMobile").val("N");
                    $("#mobileCheck").show();
                    $(".btn_mobileChk").css("display","block");
                    alert(i18n.join.join.memformtext27);
                    $("#mobileCheckTxt").text(i18n.myoffice.myofficeAlert.alert21); /* 사용할 수 없는 핸드폰 번호입니다. */
                }
            });

        },
        searchAddress(){
            axios.post(window.Laravel.back_url+'/api/getUsZipcode', {
                post_code : this.post,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
                if( response.data.address.length > 0 ){
                    this.selectState = response.data.address[0]['state'];
                    this.city = response.data.address[0]['city'];

                    //$('#post').val(seq);
                    $("#selectCity").html("");
                    var option = "";
                    var postcode_localities = response.data.address;
                    if (postcode_localities !== undefined && postcode_localities.length > 1) {
                        option += "<option>select</option>";
                        for (var i = 0; i < postcode_localities.length; i++) {
                            option += "<option value='"+postcode_localities[i]["city"]+"'>"
                                + postcode_localities[i]["city"]
                                + "</option>";
                        }
                        $("#selectCity").html(option);

                    }
                }else {
                    $('#post').val($('#r_post').val());
                    alert("Please enter a valid 5 digit zipcode.");
                }
            });

        },
        // 우편번호  가져오기
        getPostState(){   // 주(State) 목록 가져오기
            axios.post(process.env.MIX_BACK_URL+'/api/getState',{

            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    // this.shipping_info["d_state"] = value;
                    this.state_list = response.data;

                    // console.log(this.state_list);
                })
                .catch(e => {
                });
        },
        onChangeState(value) {   // 주(State) 선택

            axios.post(process.env.MIX_BACK_URL+'/api/getCity',{
                state: value,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.city = "";
                    this.Street = "";
                    this.post = "";
                    this.city_list = response.data;
                    // console.log(this.city_list);
                })
                .catch(e => {
                });
        },
        onChangeCity(value) {  // 도시(City) 선택
            // console.log(event.target.value)
            axios.post(process.env.MIX_BACK_URL+'/api/getStreet',{
                state: this.selectState,
                city: value,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.Street = "";
                    this.post = "";
                    this.street_list = response.data;
                    // console.log(response.data);
                })
                .catch(e => {
                });
        },
        onChangeStreet(value) {  // 도로(Street) 선택
            axios.post(process.env.MIX_BACK_URL+'/api/getPostCodeForDelivery',{
                state: this.selectState,
                city: this.city,
                street: value,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.post =  response.data.postcode;
                    this.post_list = response.data;
                    // console.log(response.data);
                })
                .catch(e => {
                });
        },
        withPopper(dropdownList, component, { width }) {
            /**
             * We need to explicitly define the dropdown width since
             * it is usually inherited from the parent with CSS.
             */
            dropdownList.style.width = width

            /**
             * Here we position the dropdownList relative to the $refs.toggle Element.
             *
             * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
             * the dropdownList overlap by 1 pixel.
             *
             * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
             * wrapper so that we can set some styles for when the dropdown is placed
             * above.
             */
            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: "top",
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1],
                        },
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn({ state }) {
                            component.$el.classList.toggle(
                                'drop-up',
                                state.placement === 'top'
                            )
                        },
                    },
                ],
            })

            /**
             * To prevent memory leaks Popper needs to be destroyed.
             * If you return function, it will be called just before dropdown is removed from DOM.
             */
            return () => popper.destroy()
        },
        // 주소록 레이어 오픈
        close_address_book(){
          this.address_book_open = false;
        },
        // 숫자 포멧
        formatPrice(value,digit) {
          let val = (value/1).toFixed(digit).replace('.', '.')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        search_address(){
          this.address_search_open = true;
        },
        set_address(value){
          this.address_search_open = false;
          this.selectState =value.state;
          this.city = value.district;
          this.Street = "";
          this.post = value.postcode;
        },
        close_address_search(){
          this.address_search_open = false;
        }

    }
}
</script>

<style >
.v-select.drop-up.vs--open .vs__dropdown-toggle {
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
    border-radius: 4px 4px 0 0;
    border-top-style: solid;
    border-bottom-style: none;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
