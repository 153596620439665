<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="true"></loading>
  <form name="uni_form" method="post" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
    <div class="ctn-box">
      <div class="board-inner">
        <div class="myoffice_page">

          <section class="accordion_notice_box">
            <div class="accordion_notice_item">
              <a class="accordion_notice_tit" href="#n">
                <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                {{ i18n.myoffice.myoSearchForm.notice }}
                <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></div>
              </a>
            </div>
          </section>

          <ul class="fileUpWRap clr upload-box ">
            <li class="upload-list">
              <h5>Bank Information</h5>
              <ul id="myo_form">
                <li>
                  <span class="item_title">{{ i18n.join.join.bank }}</span>
                  <span class="item_content" style="text-align: left;">
                    <div class="relative z-10">
                      <vSelect :options="bank_key_list" label="bank_name" :reduce="value => value.bank_cd" v-model="bank" :clearable="false" :disabled="accountCheck.check_yn == 'Y'" ></vSelect>
                    </div>
                          <div class="myo-mem-check">
                            <span class="radio-box">
                                <input type="radio" id="us-account1" v-model="accInfoCk" value="C" onclick="" :checked="accInfoCk == 'C'" >
                                <label for="us-account1" class="">
                                    <i class="far fa-dot-circle"></i>
                                    Checking Account
                                </label>
                            </span><!--허용-->
                            <span class="radio-box">
                                <input type="radio" id="us-account2" v-model="accInfoCk" value="S" onclick="" :checked="accInfoCk == 'S'" >
                                <label for="us-account2" class="">
                                    <i class="far fa-dot-circle"></i>
                                    Savings Account
                                </label>
                            </span><!--차단-->
                        </div>
                  </span>
                </li>
                <li>
                  <span class="item_title">{{ i18n.join.join.account }}</span>
                  <span class="item_content" style="text-align: left;"><input type="text" v-model="account" class="type_number" :readonly="accountCheck.check_yn == 'Y'" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" @blur="this.account = this.account.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"></span>
                </li>
                <li>
                  <span class="item_title">Routing No</span>
                  <span class="item_content" style="text-align: left;">
                       <input type="text" v-model="routingNo" class="type_number" maxlength='20' :readonly="accountCheck.check_yn == 'Y'" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" @blur="this.routingNo = this.routingNo.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                      </span>
                </li>
                <li>
                  <span class="item_title">{{ i18n.myoffice.AccountHolder }}</span>
                  <span class="item_content" style="text-align: left;">
                    <input type="text" v-model="depositor" :readonly="accountCheck.check_yn == 'Y'" >
                  </span>
                </li>

              </ul> <!-- 테이블1 -->
              <dl>
                <dt v-if="accountCheck.fileurl">
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img1"  width="100%" height="100%" style="vertical-align:middle;" :src="accountCheck.fileurl" alt="">
                      <div v-if="accountCheck.check_yn == 'N' && accountCheck.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="accountCheck.check_yn == 'N'" type="file" name="VOIDFile" id="VOIDFile" @change="readURL">
                    <div v-if="accountCheck.check_yn == 'N' && accountCheck.memo == '' && accountCheck.fileurl != ''" class="res-depending"><span class="upload-state1">Processing</span></div>
                    <div v-else-if="accountCheck.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="accountCheck.check_yn == 'N' && accountCheck.memo != ''" class="res-memo">{{ accountCheck.memo }}</div>
                  </div>
                  <div class="item_content_notice" style="color:#444">
                    Please upload a scanned copy of your Void Check.
                    <br><br>
                    If you are uploading an electronic copy of your bank account information, please have the following:<br>
                    Name of Account Holder, Name of Bank, Branch Location/Name, Account Number, and Routing Number

                  </div>
                </dd>
              </dl>
            </li>

            <li class="upload-list">
              <h5>Identity Document</h5>
              <!--START: 마이넘버 ------------------------------------->
              <h5 >{{ pin_types }}</h5> <!-- 마이넘버 DD 앞면 -->
              <ul id="myo_form">

                <li>
                    <span class="item_title" v-show="nt_cd == '001'"><span class="signiture remark1">&#42;</span>SSN/ITIN</span>
                    <!-- 캐나다 -->
                    <span class="item_title" v-show="nt_cd == '002'"><span class="signiture remark1">&#42;</span>SIN</span>
                  <span class="grid grid-cols-4 bg-white h-full p-2" style="text-align: left;" v-if="isForigner == false">
                    <span class="col-span-1">
                      <select   class="flex w-full  h-full text-sm md:text-base" v-model="pin_type"  :disabled="juminCheck.check_yn == 'Y'" >
                        <option value="S" v-if="nt_cd == '001'">SSN</option>
                        <option value="I" v-if="nt_cd == '001'">ITIN</option>
                        <option value="N" v-if="nt_cd == '002'">SIN</option>
                      </select>
                    </span>
                    <span class="col-span-3 ">
                      <input v-if="pin_type == 'S'" name="jumin_no"  maxlength="9"  class="type_email h-full  "  v-model="jumin_no" style="ime-mode:disabled;" type="text" :readonly="juminCheck.check_yn == 'Y'" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" @blur="this.jumin_no = this.jumin_no.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                      <input v-if="pin_type == 'I'" name="jumin_no"  maxlength="9"  class="type_email h-full  "  v-model="jumin_no" style="ime-mode:disabled;" type="text" :readonly="juminCheck.check_yn == 'Y'" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" @blur="this.jumin_no = this.jumin_no.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                      <input v-if="pin_type == 'N'" name="jumin_no"  maxlength="9"  class="type_email h-full "  v-model="jumin_no" style="ime-mode:disabled;" type="text" :readonly="juminCheck.check_yn == 'Y'" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" @blur="this.jumin_no = this.jumin_no.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                    </span>
                  </span>
                  <span class="item_content" style="text-align: left;"  v-if="isForigner == true">
                    <input type="text" v-model="forigner_jumin_no" :class="{ 'jumin_check_y' : juminCheck.check_yn == 'Y' }" maxlength="9" style="" readonly>
                  </span>
                </li>
              </ul>  <!-- 테이블2 -->
              <dl>
                <dt v-if="juminCheck.fileurl">
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      {{juminCheck.fileurl}}
                      <img id="img2"  width="100%" height="100%" style="vertical-align:middle;" :src="juminCheck.fileurl" alt="">
                      <div v-if="juminCheck.check_yn == 'N' && juminCheck.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="juminCheck.check_yn == 'N'" type="file" name="W9File" id="W9File" @change="readURL">
                    <div v-if="juminCheck.check_yn == 'N' && juminCheck.memo == '' && juminCheck.fileurl != ''" class="res-depending"><span class="upload-state1">Processing</span></div>
                    <div v-else-if="juminCheck.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="juminCheck.check_yn == 'N' && juminCheck.memo != ''" class="res-memo">{{ juminCheck.memo }}</div>
                  </div>
                  <div class="item_content_notice mt-3" v-if="isForigner == false" style="color:#444">
                    Please upload a scanned copy of the following: W-9 (W-8)<br>
                    - W-9 (W-8) can be downloaded from the Library in MyOffice<br>
                  </div>
                  <div class="item_content_notice mt-3" v-if="isForigner == true" style="color:#444">
                    Please upload a scanned copy of one of the following: <br>
                    - Scan copy of PASSPORT
                  </div>
                  <div class="item_content_notice mt-3" style="color:#444">
                    When filling out the w9 form, please make sure that:<br>
                    1. All information is written in English.<br>
                    2. Your name is written as shown on your income tax return.<br>
                    3. Your address matches the address used to register at GCOOP USA.<br>
                  </div>
                </dd>
              </dl>
            </li>

          </ul>


          <ul class="fileUpWRap clr upload-box" v-if="isForigner == true">
            <li class="upload-list">
              <h5>Tax</h5>
              <ul id="myo_form">

                <li>
                  <span class="item_title">Tax Code</span>
                  <span class="item_content" style="text-align: left;">
                    <input type="text" id="bankkatacode" name="bankkatacode" v-model="bankkatacode" :class="{ 'jumin_check_y' : taxCheck.check_yn == 'Y' }"  style="ime-mode:inactive;" :readonly="taxCheck.check_yn == 'Y'">
                  </span>
                </li>
              </ul>  <!-- 테이블3 -->
              <dl>
                <dt>
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img1" width="100%" height="100%" style="vertical-align:middle;" :src="taxCheck.fileurl" alt="">
                      <div v-if="taxCheck.check_yn == 'N' && taxCheck.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="taxCheck.check_yn =='N'" type="file" name="TXFile" id="TXFile" @change="readURL">
                    <div v-if="taxCheck.check_yn == 'N' && taxCheck.memo == '' && taxCheck.fileurl != ''" class="res-depending"><span class="upload-state1">Processing</span></div>
                    <div v-else-if="taxCheck.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="taxCheck.check_yn == 'N' && taxCheck.memo != ''" class="res-memo">{{ taxCheck.memo }}</div>
                  </div>
                  <div class="item_content_notice" style="color:#444">
                    Please upload a scanned copy or file of the following:<br>
                    - The front page of BE Form with your information and tax code
                  </div>
                </dd>
              </dl>
            </li>

            <li class="upload-list"  v-if="isForigner == true">
              <!--START: 마이넘버 ------------------------------------->
              <h5>Visa</h5> <!-- 마이넘버 DD 앞면 -->
              <ul id="myo_form">

                <li>
                  <span class="item_title">Visa</span>
                  <span class="item_content" style="text-align: left;">
                    <input type="text" id="bankhiracode" name="bankhiracode" v-model="bankhiracode" :class="{ 'jumin_check_y' : visaCheck.check_yn == 'Y' }" maxlength="14" :readonly="visaCheck.check_yn == 'Y'" style="ime-mode:inactive;">
                  </span>
                </li>
              </ul>  <!-- 테이블4 -->

              <dl>
                <dt>
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img2" width="100%" height="100%" style="vertical-align:middle;" :src="visaCheck.fileurl" alt="">
                      <div v-if="visaCheck.check_yn == 'N' && visaCheck.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="visaCheck.check_yn == 'N'" type="file" name="VIFile" id="VIFile" @change="readURL">
                    <div v-if="visaCheck.check_yn == 'N' && visaCheck.memo == ''  && visaCheck.fileurl != ''" class="res-depending"><span class="upload-state1">Processing</span></div>
                    <div v-else-if="visaCheck.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="visaCheck.check_yn == 'N' && visaCheck.memo != ''" class="res-memo">{{ visaCheck.memo }}</div>
                  </div>
                  <div class="item_content_notice" style="color:#444">
                    Please upload a scanned copy of the following:<br>
                    - Scan copy of your Visa<br>
                    (Work Permit Level 1 or 2 / MM2H / PR(Permanent Residence)
                  </div>
                </dd>
              </dl>
            </li>

          </ul>

          <!-- S : 법인 회원 서류업로드 -->
          <ul class="fileUpWRap clr upload-box " v-if="sa_kind != ''">
            <!-- S : Resale Certificate -->
            <li class="upload-list">
              <h5>Resale Certificate</h5>
              <dl>
                <dt v-if="resale_certificate.fileurl">
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img7"  width="100%" height="100%" style="vertical-align:middle;" :src="resale_certificate.fileurl" alt="">
                      <div v-if="resale_certificate.check_yn == 'N' && resale_certificate.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="resale_certificate.check_yn == 'N'" type="file" name="RCFile" id="RCFile" @change="readURL">
                    <div v-if="resale_certificate.check_yn == 'N' && resale_certificate.memo == '' && resale_certificate.fileurl != ''" class="res-depending"><span class="upload-state1">Processing</span></div>
                    <div v-else-if="resale_certificate.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="resale_certificate.check_yn == 'N' && resale_certificate.memo != ''" class="res-memo">{{ resale_certificate.memo }}</div>
                  </div>
                  <div class="item_content_notice" style="color:#444">
                    Please upload a scanned copy of your Resale Certificate.
                    <br><br>
                  </div>
                </dd>
              </dl>
            </li>
            <!-- E : Resale Certificate -->
            <!-- S : Sales Tax License -->
            <li class="upload-list">
              <h5>Sales Tax License</h5>
              <dl>
                <dt v-if="sales_tax_license.fileurl">
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img8"  width="100%" height="100%" style="vertical-align:middle;" :src="sales_tax_license.fileurl" alt="">
                      <div v-if="sales_tax_license.check_yn == 'N' && sales_tax_license.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="sales_tax_license.check_yn == 'N'" type="file" name="STFile" id="STFile" @change="readURL">
                    <div v-if="sales_tax_license.check_yn == 'N' && sales_tax_license.memo == '' && sales_tax_license.fileurl != ''" class="res-depending"><span class="upload-state1">Processing</span></div>
                    <div v-else-if="sales_tax_license.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="sales_tax_license.check_yn == 'N' && sales_tax_license.memo != ''" class="res-memo">{{ sales_tax_license.memo }}</div>
                  </div>
                  <div class="item_content_notice" style="color:#444">
                    Please upload a scanned copy of your Sales Tax License.
                    <br><br>
                  </div>
                </dd>
              </dl>
            </li>
            <!-- E : Sales Tax License -->
            <!-- S : Business Entity Addendum -->
            <li class="upload-list">
              <h5>Business Entity Addendum</h5>
              <dl>
                <dt v-if="business_entity_addendum.fileurl">
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img9"  width="100%" height="100%" style="vertical-align:middle;" :src="business_entity_addendum.fileurl" alt="">
                      <div v-if="business_entity_addendum.check_yn == 'N' && business_entity_addendum.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="business_entity_addendum.check_yn == 'N'" type="file" name="LSFile" id="LSFile" @change="readURL">
                    <div v-if="business_entity_addendum.check_yn == 'N' && business_entity_addendum.memo == '' && business_entity_addendum.fileurl != ''" class="res-depending"><span class="upload-state1">Processing</span></div>
                    <div v-else-if="business_entity_addendum.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="business_entity_addendum.check_yn == 'N' && business_entity_addendum.memo != ''" class="res-memo">{{ business_entity_addendum.memo }}</div>
                  </div>
                  <div class="item_content_notice" style="color:#444">
                    Please upload a scanned copy of your Sales Tax License.
                    <br><br>
                  </div>
                </dd>
              </dl>
            </li>
            <!-- E : Business Entity Addendum -->
          </ul>
          <!-- S : 법인 회원 서류업로드 -->

        </div>

        <div class="myo_condition_search" style="margin-top:20px;">
          <!--<button type="button" onclick="history.back()"  class="go_list_btn">{{ i18n.shop.board.Contents }}</button>-->
          <button type="button" onclick="location.href='/MyofficeMain'"  class="go_list_btn">{{ i18n.shop.board.Contents }}</button>
          <button type="submit" id="btn_submit" class="search_btn">{{ i18n.myoffice.save }}</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import vSelect from "vue-select";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";

export default {
  name: "MyforinerUpload",
  components : {
    vSelect,
    Loading,
    VueSimpleAlert
  },
  data: () => ({
    i18n: window.i18n,
    mem : "",
    accountCheck : {"check_yn" : "","memo" : "","file_name" : ""},
    bankStdRow : "",
    juminCheck : {"check_yn" : "","memo" : "","file_name" : ""},
    visaCheck : {"check_yn" : "","memo" : "","file_name" : ""},
    taxCheck : {"check_yn" : "","memo" : "","file_name" : ""},
    loading : false,
    bank_key_list : "",
    bank :"",
    account  : "",
    depositor : "",
    jumin_no : "",
    pin_type_list : [],

    forigner_jumin_no : "",
    bankkatacode : "",
    bankhiracode : "",
    isForigner : false,
    pin_type : "S",
    nt_cd : "",
    accInfoCk : "",
    routingNo : "",
    sa_kind                  : window.Laravel.sa_kind ?? '',
    resale_certificate       : "", // rc
    sales_tax_license        : "", //st
    business_entity_addendum : "", //ls
    nullRange                : ["null", undefined, "", null],
  }),
  beforeCreate() {
    axios.post(window.Laravel.back_url + '/api/getBankList',{ bz_cd : window.Laravel.bz_cd }).then(response => {
      this.bank_key_list = response.data;
    });

    axios.post(window.Laravel.back_url + '/api/getjoininfoDocu',{ bz_cd : window.Laravel.bz_cd }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
      this.accountCheck = response.data.doc_void;
      this.juminCheck = response.data.doc_w9;
      if (window.Laravel.sa_kind != "") {
        this.resale_certificate       = response.data.doc_rc;
        this.sales_tax_license        = response.data.doc_st;
        this.business_entity_addendum = response.data.doc_ls;
      }
    });

    axios.post(window.Laravel.back_url + '/api/memberInfo', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {

        this.mem = response.data;
        if(response.data.pin_type == "" || !response.data.pin_type){
          this.pin_type = (response.data.nt_cd == "001") ? "S" : "N";
        }else{
          this.pin_type = response.data.pin_type;
        }
        this.accInfoCk    = this.nullRange.filter(e => response.data.acc_type !== e) ? response.data.acc_type : ""; //accInfoCk
        this.nt_cd        = this.nullRange.filter(e => response.data.nt_cd !== e) ? response.data.nt_cd : "";
        this.depositor    = this.nullRange.filter(e => response.data.depositor !== e) ? response.data.depositor : "";
        this.account      = this.nullRange.filter(e => response.data.account !== e) ? response.data.account : "";
        this.bank         = this.nullRange.filter(e => response.data.bank_cd !== e) ? response.data.bank_cd : "";
        this.bankkatacode = this.nullRange.filter(e => response.data.bankkatacode !== e) ? response.data.bankkatacode : '';
        this.bankhiracode = this.nullRange.filter(e => response.data.bankhiracode !== e) ? response.data.bankhiracode : '' ;
        this.jumin_no     = this.nullRange.filter(e => response.data.jumin_no !== e) ? response.data.jumin_no : '';
        this.routingNo    = this.nullRange.filter(e => response.data.cyber_point !== e) ? response.data.cyber_point : '';
    });

  },
  created() {



  },
  watch : {

  },
  methods : {
    submitForm: function (event) {
      let formData = new FormData();
      formData.append('bz_cd', window.Laravel.bz_cd);

      var VOIDFile = document.getElementById("VOIDFile");
      var W9File = document.getElementById("W9File");
      var TXFile = document.getElementById("TXFile");
      var VIFile = document.getElementById("VIFile");
      if(this.accountCheck.check_yn == "N"){
        formData.append('bank', (this.bank == "Unselected") ? '' : this.bank);
        if(this.accInfoCk != ''){
          formData.append('accInfoCk', this.accInfoCk);
        }
        formData.append('routingNo', this.routingNo ?? '');
        formData.append('depositor', this.depositor ?? '');
        formData.append('account'  , this.account ?? '');
        formData.append('pin_type' , this.pin_type ?? '');
      }

        formData.append('jumin_no', this.jumin_no ?? '');



      if(VOIDFile.files[0] !== undefined){
        formData.append('VOIDFile',VOIDFile.files[0] );
      }
      if( W9File.files[0] !== undefined){
        formData.append('W9File',W9File.files[0] );
      }
      if(this.isForigner == true){

        if( TXFile.files[0] !== undefined){
          formData.append('TXFile',TXFile.files[0] );
        }

        if( VIFile.files[0] !== undefined){
          formData.append('VIFile',VIFile.files[0] );
        }
      }
      // S : 법인 서류업로드
      if (this.sa_kind) {
        var RCFile = document.getElementById("RCFile");
        var STFile = document.getElementById("STFile");
        var LSFile = document.getElementById("LSFile");
        if(RCFile.files[0] !== undefined){
          formData.append('RCFile', RCFile.files[0] );
        }
        if(STFile.files[0] !== undefined){
          formData.append('STFile', STFile.files[0] );
        }
        if(LSFile.files[0] !== undefined){
          formData.append('LSFile', LSFile.files[0] );
        }
        formData.append('sa_kind', this.sa_kind);
      }
      // E : 법인 서류업로드

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + window.Laravel.access_token
        }
      }

      this.loading = true;

      axios.post(window.Laravel.back_url+'/api/forienUpload',
        formData
        , config).then(response => {
        VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
          if(result){
            location.replace("/MyforinerUpload");
          }
        });
        this.loading = false;
      });

    },
    readURL: function (event) {
      var file   = event.target.files[0];
      var reader = new FileReader();

      reader.onload = (e) => {
        if(event.target.id == "W9File") this.juminCheck.fileurl               = e.target.result;
        if(event.target.id == "VOIDFile") this.accountCheck.fileurl           = e.target.result;
        if(event.target.id == "TXFile") this.taxCheck.fileurl                 = e.target.result;
        if(event.target.id == "VIFile") this.visaCheck.fileurl                = e.target.result;
        if(event.target.id == "RCFile") this.resale_certificate.fileurl       = e.target.result;
        if(event.target.id == "STFile") this.sales_tax_license.fileurl        = e.target.result;
        if(event.target.id == "LSFile") this.business_entity_addendum.fileurl = e.target.result;
      }
      reader.readAsDataURL(file);
    }
  }
}
</script>

<style >
.jumin_check_y { pointer-events:none;background-color:#BDBDBD }
.upload-box{display: flex; width:100%; justify-content: space-evenly;  -webkit-justify-content: space-evenly; flex-wrap: wrap;}
.upload-box .upload-list{font-size:1em; padding:30px 15px; word-break: break-word; width:calc(95% / 2); margin:10px; border-radius: 10px; min-height: 150px; box-shadow: 0px 0px 2px 2px #e7e7e7; /*border:1px solid #ccc;*/ }
.upload-box .upload-list h5{font-size:1.3em; font-weight:500; margin-top:10px; margin-bottom:20px; text-align: center;}
.upload-box .upload-list table{border:1px solid #eee; vertical-align: middle; width:100%;}
.upload-box .upload-list table tr{border-bottom:1px solid #fff;}
.upload-box .upload-list table tr td{padding: 10px; border-bottom:1px solid #ddd; word-break: break-all;}
.upload-box .upload-list table tr td:nth-child(1){width:30%; text-align: center; border-right:1px solid #ddd; background:#f9f9f9; word-break: keep-all;}
.upload-box .upload-list table input{width:100%;}
.upload-box .upload-list input[type=text], .upload-box .upload-list input[type=password]{height: 35px; border: 1px solid #BBB; width: 100%; font-size: 15px; }
.upload-box .upload-list dl{margin-top:20px;}
.upload-box .upload-list dl dt .fix_box_inner:before{content: "Please upload the file"; display: inline-block; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%; text-align: center; color: #999;}
.res-depending{text-align: center; margin:15px 0;}
.res-admit{text-align: center; margin:15px 0;}
.res-memo{text-align: center; margin:15px 0; color:#ff5722;} /*보류 박스*/
.res-memo2{position:absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255,0.7);} /*보류 박스*/
.upload-state1{border:3px double red; padding:10px 15px; display: inline-block; color:red;} /*대기*/
.upload-state2{border:3px double blue; padding:10px 15px; display: inline-block; color:blue;} /*승인*/
.upload-state3{text-align: center; color: #ff5722; position: absolute; top: 50%; left: 50%; transform:translate(-50%, -50%) rotate(348deg); border: 7px double #ff5722; padding: 5px 8px; font-size:35px;} /*보류*/
.upload_btn_box{margin-top:10px;}

@media all and (max-width:1023px){
  .upload-box{display: flex; width:100%;}
  .upload-box .upload-list{width:100%; margin:0px; margin-bottom:20px;}
  .upload-state3{font-size:20px; border: 3px double #ff5722;} /*보류*/
}
</style>
