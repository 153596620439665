<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage">
    </loading>
    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
        <address_search @set_address="set_address" @close_address_search="close_address_search" />
    </modalView>

    <div id="mem_info" >
        <!--    <section class="accordion_notice_box">-->
        <!--      <div class="accordion_notice_item">-->
        <!--        <a class="accordion_notice_tit" href="#n">-->
        <!--          <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>-->
        <!--          {{ i18n.myoffice.myoSearchForm.notice }}-->
        <!--          <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></div>-->
        <!--        </a>-->
        <!--        <div class="accordion_notice_cont">-->
        <!--          <div class="myo_search_notice">-->
        <!--            <p><span class="signiture remark1">&#42;</span>&nbsp;<span>{{ i18n.join.join.required }}</span></p>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </section>-->

        <form name="uni_form" method="post" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
            <h2 class="myo_table_tit">{{ i18n.menu.MyAutoshipEdit }}</h2>
            <ul id="myo_form" class="mgb_30">
                <!-- 회원명-->
                <li class="item table_form top-li">
                    <span class="item_title">{{ i18n.myoffice.Order.Reciever }}</span>
                    <span class="item_content"><input type="text" class="" v-model="autoship.r_name"></span>
                </li>
                <!-- 휴대전화 -->
                <li class="item table_form top-li">
                    <span class="item_title">{{ i18n.myoffice.handphone }}</span>
                    <span class="item_content m_contents">
                  <input type="text" v-model="autoship.r_mobile" class="" maxlength="11" oninput="this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                  <div id="mobileCheckTxt" class="mgt_8 mgb_8"></div>
                </span>
                </li>
                <!-- 휴대전화 -->
                <li class="item table_form top-li">
                    <span class="item_title">UPS Signature</span>
                    <span class="item_content m_contents">
                        <input name="ups_kind" id="ups_kind2" type="checkbox" class="mt-0.5" true-value="3" false_value="2" v-model="autoship.deli_kind">
                        <label for="ups_kind2" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">UPS Signature(+$5.25)</label>
                    </span>
                </li>
                <!-- 휴대전화 -->
                <li class="item table_form top-li">
                    <span class="item_title">UPS Insurance</span>
                    <span class="item_content m_contents">
                        <input type="checkbox" id="ups_kind3" class="mt-0.5"  v-model="autoship.insurance_yn" true-value="Y" false_value="N">
                        <label for="ups_kind3" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                            UPS Insurance
                        </label>
                        <VTooltip class="inline-block">
                            <i class="fal fa-question-circle cursor-pointer" aria-hidden="true"></i>
                            <template #popper>
                                Members of GCOOP USA can select UPS’s insurance service when ordering.
                                This service offers financial reimbursement if a package is lost, stolen, or damaged during the transit process.
                                If one chooses to opt out of insurance, GCOOP USA will not take responsibility for damaged, lost or stolen products.
                            </template>
                        </VTooltip>
                    </span>
                </li>
                <!-- 주소-->
                <li class="item table_form relative z-1">
                    <span class="item_title title_5">Zip Code</span>
                    <span class="item_content m_contents z-10 relative">
                        <input name="post" id="post" class="type_number relative z-1" @click="search_address" v-model="post" maxlength='5' type="text" readonly>
                        <!--                        <input type="button" @click="search_address" value="search address" class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-40 cursor-pointer">-->
                        <span class="bk_submit_btn">
                          <input type="button" @click="search_address"  class="btn smschBtn osMem_btn relative z-1 ml-2" value="Search address"/><br>
                        </span>
                      </span>
                </li>
                <li class="item table_form relative z-1">
                    <span class="item_title title_5">State</span>
                    <span class="item_content m_contents z-10 relative">
                      <input name="address" id="state" type="text" placeholder="" v-model='selectState' readonly @click="search_address"
                             class="relative z-1 w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 bg-slate-300" maxlength="40">
					          </span>
                </li>
                <li class="item table_form z-1">
                    <span class="item_title title_5">City</span>
                    <span class="item_content m_contents z-10 relative">
                        <input name="address" id="city" type="text" placeholder="" v-model='city' readonly @click="search_address"
                               class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 bg-slate-300" maxlength="40">
                      </span>
                </li>
                <li class="item table_form">
                    <span class="item_title title_5">Address Line 1</span>
                    <span class="item_content m_contents z-10 relative">
                  <input name="address" id="street" type="text" placeholder="Please enter up to 40 characters." v-model='Street'
                         class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="40">
                  </span>
                </li>
                <li class="item table_form">
                    <span class="item_title title_5">Address Line 2</span>
                    <span class="item_content m_contents z-10 relative">
                  <input name="address" id="street" type="text" placeholder="Please enter up to 40 characters." v-model='addr2_1'
                         class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="40">
                  </span>
                </li>

            </ul>
            <div class="myo_condition_search2">
                <button type="submit" class="search_btn">{{ i18n.myoffice.save }}</button>
            </div>
        </form>
    </div>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import modalView from "../../modalView";
import vSelect from "vue-select";
import {Tooltip} from "floating-vue";

export default {
    name: "AutoShipModify",
    components : {
        VueSimpleAlert,
        ShoppingCartIcon,
        Loading,
        modalView,
        vSelect,
        VTooltip : Tooltip
    },
    data: () => ({
        i18n: window.i18n,
        laravel: window.Laravel,
        no_search_messsage: '',
        isLoggedIn: false,
        loading : false,
        address_search_open:false,
        autoship : [],
        addr2_1 : "",
        addr2_2 : "",
        Street : "",
        post : "",
        city : "",
        selectState : "",
        deli_kind : "",
        insurance_yn : "",
    }),
    beforeCreate() {

    },
    created() {
        this.loading = true;
        axios.post(process.env.MIX_BACK_URL+'/api/getNormalAsMst',{
            bz_cd: window.Laravel.bz_cd,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
            .then(response => {
                this.autoship = response.data;
                this.selectState = response.data.state;
                this.city = response.data.city;
                this.Street = response.data.street;
                this.addr2_1 = response.data.trade;
                this.post = response.data.r_post;
                this.post = response.data.r_post;

                if(response.data == ""){
                    VueSimpleAlert.alert("You are not enrolled in Autoship.","","",{allowOutsideClick : false}).then((result) => {
                        if(result){
                            location.replace("/MyofficeMain");
                        }
                    });
                }
                this.loading = false;   // this.getCartList(response.data);
            })

    },
    mounted() {
    },
    methods:{
        submitForm: function (event) {
            let formData = new FormData();
            if(!this.autoship.r_addr1){
                alert.alert('Please enter your address');
                $("#addr1").focus();
                return;
            }
            if(!this.autoship.r_addr2){
                alert.alert('Please enter your address');
                $("#addr2").focus();
                return;
            }
            formData.append('r_name'  , this.autoship.r_name   ? this.autoship.r_name.trim() : '');
            formData.append('r_mobile', this.autoship.r_mobile ? this.autoship.r_mobile.trim() : '');
            formData.append('r_addr1' , this.selectState  ? this.city+"|@|"+this.selectState.trim() : '');
            formData.append('r_addr2' , this.Street  ? this.Street.trim()+"|@|"+this.addr2_1 : '');
            formData.append('r_post'  , this.post   ? this.post.trim() : '');
            formData.append('deli_kind'  , this.autoship.deli_kind   ? this.autoship.deli_kind.trim() : '2');
            formData.append('insurance_yn'  , this.autoship.insurance_yn   ? this.autoship.insurance_yn.trim() : 'N');

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + window.Laravel.access_token
                }
            }

            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/autoShipAddressModify',
                formData
                , config).then(response => {
                if(response.data.result == "1"){
                    VueSimpleAlert.alert(i18n.myoffice.memberUpChangePopNotice.notice12,"","",{allowOutsideClick : false}).then((result) => {
                        if(result){
                            location.replace("/MyAutoshipEdit");
                        }
                    });
                }
                this.loading = false;
            });

        },
        search_address(){
            this.address_search_open = true;
        },
        set_address(value){
            this.address_search_open = false;
            this.selectState =value.state;
            this.city = value.district;
            this.Street = "";
            this.addr2_1 = "";
            this.post = value.postcode;
        },
        close_address_search(){
            this.address_search_open = false;
        },
    },

}

</script>

<style scoped>

</style>
