<template>
    <div v-if="showPrice !== ''">
        <div v-if="type==='list' && isLoggedIn === false" >
            <div class="text-left pr-1">
                <p class="xl:text-base lg:text-base text-sm text-gray-400">
                    <span class="">Retail Price : </span>
                    <span class="">{{ showPrice }}</span>
                </p>
            </div>
        </div>
        <div v-else-if="type==='detail'">
            <div class="flex mb-1 py-2 border-b">
                <div class="w-1/2 font-bold text-base">Retail Price</div>
                <div
                    class="w-1/2 text-right xl:text-xl lg:text-xl text-base font-bold text-gray-400">
                    {{ showPrice }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConsumerPrice",
    props: ['price', 'isLoggedIn', 'type'],
    data: () => ({
        showPrice: '',
    }),
    created() {
        this.showPrice = this.verificationPrice(this.price)
    },
    methods: {
        verificationPrice(price) {
            if (typeof this.price === 'string') {
                return (this.price.toUpperCase().trim() === 'USD $0.00') ? '' : this.price
            }
            return ''
        }
    }
}
</script>

<style scoped>
</style>
