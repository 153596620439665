<template>
  <dl id="admin_page">

    <article class="myo_search_area">
      <form name='form_search' style="clear:both;"  v-on:submit.prevent="submitForm">
        <section class="myo_condition_box">
          <dl class="myo_search_list">
            <dt>{{ i18n.search.find }}</dt><!-- 검색 -->
            <dd class="">
              <section class="flex_box">
                <div class="myo_select_box">
                  <vSelect :options="list_cate_str" label="value" :reduce="value => value.title" :clearable="false" v-model="category"></vSelect>
                </div>

                <div class="myo_search_box">
                  <input type="text" class="myo_search_input" name="search_text" v-model="search_text">
                </div>
              </section>
            </dd>
          </dl>
        </section><!-- // myo_condition_box -->

        <div class="myo_condition_search2">
          <button type="reset"  class="reset_btn" onclick="location.reload()">{{ i18n.myoffice.Refresh }}</button>
          <button type="submit" class="search_btn btn-eft-none">{{ i18n.myoffice.Search }}</button>
        </div>
      </form>
    </article>

    <div class="position_relate_box">
      <abbr title="{{ i18n.myoffice.myofficeAlert.CanTablescroll2 }}" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
    </div>

    <section id="wrapper">
      <div class="container">
        <ul class="accordion">
          <li v-for="(Value,index) in List" class="item">
            <h2 class="accordionTitle">{{Value.subject}}<span class="accIcon"></span></h2>
            <div class="text" v-html="Value.memo"></div>
          </li>
        </ul>
      </div>
    </section>

    <div style="display: flex;  align-items: center;   justify-content: center;" class="mt-4 border-[#e0e4e7]" v-show="total_count > 0">
      <v-pagination
        v-model="page"
        :pages=total_page
        :range-size="1"
        active-color="#DCEDFF">
      </v-pagination>
    </div>
    <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4 bg-white px-2 py-3 border-[#e0e4e7]" v-show="total_count == 0">
      No Data
    </div>
  </dl>
</template>


<script>
  import vSelect from "vue-select";
  import VPagination from "@hennge/vue3-pagination";
  import Loading from "vue3-loading-overlay";
  import VueSimpleAlert from "vue3-simple-alert";

  export default {
    name: "MyFAQ",
    components : {
      VPagination,
      Loading,
      vSelect,
    },
    data: () => ({
      i18n: window.i18n,
      List : [],
      total_count : 0,
      page : 1,
      total_page : 1,
      mid : window.location.pathname,
      search_text : "",
      list_cate_str : [],
      category : 'A',
    }),
    watch : {
      page : function(){
        this.submitForm();
      }
    },
    created : function (){
      this.submitForm();
    },
    updated : function () {
      this.accordion();
    },
    methods : {
      submitForm : function (){
        axios.post(window.Laravel.back_url+'/api/Faq',{
          page : this.page,
          page_size : 20,
          mid : this.mid,
          search_text : this.search_text,
          category : this.category
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
          this.List = response.data.list.data;
          this.list_cate_str = response.data.list_cate_str;
          this.total_count = response.data.total_count;
          this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
          this.loading = false;
        });
      },
      accordion : function (){
        var accordionBtn = document.querySelectorAll('.accordionTitle');
        var allTexts = document.querySelectorAll('.text');
        var accIcon = document.querySelectorAll('.accIcon');
        //console.log(accordionBtn,allTexts,accIcon);
        // event listener
        accordionBtn.forEach(function (el) {
          el.addEventListener('click', toggleAccordion)
        });
        // function
        function toggleAccordion(el) {
          var targetText = el.currentTarget.nextElementSibling.classList;
          var targetAccIcon = el.currentTarget.children[0];
          var target = el.currentTarget;

          if (targetText.contains('show')) {
            targetText.remove('show');
            targetAccIcon.classList.remove('anime');
            target.classList.remove('accordionTitleActive');
          } else {
            accordionBtn.forEach(function (el) {
              el.classList.remove('accordionTitleActive');
              allTexts.forEach(function (el) {
                el.classList.remove('show');
              })
              accIcon.forEach(function (el) {
                el.classList.remove('anime');
              })
            })
            targetText.add('show');
            target.classList.add('accordionTitleActive');
            targetAccIcon.classList.add('anime');
          }
        }
      },
    }
  }
</script>
