<template>
  <section >
  <modalView class="z-50" v-if="halloffame_modal_open" @close-modal="halloffame_modal_open = false" :harf="true"  >
    <hall_of_fame_modal :img_url_selected="img_url_selected" @close_halloffame_modal="close_halloffame_modal" />
  </modalView>
  <!-- 명예의 전당 갤러리 리스트 페이지 -->
  <div class="xl:min-h-[800px] bg-box flex flex-col items-center">
    <div class="main-content max-w-[1400px] xl:w-full lg:w-full w-11/12 m-0">
          <!-- 타이틀 -->
          <div class="text-center pt-14 pb-14">
            <div class="bg-[url('/images/fame_gold_bg.png')] bg-no-repeat inline-block xl:py-8 lg:py-8 py-2 xl:px-12 lg:px-12 px-8 rounded-md shadow-[0px_1px_2px_2px_rgba(175,147,68,1)] shadow-[#af9344]">
              <p class="xl:text-3xl lg:text-3xl text-2xl text-black font-semibold">HALL of FAME</p>
              <p class="mt-2">
                <span class="xl:text-xl lg:text-xl text-lg text-black font-semibold">{{ this.monthName.at(this.month-1) }}</span>&nbsp;
                <span class="xl:text-xl lg:text-xl text-lg text-black font-semibold">{{ this.year }}</span>
              </p>
            </div>
          </div>
          <!-- 직급별 -->
          <div class="w-full bg-black bg-opacity-90 shadow-lg border border-[#] xl:py-20 lg:py-14 py-10 xl:px-15 lg:px-10 px-5 border-gradient xl:mb-20 lg:mb-20 mb-14">
            <!-- President -->
            <div v-if="pFlag" class="mb-32">
<!--              <h2 class="xl:text-4xl lg:text-4xl text-2xl font-semibold text-center xl:mb-10 lg:mb-10 mb-5 text-gradient pb-4">President</h2>-->
<!--              <h2 class="xl:text-4xl lg:text-4xl text-2xl font-semibold text-center xl:mb-10 lg:mb-10 mb-5 text-gradient pb-4 font-dancing-script">President</h2>
              <h1 class="xl:text-4xl lg:text-4xl text-2xl font-semibold text-center xl:mb-10 lg:mb-10 mb-5 text-gradient pb-4 font-great-vibes">Hello, World!</h1>-->


<!--            <div class="text-transparent bg-clip-text font-dancing-script font-semibold xl:mb-10 lg:mb-10 mb-5 pb-4 text-center flex items-baseline justify-center gap-3" style="background-image: linear-gradient(to bottom, #f5da44, #fde2bc, #FFEB3B); color: transparent; font-size:50px;">
              <div class="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" height="1em" viewBox="0 0 640 512">
                    <defs>
                      <linearGradient id="grad" gradientTransform="rotate(90)">
                        <stop offset="0%" stop-color="#f5da44" />
                        <stop offset="50%" stop-color="#fde2bc" />
                        <stop offset="100%" stop-color="#FFEB3B" />
                      </linearGradient>
                    </defs>
                    <path d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z" fill="url(#grad)" />
                  </svg>
              </div>
              <div>
                President
              </div>
              <div class="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" height="1em" viewBox="0 0 640 512">
                  <defs>
                    <linearGradient id="grad" gradientTransform="rotate(90)">
                      <stop offset="0%" stop-color="#f5da44" />
                      <stop offset="50%" stop-color="#fde2bc" />
                      <stop offset="100%" stop-color="#FFEB3B" />
                    </linearGradient>
                  </defs>
                  <path d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z" fill="url(#grad)" />
                </svg>
              </div>
            </div>-->

            <div class="text-transparent bg-clip-text font-great-vibes font-semibold xl:mb-10 lg:mb-10 mb-4 pb-4 text-center flex items-baseline justify-center gap-4" style="background-image: linear-gradient(to bottom, #f5da44, #fde2bc, #FFEB3B); color: transparent;">
              <div class="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="xl:w-8 lg:w-8 w-4 xl:h-8 lg:h-8 h-4" height="1em" viewBox="0 0 640 512">
                    <defs>
                      <linearGradient id="grad" gradientTransform="rotate(90)">
                        <stop offset="0%" stop-color="#f5da44" />
                        <stop offset="50%" stop-color="#fde2bc" />
                        <stop offset="100%" stop-color="#FFEB3B" />
                      </linearGradient>
                    </defs>
                    <path d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z" fill="url(#grad)" />
                  </svg>
              </div>
              <div class="title_detail">President</div>
              <div class="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="xl:w-8 lg:w-8 w-4 xl:h-8 lg:h-8 h-4" height="1em" viewBox="0 0 640 512">
                  <defs>
                    <linearGradient id="grad" gradientTransform="rotate(90)">
                      <stop offset="0%" stop-color="#f5da44" />
                      <stop offset="50%" stop-color="#fde2bc" />
                      <stop offset="100%" stop-color="#FFEB3B" />
                    </linearGradient>
                  </defs>
                  <path d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z" fill="url(#grad)" />
                </svg>
              </div>
            </div>


              <div :class="dataList.filter(data => data.rank_cd === '70').length > 1 ? 'grid gap-3 xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 mt-4' : 'flex justify-center'">
                <template v-for="data in dataList">
                  <div v-if="data.rank_cd === '70'" class="flex justify-center">
                    <div class="border relative text-center flex align-center justify-center">
                      <div class="transition duration-400 ease-in-out  absolute left-0 top-0 w-full h-full"></div>
                      <img :src="'http://cdnus.gcoop.me/' + data.thumbnail" alt="GC hall of fame" class="object-cover max-h-[350px]" />
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- Vice President -->
            <div v-if="vpFlag" class="mb-32">
              <div class="text-transparent bg-clip-text font-great-vibes font-semibold xl:mb-10 lg:mb-10 mb-4 pb-4 text-center flex items-baseline justify-center gap-4" style="background-image: linear-gradient(to bottom, #f5da44, #fde2bc, #FFEB3B); color: transparent;">
                <div class="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="xl:w-8 lg:w-8 w-4 xl:h-8 lg:h-8 h-4" height="1em" viewBox="0 0 640 512">
                    <defs>
                      <linearGradient id="grad" gradientTransform="rotate(90)">
                        <stop offset="0%" stop-color="#f5da44" />
                        <stop offset="50%" stop-color="#fde2bc" />
                        <stop offset="100%" stop-color="#FFEB3B" />
                      </linearGradient>
                    </defs>
                    <path d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z" fill="url(#grad)" />
                  </svg>
                </div>
                <div class="title_detail">Vice President</div>
                <div class="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="xl:w-8 lg:w-8 w-4 xl:h-8 lg:h-8 h-4" height="1em" viewBox="0 0 640 512">
                    <defs>
                      <linearGradient id="grad" gradientTransform="rotate(90)">
                        <stop offset="0%" stop-color="#f5da44" />
                        <stop offset="50%" stop-color="#fde2bc" />
                        <stop offset="100%" stop-color="#FFEB3B" />
                      </linearGradient>
                    </defs>
                    <path d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z" fill="url(#grad)" />
                  </svg>
                </div>
              </div>
              <div :class="dataList.filter(data => data.rank_cd === '60').length > 1 ? 'grid gap-3 xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 mt-4' : 'flex justify-center'">
                <template v-for="data in dataList">
                  <div v-if="data.rank_cd === '60'"  class="flex justify-center">
                    <div class="border relative text-center flex align-center justify-center">
                      <div class="transition duration-400 ease-in-out  absolute left-0 top-0 w-full h-full"></div>
                      <img :src="'http://cdnus.gcoop.me/' + data.thumbnail" alt="GC hall of fame" class="object-cover max-h-[350px]" />
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- Director -->
            <div v-if="dirFlag" class="mb-32">
              <div class="text-transparent bg-clip-text font-great-vibes font-semibold xl:mb-10 lg:mb-10 mb-4 pb-4 text-center flex items-baseline justify-center gap-4" style="background-image: linear-gradient(to bottom, #f5da44, #fde2bc, #FFEB3B); color: transparent;">
                <div class="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="xl:w-8 lg:w-8 w-4 xl:h-8 lg:h-8 h-4" height="1em" viewBox="0 0 640 512">
                    <defs>
                      <linearGradient id="grad" gradientTransform="rotate(90)">
                        <stop offset="0%" stop-color="#f5da44" />
                        <stop offset="50%" stop-color="#fde2bc" />
                        <stop offset="100%" stop-color="#FFEB3B" />
                      </linearGradient>
                    </defs>
                    <path d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z" fill="url(#grad)" />
                  </svg>
                </div>
                <div class="title_detail">Director</div>
                  <div class="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="xl:w-8 lg:w-8 w-4 xl:h-8 lg:h-8 h-4" height="1em" viewBox="0 0 640 512">
                      <defs>
                        <linearGradient id="grad" gradientTransform="rotate(90)">
                          <stop offset="0%" stop-color="#f5da44" />
                          <stop offset="50%" stop-color="#fde2bc" />
                          <stop offset="100%" stop-color="#FFEB3B" />
                        </linearGradient>
                      </defs>
                      <path d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z" fill="url(#grad)" />
                    </svg>
                  </div>
                </div>

              <div :class="dataList.filter(data => data.rank_cd === '50').length > 1 ? 'grid gap-3 xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 mt-4' : 'flex justify-center'">
                <template v-for="data in dataList">
                  <div v-if="data.rank_cd === '50'" >
                    <div class="border relative text-center flex align-center justify-center">
                      <div class="transition duration-400 ease-in-out  absolute left-0 top-0 w-full h-full"></div>
                      <img :src="'http://cdnus.gcoop.me/' + data.thumbnail" alt="GC hall of fame" class="object-cover max-h-[350px]" />
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- Branch Manager -->
            <div v-if="bmFlag" class="mb-32">
              <div class="text-transparent bg-clip-text font-great-vibes font-semibold xl:mb-10 lg:mb-10 mb-4 pb-4 text-center flex items-baseline justify-center gap-4" style="background-image: linear-gradient(to bottom, #f5da44, #fde2bc, #FFEB3B); color: transparent;">
                <div class="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="xl:w-8 lg:w-8 w-4 xl:h-8 lg:h-8 h-4" height="1em" viewBox="0 0 640 512">
                    <defs>
                      <linearGradient id="grad" gradientTransform="rotate(90)">
                        <stop offset="0%" stop-color="#f5da44" />
                        <stop offset="50%" stop-color="#fde2bc" />
                        <stop offset="100%" stop-color="#FFEB3B" />
                      </linearGradient>
                    </defs>
                    <path d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z" fill="url(#grad)" />
                  </svg>
                </div>
                <div class="title_detail">Branch Manager</div>
                <div class="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="xl:w-8 lg:w-8 w-4 xl:h-8 lg:h-8 h-4" height="1em" viewBox="0 0 640 512">
                    <defs>
                      <linearGradient id="grad" gradientTransform="rotate(90)">
                        <stop offset="0%" stop-color="#f5da44" />
                        <stop offset="50%" stop-color="#fde2bc" />
                        <stop offset="100%" stop-color="#FFEB3B" />
                      </linearGradient>
                    </defs>
                    <path d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z" fill="url(#grad)" />
                  </svg>
                </div>
              </div>
              <div :class="dataList.filter(data => data.rank_cd === '40').length > 1 ? 'grid gap-3 xl:grid-cols-4 lg:grid-cols-4 grid-cols-2 mt-4' : 'flex justify-center'">
                <template v-for="data in dataList">
                  <div v-if="data.rank_cd === '40'">
                    <div class="text-center p-2">
                      <div class="font-bold text-white xl:text-3xl lg:text-2xl text-lg text-gradient2">{{ data.name }}</div>
                      <div class="font-bold text-white xl:text-2xl lg:text-xl text-base">{{ `(${data.userid})` }}</div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
    </div>
  </div>
  </section>
</template>
<script>
import Loading from "vue3-loading-overlay";
import vSelect from "vue-select";
import modalView from "../../modalView.vue";
import {Tooltip} from "floating-vue";

export default {
  name: "HallofFameView",
  components : {
    // address_book,
    vSelect,
    modalView,
    Loading,
    VTooltip : Tooltip
  },
  props: ['year_month'],
  watch: {

  },
  data: () => ({
    i18n: window.i18n,
    monthName : ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec',],
    rankList : [],//'40','50','60','70'
    dataList : [],
    year : '',
    month : '',
    bmFlag : '',
    dirFlag : '',
    vpFlag : '',
    pFlag : '',
    halloffame_modal_open : false,
    img_url_selected : '',


  }),
  created() {
    this.loading = true;
    this.getHallOfFameByYearMonth();
    this.year = this.year_month.substr(0,4);
    this.month = this.year_month.substr(4,2);
    if(this.month < 10){
      this.month = '0' + this.month;
    }
  },
  mounted() {

  },
  methods:{
    getHallOfFameByYearMonth(){
      axios.post(process.env.MIX_BACK_URL+'/api/getHallOfFame',{
        bz_cd: window.Laravel.bz_cd,
        exec_mode: 'list_detail',
        yearMonth: this.year_month,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          this.dataList = response.data;
          response.data.forEach((value, index) => {
            if(value.rank_cd == '40' ){
              this.bmFlag = true;
            }
            if(value.rank_cd == '50'){
              this.dirFlag = true;
            }
            if(value.rank_cd == '60'){
              this.vpFlag = true;
            }
            if(value.rank_cd == '70'){
              this.pFlag = true;
            }
          });
        })
        .catch(e => {
        });

    },
    list_url(yearMonth){
      location.href="/HallofFameView/"+yearMonth;
    },
    /* S: Modal 창 관련 */
    show_detail(value){
      this.halloffame_modal_open = true;
      this.img_url_selected = value;
    },
    close_halloffame_modal(){
      this.halloffame_modal_open = false;
    },

    /* E: Modal 창 관련 */

  }
}
</script>


<style scoped>
.button_slide {
  border:1px solid #000;
  box-shadow: inset 0 0 0 0 #D80286;
  -webkit-transition: ease-out 0.6s;
  -moz-transition: ease-out 0.6s;
  transition: ease-out 0.6s;
  text-align:center;
}
.slide_right:hover {
  box-shadow: inset 450px 0 0 0 #D80286;
  opacity:0.2;
}
.bg-box{background:url("/images/HallofFame/bg_hallofFame2.jpg") no-repeat; background-size: cover; background-position:center top;}
.text-gradient {background-image: linear-gradient(to right, #f7e5ad, #9d5b00); -webkit-background-clip: text; -webkit-text-fill-color: transparent; /*color: transparent;*/}
.text-gradient2{background-image: linear-gradient(to right, #f7e5ad, #c79a5b); -webkit-background-clip: text; -webkit-text-fill-color: transparent; /*color: transparent;*/}
.border-gradient{border: 2px solid; border-image-slice: 1; border-image-source: linear-gradient(to right, rgba(217, 189, 158, 1), rgba(146, 108, 61, 1));}
.title_detail{font-size: 70px; letter-spacing: 3px;}

@media all and (max-width:700px) {
  .title_detail{font-size: 30px;}
}

</style>
