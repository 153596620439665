<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <p class="p_alert mb-7">You can check the current consumer sales status here.
      The consumer sales achievement rate is calculated based on the member price and sales amount of the ordered product.
      For further inquiries, please contact customer service.
    </p>
<!--  옴니트리션 현황에 대한 조회가 가능합니다.
  옴니트리션 달성률은 주문하신 제품의 소비자가와 판매금액을 기준으로 산정됩니다.
  자세한 문의는 고객센터를 통해 문의해 주시기 바랍니다.-->
  <section>
        <div class="flex 2xl:flex-row md:flex-row flex-col w-full gap-5">
            <div id="charts-area" class="2xl:w-1/3 md:w-1/2 w-full">
                <div class="p-2 m-1 mb-3 bg-white shadow-zinc-500/40 shadow-md rounded-xl">
                    <h3 class="w-full pt-6 text-center text-xl">{{ selectedYear }}</h3>
                  <!-- 미국지사 요청으로 주석처리 -->
<!--                    <h3 class="py-3 font-semibold text-[#3d79ea] text-lg mb-4 xl:flex lg:flex xl:flex-row lg:flex-row block">
                        <span>{{ username }}</span>&nbsp;I&nbsp;
                        <span>{{ user_rank }} ({{ user_rank_max }})</span>
                    </h3>-->
<!--                    <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
                        <input type="hidden" name="order" id="order">
                        <input type="hidden" name="sort" id="sort">
                        <section class="myo_condition_box">
                            <dl class="myo_search_list" >
                                <dt>Select Year</dt>
                                <dd class="accordion_cont w-full block bg-transparent">
                                    <div class="relative z-[11] lg:mb-0 mb-2 bg-white">
                                        <select name="selectYear" class="w-full" v-model="selectedYear" @change="submitForm($event)">
                                            <option v-for="year in years" :value="year">{{ year }}</option>
                                        </select>
                                    </div>
                                </dd>
                            </dl>
                        </section>
                    </form>-->
                    <div class="xl:h-80 lg:h-80 h-auto w-full px-3 mb-10">
                        <!-- 도넛 그래프 추가 -->
                        <apexcharts type="radialBar" :options="chartOptions" :series="series"></apexcharts>
                    </div>
                </div>
            </div>
            <div class="2xl:w-2/3 md:w-1/2 w-full overflow-x-scroll block sm:overflow-hidden">
                <table class="border-separate w-full table-auto text-slate-800">
                    <tbody>
                    <!-- 연/월 달성률 주문금액 소비자판매금액-->
                    <tr>
                        <th class="bg-[#172e59] text-center text-white p-3">Year</th>
                        <th class="bg-[#172e59] text-center text-white p-3">Achievement Rate</th>
                        <th class="bg-[#172e59] text-center text-white p-3">Order Amount</th>
                        <th class="bg-[#172e59] text-center text-white p-3">Consumer Sales Amount</th>
                    </tr>
                    <tr v-for="(Value,index) in omniList" class="bg-[#f0f4ff] text-center">
                        <td class="text-center p-2">{{ (Value.ord_mon)?.substring(0,4) }}</td>
                        <td class="text-center p-2">{{ Value.cs_o_rate <= 100 ? Value.cs_o_rate : 100 }}%</td>
                        <td class="text-center p-2">${{ Value.cs_ord_amt }}</td>
                        <td class="text-center p-2">${{ Value.cs_amt }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>

</template>

<script>

import numeral from "numeral";
import dateForm from '../../searchForm/dateForm.vue'
import VueApexCharts from 'vue3-apexcharts'
import moment from "moment/moment";
import Loading from "vue3-loading-overlay";
import VueSimpleAlert from "vue3-simple-alert";


export default {
    name: "MyOmnitrition",
    components : {
        apexcharts: VueApexCharts,
        dateForm,
        Loading,
        VueSimpleAlert,

    },
    data: () => ({
        showModal: false,
        working_nation : working_nation,
        i18n: window.i18n,
        userid : window.Laravel.user,
        username : window.Laravel.username,
        user_rank : window.Laravel.rank_name,
        user_rank_max : window.Laravel.rank_name_max,
        selectedYear: null,
        omniList : [],
        series: [0],
        chartOptions: {
            chart: {
                height: 300,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '60%',
                    }
                },
            },
            labels: ['Achievement Rate'],
        },
    }),
    computed : {
       /* years () {
            const year = new Date().getFullYear();
            return Array.from({length: year - 2022}, (value, index) => year - index);
        }*/
    },
    mounted: function () {
        console.log(this.chartOptions);
        // this.selectedYear = this.years[0];
        this.submitForm();
    },
    methods:{
        submitForm : function (){
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/MyOmnitrition',{
                selectYear : this.selectedYear,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if(response.data.length == 0){
                    VueSimpleAlert.alert("There are no search results","","",{confirmButtonColor: '#0abab5'});
                    this.list_msg = "No data";
                    return false;
                }
                this.omniList = response.data;
                // this.omniList.ord_mon = this.omniList.ord_mon.substring(4,6)+"/"+this.omniList.ord_mon.substring(0,4)
                this.selectedYear = (this.omniList[0].ord_mon)?.substring(0,4);
                this.series = (this.omniList[0].cs_o_rate)?.toString().split();
                this.loading = false;
            }).catch(error => console.error(error));
        },

    },
}

</script>

<style scoped>
.board-inner{background:#f0f2f4 !important;}

#charts-area {
    max-width:450px;
}

/*.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}*/

</style>
