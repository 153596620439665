<template>
  <h3 class="xl:text-2xl lg:text-xl text-lg mt-3 mb-6 font-semibold">Find My ID</h3>
  <div class="w-full flex flex-col lg:flex-row justify-start lg:py-2 lg:items-center mb-4">
    <div class="w-full lg:w-1/6 text-base font-semibold xl:mb-0 lg:mb-0 mb-2"><span class="text-red-500">*</span>Nationality</div>
    <div class="w-full lg:w-5/6 flex flex-row items-center gap-4">
      <div class="flex items-center">
        <input type="radio" id="nt_cd1" value="001" v-model="nt_cd"><label for="nt_cd1" class="cursor-pointer">&nbsp;United States</label>
      </div>
      <div class="flex items-center cursor-pointer">
        <input type="radio" id="nt_cd2" value="002" v-model="nt_cd"><label for="nt_cd2" class="cursor-pointer">&nbsp;Canada</label>
      </div>
    </div>
  </div>
  <div class="w-full flex flex-col lg:flex-row justify-start lg:py-2 lg:items-center mb-4">
    <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>Name</div>
    <div class="w-full lg:w-3/4 glow"><input type="text" class="type_pwd h-10 w-full xl:w-60 lg:w-60" v-model="name"></div>
  </div>
  <div class="w-full flex flex-col lg:flex-row justify-start lg:py-2 lg:items-center mb-4">
    <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>Birth Date</div>
    <div class="w-full lg:w-5/6 flex gap-2 relative z-30" style="text-align:left;">
      <vSelect :options="month" label="value" :clearable="false" :reduce="value => value.title"  class="xl:w-40 lg:w-40 w-full text-sm md:text-base h-[38px] border border-[#6b7280]"  v-model="strtMonth"></vSelect>
      <vSelect :options="day" label="value" :clearable="false" class="xl:w-40 lg:w-40 w-full text-sm md:text-base h-[38px] border border-[#6b7280]"  v-model="strtDay"></vSelect>
      <vSelect :options="year" label="value" :clearable="false" class="xl:w-40 lg:w-40 w-full text-sm md:text-base h-[38px] border border-[#6b7280]"  v-model="strtYear"></vSelect>
    </div>
  </div>
  <div class="w-full flex flex-col lg:flex-row justify-start lg:py-2 lg:items-center mb-4">
    <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>Phone Number</div>
    <div class="glow w-full lg:w-3/4" style="text-align:left;">
      <input type="text" v-model="mobile" style="ime-mode:disabled;" onKeyUp="" maxlength="12" class="type_number w-full xl:w-60 lg:w-60">
    </div>
  </div>
  <div id="submit_area" class="mt-8"><span class="btn"><input type="button" id="send_btn" value='Search' v-on:click="send_data" class="my-2 py-2 px-4 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 xl:w-16 lg:w-16 w-1/2 search_btn rounded-md"></span></div>
</template>

<style>
.vs__dropdown_menu{background:#fff;}
</style>
<script>
import vSelect from "vue-select";
import alert from "vue3-simple-alert";

export default {
  name: "MyFindId",
  components: {
    vSelect
  },
  data: () => ({
    i18n: window.i18n,
    year : [],
    day : [],
    month : [{title: "01", value  : "Jan"},
      {title: "02", value  : "Feb"},
      {title: "03", value  : "Mar"},
      {title: "04", value  : "Apr"},
      {title: "05", value  : "May"},
      {title: "06", value  : "Jun"},
      {title: "07", value  : "Jul"},
      {title: "08", value  : "Aug"},
      {title: "09", value  : "Sep"},
      {title: "10", value  : "Oct"},
      {title: "11", value  : "Nov"},
      {title: "12", value  : "Dec"},],
    name : "",
    pin_type_list : [{title: "N", value  : "NRIC"},{title : "P" , value : "PASSPORT"}],
    pin_type : "N",
    jumin1 : "",
    jumin2 : "",
    jumin3 : "",
    forign_jumin : "",
    mobile : "",
    strtYear : "",
    strtDay : "",
    strtMonth : "",
      nt_cd : "",
  }),
  created : function (){
    for (var i = current_year; i >= lastyear; i--){
      this.year.push(i);
    }
    for (var i = 1; i <= 31; i++){
      var day = i;
      if(day < 10){
        day = "0"+day;
      }
      this.day.push(day);
    }

  },
  methods:{
    send_data(){
      if(this.name == "") {
        alert.alert("Please enter your name!","","",{allowOutsideClick : false}).then(r => {});
        return false;
      }

      if(this.strtYear == "" || this.strtMonth == "" || this.strtDay == "") {
        alert.alert("Please select your birthday!","","",{allowOutsideClick : false}).then(r => {});
        return false;
      }

      if(this.mobile == "") {
        alert.alert("Please enter your Phone number!","","",{allowOutsideClick : false}).then(r => {});
        return false;
      }

        if(this.nt_cd == "") {
            alert.alert(i18n.join.join.memformtext33,"","",{allowOutsideClick : false}).then(r => {});
            return false;
        }




        axios.post(window.Laravel.back_url+'/api/getSearchId', {
        username : this.name,
        birthday : this.strtYear+this.strtMonth+this.strtDay,
        mobile : this.mobile,
        bz_cd : window.Laravel.bz_cd,
          nt_cd: this.nt_cd
      }).then(response => {
        if(response.data != 0){
          alert.alert("Your Member ID is "+response.data,"","",{allowOutsideClick : false}).then(r => {
            location.replace("/sso/login");
          });
        }else{
          alert.alert("The information entered does not match our records","","",{allowOutsideClick : false}).then(r => {
          });
        }

      });

    },

  }

}
</script>

<style scoped>
.vs__dropdown-toggle{border:0px solid #fff !important;}
</style>
