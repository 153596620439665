<template>
    <loading :active="false"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <article class="myo_search_area">
        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
            <section class="myo_condition_box">
                <dl class="myo_search_list">
                    <dt>{{ i18n.myoffice.Commission.PaymentMonth }}</dt>
                    <dd class="myo_search_list">
                        <section class="flex_box benefit_date_box">
                            <vSelect :options="months" label="value" :reduce="value => value.title" :clearable="false" v-model="s_month"  ></vSelect>
                            <vSelect :options="years" label="value" :reduce="value => value.value"  :clearable="false" v-model="s_year"  ></vSelect>
                            <!--vSelect :options="days" label="value" :clearable="false" v-model="s_day" v-show="s_day!='N/A'"  ></vSelect-->
                        </section>
                    </dd>
                </dl>
            </section>

            <div class="myo_condition_search2">
                <button type="reset" class="reset_btn" onclick="location.href='/MyBenefitList';">{{ i18n.myoffice.Refresh }}</button>
                <button type="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
            </div>

        </form>
    </article>

    <section class="accordion_notice_box">
        <div class="accordion_notice_item">
            <a class="accordion_notice_tit" href="#n">
                <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                {{ i18n.myoffice.myoSearchForm.notice }}
                <div class="bmenu x7">
                    <span class="btop">
                        <i class="fas fa-chevron-up" aria-hidden="true"></i>
                    </span>
                </div>
            </a>
            <div class="accordion_notice_cont">
                <div class="myo_search_notice">
                    <p>{{ i18n.myoffice.Commission.txt1 }}</p>
                    <p>{{ i18n.myoffice.myofficeAlert.CarryoverBonusAlert }}</p>
                    <p>{{ i18n.myoffice.myofficeAlert.CarryoverBonusAlert2 }}</p>
                </div>
            </div>
        </div>
    </section>

    <div id="printArea">
        <div class="cont_wrap">

            <div style="height:30px;"></div>

          <div id="divPrint" style="width:100%;" >

                <!-- Commission Statement -->
                <div style="text-align: center; margin:10px 0 15px 0;"><strong class="gray06" style="font-weight:bold; font-size:28px;">{{ i18n.myoffice.Commission.commissionStatement }}</strong></div>

                <div class="position_relate_box2">
                    <span class="table_notice_left"><!-- {{Session::get("login.name", '--' }} --> {{ i18n.myoffice.myofficeAlert.YourBonusListAlert }}</span>
                    <abbr style="display:none;" title="{{ i18n.myoffice.myofficeAlert.CanTablescroll2 }}" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
                </div><!-- 님 수고 많으셨습니다. 회원님의 수당내역은 다음과 같습니다. -->

                <div>
                  <h2 class="text-xl font-semibold mb-3">Commission Statement</h2>
                  <div class="flex flex-row w-full mb-6">
                    <div class="w-full border bg-white rounded-xl p-5">
                      <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
                        <table class="border-separate border-spacing-2 table-auto w-full">
                            <colgroup>
                              <col style="min-width: 294px; width:33%"/>
                              <col/>
                            </colgroup>
                            <tbody class="tbody_print_C" v-if="member_info" >
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.Commission.paymentDate }}</th>
                                  <td class="text-center p-2 min-w-[100px]">{{ member_info.payment_date }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.MemberId }}</th>
                                  <td class="text-center p-2 min-w-[100px]">{{ member_info.memberid }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.memberName }}</th>
                                  <td class="text-center p-2 min-w-[100px]">{{ member_info.name }}</td>
                                </tr>
                                <tr class="no-show-vcm bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.refund.lastMonthAmount }}</th><!-- 전월이월금액 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.previous_month,2) }}</td>
                                </tr>
                                <tr class="no-show-vcm bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">Total Amount Accrued this Month</th><!-- 수당발생액 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.total_amount,2) }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.refund.totalDeduction }}</th><!-- 공제합계 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.sum_of_deduction,2) }}</td>
                                </tr>
<!--                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">-->
                                  <!--th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.Commission.sumofReduction }}</td-->
                                  <!--td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.sum_of_reduction,2) }}</td-->
<!--                                </tr>-->
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.Commission.otherPayment }}</th><!-- 기타금액 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.amt_etc,2) }}</td>
                                </tr>
                                <tr class="no-show-vcm bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.refund.lowLastMonthAmount }}</th><!-- 익월이월금액 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.next_month,2) }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">Total Amount to be Deposited</th>
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.payment_amount,2) }}</td>
                                </tr>
                            </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Bank Information -->
                <div>
                  <h2 class="text-xl font-semibold mb-3">Bank Information</h2>
                  <div class="flex flex-row w-full mb-6">
                    <div class="w-full border bg-white rounded-xl p-5">
                      <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
                        <table class="border-separate border-spacing-2 table-auto w-full">
                          <colgroup>
                            <col style="min-width: 294px; width:33%"/>
                            <col/>
                          </colgroup>
                          <tbody class="tbody_print_C" v-if="member_info" >
                              <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.Commission.bankName }}</th>
                                <td class="text-center p-2 min-w-[100px]">{{ bank_info.bank_name }}</td>
                              </tr>
                              <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.Commission.accountNumber }}</th>
                                <td class="text-center p-2 min-w-[100px]">{{ bank_info.account_number }}</td>
                              </tr>
                              <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.Commission.bankHolder }}</th>
                                <td class="text-center p-2 min-w-[100px]">{{ bank_info.account_holder }}</td>
                              </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Allowance/ deduction -->
                <div>
                  <h2 class="text-xl font-semibold mb-3">{{ i18n.myoffice.Commission.BonusTaxList }}</h2><!-- 수당공제내역 -->
                  <div class="flex flex-row w-full mb-6">
                    <div class="w-full border bg-white rounded-xl p-5">
                      <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
                        <table class="border-separate border-spacing-2 table-auto w-full">
                          <thead>
                              <tr class="bg-[#172e59]">
                                  <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.type }}</th>
                                  <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.category }}</th>
                                  <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.amount }}</th>
                              </tr>
                          </thead>
                          <tbody class="tbody_print_A" >
                              <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800" v-for="Value in info_list">
                                  <td class="text-center p-2 min-w-[100px]">{{ Value.gubun }}</td>
                                  <td class="text-center p-2 min-w-[100px]">{{ Value.payname }}</td>
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.amt,2) }}</td>
                              </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

            </div> <!-- print -->
        </div>


    </div>
    <div v-show="is_print" class="myo_condition_search2">
        <button type="button" class="search_btn"  @click="benefitListReport">{{ i18n.myoffice.Commission.Printing }}</button>
    </div>

</template>

<script>
import vSelect from "vue-select";
import Popper from "vue3-popper";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import printJS from 'print-js'

export default {
    name: "MyBenefitList",
    components : {
        vSelect,
        Loading,
        printJS
    },
    data: () => ({
        i18n        : window.i18n,
        isVcMember : (window.Laravel.isVcMember === true),
        paydate     : "",
        member_info : [],
        bank_info   : [],
        info_list   : [],
        is_print    : false,
        months : [{title : "01", value : i18n.myoffice.jan},
            {title : "02", value : i18n.myoffice.feb},
            {title : "03", value : i18n.myoffice.mar},
            {title : "04", value : i18n.myoffice.apr},
            {title : "05", value : i18n.myoffice.may},
            {title : "06", value : i18n.myoffice.jun},
            {title : "07", value : i18n.myoffice.jul},
            {title : "08", value : i18n.myoffice.aug},
            {title : "09", value : i18n.myoffice.sep},
            {title : "10", value : i18n.myoffice.oct},
            {title : "11", value : i18n.myoffice.nov},
            {title : "12", value : i18n.myoffice.dec}],
        s_year : new Date().getFullYear(),
        s_month : new Date().getMonth()+1 < 10 ? "0"+(new Date().getMonth()+1) : new Date().getMonth()+1,
        s_day : "",
        years : [],
        days : [],
        loading : false,

    }),
    watch : {
        s_year : function(){
            this.getGiveDay();
        },
        s_month : function(){
            this.getGiveDay();
        }
    },
    created : function (){
        var year = "2015";
        for(year ; year <= new Date().getFullYear() ; year++){
            this.years.push(
                {value: year}
            );
        }
        this.getGiveDay();
    },
    methods : {
        getGiveDay(){
            axios.post(window.Laravel.back_url+'/api/getGiveDay',{
                month : this.s_year+""+this.s_month
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response =>{
                this.s_day = response.data.data[0].value;
                this.days  = response.data.data;
                this.submitForm();
            });
        },
        submitForm : function (){
            this.loading = true;
            if(this.s_day === 'N/A') {
              this.member_info = [];
              this.bank_info   = [];
              this.info_list   = [];
              return;
            }
            axios.post(window.Laravel.back_url+'/api/MyBenefitList',{
                s_year  : this.s_year,
                s_month : this.s_month,
                s_day   : this.s_day,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {

                if(response.data.member_info === "-1") {
                  return;
                }
                if(response.data.info_list === "-1") {
                  return;
                }

                this.is_print    = true;
                this.member_info = response.data.member_info.commission_statement;
                this.bank_info   = response.data.member_info.bank_info;
                this.info_list   = response.data.info_list;
                this.loading     = false;

            });
        },
        formatPrice(value,digit) {
            if(!value) {
              return '';
            }
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        benefitListReport(){
            printJS({'printable' : 'printArea', 'type' : 'html','css':['/myoffice/css/my_benefit_list.css'],'scanStyles':false});
          //printJS({'printable' : 'printArea', 'type' : 'html', 'scanStyles':false});
        }

    },
  mounted() {

    if (this.isVcMember === true) {
      // 'noShowVcMember' 클래스를 가진 모든 요소를 찾아 배열로 변환합니다.
      const elements = [...document.getElementsByClassName('no-show-vcm')];
      // 배열의 각 요소에 대해 `display` 스타일을 'none'으로 설정합니다.
      elements.forEach(element => element.style.display = 'none');
    }
  }
}
</script>

<style scoped>

</style>
