<template>
    <div class="w-full p-4 flex justify-between border-b">
        <h2 class="w-full text-2xl">[ 정기구독 혜택 안내 ]</h2>
        <p @click=" close_autoship_open " class="cursor-pointer p-2">X</p>
    </div>
    <div class="modal-content">
        <table>
            <tbody><tr>
                <th colspan="2">하트 적립 예시</th>
            </tr>
            <tr>
                <td>결제 BV</td>
                <td>적립하트</td>
            </tr>
            <tr>
                <td>50BV 미만</td>
                <td>0하트</td>
            </tr>
            <tr>
                <td>50BV</td>
                <td>5,000하트</td>
            </tr>
            <tr>
                <td>135BV</td>
                <td>13,500하트</td>
            </tr>
            <tr>
                <td>499BV</td>
                <td>49,900하트</td>
            </tr>
            <tr>
                <td colspan="2">1회 구매 최대 적립 49,900하트 (극점)</td>
            </tr>
            </tbody></table>
        <p>20,000 하트 이상 적립된 시점부터 일반구매를 통해 사용 가능합니다. (정기구독, 지페스타 사용 불가)</p>
        <p>20,000 하트 이상 적립된 시점부터 <span class="point">일반구매 &gt; 결제수단 &gt; 일반결제 &gt; 하트 결제 메뉴</span>가 노출됩니다.</p>
        <p>적립 기준: 정기구독 50BV 이상 주문 BV * 10% * 1,000 하트</p>
    </div>
</template>

<script>

export default {
    name: "AutoShipAgreeText",
    components : {
        // address_book,
    },
    props:{
        openlayer: Boolean
    },
    data: () => ({
    }),
    methods:{
        close_autoship_open(){
            // this.openlayer = false;
            this.$emit("close_autoship_open");
        },

    }
}
</script>

<style scoped>
/*모달 콘텐츠*/
.modal-header {position: relative; border-radius:10px 10px 0 0; background:#fff; height:40px; box-sizing: border-box;}
.modal-body{border-radius: 0 0 10px 10px; overflow: hidden;}
.modal-content {padding:20px; height:400px; overflow-y: scroll;}
.modal-content {-ms-overflow-style: none; /* IE and Edge */ scrollbar-width: none; /* Firefox */}
.modal-content::-webkit-scrollbar {display: none; /* Chrome, Safari, Opera*/}
.modal-content h5{ font-weight:500; color:#333; font-size:20px; margin-bottom:25px; text-align:center;}
.modal-content table{background: #eef2f9; color:#333; word-break: break-word;     width: 100%;}
.modal-content table tr{}
.modal-content table th{text-align:center; padding:8px; border: 2px solid #fff; background:#c3cbd9; font-size: 15px;}
.modal-content table td{text-align:center; padding:5px; border: 2px solid #fff; font-weight: 400; font-size: 14px;}
.modal-content p{font-size:15px;}
.modal-content .point{color:#2196f3;}
.modal-close {position: absolute; top: 10px; right: 15px; color: #444; font-size:25px;}
.modal-close i{font-size:25px ;}
.modal-close:hover {color: #777;}

</style>
