<template>

    <div class="member-search">
        <loading :active="loading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage"></loading>
        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
            <input type="hidden" name="order" id="order">
            <input type="hidden" name="sort" id="sort">
            <section class="myo_condition_box">

                <section class="flex_box_quad">
                    <productNameForm></productNameForm>
                </section>

            </section>

            <div class="myo_condition_search">
                <button type="button" v-on:click="reset" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                <button type="submit" id="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
            </div>
        </form>
    </div>

    <p class="p_alert">You can check the inventory and consumer sales status of the products that you ordered.
      The sales ratio is the sales ratio for each product, and the final total ratio must exceed 80% in order to receive commission.
      If you sold products to consumers, you must register the order in the consumer order registration menu.
    </p><!-- *주문하신 제품의 재고와 소비자 판매 현황을 확인하실 수 있습니다.
판매 비율은 각 제품 별 판매 비율이며, 최종 합계 비율이 80%를 초과해야 수당을 지급받을 수 있습니다.
소비자에게 제품을 판매하신 경우, 반드시 소비자 주문등록 메뉴에서 주문을 등록해 주시기 바랍니다.
 -->

    <section>
        <div class="w-full overflow-x-scroll sm:overflow-hidden block">
            <table class="border-separate w-full table-auto text-slate-800">
                <tbody>
                <tr>
                    <!-- 제품명 잔여재고 판매완료 합계 소비자가 판매금액 합계금액 판매비율 -->
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Product Name</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Available inventory</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Sale Complete</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Total</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Price</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Sale Price</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Total Price</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Sales percentage</th>
                </tr>
                <tr v-for="(Value,index) in productList" class="bg-[#f0f4ff] text-center">
                    <td class="text-center p-2 min-w-[100px]">{{ Value.pdt_name }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ Value.now_qty }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ Value.sel_qty }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ Value.total_qty }}</td>
                    <td class="text-center p-2 min-w-[100px]">${{ Value.retail_amt }}</td>
                    <td class="text-center p-2 min-w-[100px]">${{ Value.sel_amt }}</td>
                    <td class="text-center p-2 min-w-[100px]">${{ Value.total_amt }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ Value.o_rate }}%</td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center">
                    <th class="bg-[#172e59] text-center text-white p-2 min-w-[100px]">Total</th>
                    <td class="text-center p-2 min-w-[100px]">
                        <span v-if="productList.reduce((partialSum, a) => {return parseInt(partialSum +=  parseInt(a.now_qty)) }, 0) >= 0">
                            {{ productList.reduce((partialSum, a) => {return parseInt(partialSum +=parseInt(a.now_qty)) }, 0) }}
                        </span>
                    </td>
                    <td class="text-center p-2 min-w-[100px]">
                        <span v-if="productList.reduce((partialSum, a) => {return parseInt(partialSum +=  parseInt(a.sel_qty)) }, 0) >= 0">
                            {{ productList.reduce((partialSum, a) => {return parseInt(partialSum +=parseInt(a.sel_qty)) }, 0) }}
                        </span>
                    </td>
                    <td class="text-center p-2 min-w-[100px]">
                        <span v-if="productList.reduce((partialSum, a) => {return parseInt(partialSum +=  parseInt(a.total_qty)) }, 0) >= 0">
                            {{ productList.reduce((partialSum, a) => {return parseInt(partialSum +=parseInt(a.total_qty)) }, 0) }}
                        </span>
                    </td>
                    <td class="text-center p-2 min-w-[100px]"></td>
                    <td class="text-center p-2 min-w-[100px]">
                        <span v-if="productList.reduce((partialSum, a) => {return parseFloat(partialSum +=  parseFloat(a.sel_amt)) }, 0) >= 0">
                            ${{ productList.reduce((partialSum, a) => {return parseFloat(partialSum +=parseFloat(a.sel_amt)) }, 0) }}
                        </span>
                    </td>
                    <td class="text-center p-2 min-w-[100px]">
                        <span v-if="productList.reduce((partialSum, a) => {return parseFloat(partialSum += parseFloat(a.total_amt)) }, 0) >= 0">
                            ${{ productList.reduce((partialSum, a) => {return parseFloat(partialSum += parseFloat(a.total_amt)) }, 0) }}
                        </span>
                    </td>
                    <td class="text-center p-2 min-w-[100px]">
                        <span v-if="productList.reduce((partialSum, a) => {return parseFloat(partialSum += parseFloat(a.o_rate)) }, 0) >= 0">
                            {{ parseFloat(productList.reduce((partialSum, a) => {return parseFloat(partialSum += parseFloat(a.sel_amt)) }, 0) / productList.reduce((partialSum, a) => {return parseFloat(partialSum += parseFloat(a.total_amt)) }, 0)*100).toFixed(2) }}%
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </section>

</template>

<script>

import numeral from "numeral";
import productNameForm from '../../searchForm/productNameForm.vue'
import VueSimpleAlert from "vue3-simple-alert";
import Loading from "vue3-loading-overlay";


export default {
    name: "MyOrderItems",
    components : {
        productNameForm,
        Loading,
        VueSimpleAlert,

    },
    data: () => ({
        showModal: false,
        working_nation : working_nation,
        loading : false,
        i18n: window.i18n,
        userid : window.Laravel.user,
        productList : [],
    }),
    watch : {
        page: function(){
            $("#submit").click();
        }
    },
    mounted() {
        $("#submit").click();
    },
    methods:{
        submitForm : function (event){
            event.preventDefault();
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/MyCustomerOrderCreate',{
                down_name : down_name.value,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if(response.data.total_count == 0){
                    VueSimpleAlert.alert("There are no search results","","",{confirmButtonColor: '#0abab5'});
                    this.list_msg = "No data";
                }
                console.log(response.data);
                this.productList = response.data;
                this.total_count = response.data.length;
                this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
                this.loading = false;
            }).catch(error => console.error(error));
        },

    },
}

</script>

<style scoped>
.board-inner{background:#f0f2f4 !important;}

/*.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}*/

</style>
