<template>

    <div class="member-search">
        <loading :active="loading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage"></loading>
        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
            <input type="hidden" name="order" id="order">
            <input type="hidden" name="sort" id="sort">
            <section class="myo_condition_box">

                <section class="flex_box_quad">
                    <div class="relative">
                        <dateForm @set_s_date="set_s_date" @set_e_date="set_e_date" ></dateForm>
                    </div>
                </section>
                <section class="flex_box_quad">
                    <nameForm></nameForm>
                </section>

            </section>

            <div class="myo_condition_search">
                <button type="button" v-on:click="reset" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                <button type="submit" id="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
            </div>
        </form>
    </div>

    <p class="p_alert">You can register consumers here. Please use the 1:1 inquiry board to delete the registered consumer information.</p>
    <!--소비자를 등록할 수 있습니다.  등록된 소비자 정보의 삭제는 1:1 문의 게시판을 이용해 주시기 바랍니다.-->

  <section>
        <div class="w-full overflow-x-scroll sm:overflow-hidden block">
            <table class="border-separate w-full table-auto text-slate-800">
                <tbody>
                <!-- 등록일자 소비자명 휴대폰번호 이메일 주소 -->
                <tr>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">No.</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Registration Date</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Consumer Name</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Phone Number</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Email Address</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Address</th>
                </tr>
                <tr v-for="(Value,index) in customerList" class="bg-[#f0f4ff] text-center">
                    <td class="text-center p-2 min-w-[100px]">{{ total_count - ((page-1)*20 + index) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ (Value.reg_date).substring(4, 6) }}/{{ (Value.reg_date).substring(6, 8) }}/{{ (Value.reg_date).substring(0, 4) }}</td>
                    <td class="text-center p-2 min-w-[100px] cursor-pointer" @click="openForm(Value.cs_id)">{{ Value.cs_name }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ Value.mobile }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ Value.email }}</td>
                    <td class="text-center p-2 min-w-[200px]">{{ (Value.addr2!= null?Value.addr2:'').replace('|@|', ', ') }} {{ (Value.addr1!= null?Value.addr1:'').replace('|@|', ', ') }} {{ Value.addr3 }} {{ Value.state }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
            <v-pagination
                v-model="page"
                :pages=total_page
                :range-size="1"
                active-color="#DCEDFF">
            </v-pagination>
        </div>
        <div class="text-center border-gray-500 py-3 flex justify-center mt-3">
            <button type="submit" class="border border-gray-500 px-8 py-2 bg-[#172e59] text-white text-base inline-block w-auto rounded-xl flex items-center justify-between hover:bg-[#254a8f]" @click="openForm()">Click to Register</button>
        </div>
    </section>

    <!--    -->
    <div class="fixed w-full h-full bg-black/80 z-[12] top-0 left-0 cursor-pointer" v-if="showModal" @click="closeForm"></div>
    <div class="fixed top-2/4 left-2/4 z-[13] translate-x-[-50%] translate-y-[-50%] bg-white xl:p-5 lg:p-5 p-3 max-w-[500px] w-10/12 xl:h-fit lg:h-fit h-96 rounded-md shadow-zinc-500/40 shadow-xl overflow-x-scroll" v-if="showModal">
        <div>
            <div class="flex flex-row justify-between items-center mb-3 px-2 py-3">
                <h3 class="xl:text-lg text-lg text-[#0e1b35] break-keep leading-tight">Consumer Registration</h3>
                <button class="ml-4 self-start" @click="closeForm">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 inline-block cursor-pointer fill-[#091223]" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
                </button>
            </div>
            <div class="overflow-x-scroll block xl:scrollbar-hide lg:scrollbar-hide">
                <form>
                <table class="w-full border-separate break-keep xl:text-base xl:text-base text-sm">
                        <thead>
                        <tr class="">
                            <th class="bg-[#172e59] text-center text-white p-3 break-keep xl:min-w-[150px] lg:min-w-[150px] min-w-[80px]">Consumer Name</th>
                            <th class="bg-[#f0f4ff] text-left text-white p-2 break-keep min-w-[200px]"><input type="text" class="text-black h-[40px]" :disabled="disabled == 1" v-model="customer.cs_name"/></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="bg-[#172e59] text-center text-white p-3 break-keep min-w-[150px]">Mobile Number</td>
                            <td class="bg-[#f0f4ff] text-left p-2"><input type="text" class="text-black h-[40px]" v-model="customer.mobile" /></td>
                        </tr>
                        <tr>
                            <td class="bg-[#172e59] text-center text-white p-3 break-keep min-w-[150px]">E-mail</td>
                            <td class="bg-[#f0f4ff] text-left p-2"><input type="text" class="text-black h-[40px]" v-model="customer.email" /></td>
                        </tr>
                        <tr>
                            <td class="bg-[#172e59] text-center text-white p-3 break-keep min-w-[150px]">Zip Code</td>
                            <td class="bg-[#f0f4ff] text-left p-2">
                                <div class="flex xl:flex-row lg:flex-row flex-col gap-1">
                                    <input type="text" class="xl:w-1/2 lg:w-1/2 w-full text-black h-[40px]" v-model="customer.post" @click="search_address" readonly />
                                    <button type="button" @click="search_address" class="xl:w-1/2 lg:w-1/2 w-full h-[40px] px-8 py-2 bg-[#172e59] text-white text-base text-center hover:bg-[#254a8f]">Search</button>
                                </div>
                            </td>
                        </tr>
                        <!--              <tr>
                                        <td class="bg-[#172e59] text-center text-white p-3 break-keep min-w-[150px]">City</td>
                                        <td class="bg-[#f0f4ff] text-left p-2"><input type="text" class="text-black h-[40px]" v-model="customer.cs_name" /></td>
                                      </tr>
                                      <tr>
                                        <td class="bg-[#172e59] text-center text-white p-3 break-keep min-w-[150px]">State</td>
                                        <td class="bg-[#f0f4ff] text-left p-2"><input type="text" class="text-black h-[40px]" v-model="customer.cs_name" /></td>
                                      </tr>-->
                        <tr>
                            <td class="bg-[#172e59] text-center text-white p-3 break-keep min-w-[150px]">City, States</td>
                            <td class="bg-[#f0f4ff] text-left p-2"><input type="text" class="w-full text-black h-[40px]" v-model="customer.addr1" readonly /></td>
                        </tr>
                        <tr>
                            <td class="bg-[#172e59] text-center text-white p-3 break-keep min-w-[150px]">Address</td>
                            <td class="bg-[#f0f4ff] text-left p-2"><input type="text" class="w-full text-black h-[40px]" v-model="customer.addr2" /></td>
                        </tr>
                        </tbody>
                </table>
                </form>
            </div>

            <div class="flex flex-row justify-end gap-3 mt-3">
                <button type="button" class="border border-gray-500 px-8 py-2 bg-slate-300 text-[#172e59] text-base inline-block w-auto rounded-xl flex items-center justify-between hover:bg-[#778394]" @click="hide_modal();">Cancel</button>
                <button type="submit" class="border border-gray-500 px-8 py-2 bg-[#172e59] text-white text-base inline-block w-auto rounded-xl flex items-center justify-between hover:bg-[#254a8f]" @click.prevent="submitCustomer()">Register</button>
            </div>

        </div>
    </div>

    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
        <address_search @set_address="set_address" @close_address_search="close_address_search" :address_kind="address_kind"/>
    </modalView>

</template>

<script>

import numeral from "numeral";
import dateForm from '../../searchForm/dateForm.vue'
import nameForm from '../../searchForm/nameForm.vue'
import VPagination from "@hennge/vue3-pagination";
import VueSimpleAlert from "vue3-simple-alert";
import modalView from "../../modalView";
import Loading from "vue3-loading-overlay";
import moment from 'moment';
import { isReactive, toRaw } from 'vue';



export default {
    name: "MyCustomer",
    components : {
        nameForm,
        dateForm,
        VPagination,
        Loading,
        VueSimpleAlert,
        modalView,

    },
    data: () => ({
        showModal: false,
        working_nation : working_nation,
        loading : false,
        fullPage : true,
        i18n: window.i18n,
        total_count : 0,
        page : 1,
        total_page : 1,
        s_date: '',
        e_date: '',
        customerList : [],
        customer : [],
        disabled: 0,
        address_search_open:false,
    }),
    watch : {
        page: function(){
            $("#submit").click();
        }
    },
    mounted() {
        $("#submit").click();
    },
    methods:{
        submitForm : function (event){
            event.preventDefault();
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/MyCustomer',{
                s_date : this.s_date,
                e_date : this.e_date,
                down_name : down_name.value,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if(response.data.total_count == 0){
                    VueSimpleAlert.alert("There are no search results","","",{confirmButtonColor: '#0abab5'});
                    this.list_msg = "No data";
                }
                console.log(response.data);
                this.customerList = response.data;
                this.total_count = response.data.length;
                this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
                this.loading = false;
            }).catch(error => console.error(error));

        },
        openForm(cs_id) {
            this.loading = true;
            if(typeof cs_id !== 'undefined'){
                axios.post(window.Laravel.back_url+'/api/MyCustomerShow',{
                    cs_id : cs_id,
                },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                    if(response.data.total_count == 0){
                        VueSimpleAlert.alert("There are no search results","","",{confirmButtonColor: '#0abab5'});
                        this.list_msg = "No data";
                    }
                    console.log(response.data);
                    this.customer = response.data;
                    this.disabled = 1;
                }).catch(error => console.error(error));
            }
            this.showModal = true;
            this.loading = false;
        },
        closeForm(){
            this.customer = [];
            this.disabled = 0;
            this.showModal = false;
            this.loading = false;
        },
        isObject(value) {
            return value !== null && !Array.isArray(value) && typeof value === 'object'
        },
        getRawData (data) {
            return isReactive(data) ? toRaw(data) : data
        },
        toDeepRaw (data) {
            const rawData = this.getRawData(data)

            for (const key in rawData) {
                const value = rawData[key]

                if (!this.isObject(value) && !Array.isArray(value)) {
                    continue
                }

                rawData[key] = this.toDeepRaw(value)
            }

            return rawData // much better: structuredClone(rawData)
        },
        submitCustomer() {
            this.loading = true;
            let data = this.customer;
            if(!this.isObject(this.customer)) {
                data = Object.assign({}, this.toDeepRaw(this.customer));

                axios.post(window.Laravel.back_url+'/api/MyCustomerStore', data,
                    {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                    if(response.data.total_count == 0){
                        VueSimpleAlert.alert("There are no search results","","",{confirmButtonColor: '#0abab5'});
                        this.list_msg = "No data";
                    }else{
                        console.log(response.data);
                        $("#submit").click();
                        VueSimpleAlert.alert("Regist OK!","","",{confirmButtonColor: '#0abab5'});
                    }
                    // this.customer = response.data;
                }).catch(error =>
                    console.error(error)
                ).then(() => {
                    this.loading = false;
                    this.closeForm();
                });
            }else{
                axios.post(window.Laravel.back_url+'/api/MyCustomerUpdate', data,
                    {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                    if(response.data.total_count == 0){
                        VueSimpleAlert.alert("There are no search results","","",{confirmButtonColor: '#0abab5'});
                        this.list_msg = "No data";
                    }else{
                        console.log(response.data);
                        $("#submit").click();
                        VueSimpleAlert.alert("Update OK!","","",{confirmButtonColor: '#0abab5'});
                    }

                    // this.customer = response.data;
                }).catch(error =>
                    console.error(error)
                ).then(() => {
                    this.loading = false;
                    this.closeForm();
                });

            }
        },
        set_s_date(s_date){
            this.s_date = moment(s_date).format('YYYY-MM-DD');
        },
        set_e_date(e_date){
            this.e_date = moment(e_date).format('YYYY-MM-DD');
        },
        moment: function () {
            return moment();
        },
        search_address(){
            this.address_search_open = true;
        },
        set_address(value){
            this.address_search_open = false;
            this.customer.addr1 = value.district+', '+value.state+', '+value.country;
            this.customer.post = value.postcode;

        },
        hide_modal(){
            this.showModal =false;
            this.customer = [];
        },
      close_address_search() {
        this.address_search_open = false;
      }

    },
}

</script>

<style scoped>
.board-inner{background:#f0f2f4 !important;}

/*.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}*/

</style>
