<template>
  <div class="relative z-[999]">
    <div class="w-full max-h-[98%] p-2.5 justify-between overflow-auto">
      <div class="text-right"><span @click="isUfPayModalClose"  class="cursor-pointer p-2 font-semibold text-gray-800">X</span></div>
      <div class="text-[13px] leading-8">
        <p>Congratulations on your commission!</p><p class="p-1"></p>
        <p class="mt-1">Please make sure that the following steps have been completed so that you may receive your commission.</p>
        <div class="pl-6 mt-1 mb-1">
          <p class="text-red-500 font-semibold text-[15px]">1.	Consumer Sales Registration at 80% or over</p>
          <p class="pl-6 mb-1 indent-[-6] hypen-tag">Consumer Sales Registration Guide can be found by going to MyOffice > Customer Service Center > Library</p>
          <p class="p-1"></p>
          <p class="text-red-500 font-semibold text-[15px]">2.	Photos of W9(W8) and Void Check Uploaded</p>
          <p class="pl-6 indent-[-6] hypen-tag">Photos of your W9 and Void Check can be uploaded by going to MyOffice > My Information > Commission Info</p>
          <p class="pl-14 indent-[-14] circle-tag">A copy of the W9(W8) form can found by going to Customer Service Center > Library</p>
          <p class="pl-6 indent-[-6] hypen-tag">Please note that the information on the uploaded documents must match that of the information you used to register to receive your commission.</p>
        </div><p class="p-1"></p>
        <p>For additional inquiries or questions, please contact us through the 1:1 inquiry board.</p>
        <p>Thank you.</p>
      </div>
    </div>
    <div class="absolute text-center m-auto my-4 md:my-0 lg:my-0 w-[90%] lg:w-full">
      <input type="button"  class="h-10 w-full lg:w-32 leading-6 text-white bg-[#172e59] cursor-pointer mt-2  ml-2 xl:ml-2 lg:mt-0 xl:mt-0" @click="locationAfterConfirm" value="Confirm"/>
      <input type="button" class="h-10 w-full lg:w-32 leading-6 bg-[#d1d5de] hover:bg-[#cbcfdd] cursor-pointer mt-2  ml-2 xl:ml-2 lg:mt-0 xl:mt-0" @click="isUfPayModalClose" value="Cancel">
    </div>
  </div>
</template>


<script>

export default {
name: "Is_uf_Pay",
components : {
},
data: () => ({
}),
methods:{
  isUfPayModalClose(){
    this.$emit("close-modal");
  },
  locationAfterConfirm() {
    location.replace('/MyforinerUpload');
  }

}
}
</script>
<style scoped>
  p.hypen-tag::before { content: '-'; padding-right: 1em; }
  p.circle-tag::before { content: 'o'; padding-right: 1em; }
</style>
