<template>
  <dl class="myo_search_list">
    <dt>{{ i18n.myoffice.Country }}</dt>
    <dd>
        <select id="reg_type" v-model="bz_cd" class="select1 wide">
            <option value="" >ALL</option>
            <option value="USA" >USA</option>
            <option value="CAN" >CANADA</option>
        </select>
    </dd>
  </dl>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "countryForm",
    components : {
      vSelect
    },
    data: () => ({
      i18n: window.i18n,
      countries : [],
        bz_cd : "",
    }),
    props:{
    },
    watch : {
        bz_cd(bz_cd){
            this.set_bz_cd(bz_cd);
        }
    },
    methods : {
        set_bz_cd(bz_cd){
            this.$emit("set_bz_cd",bz_cd);
        }
    }
}
</script>

<style scoped>

</style>
