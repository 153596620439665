<template>
    <loading :active="loading"
             :can-cancel="true"
             :is-full-page="true"></loading>

    <div style="height: 10px; clear:both;"></div>
    <div id="search_result">
        <div id="orders">
            <h4 class='order-line-name'>{{ i18n.myoffice.dashboard.Line  }} {{ ltotal }} </h4>
            <div id=""  class="list-table-wrap">
                <table class="list-table">
                    <thead>
                    <tr>
                        <th>{{ i18n.myoffice.MemberId  }}</th>
                        <th>{{ i18n.myoffice.memberName  }}</th>
                        <th>{{ i18n.myoffice.step  }}</th>
                        <th>{{ i18n.myoffice.MyMember.curRank}}</th>
                        <th>{{ i18n.myoffice.MyBusiness.salespv}}</th>
                        <th>{{ i18n.myoffice.MyBusiness.refundedPv}}</th>
                        <th>{{ i18n.myoffice.MyBusiness.totalPv}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td align="center" colspan="4">{{ i18n.myoffice.total }}</td>
                        <td align="right"><span v-if="rlists.length != 0">{{ formatPrice(ord_pv,1) }}</span></td>
                        <td align="right"><span v-if="rlists.length != 0">{{ formatPrice(ord_re_pv,1) }}</span></td>
                        <td align="right"><span v-if="rlists.length != 0">{{ formatPrice(ord_total,1) }}</span></td>
                    </tr>
                    <tr v-for="(value) in rlists" class="whitespace-nowrap" >
                        <td v-if="value.userid" align="center">{{ value.userid ? value.userid : '' }}</td>
                        <td v-if="value.userid" align="center">{{ value.username ? value.username : '' }}</td>
                        <td v-if="value.userid" align="center">{{value.lv}}</td>
                        <td v-if="value.userid" align="right">{{ value.rank_name }}</td>
                        <td v-if="value.userid" align="right">{{ formatPrice(value.ord_pv,1) }}</td>
                        <td v-if="value.userid" align="right">{{ formatPrice(value.ord_re_pv,1) }}</td>
                        <td v-if="value.userid" align="right">{{ formatPrice(value.ord_total,1) }}</td>
                    </tr>
                    <tr v-if="rlists.length == 0">
                        <td colspan="13" style="text-align: center">
                          {{ this.list_msg }}
                        </td>
                    </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </div>
</template>

<script>
import dateForm from '../../searchForm/dateForm.vue'
import memberForm from '../../searchForm/memberForm.vue'
import divisionForm from '../../searchForm/divisionForm.vue'
import countryForm from '../../searchForm/countryForm.vue'
import orderClassification from '../../searchForm/orderClassification.vue'
import rankForm from '../../searchForm/rankForm.vue'
import ordeType from '../../searchForm/ordeType.vue'
import transactionStatus from '../../searchForm/transactionStatus.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";
import { Tooltip } from "floating-vue";
import 'floating-vue/dist/style.css';
import modalView from "../../modalView";

export default {
    name: "MyOrderLegsDetail",
    components : {
        dateForm,
        memberForm,
        divisionForm,
        countryForm,
        orderClassification,
        rankForm,
        ordeType,
        transactionStatus,
        VPagination,
        Loading,
        VTooltip : Tooltip,
        modalView
    },
    data: ()=>({
        i18n: window.i18n,
        lists : [],
        rlists : [],
        ltotal : "",
        rtotal : "",
        rank_cd : "",
        bz_cd : "",
        ord_kind : "",
        kind_cd : "",
        rcpt_yns : "",
        loading : false,
        fullPage : true,
        list_msg : "Click 'Search' to view the data",
        lbz_cd : 0,
        rbz_cd : 0,
        ord_pv  : 0,
        ord_re_pv  : 0,
        ord_total  : 0,
    }),
    props:{selectid : String,s_date: String,e_date: String},
    created() {
        this.searchList();
    },
    methods : {
        searchList(){
            event.preventDefault();
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/getOrderLegsDetail',{
                s_date : this.s_date,
                e_date : this.e_date,
                level : 2,
                sid : this.selectid,
                bz_cd : this.bz_cd,
                ord_kind : this.ord_kind,
                kind_cd : this.kind_cd,
                rcpt_yns : this.rcpt_yns,
                rank_cd : this.rank_cd,
                left_search : 2,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.loading = false;
                this.rlists = response.data.list;
                this.rtotal = response.data.total;
                this.rbz_cd = response.data.bz_cd;
                this.ord_pv = response.data.ord_pv;
                this.ord_re_pv = response.data.ord_re_pv;
                this.ord_total = response.data.ord_total;

                if(this.ltotal == 0 && this.rtotal == 0){
                    VueSimpleAlert.alert("There are no search results","","",{});
                    this.list_msg = "No data";
                    this.sid_name = "";
                }

            });

        },
        setSelected(rank){
            this.rank_cd = "";
            rank.forEach((value, index) => {
                this.rank_cd += value.id
                if(rank.length != index+1){
                    this.rank_cd += ",";
                }
            });
        },
        updatePageHandler(page){
            //this.page = page;
        },
        notCheck(id){
            return this.order != id ? true : false;
        },
        asconCheck(id){
            return this.order == id && this.sort == "asc" ? true : false;
        },
        descCheck(id){
            return this.order == id && this.sort == "desc" ? true : false;
        },
        reSortArray(id){
            this.order = id;
            this.sort = this.sort == "asc" ? "desc" : "asc";
            $("#submit").click();

        },
        set_s_date(s_date){
            this.s_date = s_date;
        },
        set_e_date(e_date){
            this.e_date = e_date;
        },
        set_date_type(date_type){
            this.date_type = date_type;
        },
        set_bz_cd(bz_cd){
            this.bz_cd = bz_cd;
        },
        set_ord_kind(ord_kind){
            this.ord_kind = ord_kind;
        },
        set_kind_cd(kind_cd){
            this.kind_cd = kind_cd;
        },
        set_rcpt_yns(rcpt_yns){
            this.rcpt_yns = rcpt_yns;
        },
        formatPrice(value,digit) {
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        set_search_id(search_id){

        }
    }
}
</script>

<style>
#search_result:after { content: ""; display: block; clear:both;}
#orders-left { width:calc(50% - 5px); float:left;}
#orders-right { width:calc(50% - 5px); float:right;}
.fal.fa-search {font-size: 16px; padding:3px; border:1px solid #dadada;}
@media    all and (min-width:991px ) and (max-width:1000px) {
    #orders-left { width:calc(100%); float:none; margin-bottom: 20px;}
    #orders-right { width:calc(100%); float:none;}
}

@media    all and (max-width:770px) {
    #orders-left { width:calc(100%); float:none; margin-bottom: 20px;}
    #orders-right { width:calc(100%); float:none;}
}

/*테이블 타이블 */
.order-line-name { background: #272c33; color:#fff; padding:10px; margin: 0;}

</style>
