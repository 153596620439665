<template>
    <loading :active="false"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <div id="divPrint">
      <!-- 기타환수내역 -->
      <!--div>
        <h2 class="text-xl font-semibold mb-3">Etc Commission</h2>
        <div class="flex flex-row w-full mb-6">
          <div class="w-full border bg-white rounded-xl p-5">
            <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
              <table class="border-separate border-spacing-2 table-auto w-full">
                  <thead>
                      <tr class="bg-[#172e59]">
                        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">No</th>
                        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.date }}</th>
                        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MemberId }}</th>
                        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.memberName }}</th>
                        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.Order.amount }}</th>
                        <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.remark }}</th>
                      </tr>
                  </thead>
                  <tbody class="tbody_print_A" >
                      <tr v-for="(Value,index) in Etc_List" class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800" >
                        <td class="text-center p-2 min-w-[100px]">{{ Etc_Total - ((Etc_Page-1)*20 + index) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ Value.pay_date }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ Value.userid }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ Value.username }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ Value.amt }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ Value.remark }}</td>
                      </tr>
                      <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800" v-if="Etc_Total == 0">
                        <td class="text-center p-2 min-w-[100px]" colspan="6">No Data</td>
                      </tr>
                  </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
        <v-pagination
          v-model      = "Etc_Page"
          :pages       = Etc_Total_page
          :range-size  = "1"
          active-color = "#DCEDFF">
        </v-pagination>
      </div-->

        <!-- 수당환수내역 -->
        <div>
          <h2 class="text-xl font-semibold mb-3">{{ i18n.myoffice.Commission.otherPayment }}</h2>
          <div class="flex flex-row w-full mb-6">
            <div class="w-full border bg-white rounded-xl p-5">
              <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
                <table class="border-separate border-spacing-2 table-auto w-full">
                    <thead>
                      <tr class="bg-[#172e59]">
                          <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">No</th>
                          <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.date }}</th>
                          <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MemberId }}</th>
                          <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.memberName }}</th>
                          <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.Order.amount }}</th>
                          <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.remark }}</th>
                      </tr>
                    </thead>
                    <tbody class="tbody_print_A" >
                      <tr v-for="(Value,index) in Reduction_List" class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800" >
                          <td class="text-center p-2 min-w-[100px]">{{ Reduction_Total - ((Reduction_Page-1)*20 + index) }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.pay_date }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.userid }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.username }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.amt }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.remark }}</td>
                      </tr>
                      <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800" v-if="Reduction_Total == 0">
                        <td class="text-center p-2 min-w-[100px]" colspan="6">No Data</td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
          <v-pagination
            v-model      = "Reduction_Page"
            :pages       = Reduction_Total_page
            :range-size  = "1"
            active-color = "#DCEDFF">
          </v-pagination>
        </div>
    </div><!-- divPrint -->


</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
    name: "MyBenefitAllowanceReturn",
    components : {
        VPagination,
        Loading
    },
    data: () => ({
        i18n                  : window.i18n,
        Etc_List              : [], // 기타환수내역 리스트
        Etc_Total             : 0,  // 기타환수내역 개수
        Etc_Page              : 1,  // 기타환수내역 페이지
        Etc_Total_page        : 20,  // 기타환수내역 총 페이지 블럭 개수
        Reduction_List        : [], // 수당환수내역 리스트
        Reduction_Total       : 0,  // 수당환수내역 개수
        Reduction_Page        : 1,  // 수당환수내역 페이지
        Reduction_Total_page  : 20,  // 수당환수내역 총 페이지 블럭 개수
        loading               : false,
    }),
    watch : {
      Etc_Page : function() {
        this.getEtcList();
      },
      Reduction_Page : function() {
        this.getReductionList();
      }
    },
    created() {
        this.loading = true;
        this.getEtcList();
        this.getReductionList();
    },
  methods : {
    // 기타환수내역
    getEtcList : function() {
      axios.post(window.Laravel.back_url+'/api/MyBenefitAllowanceReturn',{
        page : this.Etc_Page, mode : 'etc'
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        this.Etc_List       = response.data.etc.list.data;
        this.Etc_Total      = response.data.etc.total;
        this.Etc_Total_page = response.data.etc.total_page;
        this.loading        = false;
      });

    },
    // 수당환수내역
    getReductionList : function() {
      axios.post(window.Laravel.back_url+'/api/MyBenefitAllowanceReturn',{
        page : this.Reduction_Page, mode : 'reduction'
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        this.Reduction_List       = response.data.reduction.list.data;
        this.Reduction_Total      = response.data.reduction.total;
        this.Reduction_Total_page = response.data.reduction.total_page;;
        this.loading              = false;
      });
    }
  } // methods end
}
</script>

<style scoped>

</style>
