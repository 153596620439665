<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>

  <div id="mem_info" class="myo-modi-mem">
    <!--    <section class="accordion_notice_box">-->
    <!--      <div class="accordion_notice_item">-->
    <!--        <a class="accordion_notice_tit" href="#n">-->
    <!--          <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>-->
    <!--          {{ i18n.myoffice.myoSearchForm.notice }}-->
    <!--          <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></div>-->
    <!--        </a>-->
    <!--        <div class="accordion_notice_cont">-->
    <!--          <div class="myo_search_notice">-->
    <!--            <p><span class="signiture remark1">&#42;</span>&nbsp;<span>{{ i18n.join.join.required }}</span></p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->

      <h2 class="myo_table_tit">{{ i18n.menu.MyAutoshipWithdrawal }}</h2>
      <div class="myo_condition_search2">
<!--        <button type="button" v-on:click="show_modify" class="my-2 py-2 px-4 leading-6 text-white bg-gray-500 w-24 text-center w-1/2 xl:1/5 lg:w-1/5 rounded">{{ i18n.myoffice.Order.Cancel }}</button>-->
        <button type="button" v-show="this.autoship != ''" v-on:click="submitForm" class="my-2 py-2 px-4 leading-6 text-white bg-gray-900 w-24 text-center w-1/2 xl:1/5 lg:w-1/5 rounded">{{ i18n.shop.autoshipse2.tab_as_cancel }}</button>
      </div>
  </div>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import Loading from 'vue3-loading-overlay';
import {createPopper} from "@popperjs/core";
import modalView from "../../modalView";
import vSelect from "vue-select";

export default {
  name: "AutoShipWithdrawal",
  components : {
    VueSimpleAlert,
    ShoppingCartIcon,
    Loading,
    modalView,
    vSelect,
  },
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
    no_search_messsage: '',
    isLoggedIn: false,
    loading : false,
    address_search_open:false,
    autoship : [],
  }),
  beforeCreate() {

  },
  created() {
    this.loading = true;
    axios.post(process.env.MIX_BACK_URL+'/api/getNormalAsMst',{
      bz_cd: window.Laravel.bz_cd,
    },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
      .then(response => {
        this.autoship = response.data;
        if(response.data == ""){
          VueSimpleAlert.alert("You are not enrolled in Autoship","","",{allowOutsideClick : false}).then((result) => {
            if(result){
              location.replace("/MyofficeMain");
            }
          });
        }
        this.loading = false;   // this.getCartList(response.data);
      })
  },
  mounted() {
  },
  methods:{
    submitForm: function (event) {
      let formData = new FormData();

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + window.Laravel.access_token
        }
      }

      VueSimpleAlert.confirm("Are you sure you want to cancel AutoShip?","","",{allowOutsideClick : false,  cancelButtonText:"Cancel"}).then((result) => {
        if(result){
          this.loading = true;
          axios.post(window.Laravel.back_url+'/api/autoShipAddressWithdrawal',formData
            , config).then(response => {
            if(response.data.result == "1"){
              VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false }).then((result) => {
                if(result){
                  location.replace("/MyofficeMain");
                }
              });

            }
            this.loading = false;
          });
        }
      });

    },
  },

}

</script>

<style scoped>

</style>
