<template>
  <loading :active="loading"
           :can-cancel="true"
           :is-full-page="true"></loading>
  <section class="mx-auto lg:py-6 md:p-0 bg-[#f0f2f4]">
    <div class="w-full lg:flex lg:justify-start">
      <div class="w-full">
        <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
          <div class="w-full xl:py-10 lg:py-10 pb-0">
            <div class="flex justify-start item-start flex-col">
              <div class="w-full divide-y lg:p-2" id="infomodify" >
                <div class="xl:py-2 lg:py-2 py-4">
                  <div class="flex justify-center text-lg">
                    <div class="flex flex-col items-center rounded-md bg-white shadow-md shadow-slate-200/40 p-4 max-w-[450px] w-11/12">
                      <h2 class="mt-8 xl:mb-10 lg:mb-10 mb-6 px-6 text-center xl:text-xl lg:text-xl text-lg font-extrabold tracking-tight text-[#0e1b35]">{{i18n.join.new_result.member_result_tit}}</h2>
                      <div class="my-5 flex flex-col items-center justify-center">
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" class="w-24 fill-[#172e59]" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"/></svg>
                        </span>
                        <p class="mt-4 mb-2 text-center xl:text-xl lg:text-xl text-lg">Your Member ID is</p>
                        <span class="xl:text-2xl lg:text-2xl text-xl font-bold text-[#172e59]">{{ request["userid"]}}</span>
                      </div>
                      <p class="xl:mt-4 lg:mt-4 mt-2 mb-2 text-center xl:text-base lg:text-base text-sm">You can access all services once you login.</p>
                      <div class="w-full flex item-center justify-center mb-2">
                        <!--<button type="button" class="w-24 flex flex-row items-center justify-around">Sign In <svg xmlns="http://www.w3.org/2000/svg" class="w-3" viewBox="0 0 320 512">&lt;!&ndash;! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. &ndash;&gt;<path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></button>-->
                        <button type="button" class="font-medium w-full max-w-[320px] flex flex-row items-center justify-end py-4 text-[#243a63] rounded-md text-base hover:text-[#591734]" v-on:click="autoLogin(request['xid'])">
                          Sign In
                          <svg xmlns="http://www.w3.org/2000/svg" class="w-2 ml-2" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                        </button>
                        <!--bg-[#d1d5de] hover:bg-[#afb2ba]-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import MypageTop from './mypage/MypageTop.vue';
import SideMenu from './mypage/SideMenu.vue';
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import {createPopper} from "@popperjs/core";
import modalView from "./modalView";
import vSelect from "vue-select";
import dayjs from "dayjs";

export default {
  name: "MyRegister_ok",
  components : {
    VueSimpleAlert,
    ShoppingCartIcon,
    MypageTop,
    SideMenu,
    Loading,
    modalView,
    vSelect
  },
  props: ['request'],
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,

    no_search_messsage: '',
    isLoggedIn: false,
    loading : false,
    mem: "",
    myconfig:"",



  }),

  beforeCreate() {


  },
  created() {

  },

  mounted() {

  },
  methods:{
    login: function(event) {location.href = "/sso/login?bz_cd="+window.Laravel.bz_cd;},
    autoLogin: function(xid) { location.href = "/setAutoLogin?xid="+xid.trim();},

  },
}


</script>

<style >
.v-select{ min-width: 100px; z-index:0 }
.m_menu{display:none;}
</style>
