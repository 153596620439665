<template>
    <loading :active="loading"
             :can-cancel="true"
             :is-full-page="true"></loading>

    <div v-show="true">
        <search_date v-if="isEmptyObject(dash_d_val_ori) === false" :getYear="currentYear" :getMonth="currentMonth" :dash_d_val="dash_d_val_ori" @searchMonthDashBoard="searchMonthDashBoard"></search_date>
        <search_date v-else :getYear="currentYear" :getMonth="currentMonth" :dash_d_val="dash_d_val_ori" @searchMonthDashBoard="searchMonthDashBoard"></search_date>
    </div>

    <div id="week" style="margin-top:30px;" >
        <b>
        </b>
    </div>

    <div>
        <h2 class="text-xl font-semibold mb-3">Total allowance or bonus</h2>
        <div class="flex flex-row w-full mb-6">
            <div class="w-full border bg-white rounded-xl p-5">
                <div class="overflow-x-scroll w-full block full:scrollbar-hide full:overflow-hidden">
                    <table class="border-separate border-spacing-2 table-auto w-full">
                        <thead>
                        <tr class="bg-[#172e59]">
                            <th class="p-2 text-white break-keep 2xl:w-30">{{ i18n.myoffice.Commission.SettlementDate}}</th>
                            <!--                <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.Commission.CurrentRank}}</th>-->
                            <!--                <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.AttainmentRank}}</th>-->

                            <th class="p-2 text-white break-keep">Status</th>
                            <th class="p-2 text-white break-keep">Order PV</th>
                            <th class="p-2 text-white break-keep" v-for="Value in name_list">{{ Value.pay_name }}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.TotalAmount}}</th>

                            <!--                <th class="p-2 text-white break-keep">PURCHASE BONUS || {{ i18n.myoffice.Commission.PurchaseAllowance}}</th>-->
                            <!--                <th class="p-2 text-white break-keep">SALES BONUS || {{ i18n.myoffice.Commission.SalesBonus}}</th>-->
                            <!--                <th class="p-2 text-white break-keep">MANAGEMENT BONUS || {{ i18n.myoffice.Commission.ManagementBonus}}</th>-->
                            <!--                <th class="p-2 text-white break-keep">RANK BONUS || {{ i18n.myoffice.Commission.RankBonus}}</th>-->
                            <!--                <th class="p-2 text-white break-keep">SHARED RANK BONUS || {{ i18n.myoffice.Commission.SharedRankBonus}}</th>-->
                            <!--                <th class="p-2 text-white break-keep">2P SHARED SALES BONUS || {{ i18n.myoffice.Commission.Allowance2p}}</th>-->

                        </tr>
                        </thead>
                        <tbody>
                        <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                            <td class="text-center p-2 min-w-[100px]">{{ List.paydate }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ List.ranknewname }}</td>
                            <!--                <td class="text-center p-2 min-w-[100px]">{{ List.rankmaxname }}</td>-->

                            <!-- 구매수당 //-->
                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.ordpv ?? 0,2) }}</td>

                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt5 ?? 0,2) }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt1 ?? 0,2) }}</td>

                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt2 ?? 0,2) }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt3 ?? 0,2) }}</td>

                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt4 ?? 0,2) }}</td>
                            <!-- 2P수당 //-->
                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt6 ?? 0,2) }}</td>

                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.totalamt ?? 0,2) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center border-gray-500 py-3 flex justify-center mt-3" v-if="false">
                    <a href="" class="border border-gray-500 px-8 py-2 bg-[#172e59] text-white text-base inline-block w-auto rounded-xl flex items-center justify-between hover:bg-[#254a8f]">
                        View More
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white inline-block" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path class="text-red-500" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <h2 v-show="false" class="text-xl text-[] font-semibold mb-3">Business Status</h2> <!--비즈니스 현황-->
    <!-- 그래프 -->
    <div v-show="false" class="flex flex-row w-full mb-6">
        <div class="w-full border bg-white rounded-xl p-5">
            <div class="overflow-x-auto w-full block full:scrollbar-hide full:overflow-hidden">
                <table class="border-separate border-spacing-2 table-auto w-full">
                    <thead>
                    <tr>
                        <th colspan="7" class="py-2 bg-[#2e5db3] text-white">My Performance Status</th><!--본인 실적 현황-->
                        <th colspan="6" class="no-show-vcm py-2 bg-[#2e5db3] text-white">Performance Status of 1st Downline</th><!--직추천 1대 실적 현황-->
                    </tr>
                    <tr class="bg-[#172e59]">
                        <th class="p-2"></th>
                        <th class="p-2 text-white break-keep w-20">{{ i18n.myoffice.MyBusiness.amount}}</th>
                        <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.MyBusiness.amountRefunded}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountpv}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.refundedPv}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountTotal}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.totalPv}}</th>

                        <th class="no-show-vcm p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amount}}</th>
                        <th class="no-show-vcm p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountRefunded}}</th>
                        <th class="no-show-vcm p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountpv}}</th>
                        <th class="no-show-vcm p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.refundedPv}}</th>
                        <th class="no-show-vcm p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountTotal}}</th>
                        <th class="no-show-vcm p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.totalPv}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                        <th class="p-2 text-white bg-[#172e59] min-w-[100px]">{{ i18n.myoffice.MyBusiness.thisMonth}}</th>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT_A1 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT_R1 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV_A1 ?? 0,1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV_R1 ?? 0,1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT1 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV1 ?? 0,1) }}</td>

                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT_A1 ?? 0,2) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT_R1 ?? 0,2) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV_A1 ?? 0,1) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV_R1 ?? 0,1) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT1 ?? 0,2) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV1 ?? 0,1) }}</td>
                    </tr>
                    <tr class="bg-[#f0f4ff] text-center text-slate-800">
                        <th class="p-2 text-white bg-[#172e59] min-w-[100px]">{{ i18n.myoffice.MyBusiness.prevMonth}}</th>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT12 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT22 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV12 ?? 0,1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV22 ?? 0,1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT2 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV2 ?? 0,1) }}</td>

                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT12 ?? 0,2) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT22 ?? 0,2) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV12 ?? 0,1) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV22 ?? 0,1) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT2 ?? 0,2) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV2 ?? 0,1) }}</td>
                    </tr>
                    <tr class="bg-[#f0f4ff] text-center text-slate-800">
                        <th class="p-2 text-white bg-[#172e59] min-w-[100px]">{{ i18n.myoffice.MyBusiness.prev2Month}}</th>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT13 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT23 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV13 ?? 0,1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV23 ?? 0,1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT3 ?? 0,2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV3 ?? 0,1) }}</td>

                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT13 ?? 0,2) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT23 ?? 0,2) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV13 ?? 0,1) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV23 ?? 0,1) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT3 ?? 0,2) }}</td>
                        <td class="no-show-vcm text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV3 ?? 0,1) }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center border-gray-500 py-3 flex justify-center mt-3" v-if="false">
                <a href="" class="border border-gray-500 px-8 py-2 bg-[#172e59] text-white text-base inline-block w-auto rounded-xl flex items-center justify-between hover:bg-[#254a8f]">
                    View More
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white inline-block" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path class="text-red-500" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                </a>
            </div>
        </div>
    </div><!-- //나와 -->

    <div v-if="false" class="no-show-vcm flex-col w-full gap-5 mb-6 bg-white border rounded-xl p-5">
        <div class="w-full overflow-x-scroll block sm:overflow-hidden"> <!--xl:scrollbar-hide lg:scrollbar-hide-->
            <table class="border-separate border-spacing-2 w-full table-auto">
                <thead>
                <tr>
                    <th colspan="5" class="text-center text-base py-2 bg-[#2e5db3] text-white">Downline Sales Information</th> <!--산하 매출정보-->
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.monthly}}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.amount}}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.amountRefunded}}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Total PV</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Total amount of legs</th> <!--총 레그 수-->
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.thisMonth}}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_A1 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_R1 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV1 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500"><button type="button" @click="getLegList(0)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.D_LEG_CNT1 ?? 0 }}</button></td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.prevMonth}}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_A2 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_R2 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV2 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px]"><button type="button" @click="getLegList(-1)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.D_LEG_CNT2 ?? 0 }}</button></td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.prev2Month}}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_A3 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_R3 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV3 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px]"><button type="button" @click="getLegList(-2)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.D_LEG_CNT3 ?? 0 }}</button></td>
                </tr>
                <!--                <tr class="text-left">
                                  <td colspan="5" class="text-[#e1253f] p-2 text-sm">* 총 레그 수 클릭 시 해당 레그의 회원 확인 가능</td>
                                </tr>-->
                </tbody>
            </table>
        </div>
        <p class="text-[#e1253f] p-2 text-sm">* If you click the total amount of legs, you can view the 1st downline’s members’ status</p>
        <!--* 총 레그 수 클릭 시 해당 레그의 회원 확인 가능-->


        <!-- 모달 팝업 -->
        <div class="fixed w-full h-full bg-black/80 z-[10] top-0 left-0 cursor-pointer" v-if="showModal"></div>
        <!-- 모달 팝업 콘텐츠 -->
        <div class="fixed top-2/4 left-2/4 z-[11] translate-x-[-50%] translate-y-[-50%] bg-white p-5 w-full max-w-xl h-1/2 rounded-md shadow-zinc-500/40 shadow-xl overflow-x-scroll sm:overflow-hidden" v-if="showModal">
            <div class="text-right mb-2">
                <button class="close" @click="showModal = false">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 inline-block cursor-pointer fill-[#091223]" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
                </button>
            </div>
            <div class="h-72">
                <h4 class="text-center font-semibold text-[#172e59] text-lg mb-3 break-words">downline’s members’ status</h4> <!-- 산하 1대 레그 별 회원 현황 -->
                <div class="text-center mb-3 h-80 overflow-scroll tailwind-scrollbar-hide scrollbar-hide">
                    <table class="w-full border-separate sm:overflow-hidden">
                        <thead>
                        <tr>
                            <th class="bg-[#172e59] text-center text-white p-3 w-24">Member ID</th>
                            <th class="bg-[#172e59] text-center text-white p-3">Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="leg in legs" class="bg-[#f0f4ff] text-center">
                            <td class="text-center p-2">{{ leg.userid }}</td>
                            <td class="text-center p-2"><a href="" class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{ leg.username }}</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <p class="text-[#e1253f] text-sm break-words mb-2">*If you click on the member’s name, you can see the member’s leg sales status.</p>
                <!--  *회원명을 클릭 하시면 해당 회원 기준의 레그 별 매출 집계를 확인하실 수 있습니다.-->
            </div>
        </div>
        <!-- // 핍업 -->
    </div>

    <div v-show="false" class="no-show-vcm w-full mb-6 bg-white rounded-xl p-5 border">
        <div class="overflow-x-scroll sm:overflow-hidden block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
            <table class="border-separate w-full table-auto text-slate-800">
                <tbody>
                <tr>
                    <th colspan="6" class="bg-[#2e5db3] text-white text-center p-3 min-w-[150px]">My Rank Information</th> <!--나의 직급 정보-->
                </tr>
                <tr>
                    <th class="bg-[#172e59] text-center text-white p-3">My First Downline’s Rank</th> <!--직추천산하직급-->
                    <th class="bg-[#172e59] text-center text-white p-3">{{ i18n.myoffice.MyBusiness.thisMonth}}</th>
<!--                    <th class="bg-[#172e59] text-center text-white p-3">{{ i18n.myoffice.MyBusiness.prevMonth}}</th>-->
<!--                    <th class="bg-[#172e59] text-center text-white p-3">{{ i18n.myoffice.MyBusiness.prev2Month}}</th>-->
<!--                    <th class="bg-[#172e59] text-center text-white p-3">{{ i18n.myoffice.MyBusiness.month3CumulativeTotal}}</th> &lt;!&ndash;3개월 누적 합계&ndash;&gt;-->
                    <th class="bg-[#172e59] text-center text-white p-3">{{ i18n.myoffice.MyBusiness.rank}}</th>
                </tr>
                <tr class="bg-[#f0f4ff] text-center">
                    <td class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">Gold</td>
                    <td class="text-center p-2 min-w-[100px]">{{ g1 }}</td>
<!--                    <td class="text-center p-2 min-w-[100px]">{{ g2 }}</td>-->
<!--                    <td class="text-center p-2 min-w-[100px]">{{ g3 }}</td>-->
<!--                    <td class="text-center p-2 min-w-[100px]">{{ g_total }}</td>-->
                    <!--                    <td rowspan="6" class="text-center p-2 min-w-[150px]"><span class="font-semibold text-[#0d6efd]">{{ dash_d_val.RANK_NEW }}</span></td>-->
                    <td rowspan="8" class="text-center p-2 min-w-[150px]"><span class="font-semibold text-[#0d6efd]">{{ dash_d_val.RANK_NEW }}</span></td>
                </tr>

                <tr class="bg-[#f0f4ff] text-center">
                    <td class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">Branch Manager</td>
                    <td class="text-center p-2 min-w-[100px]">{{ bm1 }}</td>
<!--                    <td class="text-center p-2 min-w-[100px]">{{ bm2 }}</td>-->
<!--                    <td class="text-center p-2 min-w-[100px]">{{ bm3 }}</td>-->
<!--                    <td class="text-center p-2 min-w-[100px]">{{ bm_total }}</td>-->
                </tr>

                <tr class="bg-[#f0f4ff] text-center">
                    <td class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">Manager</td>
                    <td class="text-center p-2 min-w-[100px]">{{ m1 }}</td>
<!--                    <td class="text-center p-2 min-w-[100px]">{{ m2 }}</td>-->
<!--                    <td class="text-center p-2 min-w-[100px]">{{ m3 }}</td>-->
<!--                    <td class="text-center p-2 min-w-[100px]">{{ m_total }}</td>-->
                </tr>
                <tr class="bg-[#f0f4ff] text-center">
                    <td class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">General Manager</td>
                    <td class="text-center p-2 min-w-[100px]">{{ gm1 }}</td>
<!--                    <td class="text-center p-2 min-w-[100px]">{{ gm2 }}</td>-->
<!--                    <td class="text-center p-2 min-w-[100px]">{{ gm3 }}</td>-->
<!--                    <td class="text-center p-2 min-w-[100px]">{{ gm_total }}</td>-->
                </tr>

                <tr class="bg-[#f0f4ff] text-center">
                    <td class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">Director</td>
                    <td class="text-center p-2 min-w-[100px]">{{ d1 }}</td>
<!--                    <td class="text-center p-2 min-w-[100px]">{{ d2 }}</td>-->
<!--                    <td class="text-center p-2 min-w-[100px]">{{ d3 }}</td>-->
<!--                    <td class="text-center p-2 min-w-[100px]">{{ d_total }}</td>-->
                </tr>
                <tr class="bg-[#f0f4ff] text-center">
                    <td class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">Vice President</td>
                    <td class="text-center p-2 min-w-[100px]">{{ vp1 }}</td>
<!--                    <td class="text-center p-2 min-w-[100px]">{{ vp2 }}</td>-->
<!--                    <td class="text-center p-2 min-w-[100px]">{{ vp3 }}</td>-->
<!--                    <td class="text-center p-2 min-w-[100px]">{{ vp_total }}</td>-->
                </tr>
                <tr class="bg-[#f0f4ff] text-center">
                    <td class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">President</td>
                    <td class="text-center p-2 min-w-[100px]">{{ p1 }}</td>
<!--                    <td class="text-center p-2 min-w-[100px]">{{ p2 }}</td>-->
<!--                    <td class="text-center p-2 min-w-[100px]">{{ p3 }}</td>-->
<!--                    <td class="text-center p-2 min-w-[100px]">{{ p_total }}</td>-->
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div v-if="list1_total_count">
        <h2 class="text-xl font-semibold mb-3">Sales Bonus <span class="text-base font-normal">(Unit : Order PV)</span></h2>
        <div class="flex flex-row w-full mb-6">
            <div class="w-full border bg-white rounded-xl p-5">
                <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
                    <table class="border-separate border-spacing-2 table-auto w-full">
                        <thead>
                        <tr class="bg-[#172e59]">
                            <th class="p-2 text-white break-keep w-20">{{ i18n.myoffice.Commission.No}}</th>
                            <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.Commission.MemberID}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Name}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.CurrentRank}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.amount}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.PaymentRate}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyMember.generation}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.GeneratedAmount}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800" v-for="Value in list1">
                            <td class="text-center p-2 min-w-[100px]">{{ Value.rn }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.userid}}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ stepRemove(Value.username) }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rank_name }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_basic ?? 0,1) }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rate }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.tg_lv }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_amt ?? 0,2) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <v-pagination
                    v-model="list1_page" v-show="list1_total_page > 1"
                    :pages=list1_total_page
                    :range-size="1"
                    class="my-pagination"
                    @update:modelValue="(event) => updateHandler(1, event)"
                    active-color="#DCEDFF">
                </v-pagination>
            </div>
        </div>
    </div>
    <div v-if="list2_total_count">
        <h2 class="text-xl font-semibold mb-3">Management Bonus <span class="text-base font-normal">(Unit : Sales bonus)</span></h2>
        <div class="flex flex-row w-full mb-6">
            <div class="w-full border bg-white rounded-xl p-5">
                <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
                    <table class="border-separate border-spacing-2 table-auto w-full">
                        <thead>
                        <tr class="bg-[#172e59]">
                            <th class="p-2 text-white break-keep w-20">{{ i18n.myoffice.Commission.No}}</th>
                            <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.Commission.MemberID}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Name}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.CurrentRank}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.amount}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.PaymentRate}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyMember.generation}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.GeneratedAmount}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800" v-for="Value in list2">
                            <td class="text-center p-2 min-w-[100px]">{{ Value.rn }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.userid }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.username }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rank_name }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_basic ?? 0,2) }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rate }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.tg_lv }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_amt ?? 0,2) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <v-pagination
                    v-model="list2_page" v-show="list2_total_page > 1"
                    :pages=list2_total_page
                    :range-size="1"
                    class="my-pagination"
                    @update:modelValue="(event) => updateHandler(2, event)"
                    active-color="#DCEDFF">
                </v-pagination>
            </div>
        </div>
    </div>
    <div v-if="list4_total_count">
        <h2 class="text-xl font-semibold mb-3">Shared Rank Bonus <span class="text-base font-normal">(Unit : Order PV)</span></h2>
        <div class="flex flex-row w-full mb-6">
            <div class="w-full border bg-white rounded-xl p-5">
                <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
                    <table class="border-separate border-spacing-2 table-auto w-full">
                        <thead>
                        <tr class="bg-[#172e59]">
                            <th class="p-2 text-white break-keep w-20">{{ i18n.myoffice.Commission.No}}</th>
                            <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.Commission.MemberID}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Name}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.CurrentRank}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.amount}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.PaymentRate}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyMember.generation}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.GeneratedAmount}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800" v-for="Value in list4">
                            <td class="text-center p-2 min-w-[100px]">{{ Value.rn }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.userid }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.username }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rank_name }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_basic ?? 0,2) }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rate }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.tg_lv }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_amt ?? 0,2) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <v-pagination
                    v-model="list4_page" v-show="list4_total_page > 1"
                    :pages=list4_total_page
                    :table_kind="4"
                    :range-size="1"
                    class="my-pagination"
                    @update:modelValue="(event) => updateHandler(4, event)"
                    active-color="#DCEDFF">
                </v-pagination>
            </div>
        </div>
    </div>

    <div v-if="list3_total_count">
        <h2 class="text-xl font-semibold mb-3">Rank Bonus <span class="text-base font-normal">(Unit : Order PV)</span></h2>
        <div class="flex flex-row w-full mb-6">
            <div class="w-full border bg-white rounded-xl p-5">
                <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
                    <table class="border-separate border-spacing-2 table-auto w-full">
                        <thead>
                        <tr class="bg-[#172e59]">
                            <th class="p-2 text-white break-keep w-20">{{ i18n.myoffice.Commission.No}}</th>
                            <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.Commission.MemberID}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Name}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.CurrentRank}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.amount}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.PaymentRate}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyMember.generation}}</th>
                            <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.GeneratedAmount}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800" v-for="Value in list3">
                            <td class="text-center p-2 min-w-[100px]">{{ Value.rn }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.userid }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.username }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rank_name }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_basic ?? 0,1) }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rate }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ Value.tg_lv }}</td>
                            <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_amt ?? 0,2) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <v-pagination
                    v-model="list3_page" v-show="list3_total_page > 1"
                    :pages=list3_total_page
                    :range-size="1"
                    class="my-pagination"
                    @update:modelValue="(event) => updateHandler(3, event)"
                    active-color="#DCEDFF">
                </v-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import vSelect from "vue-select";
import Popper from "vue3-popper";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import modalView from "../../modalView.vue";

export default {
    name: "MyBenefitDetailHistory",
    components : {
        vSelect,
        Popper,
        VPagination,
        Loading
    },
    data: () => ({
        i18n: window.i18n,
        isVcMember : (window.Laravel.isVcMember === true),
        title: [],
        products: [],
        page : 1,
        total_page : 1,
        total_count : 1,
        cnt_list_limit : 10,
        checkCondition : false,
        name_list : [],
        name_date : [],
        table_kind : "",
        List : [],
        s_year : "",
        s_month : "",
        s_day : "",
        dashData : "",
        months : [{title : "01", value : i18n.myoffice.jan},
            {title : "02", value : i18n.myoffice.feb},
            {title : "03", value : i18n.myoffice.mar},
            {title : "04", value : i18n.myoffice.apr},
            {title : "05", value : i18n.myoffice.may},
            {title : "06", value : i18n.myoffice.jun},
            {title : "07", value : i18n.myoffice.jul},
            {title : "08", value : i18n.myoffice.aug},
            {title : "09", value : i18n.myoffice.sep},
            {title : "10", value : i18n.myoffice.oct},
            {title : "11", value : i18n.myoffice.nov},
            {title : "12", value : i18n.myoffice.dec}],
        years : [],
        days : [],

        cs_omni_list : {ord_amt : 0,cs_amt : 0, o_rate : 0,ord_mon : ""},
        mem_stamp_h_user : {pv_now: 0 , pv_ready:0},
        dash_info : [],
        /** S : getDashBoard*/
        dash_d_val : [],
        dash_d_name : [],
        dash_d_code : [],
        dash_d_val_ori : [],

        m1  : 0,
        m2  : 0,
        m3  : 0,
        m_total  : 0,

        gm1  : 0,
        gm2  : 0,
        gm3  : 0,
        gm_total  : 0,

        //My Rank Information
        bm1 : 0,
        bm2 : 0,
        bm3 : 0,
        bm_total : 0,
        g1 : 0,
        g2 : 0,
        g3 : 0,
        g_total : 0,
        d1 : 0,
        d2 : 0,
        d3 : 0,
        d_total : 0,
        vp1 : 0,
        vp2 : 0,
        vp3 : 0,
        vp_total : 0,
        p1 : 0,
        p2 : 0,
        p3 : 0,
        p_total : 0,
        rank_need : "",
        rank_need1 : 0,
        rank_need2 : 0,

        legs : [],
        list1 : [],
        list2 : [],
        list3 : [],
        list4 : [],
        list5 : [],
        name_list1 : [],
        name_list2 : [],
        name_list3 : [],
        name_list4 : [],
        name_list5 : [],
        list1_total_count: 0,
        list1_page : 1,
        list1_total_page : 1,
        list2_total_count: 0,
        list2_page : 1,
        list2_total_page : 1,
        list3_total_count: 0,
        list3_page : 1,
        list3_total_page : 1,
        list4_total_count: 0,
        list4_page : 1,
        list4_total_page : 1,
        list5_total_count: 0,
        list5_page : 1,
        list5_total_page : 1,
        cnt_top_chk : 0,
        isNewBusinessStatus : true,
        currentYear : '',
        currentMonth : '',
        name_data : [],

        /** E : getDashBoard*/

        loading : true,
        showModal: false,

    }),
    watch : {
        s_year : function(){
            this.get_days();
        },
        s_month : function(){
            this.get_days();
        },
        // list3_page : function(val){
        //   this.get_list("list3", 3, val);
        // }
    },
    created : function (){

        // var year = "2015";
        // for(year ; year <= new Date().getFullYear() ; year++){
        //     this.years.push(
        //         {value: year}
        //     );
        // }
        // if(this.s_year == ""){
        //     let currDate = new Date();
        //     this.s_year = String(currDate.getFullYear());
        // }
        //
        // if(this.s_month == ""){
        //     let currDate = new Date();
        //
        //     this.s_month = currDate.getMonth() + 1;
        //     if(this.s_month < 10){
        //         this.s_month = String(0) + String(this.s_month);
        //     }
        // }

        const currentDate = new Date();
        this.currentYear = currentDate.getFullYear();
        this.currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');

        // this.submit();
        // this.searchMonthDashBoard()

    },
    methods : {

        totalSum(arr){
            let sum = 0;
            arr.forEach(element => {
                if ( element === null || element === undefined || element === false){
                    element = 0;
                }
                sum += parseInt(element)
            });
            return sum; // 계산된 합계 반환
        },

        updateHandler(titNum, newPage) {
            // console.log('titNum :', titNum);
            // console.log('Page updateHandler to:', newPage);
            const type = 'list' + String(titNum);
            this.get_list(type, titNum, newPage);
        },

        get_list(tit, tit_num, page){

            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/getTotalAllowanceOrBunusList',{
                s_year : this.currentYear,
                s_month : this.currentMonth,
                tit: tit,
                number: tit_num,
                page : page,
                page_size : this.cnt_list_limit,
            },{
                headers: {
                    Authorization: 'Bearer ' + window.Laravel.access_token
                }
            }).then(response =>{
                if(tit == "list1"){
                    this.list1 = response.data.list.data;
                    this.name_list1 = response.data.name_list;
                    this.list1_total_count = response.data.list_total;
                    this.list1_total_page = parseInt(this.list1_total_count/this.cnt_list_limit)+parseInt(this.list1_total_count%this.cnt_list_limit > 0 ? 1 : 0);
                }
                if(tit == "list2"){
                    this.list2 = response.data.list.data;
                    this.name_list2 = response.data.name_list;
                    this.list2_total_count = response.data.list_total;
                    this.list2_total_page = parseInt(this.list2_total_count/this.cnt_list_limit)+parseInt(this.list2_total_count%this.cnt_list_limit > 0 ? 1 : 0);
                }
                if(tit == "list3"){
                    this.list3 = response.data.list.data;
                    this.name_list3 = response.data.name_list;
                    this.list3_total_count = response.data.list_total;
                    this.list3_total_page = parseInt(this.list3_total_count/this.cnt_list_limit)+parseInt(this.list3_total_count%this.cnt_list_limit > 0 ? 1 : 0);
                }
                if(tit == "list4"){
                    this.list4 = response.data.list.data;
                    this.name_list4 = response.data.name_list;
                    this.list4_total_count = response.data.list_total;
                    this.list4_total_page = parseInt(this.list4_total_count/this.cnt_list_limit)+parseInt(this.list4_total_count%this.cnt_list_limit > 0 ? 1 : 0);
                }
                this.loading = false;
            });

        },
        get_days(){
            /*axios.post(window.Laravel.back_url+'/api/getWeekDay',{
                month : this.s_year+""+this.s_month
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response =>{
              console.log(response.data);
                this.s_day = response.data.data[0].value;
                this.days = response.data.data;
            });*/
        },
        tooltip(){
            var $this = this;
            var $href = $this.$attrs.href, $top = $this.$attrs.top + 22, $left = $this.$attrs.left ;

            var config = {
                tooltipstyle : {
                    position : 'absolute',
                    top : $top,
                    left : $left,
                    zIndex: 50
                }};

            $($href).css(config.tooltipstyle);
            (this.$type == 'mouseenter') ? $($href).show() : $($href).hide();
            return false;

        },
        formatPrice(value,digit) {
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        isEmptyObject(obj) {
            return Object.keys(obj).length === 0;
        },
        applyBusinessStatus(response){

            response.data.forEach((value, index) => {

                this.dash_d_val[value.d_col] = value.d_val??"";
                this.dash_d_name[value.d_col] = value.d_name??"";
                this.dash_d_code[value.d_col] = value.d_code??"";


                if(value.d_col == "ACT_YN"){
                    this.act_yn = value.d_code;
                }else if (value.d_col === 'PAY_DATE'){
                    this.currentYear = value.d_code.substring(0, 4);
                    this.currentMonth = value.d_code.substring(4, 6);
                }


                /* 해당월, 전월, 전전월 구분은 D_CODE 컬럼을 이용하여 구분함
                  따라서, D_COL 컬럼과 D_CODE 컬럼을 합처서 변수명으로 사용하여 월을 구분함
                */
                if(value.d_col == "LV_1" || value.d_col == "LV_2"){
                    this.dash_d_val[value.d_col + value.d_name] = value.d_val;
                    this.dash_d_code[value.d_col + value.d_name] = value.d_code;
                }
                /*주문합계*/
                if(
                    value.d_col == "ORD_AMT_A" || value.d_col == "ORD_AMT_R" || value.d_col == "ORD_AMT"
                    || value.d_col == "ORD_PV_A" || value.d_col == "ORD_PV_R" || value.d_col == "ORD_PV"
                    || value.d_col == "R_ORD_AMT_A" || value.d_col == "R_ORD_AMT_R"  || value.d_col == "R_ORD_AMT"
                    || value.d_col == "R_ORD_PV_A" || value.d_col == "R_ORD_PV_R" || value.d_col == "R_ORD_PV"

                ){
                    this.dash_d_val[value.d_col + value.d_code] = value.d_val??0;

                }
                if(value.d_col == "D_ORD_PV_A" || value.d_col == "D_ORD_PV_R" || value.d_col == "D_ORD_PV" || value.d_col == "D_LEG_CNT"){
                    this.dash_d_val[value.d_col + value.d_code] = value.d_val??0;
                }
                //산하 PV
                if(value.d_col == "LEG_N"){
                    this.dash_d_code[value.d_col + value.d_code] = value.d_name;
                    this.dash_d_val[value.d_col + value.d_code] = value.d_val??0;
                }


                // 미국에서만 사용하는 currently pv 값
                if(value.d_col == "ORD_PV"){
                    this.rank_ord_pv = (value.d_val === null ) ? "0" : value.d_val
                }

                if(value.d_col == "RANK_NEED"){
                    this.rank_need = value.d_name; //달성인원직급
                    this.rank_need1 = value.d_code; //필요회원수
                    this.rank_need2 = value.d_val; //달성인원수
                }

                if(value.d_col == "RANK_OLD"){
                    this.rank_old_name = value.d_val; //달성인원수
                }

                // if(value.d_col == "RANK01"){//MEMBER
                //     this.g1 = value.d_name; //나의 하선 당월
                //     this.g2 = value.d_code; //나의 하선 전월
                //     this.g3 = value.d_val; //나의 하선 전전월
                //     this.g_total =  parseInt(this.g1) + parseInt(this.g2) + parseInt(this.g3);
                // }
                // if(value.d_col == "RANK10"){//BRONZE
                //     this.g1 = value.d_name; //나의 하선 당월
                //     this.g2 = value.d_code; //나의 하선 전월
                //     this.g3 = value.d_val; //나의 하선 전전월
                //     this.g_total =  parseInt(this.g1) + parseInt(this.g2) + parseInt(this.g3);
                // }
                // if(value.d_col == "RANK20"){//SILVER
                //     this.g1 = value.d_name; //나의 하선 당월
                //     this.g2 = value.d_code; //나의 하선 전월
                //     this.g3 = value.d_val; //나의 하선 전전월
                //     this.g_total =  parseInt(this.g1) + parseInt(this.g2) + parseInt(this.g3);
                // }
                if(value.d_col == "RANK30"){//GOLD
                    this.g1 = value.d_name ?? 0 ; //나의 하선 당월
                    this.g2 = value.d_code ?? 0 ; //나의 하선 전월
                    this.g3 = value.d_val ?? 0 ; //나의 하선 전전월
                    this.g_total = this.totalSum([this.g1, this.g2, this.g3])
                    // this.g_total =  parseInt(this.g1) + parseInt(this.g2) + parseInt(this.g3);
                }
                if(value.d_col == "RANK40"){//BRANCH MANAGER
                    this.bm1 = value.d_name ?? 0 ; //나의 하선 당월
                    this.bm2 = value.d_code ?? 0 ; //나의 하선 전월
                    this.bm3 = value.d_val ?? 0 ; //나의 하선 전전월
                    this.bm_total = this.totalSum([this.bm1, this.bm2, this.bm3])
                    // this.bm_total =  parseInt(this.bm1) + parseInt(this.bm2) + parseInt(this.bm3);
                }

                if(value.d_col == "RANK42"){//MANAGER
                    this.m1 = value.d_name ?? 0 ; //나의 하선 당월
                    this.m2 = value.d_code ?? 0 ; //나의 하선 전월
                    this.m3 = value.d_val ?? 0 ; //나의 하선 전전월
                    this.m_total = this.totalSum([this.m1, this.m2, this.m3])
                    // this.bm_total =  parseInt(this.bm1) + parseInt(this.bm2) + parseInt(this.bm3);
                }

                if(value.d_col == "RANK45"){//GENERAL_MANAGER
                    this.gm1 = value.d_name ?? 0 ; //나의 하선 당월
                    this.gm2 = value.d_code ?? 0 ; //나의 하선 전월
                    this.gm3 = value.d_val ?? 0 ; //나의 하선 전전월

                    this.gm_total = this.totalSum([this.gm1, this.gm2, this.gm3])
                    // this.bm_total =  parseInt(this.bm1) + parseInt(this.bm2) + parseInt(this.bm3);
                }
                if(value.d_col == "RANK50"){//DIRECTOR
                    this.d1 = value.d_name ?? 0 ; //나의 하선 당월
                    this.d2 = value.d_code ?? 0 ; //나의 하선 전월
                    this.d3 = value.d_val ?? 0 ; //나의 하선 전전월
                    this.d_total = this.totalSum([this.d1, this.d2, this.d3])
                    // this.d_total = parseInt(this.d1) + parseInt(this.d2) + parseInt(this.d3);
                }

                if(value.d_col == "RANK60"){//VICE PRESIDENT
                    this.vp1 = value.d_name ?? 0 ; //나의 하선 당월
                    this.vp2 = value.d_code ?? 0 ; //나의 하선 전월
                    this.vp3 = value.d_val ?? 0 ; //나의 하선 전전월
                    this.vp_total = this.totalSum([this.vp1, this.vp2, this.vp3])
                    // this.vp_total = parseInt(this.vp1) + parseInt(this.vp2) + parseInt(this.vp3);
                }

                if(value.d_col == "RANK70"){//PRESIDENT
                    this.p1 = value.d_name ?? 0 ; //나의 하선 당월
                    this.p2 = value.d_code ?? 0 ; //나의 하선 전월
                    this.p3 = value.d_val ?? 0 ; //나의 하선 전전월
                    this.p_total = this.totalSum([this.p1, this.p2, this.p3])
                    // this.p_total = parseInt(this.p1) + parseInt(this.p2) + parseInt(this.p3);
                }
            });
        },
        /**
         * 202404 이전 마이오피스 데이터 내역
         * @param response
         */
        applyLegacyBusinessStatus(response){

            response.data.forEach((value, index) => {


                if(value.d_col =="RANK_CURR"){

                    this.rank_curr_code = value.d_code;
                    this.rank_curr_name = value.d_val;
                }

                this.dash_d_val[value.d_col] = value.d_val??"";
                this.dash_d_name[value.d_col] = value.d_name??"";
                this.dash_d_code[value.d_col] = value.d_code??"";

                if ((value.d_col).substring(0, 2) == "MR") {
                    for (var i = 0; i < 4; i++) {
                        var currentMonthValue = this.currentMonth - i;
                        if (value.d_col.slice(-2).replace(/\b0*(\d+)\b/g, '$1') == currentMonthValue) {
                            if (!this.mrArr[value.d_val]) {
                                this.mrArr[value.d_val] = []; // 배열로 초기화
                            }
                            // 배열에 값을 추가
                            if(value.d_val == "MY_RANK" || value.d_val == "RANK_KIND"  ){
                                this.mrArr[value.d_val].push(value.d_name);
                            }else{
                                this.mrArr[value.d_val].push(value.d_code);
                            }
                        }
                    }
                }
                if ((value.d_col).substring(0, 3) == "NEW") {
                    for (var i = 0; i < 4; i++) {
                        var currentMonthValue = this.currentMonth - i;
                        if (value.d_col.slice(-2).replace(/0/g, '') == currentMonthValue) {
                            if (!this.newArr[value.d_val]) {
                                this.newArr[value.d_val] = []; // 배열로 초기화
                            }
                            // 배열에 값을 추가
                            this.newArr[value.d_val].push(value.d_code);
                        }
                    }
                }




                /* 해당월, 전월, 전전월 구분은 D_CODE 컬럼을 이용하여 구분함
                  따라서, D_COL 컬럼과 D_CODE 컬럼을 합처서 변수명으로 사용하여 월을 구분함
                */
                // 230714 dylee 미주 요청으로 이번달 직급 유지 여부를 ufPAY_DASH 에 추가요청함
                if(value.d_col == "ACT_YN"){
                    this.act_yn = value.d_val;
                }

                if(value.d_col == "LV_1" || value.d_col == "LV_2"){
                    this.dash_d_val[value.d_col + value.d_name] = value.d_val;
                    this.dash_d_code[value.d_col + value.d_name] = value.d_code;
                }
                /*주문합계*/
                if(
                    value.d_col == "ORD_AMT_A" || value.d_col == "ORD_AMT_R" || value.d_col == "ORD_AMT"
                    || value.d_col == "ORD_PV_A" || value.d_col == "ORD_PV_R" || value.d_col == "ORD_PV"
                    || value.d_col == "R_ORD_AMT_A" || value.d_col == "R_ORD_AMT_R"  || value.d_col == "R_ORD_AMT"
                    || value.d_col == "R_ORD_PV_A" || value.d_col == "R_ORD_PV_R" || value.d_col == "R_ORD_PV"

                ){
                    this.dash_d_val[value.d_col + value.d_code] = value.d_val??0;

                }
                if(value.d_col == "D_ORD_PV_A" || value.d_col == "D_ORD_PV_R" || value.d_col == "D_ORD_PV" || value.d_col == "D_LEG_CNT"){
                    this.dash_d_val[value.d_col + value.d_code] = value.d_val??0;
                }

                if(value.d_col == "RANK_NEED"){
                    this.rank_need = value.d_name; //달성인원직급
                    this.rank_need1 = value.d_code; //필요회원수
                    this.rank_need2 = value.d_val; //달성인원수

                }


                // if(value.d_col == "D2_RANK_30"){//GOLD
                //     this.g1 = value.d_name; //나의 하선 당월
                //     this.g2 = value.d_code; //나의 하선 전월
                //     this.g3 = value.d_val; //나의 하선 전전월
                //     this.g_total =  value.d_code;
                // }
                // if(value.d_col == "D2_RANK_40"){//BRANCH MANAGER
                //     this.bm1 = value.d_name; //나의 하선 당월
                //     this.bm2 = value.d_code; //나의 하선 전월
                //     this.bm3 = value.d_val; //나의 하선 전전월
                //     this.bm_total =  value.d_code;
                // }
                // if(value.d_col == "D2_RANK_50"){//DIRECTOR
                //     this.d1 = value.d_name; //나의 하선 당월
                //     this.d2 = value.d_code; //나의 하선 전월
                //     this.d3 = value.d_val; //나의 하선 전전월
                //     this.d_total = value.d_code;
                // }
                //
                // if(value.d_col == "D2_RANK_60"){//VICE PRESIDENT
                //     this.vp1 = value.d_name; //나의 하선 당월
                //     this.vp2 = value.d_code; //나의 하선 전월
                //     this.vp3 = value.d_val; //나의 하선 전전월
                //     this.vp_total = value.d_code;
                // }
                //
                // if(value.d_col == "D2_RANK_70"){//PRESIDENT
                //     this.p1 = value.d_name; //나의 하선 당월
                //     this.p2 = value.d_code; //나의 하선 전월
                //     this.p3 = value.d_val; //나의 하선 전전월
                //     this.p_total = value.d_code;
                // }
            })
        },
        apiDashboard(){
            axios.post(window.Laravel.back_url+'/api/dashboard',{
                s_year : this.currentYear,
                s_month : this.currentMonth,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.cs_omni_list = response.data.cs_omni_list;
                this.cs_omni_list.ord_mon = this.cs_omni_list.ord_mon.substring(4,6)+"/"+this.cs_omni_list.ord_mon.substring(0,4);

                // 사용하지 않는 것으로 추정
                // this.series[0] = this.cs_omni_list.o_rate;
                this.mem_stamp_h_user = response.data.mem_stamp_h_user;
            });
        },

        getTotalAllowanceOrBunus(){
            axios.post(window.Laravel.back_url+'/api/getTotalAllowanceOrBunus',{
                s_year : this.currentYear,
                s_month : this.currentMonth,
                s_day : this.s_day.code

            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.name_list = response.data.name_list;
                this.List = response.data.name_data[0];

                // console.group("== data ==")
                // console.dir(this.List);
                // console.groupEnd("== data ==")


                this.name_date = response.data.name_date;
                // this.s_year = response.data.s_year;
                // this.s_month = response.data.s_month;

                this.currentYear = response.data.s_year;
                this.currentMonth = response.data.s_month;

                this.s_day = response.data.s_day;
                this.dashData = response.data.dashData;
                this.cnt_top_chk = response.data.cnt_top_chk;
                this.get_list("list1",1,1);
                this.get_list("list2",2,1);
                this.get_list("list3",3,1);
                this.get_list("list4",4,1);
                this.loading = false;
            });
        },
        apiGetDashBoard() {

            // this.currentYear = this.s_year;
            // this.currentMonth = this.s_month;
            //this.loading = true;
            // this.isNewBusinessStatus = ( Number(this.s_year + this.s_month) >= 202404) ? true : false;

            axios.post(window.Laravel.back_url + '/api/getDashBoard', {
                s_year: this.currentYear,
                s_month: this.currentMonth,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {

                this.applyBusinessStatus(response)
                // if (this.isNewBusinessStatus === true) {
                //     this.applyBusinessStatus(response)
                // } else {
                //     this.applyLegacyBusinessStatus(response)
                // }

                // deep copy
                if (this.isEmptyObject(this.dash_d_val_ori) === true) {
                    this.dash_d_val_ori = this.deepCopy(this.dash_d_val);
                }
            })
        },
        /**
         * deep copy
         * @param obj
         * @param hash
         * @returns {*[]|{}|RegExp|*|Date|null}
         */
        deepCopy(obj, hash = new WeakMap()) {
            if (obj === null) return null;
            if (typeof obj !== 'object') return obj;
            if (obj instanceof Date) return new Date(obj);
            if (obj instanceof RegExp) return new RegExp(obj.source, obj.flags);
            if (hash.has(obj)) return hash.get(obj);

            const clone = Array.isArray(obj) ? [] : {};
            hash.set(obj, clone);

            Object.keys(obj).forEach(key => {
                clone[key] = this.deepCopy(obj[key], hash);
            });

            return clone;
        },

        stepRemove(userName) {
            // 정규식: 괄호와 그 안의 숫자와 'step' 단어 포함하여 제거
            var regex = /\s?\(\d+step\)/g;
            // 정규식을 사용하여 문자열에서 패턴 제거
            return userName.replace(regex, '');
        },
        searchMonthDashBoard(year, month){

            // console.log(" !!! searchMonthDashBoard !!!")

            this.loading = true;
            this.currentYear = (year !== '' ) ? year : this.currentYear;
            this.currentMonth = (month !== '' ) ? month : this.currentMonth;
            // this.isNewBusinessStatus = ( Number(this.currentYear + this.currentMonth) >= 202404 || this.currentYear === '') ? true : false;

            this.loading = true;
            this.apiGetDashBoard()
            this.apiDashboard()
            this.getTotalAllowanceOrBunus()
        },
        // submit(){
        //
        //     this.loading = true;
        //     this.apiGetDashBoard()
        //
        //     // axios.post(window.Laravel.back_url+'/api/getDashBoard',{
        //     //     s_date: this.s_year + this.s_month,
        //     // },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        //     //   response.data.forEach((value, index) => {
        //     //     this.dash_d_val[value.d_col] = value.d_val;
        //     //     if(value.d_col == "LV_1" || value.d_col == "LV_2"){
        //     //       this.dash_d_val[value.d_col + value.d_name] = value.d_val;
        //     //       this.dash_d_code[value.d_col + value.d_name] = value.d_code;
        //     //     }
        //     //
        //     //     /*주문합계*/
        //     //     if(
        //     //       value.d_col == "ORD_AMT_A" || value.d_col == "ORD_AMT_R" || value.d_col == "ORD_AMT"
        //     //       || value.d_col == "ORD_PV_A" || value.d_col == "ORD_PV_R" || value.d_col == "ORD_PV"
        //     //       || value.d_col == "R_ORD_AMT_A" || value.d_col == "R_ORD_AMT_R"  || value.d_col == "R_ORD_AMT"
        //     //       || value.d_col == "R_ORD_PV_A" || value.d_col == "R_ORD_PV_R" || value.d_col == "R_ORD_PV"
        //     //
        //     //     ){
        //     //       this.dash_d_val[value.d_col + value.d_code] = value.d_val;
        //     //     }
        //     //
        //     //     if(value.d_col == "D_ORD_PV_A" || value.d_col == "D_ORD_PV_R" || value.d_col == "D_ORD_PV" || value.d_col == "D_LEG_CNT"){
        //     //       this.dash_d_val[value.d_col + value.d_code] = value.d_val;
        //     //     }
        //     //
        //     //     if(value.d_col == "RANK_NEED"){
        //     //       this.rank_need = value.d_name; //달성인원직급
        //     //       this.rank_need1 = value.d_code; //필요회원수
        //     //       this.rank_need2 = value.d_val; //달성인원수
        //     //
        //     //     }
        //     //
        //     //     if(value.d_col == "GOLD"){
        //     //       this.g1 = value.d_name; //나의 하선 당월
        //     //       this.g2 = value.d_code; //나의 하선 전월
        //     //       this.g3 = value.d_val; //나의 하선 전전월
        //     //       this.g_total =  parseInt(this.g1) + parseInt(this.g2) + parseInt(this.g3);
        //     //     }
        //     //     if(value.d_col == "BRANCH MANAGER"){
        //     //       this.bm1 = value.d_name; //나의 하선 당월
        //     //       this.bm2 = value.d_code; //나의 하선 전월
        //     //       this.bm3 = value.d_val; //나의 하선 전전월
        //     //       this.bm_total =  parseInt(this.bm1) + parseInt(this.bm2) + parseInt(this.bm3);
        //     //     }
        //     //
        //     //       if(value.d_col == "MANAGER"){
        //     //           this.m1 = value.d_name; //나의 하선 당월
        //     //           this.m2 = value.d_code; //나의 하선 전월
        //     //           this.m3 = value.d_val; //나의 하선 전전월
        //     //           this.m_total =  parseInt(this.m1) + parseInt(this.m2) + parseInt(this.m3);
        //     //       }
        //     //
        //     //       if(value.d_col == "MANAGER"){
        //     //           this.gm1 = value.d_name; //나의 하선 당월
        //     //           this.gm2 = value.d_code; //나의 하선 전월
        //     //           this.gm3 = value.d_val; //나의 하선 전전월
        //     //           this.gm_total =  parseInt(this.gm1) + parseInt(this.gm2) + parseInt(this.gm3);
        //     //       }
        //     //
        //     //     if(value.d_col == "DIRECTOR"){
        //     //       this.d1 = value.d_name; //나의 하선 당월
        //     //       this.d2 = value.d_code; //나의 하선 전월
        //     //       this.d3 = value.d_val; //나의 하선 전전월
        //     //       this.d_total = parseInt(this.d1) + parseInt(this.d2) + parseInt(this.d3);
        //     //     }
        //     //
        //     //     if(value.d_col == "VICE PRESIDENT"){
        //     //       this.vp1 = value.d_name; //나의 하선 당월
        //     //       this.vp2 = value.d_code; //나의 하선 전월
        //     //       this.vp3 = value.d_val; //나의 하선 전전월
        //     //       this.vp_total = parseInt(this.vp1) + parseInt(this.vp2) + parseInt(this.vp3);
        //     //     }
        //     //
        //     //     if(value.d_col == "PRESIDENT"){
        //     //       this.p1 = value.d_name; //나의 하선 당월
        //     //       this.p2 = value.d_code; //나의 하선 전월
        //     //       this.p3 = value.d_val; //나의 하선 전전월
        //     //       this.p_total = parseInt(this.p1) + parseInt(this.p2) + parseInt(this.p3);
        //     //     }
        //     //   });
        //     // });
        //     // axios.post(window.Laravel.back_url+'/api/dashboard',{
        //     // },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        //     //     this.cs_omni_list = response.data.cs_omni_list;
        //     //     this.cs_omni_list.ord_mon = this.cs_omni_list.ord_mon.substring(4,6)+"/"+this.cs_omni_list.ord_mon.substring(0,4);
        //     //
        //     //     // 사용하지 않는 것으로 추정
        //     //     // this.series[0] = this.cs_omni_list.o_rate;
        //     //     this.mem_stamp_h_user = response.data.mem_stamp_h_user;
        //     // });
        //
        //     // axios.post(window.Laravel.back_url+'/api/getTotalAllowanceOrBunus',{
        //     //   s_year : this.s_year, s_month : this.s_month, s_day : this.s_day.code
        //     // },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        //     //   this.name_list = response.data.name_list;
        //     //   this.List = response.data.name_data[0];
        //     //
        //     //   console.group("== data ==")
        //     //   console.dir(this.List);
        //     //   console.groupEnd("== data ==")
        //     //
        //     //
        //     //   this.name_date = response.data.name_date;
        //     //   this.s_year = response.data.s_year;
        //     //   this.s_month = response.data.s_month;
        //     //   this.s_day = response.data.s_day;
        //     //   this.dashData = response.data.dashData;
        //     //   this.cnt_top_chk = response.data.cnt_top_chk;
        //     //   this.get_list("list1",1,1);
        //     //   this.get_list("list2",2,1);
        //     //   this.get_list("list3",3,1);
        //     //   this.get_list("list4",4,1);
        //     //   this.loading = false;
        //     // });
        // },
        getLegList(month){//본인 산하 직추천 명단
            this.showModal = true;
            this.legs = [];
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/getLeglist',{month : month,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.legs = response.data;

            });
            this.loading = false;
        },

    },
    mounted() {

        if (this.isVcMember === true) {
            // 'noShowVcMember' 클래스를 가진 모든 요소를 찾아 배열로 변환합니다.
            const elements = [...document.getElementsByClassName('no-show-vcm')];
            // 배열의 각 요소에 대해 `display` 스타일을 'none'으로 설정합니다.
            elements.forEach(element => element.style.display = 'none');
        }

        // console.log(this.currentYear + " <--- this.currentYear A")
        // console.log(this.currentMonth + " <--- this.currentMonth A")

        this.searchMonthDashBoard('', '');

    }

}
</script>

<style scoped>
:deep(.popper) {
    max-width: 600px;
    word-break: break-all;
    white-space: pre-line;

}
</style>

<style >

.my-pagination {
    justify-content: center;
    height:80px;
}
.v-select{ min-width: 100px; z-index:0 }
/*배솧비 툴팁*/
.tooltip{display: inline-block;}
.tooltip i{color:#0abab5; font-weight:400; vertical-align: baseline}
.tooltip-content{ display: none; text-align: left; font-size: 12px; background-color: #fff; padding:10px; border-radius:10px; font-size:1em; box-shadow:5px 5px 5px #888; background:#0abab5; color:#fff;}
/* 툴팁 삭제버튼 추가 */
.tooltip-content:after{display:inline-block; content:' '; width:20px;}
.tooltip-close{position:absolute; top:7px; right:12px;}
:root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 32px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}


</style>
