<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>
  <section class="xl:min-h-[800px] bg-box flex flex-col items-center">
    <div class="main-content max-w-[1400px] xl:w-full lg:w-full m-0">
      <!-- 타이틀 -->
      <div class="main-card text-center">
        <ul>
          <li>
            <img src="https://cdnus.gcoop.me/us/images/gcoop_us2.png" alt="gcoop usa">
          </li>
          <li>
            Please enter your gift card number.
          </li>
          <li>
            <input id="cardNumber" type="text" required="required" ref="cardNumber"
                   placeholder="Enter gift card number" maxlength="19" v-model="cardNumber" @keyup="cardNumberKeyUp"
                   @input="validateInput">
            <!--               :disabled="isNotActivate"-->
          </li>
          <li>
            <vue-recaptcha
              ref="recaptcha"
              sitekey="6Lfrz3wkAAAAAIZkkULRXj9qyUHLO_eTYZJKHtn9"
              @verify="verifyCapcha"
              @expired="onExpired"/>
          </li>
          <li>
            <button @click="reqUseGiftCard" type="button" :class="{ 'button-effect' : cardNumber.length===19 }" class="srh faq_write_btn" style="border-radius: 5px;">
              <span class="btn-eft-none">Activate</span>
            </button>
          </li>
          <li>
            GCOOP USA Gift Card
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>

import {VueRecaptcha} from 'vue-recaptcha';
import VueSimpleAlert from "vue3-simple-alert";
import Loading from 'vue3-loading-overlay';

export default {
  name: "Activate",
  components: {
    VueRecaptcha,
    VueSimpleAlert,
    Loading,
  },
  props: [],
  watch: {},
  data: () => ({
    i18n: window.i18n,
    cardNumber: '',
    recaptchaRes: '',
    loading: false,
    isNotActivate : false
  }),
  created() {
    this.loading = false;
  },
  mounted() {
  },
  methods: {
    /**
     * 난수 생성기
     * @param length 난수의 길이
     * @returns {string}
     */
    generateRandomString(length) {
      const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      let result = '';

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }

      return result;
    },

    /*
    * 기프트 카드 사용
     */
    reqUseGiftCard() {

      // 5분간 이용 금지
      if ( this.getNotActivate() === true){
        VueSimpleAlert.alert('You have entered the incorrect card number too many times. Please try again after 5 minutes.', '', 'error', {allowOutsideClick: false}).then((result) => {
          this.cardNumber = '';
          this.goCardNumberfoucs();
        });
        return ;
      }

      if (this.cardNumber.length !== 19) {
        VueSimpleAlert.alert(
          'Please enter gift card number.', '', 'warning', {allowOutsideClick: false}
        ).then((result) => {
          this.goCardNumberfoucs();
        })
        return;
      }

      if (this.recaptchaRes === '') {
        VueSimpleAlert.alert('Please check recaptcha.', '', 'warning', {allowOutsideClick: false}).then((result) => {
        })
        return;
      }

      VueSimpleAlert.confirm('Do you want to enter a gift card number?', '', 'question').then(() => {

        this.loading = true;

        axios.post(window.Laravel.back_url + '/api/registerGiftCard', {
          cardNumber: Crypto().encrypt([this.cardNumber, login_userid, this.generateRandomString(10)].join("||")),
          userId: Crypto().encrypt([login_userid, this.generateRandomString(10)].join("||")),
        }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {

          this.loading = false;
          if (response.data.result === 0) {
            VueSimpleAlert.alert(response.data.resultMessage, '', 'success', {allowOutsideClick: false}).then((result) => {
              this.loading = true;
              window.location.reload();
            });
          } else {

            // block 시간 반영
            if ( response.data.result === 3 ) {
              this.setNotActivate(true)
              setInterval(this.setNotActivate, 1000 * 60 * response.data.blockFewMinute); // 1초마다 확인 (필요에 따라 조절)
            }

            VueSimpleAlert.alert(response.data.resultMessage, '', 'error', {allowOutsideClick: false}).then((result) => {
              this.cardNumber = '';
              this.goCardNumberfoucs();
            });
          }
        })

      }).catch((error) => {

      });
    },

    getNotActivate() {
      return this.isNotActivate
    },
    setNotActivate(isBool) {
      this.isNotActivate = ( isBool === undefined) ? false : isBool
    },

    goCardNumberfoucs(){
      setTimeout(() => {
        this.$refs.cardNumber.focus();
      }, 500);
    },
    /**
     * 구글 리캡챠 ( 인증 처리 완료 )
     * @param response
     */
    verifyCapcha(response) {
      this.recaptchaRes = response;
      // this.$refs.recaptcha.execute();
    },

    /**
     * 구글 리캡챠 이용 시간 만료
     */
    onExpired() {
      this.recaptchaRes = '';
      this.$refs.recaptcha.reset()
    },
    /**
     * input type='text'에 정규 표현식을 사용하여 영어, 숫자, 하이픈만 허용 / 소문자는 대문자로 변환
     */
    validateInput() {
      this.cardNumber = this.cardNumber.replace(/[^A-Za-z0-9-]/g, "").toUpperCase();
    },
    /**
     * 카드 번호 입력
     */
    cardNumberKeyUp() {

      const cleanInput = this.cardNumber.replace(/[\s-]/g, '');
      // 4자리마다 하이픈 추가
      const formattedArray = Array.from(cleanInput).map((char, index) => {
        return (index > 0 && index % 4 === 0) ? `-${char}` : char;
      });

      this.cardNumber = formattedArray.join('');

    }
  }
}
</script>

<style lang="scss" scoped>


.button-effect {
  //box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.main-card {
  ul {
    background-color: #B0C1DD;
    border-radius: 20px;
    max-width: 640px;
    margin: 0 auto;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);

    li {
      width: 100%;
      display: grid;
      place-items: center;

      &:first-child {
        img {
          display: inline;
          width: 100%;
          max-width: 350px;
          height: 100%;
          padding: 20px;
        }
      }

      &:nth-child(2) {
        background-color: #ffffff;
        height: 110px;
        font-weight: 600;
        font-size: 1.1em;
        letter-spacing: 0.1em
      }

      &:nth-child(3) {
        background-color: #ffffff;
        height: 50px;
        padding: 0 15px;

        input[type='text'] {
          height: 3.0em;
          min-width:302px;
          width: 90%;
          text-align: center;
          font-weight: bold;

          &::placeholder {
          }
        }
      }

      &:nth-child(4) {
        background-color: #ffffff;
        height: 100px;
      }

      &:nth-child(5) {
        background-color: #ffffff;
      }

      &:nth-child(6) {
        background-color: #ffffff;
        height: 70px;
        border-radius: 0 0 20px 20px;
      }
    }
  }
}

/* 768px 이상의 너비를 가진 화면에서 글자 크기 조정 */
@media (min-width: 768px) {
  #cardNumber {
    letter-spacing: 0.4em;
  }
}

/* 1024px 이상의 너비를 가진 화면에서 글자 크기 더 크게 조정 */
@media (min-width: 1024px) {
  #cardNumber {
    letter-spacing: 0.7em;
  }
}

</style>
