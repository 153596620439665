<template>

    <div class="member-search">
        <loading :active="loading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage"></loading>
        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
            <input type="hidden" name="order" id="order">
            <input type="hidden" name="sort" id="sort">
            <section class="myo_condition_box">

                <section class="flex_box_quad">
                    <div class="relative z-50">
                        <dateForm @set_s_date="set_s_date" @set_e_date="set_e_date" ></dateForm>
                    </div>
                </section>
                <section class="flex_box_quad">
                    <nameForm></nameForm>
                </section>

            </section>

            <div class="myo_condition_search">
                <button type="button" v-on:click="reset" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                <button type="submit" id="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
            </div>
        </form>
    </div>

    <p class="p_alert">You can search consumer orders here. By downloading the purchase order, you can check the purchase order for the corresponding order.</p>
    <!-- 소비자 주문에 대한 조회를 하실 수 있습니다.  구매 계약서 다운로드를 통해 해당 주문에 대한 구매계약서를 확인할 수 있습니다.-->

  <section>
        <div class="w-full overflow-x-scroll sm:overflow-hidden block">
            <table class="border-separate w-full table-auto text-slate-800">
                <tbody>
                <!-- 주문번호 주문일자 소비자명 배송방법 금액 구매계약서 -->
                <tr>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{i18n.inquriy.inquriy.ord_no}}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.Order.orderDate }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.dashboard.consumerName }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.Order.Deliverymethod }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.Commission.price }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.Order.purchaseform }}</th>
                </tr>
                <tr v-for="(Value,index) in orderList" class="bg-[#f0f4ff] text-center">
                    <td class="text-center p-2 break-keep">{{ Value.cs_ono }}</td>
                    <td class="text-center p-2 break-keep">{{ (Value.ord_date).substring(4, 6) }}/{{ (Value.ord_date).substring(6, 8) }}/{{ (Value.ord_date).substring(0, 4) }}</td>
                    <td class="text-center p-2 break-keep">{{ Value.cs_name }}</td>
                    <td class="text-center p-2 break-keep">{{ Value.deli_kind }}</td>
                    <td class="text-center p-2 break-keep">${{ Value.ord_amt }}</td>
                    <td class="text-center p-2"><button type="button" v-on:click="popup_invoice(Value.cs_ono);" class="rounded-md bg-[#00133e] text-white p-2 hover:bg-[#737895] ">Download</button></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
            <v-pagination
                v-model="page"
                :pages=total_page
                :range-size="1"
                active-color="#DCEDFF">
            </v-pagination>
        </div>
    </section>

</template>

<script>

import numeral from "numeral";
import dateForm from '../../searchForm/dateForm.vue'
import nameForm from '../../searchForm/nameForm.vue'
import moment from "moment/moment";
import VueSimpleAlert from "vue3-simple-alert";
import VPagination from "@hennge/vue3-pagination";
import Loading from "vue3-loading-overlay";


export default {
    name: "MyCustomerOrder",
    components : {
        nameForm,
        dateForm,
        VPagination,
        Loading,
        VueSimpleAlert,

    },
    data: () => ({
        showModal: false,
        working_nation : working_nation,
        loading : false,
        fullPage : true,
        i18n: window.i18n,
        total_count : 0,
        page : 1,
        total_page : 1,
        s_date: '',
        e_date: '',
        orderList : [],

    }),
    watch : {
        page: function(){
            $("#submit").click();
        }
    },
    mounted() {
        $("#submit").click();
    },
    methods:{
        submitForm : function (event){
            event.preventDefault();
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/MyCustomerOrder',{
                s_date : this.s_date,
                e_date : this.e_date,
                down_name : down_name.value,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if(response.data.total_count == 0){
                    VueSimpleAlert.alert("There are no search results","","",{confirmButtonColor: '#0abab5'});
                    this.list_msg = "No data";
                }
                console.log(response.data);
                this.orderList = response.data;
                this.total_count = response.data.length;
                this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
                this.loading = false;
            }).catch(error => console.error(error));
        },


        set_s_date(s_date){
            this.s_date = moment(s_date).format('YYYY-MM-DD');
        },
        set_e_date(e_date){
            this.e_date = moment(e_date).format('YYYY-MM-DD');
        },
        moment: function () {
            return moment();
        },
        popup_invoice(ord_no) {
            window.open(window.Laravel.back_url+"/api/getInvoiceCustomer?ordno="+ord_no);

        },
    },
}

</script>

<style scoped>
.board-inner{background:#f0f2f4 !important;}

/*.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}*/

</style>
