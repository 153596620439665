<template>
    <dl class="myo_search_list" >
        <dt>{{ i18n.myoffice.myoSearchForm.selectDate }}</dt>
        <dd class="accordion_cont w-full xl:flex lg:flex xl:flex-row lg:flex-row xl:gap-2 lg:gap-2 block bg-transparent">
<!--      <dd class="accordion_cont w-full block lg:grid lg:grid-cols-4 lg:gap-2 bg-transparent">-->
            <div v-show="is_view == false" class="z-[12] lg:mb-0 mb-2">
                <vSelect :options="date_type_list" label="value" :reduce="value => value.title"  :clearable="false" v-model="date_type" ></vSelect>
            </div>
            <div class="flex xl:flex-row lg:flex-row flex-col gap-2">
              <div class="z-[13] relative xl:w-32 lg:w-32 w-full"><vSelect :options="months" label="value" :reduce="value => value.title" :clearable="false" v-model="s_month" class="xl:w-32 lg:w-32 w-full"></vSelect></div>
              <div class="z-[14] relative xl:w-32 lg:w-32 w-full"><vSelect :options="years" label="value" :reduce="value => value.value"  :clearable="false" v-model="s_year" class="xl:w-32 lg:w-32 w-full"></vSelect></div>
              <span class="hyphen_icon">-</span>
              <div class="z-[13] relative xl:w-32 lg:w-32 w-full"><vSelect :options="months" label="value" :reduce="value => value.title" :clearable="false" v-model="e_month" class="xl:w-32 lg:w-32 w-full"></vSelect></div>
              <div class="z-[14] relative xl:w-32 lg:w-32 w-full"><vSelect :options="years" label="value" :reduce="value => value.value"  :clearable="false" v-model="e_year" class="xl:w-32 lg:w-32 w-full"></vSelect></div>
            </div>
        </dd>
    </dl>
</template>

<script>
import vSelect from "vue-select";
import datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: "dateForm",
    components: {
        datepicker,
        vSelect
    },
    data: () => ({
        i18n: window.i18n,
        locale :"ko",
        format : "MM/dd/yyyy",
        startDate : date_month,
        endDate : date_today,
        selectTerm : "",
        years : [],
        s_year : "",
        s_month : "",
        e_year : "",
        e_month : "",
        date_type_list :  [{title : "order", value : i18n.myoffice.Order.orderDate},
            {title : "accept", value : i18n.myoffice.Order.depositdate}],
        date_type_list1 : [{title : "register", value : i18n.myoffice.RegistrationDate},
            {title : "update", value : i18n.myoffice.UpdateDate}],
        date_type_list2 : [{title : "order", value : i18n.myoffice.Order.orderDate},
            {title : "accept", value : i18n.myoffice.Order.depositdate}],
        date_type: "order",
        months : [{title : "01", value : i18n.myoffice.jan},
          {title : "02", value : i18n.myoffice.feb},
          {title : "03", value : i18n.myoffice.mar},
          {title : "04", value : i18n.myoffice.apr},
          {title : "05", value : i18n.myoffice.may},
          {title : "06", value : i18n.myoffice.jun},
          {title : "07", value : i18n.myoffice.jul},
          {title : "08", value : i18n.myoffice.aug},
          {title : "09", value : i18n.myoffice.sep},
          {title : "10", value : i18n.myoffice.oct},
          {title : "11", value : i18n.myoffice.nov},
          {title : "12", value : i18n.myoffice.dec}],
        watchData : ["s_year", "e_month", "e_year", "s_month"],
    }),
    props:{
        s_date : "",
        e_date : "",
        is_view: false,
        start_plusday : "",
    },
    watch : {
        s_year : function (){
          this.set_s_date();
        },
        s_month : function (){
          this.set_s_date();
        },
        e_month : function (){
          this.set_e_date();
        },
        e_year : function (){
          this.set_e_date();
        },
        selectTerm : function (){
            if(this.selectTerm == "today"){
                this.today();
            }
            if(this.selectTerm == "week"){
                this.week();
            }
            if(this.selectTerm == "current_month"){
                this.current_month();
            }
            if(this.selectTerm == "before_1month"){
                this.before_1month();
            }
            if(this.selectTerm == "before_2month"){
                this.before_2month();
            }
            if(this.selectTerm == "before_3month"){
                this.before_3month();
            }
            if(this.selectTerm == "all_date"){
                this.all_date();
            }

        }

    },
    created : function () {
        let currDate = new Date();
        var curr_year = String(currDate.getFullYear());
        var curr_month = currDate.getMonth() + 1;
        if(curr_month < 10) curr_month = String(0) + String(curr_month);
        if(this.s_year == "") this.s_year = curr_year;
        if(this.e_year == "") this.e_year = curr_year;
        if(this.s_month == "") this.s_month = curr_month;
        if(this.e_month == "") this.e_month = curr_month;

        var year = "2015";
        for(year ; year <= new Date().getFullYear() ; year++){
          this.years.push(
            {value: year}
          );
        }
        this.endDate = this.start_plusday == 1 ? date_plus_7day : date_today;
        if(this.date_type == "register"){
            this.date_type_list = this.date_type_list1;
        }
        if(this.date_type == "order"){
            this.date_type_list = this.date_type_list2;
        }
        this.set_s_date(this.startDate);
        this.set_e_date(this.endDate);

    },
    methods : {
        dateChange(){
        },

        prev_month() {
            this.startDate = date_prev_month_s;
            this.endDate = date_prev_month_e;
        },
        last_3month(){
            this.startDate = date_before_3month_s;
            this.endDate = date_today;
        },
        this_year() {
            this.startDate = date_this_year_s;
            this.endDate = date_today;
        },
        all_date() {
            this.startDate = date_all_first;
            this.endDate = date_today;
        },
        current_month(){
            this.startDate = date_current_month;
            this.endDate = date_today;
        },
        before_1month(){
            this.startDate = date_before_1month_s;
            this.endDate = date_before_1month_e;
        },
        before_2month(){
            this.startDate = date_before_2month_s;
            this.endDate = date_before_2month_e;
        },
        before_3month(){
            this.startDate = date_before_3month_s;
            this.endDate = date_before_3month_s;
        },
        set_s_date(){
            var s_date = String(this.s_year) + String(this.s_month);
            this.$emit("set_s_date",s_date);
        },
        set_e_date(){
            var e_date = String(this.e_year) + String(this.e_month);
            this.$emit("set_e_date",e_date);
        },
        set_date_type(date_type){
            this.$emit("set_date_type",date_type);
        }
    }
}
</script>

<style>
.myo_date_input{
  border-radius: 8px !important;
  padding : 2px 8px 2px 8px;
  height: 35px;
    padding-left:32px !important; text-align: center;
}
.myo_date_box input{}

</style>
