<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <modalView class="z-50" v-if="address_book_open" @close-modal="address_book_open = false" :harf="true"  >
        <address_book @get_info_address_book="get_info_address_book" @close_address_book="close_address_book" :nt_cd="mem_center"/>
    </modalView>
    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
        <address_search @set_address="set_address" @close_address_search="close_address_search" :nt_cd="mem_center"/>
    </modalView>
<!--    <modalView class="z-50" v-if="tax_detail_open" @close-modal="tax_detail_open = false" :harf="true"  >
      <tax_detail @set_address="set_address" @close_tax_detail="close_tax_detail" :tax_rate="tax_rate" />
    </modalView>-->

    <!--    <div>-->
    <!--      <address_book @close_address_book="close_address_book" @get_info_address_book="get_info_address_book" v-if="address_book_open" />-->
    <!--  </div>-->
    <section class="bg-[#f0f2f4]">
        <div class="container mx-auto py-6">
            <div class="max-w-8xl mx-auto px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8">
                <div class="flex xl:flex-row lg:flex-row flex-col">
                    <div class="w-full sm:w-3/4">
                        <div class="flex justify-between pb-8 max-w-8xl lg:max-w-6xl mx-auto" v-show="true">
                            <button @click="link('/cart')" :class="is_giftcard == 0 ? 'bg-[#2c80c4] hover:bg-[#2c80c4] text-white ' : 'border-white border-gray-200 bg-white text-gray-500 hover:bg-gray-200 hover:bg-gray-300'" class=" font-semibold border  py-3 text-sm  uppercase w-1/3 max-w-2xl"><a >CART</a></button>
                            <button @click="link('/AsCart')" :class="is_giftcard == 2 ? 'bg-[#2c80c4] hover:bg-[#2c80c4] text-white ' : 'border-white border-gray-200 bg-white text-gray-500 hover:bg-gray-200 hover:bg-gray-300'" class="font-semibold  py-3 text-sm  nuppercase w-1/3 max-w-2xl"><a >AUTOSHIP</a></button>
                            <button @click="link('/GiftCardCart')" :class="is_giftcard == 1 ? 'bg-[#2c80c4] hover:bg-[#2c80c4] text-white' : ' border-white border-gray-200 bg-white text-gray-500 hover:bg-gray-200 hover:bg-gray-300'" class="font-semibold  py-3 text-sm  nuppercase w-1/3 max-w-2xl"><a >GIFT CARD</a></button>
                        </div>


                        <div class="flex justify-between mb-2 lg:mb-6">
                            <h1 class="font-semibold text-2xl">Shopping Cart</h1>
                            <h2 class="font-semibold text-lg md:text-xl">{{ products.length }} Items</h2>
                        </div>
                        <div class=" pb-2 mb-1 ">
                            <button class="float-left mt-1 p-2 xl:text-sm lg:text-sm text-xs text-[#4f65ae] bg-[#d1d5de] hover:bg-[#cbcfdd] rounded" @click="allSelect">Select all items</button>
                            <button class="float-right mt-1 p-2 xl:text-sm lg:text-sm text-xs text-[#4f65ae] bg-[#d1d5de] hover:bg-[#cbcfdd] rounded" @click="removeSelect">Remove selected items</button>
                        </div>

                        <section class="mt-14 bg-white shadow-zinc-500/40 shadow-md rounded-md xl:p-6 lg:p-6 p-3"> <!--clear-both-->

                          <div class="hidden lg:flex w-full" >
                                <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-7/12 w-full p-2 border">Product Details</h3>
                                <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-2/12 w-full p-2 border">QTY</h3>
                                <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-2/12 w-full p-2 border">Total</h3>
                                <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-1/12 w-full p-2 border">&nbsp;</h3>
                            </div>
                            <!--// 장바구니 제품 목록-->
                            <div v-for="(product, i) in products" :key="product.seq_pd" v-if="isEnterProduct" class="flex xl:flex-row lg:flex-row flex-col py-0 w-full">
                                <div v-if="product.master_seq == null" class="xl:flex_row border-b border-slate-200 last:border-b-0"></div>
                                <div v-if="product.master_seq == null" class="xl:flex lg:flex block  xl:w-7/12 lg:w-7/12 w-full xl:py-5 lg:py-5"> <!-- product -->
                                    <span class="p-2 flex items-center justify-between">
                                      <input v-if="product.master_seq==null" type="checkbox" :name="product.seq_pd" :value="product.seq_pd" v-model="selectedItems" class="cursor-pointer text-[#4e64ad]">
                                      <a href="#" v-on:click.prevent="removeItem($event,i)" class="w-5 font-semibold hover:text-red-500 text-gray-500 text-xs xl:border lg:border border-slate-400 inline-block p-1 xl:hidden lg:hidden block">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                                      </a>
                                    </span>
                                    <div class="flex w-full xl:px-0 lg:px-0 px-2">
                                        <a :href="'/product/item/'+product.seq_pd" class="inline-block">
                                            <div class="relative xl:w-24 lg:w-24 xl:h-24 lg:h-24 w-20 h-20 aspect-w-1 aspect-h-1 border rounded-md overflow-hidden">
                                                <img class="xl:w-24 lg:w-24 xl:h-24 lg:h-24 w-20 h-20 max-w-none	" v-bind:src="'https://cdnus.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename " alt="">
                                                <div class="sold-out-box2" v-if="product.product.status == 2">
                                                    <img src="/images/badge/us/pc_temp_sold_out.png" alt="sold out" />
                                                </div>
                                                <div class="sold-out-box2" v-if="product.product.status == 3">
                                                    <img src="/images/badge/us/pc_sold_out.png" alt="sold out" />
                                                </div>
                                                <div class="coming_box" v-if="product.product.status == 4">
                                                    <img src="/images/badge/us/pc_coming_soon.png" alt="sold out" />
                                                </div>
                                                <div class="badge_box" v-if="product.badges.image_link != ''">
                                                    <img :src="product.badges.image_link" alt="sold out" />
                                                </div>
                                            </div>
                                        </a>
                                        <div class="flex flex-col justify-between flex-grow relative px-2">
                                            <a :href="'/product/item/'+product.seq_pd" class="block">
                                                <div class=" xl:text-base lg:text-base text-sm font-semibold leading-6 text-gray-800 mb-2">{{ product.product.pdt_name }}</div>
                                                <div class="text-red-500 text-xs lg:text-sm" v-if="product.product.deli_date2" v-html="product.product.deli_date2"></div>
                                                <p class="text-xs ">
                                                    USD ${{ formatPrice(product.prices.amt,2) }} /
                                                    <span class="text-blue-500">PV {{ formatPrice(product.prices.pv,2) }}</span>
                                                </p>
                                                <div class="text-right absolute bottom-0 right-0 z-[5]">
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="product.master_seq == null" class="xl:flex lg:flex hidden justify-center xl:w-2/12 lg:w-2/12 p-2 items-center">
                                    <div class="flex items-center px-4 sm:px-3 font-semibold font-heading text-gray-500 border border-gray-300 focus:ring-blue-300 focus:border-blue-300 rounded-md bg-white">
                                        <button @click="decrement($event,i)" class="py-2 sm:py-1" v-if="product.master_seq == null">
                                            <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-[#2c7fc3]"><g opacity="1"><rect x="12" width="2" height="12" transform="rotate(90 12 0)" fill="currentColor"></rect></g></svg>
                                        </button>
                                        <input type="number" name="qty" v-model="product.qty" @input="changeQty($event,i)"
                                               :readonly="product.master_seq !=null"
                                               class="w-12 m-0 xl:px-2 xl:py-2 lg:px-2 lg:py-2 p-1 text-center border-0 focus:ring-transparent focus:outline-none rounded-md" id="custom-input-number">
                                        <button @click="increment($event,i)" class="py-2 sm:py-1" v-if="product.master_seq == null">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-[#2c7fc3]"><g opacity="1"><rect x="5" width="2" height="12" fill="currentColor"></rect><rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)" fill="currentColor"></rect></g></svg>
                                        </button>
                                    </div>
                                </div>
                                <div v-if="product.master_seq == null" class="text-center xl:w-2/12 lg:w-2/12 font-semibold text-md p-2 hidden xl:flex lg:flex xl:flex-col lg:flex-col justify-center">
                                    <p class="text-sm xl:text-md lg:text-md ">USD ${{ formatPrice(product.prices.amt * product.qty,2) }}</p>
                                    <p class="text-sm xl:text-md lg:text-md text-blue-500">PV {{ formatPrice(product.prices.pv * product.qty,2) }}</p>
                                </div>
                                <!-- S : 장바구니 삭제-->
                                <div class="text-center xl:w-1/12 lg:w-1/12 font-semibold text-md p-2 hidden xl:flex lg:flex xl:flex-col lg:flex-col justify-center" v-if="product.master_seq == null">
                                    <a href="#" v-on:click.prevent="removeItem($event,i)" class="border border-gray-300 xl:flex lg:flex hidden w-8 h-8  items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="hover:text-red-500 text-gray-500 text-xs w-4 h-4"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                                    </a>
                                </div>
                               <!-- E : 장바구니 삭제-->
                                <!-- 모바일 카운트 토탈 -->
                                <div v-if="product.master_seq == null" class="xl:hidden lg:hidden flex xl:justify-around lg:justify-around justify-between xl:mt-0 lg:mt-0 mt-4">
                                    <div class="flex justify-center xl:w-1/2 lg:w-1/2 w-full p-2 ">
                                        <div class="inline-flex items-center px-4 sm:px-3 font-semibold font-heading text-gray-500 border border-gray-300 focus:ring-blue-300 focus:border-blue-300 rounded-md bg-white">
                                            <button @click="decrement($event,i)" class="py-2 sm:py-1">
                                                <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-[#2c7fc3]"><g opacity="1"><rect x="12" width="2" height="12" transform="rotate(90 12 0)" fill="currentColor"></rect></g></svg>
                                            </button>
                                            <input type="number" name="qty" v-model="product.qty" @input="changeQty($event,i)" class="w-12 m-0 xl:px-2 xl:py-2 lg:px-2 lg:py-2 p-1 text-center border-0 focus:ring-transparent focus:outline-none rounded-md" id="custom-input-number">
                                            <button @click="increment($event,i)" class="py-2 sm:py-1">
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-[#2c7fc3]"><g opacity="1"><rect x="5" width="2" height="12" fill="currentColor"></rect><rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)" fill="currentColor"></rect></g></svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="text-center xl:w-1/2 lg:w-1/2 w-full font-semibold text-md p-2 ">
                                        <p class="text-sm xl:text-md lg:text-md ">USD ${{ formatPrice(product.prices.amt * product.qty,2) }}</p>
                                        <p class="text-sm xl:text-md lg:text-md text-blue-500">{{ formatPrice(product.prices.pv * product.qty,2) }}PV</p>
                                    </div>
                                </div>
                            </div>

                            <!-- S : 자율구성상품-->
                            <div v-for="(mst, i) in config_mst_list" :key="mst.seq">
                              <div class="flex xl:flex-row lg:flex-row flex-col border-t border-slate-200 last:border-b-0 xl:py-5 lg:py-5 pb-1 w-full">
                                <div class="xl:flex lg:flex block  lg:w-7/12 w-full xl:py-5">
                                  <span class="p-2 flex items-center justify-between">
                                    <input type="checkbox" :name="mst.seq_pd" :value="mst.seq_pd" v-model="selectedItems" class="cursor-pointer text-[#4e64ad]">
                                    <a href="#" v-on:click.prevent="removeItem($event,i)" class="w-5 font-semibold hover:text-red-500 text-gray-500 text-xs xl:border lg:border border-slate-400 inline-block p-1 xl:hidden lg:hidden block">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                                    </a>
                                  </span>
                                  <div class="flex w-full xl:px-0 lg:px-0 px-2">
                                    <a :href="'/product/item/'+mst.seq_pd" class="inline-block">
                                      <div class="relative xl:w-24 lg:w-24 xl:h-24 lg:h-24 w-20 h-20 aspect-w-1 aspect-h-1 border rounded-md overflow-hidden">
                                        <img class="xl:w-24 lg:w-24 xl:h-24 lg:h-24 w-20 h-20 max-w-none" v-bind:src="'https://cdnus.gcoop.me/public' + mst.pdt_info.images[0].path + '/' + mst.pdt_info.images[0].filename" v-bind:alt=" mst.pdt_info.images[0].filename " alt="">
                                        <div class="sold-out-box2" v-if="mst.pdt_info.status == 2">
                                          <img src="/images/badge/us/pc_temp_sold_out.png" alt="sold out" />
                                        </div>
                                        <div class="sold-out-box2" v-if="mst.pdt_info.status == 3">
                                          <img src="/images/badge/us/pc_sold_out.png" alt="sold out" />
                                        </div>
                                        <div class="coming_box" v-if="mst.pdt_info.status == 4">
                                          <img src="/images/badge/us/pc_coming_soon.png" alt="sold out" />
                                        </div>
                                        <div class="badge_box" v-if="mst.pdt_info.image_link != ''">
                                          <img :src="mst.pdt_info.image_link" alt="sold out" />
                                        </div>
                                      </div>
                                    </a>
                                    <div class="flex flex-col justify-between flex-grow relative px-2">
                                      <a :href="'/product/item/'+mst.pdt_info.seq_pd" class="block">
                                        <div class=" xl:text-base lg:text-base text-sm font-semibold leading-6 text-gray-800 mb-2">{{ mst.pdt_info.pdt_name }}</div>
                                        <div class="text-red-500 text-xs lg:text-sm" v-if="mst.pdt_info.deli_date2" v-html="mst.pdt_info.deli_date2"></div>
                                        <p class="text-xs ">
                                          USD ${{ formatPrice(mst.pdt_info.prices.amt,2) }} /
                                          <span class="text-blue-500">PV {{ formatPrice(mst.pdt_info.prices.pv,2) }}</span>
                                        </p>
                                        <div class="text-right absolute bottom-0 right-0 z-[5]">
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div class="xl:flex lg:flex hidden justify-center xl:w-2/12 lg:w-2/12 p-2 items-center">
                                  <div class="flex items-center px-4 sm:px-3 font-semibold font-heading text-gray-500 border border-gray-300 focus:ring-blue-300 focus:border-blue-300 rounded-md bg-white">
                                    <input type="number" name="qty" v-model="mst.mst_total_qty"  readonly class="w-12 m-0 xl:px-2 xl:py-2 lg:px-2 lg:py-2 p-1 text-center border-0 focus:ring-transparent focus:outline-none rounded-md item-amount">
                                  </div>
                                </div>
                                <!-- S : 상품가격-->
                                <div class="text-center xl:w-2/12 lg:w-2/12 font-semibold text-md p-2 hidden xl:flex lg:flex xl:flex-col lg:flex-col justify-center">
                                  <p class="text-sm xl:text-md lg:text-md ">USD ${{ formatPrice(mst.pdt_info.prices.amt * mst.mst_total_qty,2) }}</p>
                                  <p class="text-sm xl:text-md lg:text-md text-blue-500">PV {{ formatPrice(mst.pdt_info.prices.pv * mst.mst_total_qty,2) }}</p>
                                </div>
                                <!-- E : 상품가격-->
                                <!-- S : 장바구니 삭제-->
                                <div class="text-center lg:w-1/12 font-semibold text-md p-2 hidden xl:flex lg:flex xl:flex-col lg:flex-col justify-center">
                                  <a href="#" v-on:click.prevent="removeItem($event,mst.seq,'Y')" class="border border-gray-300 xl:flex lg:flex hidden w-8 h-8  items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="hover:text-red-500 text-gray-500 text-xs w-4 h-4"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                                  </a>
                                </div>
                                <!-- E : 장바구니 삭제-->
                                <!-- 모바일 카운트 토탈 -->
                                <div class="xl:hidden lg:hidden flex xl:justify-around lg:justify-around justify-between xl:mt-0 lg:mt-0 mt-4">
                                  <div class="flex justify-center xl:w-1/2 lg:w-1/2 w-full p-2 ">
                                    <div class="inline-flex items-center px-4 sm:px-3 font-semibold font-heading text-gray-500 border border-gray-300 focus:ring-blue-300 focus:border-blue-300 rounded-md bg-white">
                                      <input type="number" name="qty" readonly v-model="mst.mst_total_qty" class="w-12 m-0 xl:px-2 xl:py-2 lg:px-2 lg:py-2 p-1 text-center border-0 focus:ring-transparent focus:outline-none rounded-md item-amount" id="custom-input-number">
                                    </div>
                                  </div>
                                  <div class="text-center xl:w-1/2 lg:w-1/2 w-full font-semibold text-md p-2 ">
                                    <p class="text-sm xl:text-md lg:text-md ">USD ${{ formatPrice(mst.pdt_info.prices.amt * mst.mst_total_qty,2) }}</p>
                                    <p class="text-sm xl:text-md lg:text-md text-blue-500">{{ formatPrice(mst.pdt_info.prices.pv * mst.mst_total_qty,2) }}PV</p>
                                  </div>
                                </div>
                              </div>
                              <!-- S : 구성품 -->
                              <div v-for="(detail, j) in config_pdt_info[mst.seq]" :key="detail" class="pl-10">
                                  <div class="flex xl:flex-row lg:flex-row flex-col w-full">
                                    <div class="xl:flex lg:flex block lg:w-11/12 w-full py-1">
                                      <div class="flex xl:px-0 lg:px-0 px-2">
                                        <a :href="'/product/item/'+mst.seq_pd" class="inline-block">
                                          <div class="relative xl:w-24 lg:w-24 xl:h-24 lg:h-24 w-20 h-20 aspect-w-1 aspect-h-1 border rounded-md overflow-hidden">
                                            <img class="xl:w-24 lg:w-24 xl:h-24 lg:h-24 w-20 h-20 max-w-none	" v-bind:src="'https://cdnus.gcoop.me/public' + detail.images[0].path + '/' + detail.images[0].filename" v-bind:alt=" detail.images[0].filename " alt="">
                                          </div>
                                        </a>
                                        <div class="lg:hidden text-sm font-semibold padding-pdt-name">
                                          <div class=" xl:text-base lg:text-base leading-5 text-gray-800 mb-2">{{ detail.product.pdt_name }}</div>
                                          <div>QTY : {{ detail.qty }}</div>
                                        </div>

                                      </div>
                                      <div class="xl:flex lg:flex flex-col justify-between flex-grow relative px-2 hidden ">
                                        <div class=" xl:text-base lg:text-base text-sm font-semibold leading-6 text-gray-800 mb-2">{{ detail.product.pdt_name }}</div>
                                      </div>
                                      <div class="xl:flex lg:flex hidden justify-center xl:w-2/12 lg:w-2/12 p-2 items-center">
                                        <div class="flex items-center px-4 sm:px-3 font-semibold font-heading text-gray-500 border border-gray-300 focus:ring-blue-300 focus:border-blue-300 rounded-md bg-white">
                                          <input type="number" name="qty" v-model="detail.qty" readonly
                                                 class="w-12 m-0 xl:px-2 xl:py-2 lg:px-2 lg:py-2 p-1 text-center border-0 focus:ring-transparent focus:outline-none rounded-md">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                            <!-- E : 구성품 -->
                           <!-- E : 자율구성상품-->
                            <div class="flex justify-center my-4">
                                <a href="/product/list/all" v-on:click.prevent="prevUrl" class="font-semibold text-blue-500 text-sm">
                                    <!--                                <svg class="fill-current mr-2 text-blue-500 w-4" viewBox="0 0 448 512"><path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"/></svg>-->
                                    Add Products +
                                </a>
                            </div>
                        </section>


                        <div class="w-full text-center mb-12 pt-3 " v-if="isEnterProduct==false">
                            <button @click="isEnterProduct = !isEnterProduct" class="bg-[#2c80c4] text-white px-10 py-2 rounded-xl">View Product Details</button>
                        </div>


                        <div class="w-full text-center mb-12 mt-3">
                            <button v-if="false && !isEnterShipping" @click="isEnterShipping = !isEnterShipping" class="bg-[#2c80c4] text-white px-10 py-2 rounded-xl">Enter shipping information</button>
                            <button v-if="false && isEnterShipping" @click="isEnterShipping = !isEnterShipping" class="bg-gray-200 text-white px-10 py-2 rounded-xl">Close shipping information</button>
                        </div>
                        <div class="mb-12 bg-white shadow-zinc-500/40 shadow-md rounded-md p-6" v-show="is_giftcard == 0">
                            <div class="flex justify-between border-b pb-8">
                                <h1 class="text-lg xl:text-xl lg:text-xl font-semibold leading-5 text-gray-800 border-l-[10px] border-[#4493d5] pl-3">Shipping Address</h1>
                            </div>
                            <form name="shipping_info" class="justify-center w-full mx-auto" method="post">
                                <input type="hidden" name="_token" :value="csrf">
                                <input type="hidden" name="order_seq">
                                <input type="hidden" name="shipping_fee">
                                <input type="hidden" name="tax_fee">
                                <input type="hidden" name="pay_total_amt">
                                <input type="hidden" name="postcode" :value='this.shipping_info["d_post"]'>
                                <input type="hidden" name="city" :value='this.shipping_info["d_city"]'>
                                <input type="hidden" name="state" :value='this.shipping_info["d_state"]'>
                                <input type="hidden" name="address2_1" :value='this.shipping_info["d_address"]'>
                                <input type="hidden" name="address2_2" :value='this.shipping_info["d_address2"]'>
                                <div class="mt-10">

                                    <div class="space-x-0 sm:flex lg:space-x-4">
                                        <div class="w-full flex sm:w-1/4" >
                                            <input name="order-info" id="order-info1" ref="order-info1" type="radio" class="mt-0.5" value="1" @click="change_shipping('Same')" checked>
                                            <label for="order-info1" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                Same as My information</label>
                                        </div>
                                        <div class="w-full flex sm:w-1/4">
                                            <input name="order-info" id="order-info2" type="radio" class="mt-0.5" value="2" @click="change_shipping('Enroll')" >
                                            <label for="order-info2" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                Enter New Address</label>
                                        </div>
                                        <div class="w-full flex sm:w-1/4" >
                                            <input name="order-info" id="order-info3" type="radio" class="mt-0.5" value="3" @click="change_shipping('Manage')">
                                            <label for="order-info3" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                Manage addresses</label>
                                        </div>
                                        <div class="w-full flex sm:w-1/4">
                                          <input name="order-info" id="order-info4" type="radio" class="mt-0.5" value="4" @click="change_shipping('pickup')">
                                          <label for="order-info4" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                              Pickup
                                          </label>
                                        </div>
                                    </div>

                                    <div class="mt-6 space-x-0 lg:flex lg:space-x-4">
                                        <div class="md-input-box w-full lg:w-1/3 mt-6">
                                            <input name="d_firstName" id="d_firstName" ref="d_firstname" type="text" placeholder=" " v-model='this.shipping_info["d_firstname"]'
                                                   class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                            <label for="d_firstName" class="md-label block mb-6 text-sm font-semibold text-gray-500">Reciever(First Name)</label>
                                        </div>
                                        <div class="md-input-box w-full lg:w-1/2 mt-6">
                                          <input name="d_lastname" id="d_lastname" ref="d_lastname" type="text" placeholder=" " v-model='this.shipping_info["d_lastname"]'
                                                 class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                          <label for="d_lastname" class="md-label block mb-6 text-sm font-semibold text-gray-500">Reciever(Last Name)</label>
                                        </div>
                                        <div class="md-input-box w-full lg:w-1/2 mt-6 ">
                                            <input name="mobile" id="mobile" ref="d_mobile" type="number" @keyup="regNumber('d_mobile')" placeholder=" " v-model='this.shipping_info["d_mobile"]'
                                                   class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                            <label for="mobile" class="md-label block mb-3 text-sm font-semibold text-gray-500">Mobile</label>
                                        </div>
                                    </div>

                                    <div class="mt-4" v-show="deli_kind !='1'">
                                        <input type="button" @click="search_address('M')" value="search address" class="bg-[#2c80c4] font-semibold py-3 text-sm text-white uppercase w-full">
                                    </div>

                                    <div v-show="deli_kind =='1'" class="text-red-500 mt-6">
                                        * You will not be able to pick up if you do not specify the location and time.
                                    </div>
                                    <div v-show="deli_kind =='1'" class="mt-3 space-x-0 lg:flex lg:space-x-4">
                                        <div class="md-input-box w-full lg:w-1/2 pb-2 lg:pb-0 z-11">
                                            <vSelect  v-show="deli_kind =='1'" name="state" id="pickup_list" ref="center" :options="pickup_list" placeholder="Select" label="pickup_name" :reduce="value => value.pickup_cd" v-model='pickup_cd' :clearable="false"  class="block border border-gray-300 rounded w-full text-gray-600 text-sm z-[20]"></vSelect>
                                        </div>
                                        <div class="md-input-box w-full lg:w-1/2 pb-2 lg:pb-0">
                                            <datepicker id="s_date" name="s_date" v-model="pickup_date" :min-date="start_date"  :max-date="end_date" week-start="0" :disabled-week-days="[6, 0]" inputClassName="myo_date_input" locale="en-US" :format="format" model-type="yyyy-MM-dd" autoApply :enableTimePicker="false" :clearable="false"  ></datepicker>
                                        </div>
                                        <div class="md-input-box  w-full lg:w-1/2 z-10">
                                            <vSelect  v-show="deli_kind =='1'" name="state" id="pickup_time_list" ref="center" :options="pickup_time_list" placeholder="Select" label="pickup_name" :reduce="value => value.pickup_cd" v-model='pickup_time_cd' :clearable="false"  class="block border border-gray-300 rounded w-full text-gray-600 text-sm"></vSelect>
                                        </div>
                                    </div>
                                    <div v-show="deli_kind =='1'" class="mt-6">
                                        <spa>
<!--                                            3PL : 6710 Wilson Ave, Los Angeles, CA 90001<br>-->
                                            LA Office : 3435 Wilshire Blvd Ste 2125, Los Angeles, CA 90010<br>
                                            <!--GSC : 5835 E 61st st COMMERCE, CA, 90040-->
                                        </spa>
                                    </div>

                                    <div v-show="deli_kind !='1'" class="mt-6 space-x-0 lg:flex lg:space-x-4">
                                      <div class="md-input-box w-full lg:w-1/2 mt-6">
                                        <input name="address" id="address" ref="d_address" type="text" placeholder=" " :readonly="deli_kind =='1'" v-model='this.shipping_info["d_address"]'
                                               @keyup="regText('d_address')"  class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="40">
                                        <label for="address" class="md-label block mb-6 text-sm font-semibold text-gray-500">Address Line1</label>
                                        <div class="text-xs text-red-400 pl-1">Street Address</div>
                                      </div>
                                      <div class="md-input-box mt-6 w-full lg:w-1/2">
                                        <input name="address2" id="address2" ref="d_address2" type="text" placeholder=" " :readonly="deli_kind =='1'" v-model='this.shipping_info["d_address2"]'
                                               @keyup="regText('d_address2')"  class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="40">
                                        <label for="address2" class="md-label block mb-6 text-sm font-semibold text-gray-500">Address Line2</label>
                                        <div class="text-xs text-red-400 pl-1">Apt, Ste, Unit, etc.</div>
                                      </div>
                                    </div>
                                    <div v-show="deli_kind !='1'" class="mt-6 space-x-0 lg:flex lg:space-x-4">
                                        <div class="md-input-box relative z-[13] mt-6 w-full lg:w-1/2 ">
                                          <input name="city" id="city" ref="d_city" type="text" placeholder=" " v-model='this.shipping_info["d_city"]' readonly @click="search_address('M')"
                                                 class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="40">
                                          <label for="city" class="md-label block mb-6 text-sm font-semibold text-gray-500">City</label>
                                        </div>
                                        <div class="md-input-box relative z-[14] w-full lg:w-1/2 mt-6">
                                            <input name="address" id="state" ref="d_state" type="text" placeholder=" " v-model='this.shipping_info["d_state"]' readonly @click="search_address('M')"
                                                   class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="40">
                                            <label for="state" class="md-label block mb-6 text-sm font-semibold text-gray-500">State</label>
                                        </div>
                                    </div>
                                    <div v-show="deli_kind !='1'" class="mt-6 space-x-0 lg:flex lg:space-x-4">
                                        <div class="md-input-box relative z-[11] mt-6 w-full lg:w-1/2 mt-6">
                                            <input name="postcode" id="postcode" ref="postcode" type="text" placeholder=" " v-model='this.shipping_info["d_post"]' readonly @click="search_address('M')"
                                                   class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 cursor-pointer" maxlength="40">
                                            <label for="postcode" class="md-label block mb-6 text-sm font-semibold text-gray-500">Zip Code</label>
                                        </div>
                                        <div class="md-input-box relative z-[14] w-full lg:w-1/2 mt-6">
                                          <input ref="country" type="text" placeholder=" " v-model='this.shipping_info["country"]' readonly @click="search_address('M')"
                                                 class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="40">
                                          <label for="country" class="md-label block mb-6 text-sm font-semibold text-gray-500">Country</label>
                                        </div>
                                    </div>
                                    <div v-show="deli_kind !='1'" class="relative pt-3 xl:pt-6" v-if="msg_post !== ''">
                                        <div class="text-red-500">{{ this.msg_post }}</div>
                                    </div>
                                    <div class="relative pt-3 xl:pt-6 hidden">
                                        <label for="note" class="block mb-3 text-sm font-semibold text-gray-500"> Notes (Optional)</label>
                                        <textarea name="note" id="note" rows="4" placeholder="" class="flex items-center w-full px-4 py-3 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-600"></textarea>
                                    </div>
<!--                                    <div class="text-red-500 font-semibold text-sm mt-4">※ Please do not enter duplicate address information in the two fields below(Ex. State, City, Street, Post code). Only detailed information should be entered in the two fields below(Building, House etc).</div>-->
                                </div>
                            </form>
                        </div>
                        <!-- S : Billing Address-->
                        <div class="mb-12 bg-white shadow-zinc-500/40 shadow-md rounded-md p-6" v-if="isEnterShipping">
                          <div class="flex justify-between border-b pb-8">
                            <h1 class="text-lg xl:text-xl lg:text-xl font-semibold leading-5 text-gray-800 border-l-[10px] border-[#4493d5] pl-3">Billing Address</h1>
                          </div>
                          <div class="mt-10">
                            <div class="space-x-0 sm:flex lg:space-x-4">
                              <div class="w-full flex sm:w-1/3" >
                                <input name="billing-info" id="billing-info1" type="radio" class="mt-0.5" value="1" @click="change_billing('Same')" checked>
                                <label for="billing-info1" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                  Same as My information</label>
                              </div>
                                <div class="w-full flex sm:w-1/3" >
                                    <input name="billing-info" id="billing-info4" type="radio" class="mt-0.5" value="4" @click="change_billing('Above')">
                                    <label for="billing-info4" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                        Same as Above</label>
                                </div>
                                <div class="w-full flex sm:w-1/3">
                                <input name="billing-info" id="billing-info-info2" type="radio" class="mt-0.5" value="2" @click="change_billing('Enroll')" >
                                <label for="billing-info-info2" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                    Enter New Address</label>
                              </div>
                              <div class="w-full flex sm:w-1/3" >
                                <input name="billing-info" id="billing-info-info3" type="radio" class="mt-0.5" value="3" @click="change_billing('Manage')">
                                <label for="billing-info-info3" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                  Manage addresses</label>
                              </div>
                            </div>

                            <div class="mt-6 space-x-0 lg:flex lg:space-x-4">
                              <div class="md-input-box w-full lg:w-1/3 mt-6">
                                <input type="text" placeholder=" " v-model='o_firstname'
                                       class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                <label class="md-label block mb-6 text-sm font-semibold text-gray-500">First Name</label>
                              </div>
                              <div class="md-input-box w-full lg:w-1/3 mt-6">
                                <input type="text" placeholder=" " v-model='o_lastname'
                                       class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                <label class="md-label block mb-6 text-sm font-semibold text-gray-500">Last Name</label>
                              </div>
                              <div class="md-input-box w-full lg:w-1/3 mt-6 ">
                                <input type="number" @keyup="regNumber('d_mobile')" placeholder=" " v-model='o_mobile'
                                       class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                <label class="md-label block mb-3 text-sm font-semibold text-gray-500">Mobile</label>
                              </div>
                            </div>

                            <div class="mt-4">
                              <input type="button" @click="search_address('B')" value="search address" class="bg-[#2c80c4] font-semibold py-3 text-sm text-white uppercase w-full">
                            </div>
                            <div class="mt-6 space-x-0 lg:flex lg:space-x-4">
                              <div class="md-input-box w-full lg:w-1/2 mt-6">
                                <input type="text" placeholder=" " v-model='o_addr2_1'
                                       @keyup="regText('o_addr2_1')"  class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="40">
                                <label class="md-label block mb-6 text-sm font-semibold text-gray-500">Address Line1</label>
                                <div class="text-xs text-red-400 pl-1">Street Address</div>
                              </div>
                              <div class="md-input-box mt-6 w-full lg:w-1/2">
                                <input type="text" placeholder=" " v-model='o_addr2_2'
                                       @keyup="regText('o_addr2_2')"  class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="40">
                                <label class="md-label block mb-6 text-sm font-semibold text-gray-500">Address Line2</label>
                                <div class="text-xs text-red-400 pl-1">Apt, Ste, Unit, etc.</div>
                              </div>
                            </div>
                            <div class="mt-6 space-x-0 lg:flex lg:space-x-4">
                              <div class="md-input-box relative z-[13] mt-6 w-full lg:w-1/2 ">
                                <input type="text" placeholder=" " v-model='o_city' readonly @click="search_address('B')"
                                       class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="40">
                                <label class="md-label block mb-6 text-sm font-semibold text-gray-500">City</label>
                              </div>
                              <div class="md-input-box relative z-[14] w-full lg:w-1/2 mt-6">
                                <input type="text" placeholder=" " v-model='o_state' readonly @click="search_address('B')"
                                       class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="40">
                                <label class="md-label block mb-6 text-sm font-semibold text-gray-500">State</label>
                              </div>
                            </div>
                            <div class="mt-6 space-x-0 lg:flex lg:space-x-4">
                              <div class="md-input-box relative z-[11] mt-6 w-full lg:w-1/2 mt-6">
                                <input type="text" placeholder=" " v-model='o_post' readonly @click="search_address('B')"
                                       class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 cursor-pointer" maxlength="40">
                                <label class="md-label block mb-6 text-sm font-semibold text-gray-500">Zip Code</label>
                              </div>
                              <div class="md-input-box relative z-[14] w-full lg:w-1/2 mt-6">
                                <input type="text" placeholder=" " v-model='o_country' readonly @click="search_address('B')"
                                       class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="40">
                                <label class="md-label block mb-6 text-sm font-semibold text-gray-500">Country</label>
                              </div>
                            </div>
                            <div class="relative pt-3 xl:pt-6" v-if="msg_post !== ''">
                              <div class="text-red-500">{{ this.msg_post }}</div>
                            </div>
                            <div class="relative pt-3 xl:pt-6 hidden">
                              <label for="note" class="block mb-3 text-sm font-semibold text-gray-500"> Notes (Optional)</label>
                              <textarea name="note" id="note" rows="4" placeholder="" class="flex items-center w-full px-4 py-3 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-600"></textarea>
                            </div>
                            <!--                                    <div class="text-red-500 font-semibold text-sm mt-4">※ Please do not enter duplicate address information in the two fields below(Ex. State, City, Street, Post code). Only detailed information should be entered in the two fields below(Building, House etc).</div>-->
                          </div>
                        </div>
                        <!-- E : Billing Address-->
                    </div>

                    <div id="summary" class="w-full sm:w-1/4 xl:px-4 lg:px-4 px-0">
                        <div class="px-6 py-6 bg-white shadow-zinc-500/40 shadow-md rounded-md">
                            <h1 class="text-lg xl:text-xl lg:text-xl  font-semibold leading-5 text-gray-800 border-l-[10px] border-[#4493d5] pl-3">Order Summary</h1>
                            <div class="flex justify-between mt-10 mb-5">
                                <label class="font-semibold uppercase py-1 w-1/3">Items ({{ products.length }})</label>
                                <div class="font-semibold w-2/3">
                                    <p class="hidden text-base text-right">USD ${{ formatPrice(sum_item.price,2) }}</p>
                                    <p class="hidden text-blue-500 text-base text-right">USD ${{ formatPrice(sum_item.vat,1) }}</p>
                                    <p class="text-base text-right">USD ${{ formatPrice(sum_item.amt,2) }}</p>
                                    <p v-show="false" class="text-gray-700 text-sm text-right">{{ formatPrice(total_weight,3) }} lbs</p>
                                </div>
                            </div>
                            <div class="flex justify-between mt-10 mb-5" v-show="is_giftcard == 0">
                                <label class="font-semibold uppercase py-1 w-1/3">PV</label>
                                <div class="font-semibold w-2/3">
                                    <p class="text-blue-500 text-base text-right">{{ formatPrice(sum_item.bv,2) }}</p>
                                </div>
                            </div>
                            <!--                        <div class="flex justify-between mt-10 mb-5"  v-if="fee_shipping.length">
                                                        <label class="font-semibold uppercase py-1 w-1/2">Global Shipping</label>
                                                        <div class="font-semibold w-1/2">
                                                            <p class="text-lg">USD $0</p>
                                                            <p class="text-[#4f65ae] text-sm">FREE Shipping</p>
                                                        </div>
                                                    </div>-->
<!--                            <div class="flex justify-between mt-10 mb-5" @click="tax_detail">
                                <label class="font-semibold uppercase py-1 w-1/3">Tax detail <i class="fal fa-question-circle cursor-pointer" aria-hidden="true"></i></label>
                                <div class="font-semibold w-2/3">
                                    <p class="text-lg text-right">$ {{ formatPrice(sum_item.vat,2) ?? "Calculating,," }}</p>
                                </div>
                            </div>-->
                            <div class="flex justify-between mt-10 mb-5" v-if="false && deli_kind !='1'">
                                <label class="font-semibold uppercase py-1 w-1/3">Shipping</label>
                                <div class="font-semibold w-2/3">
                                    <p class="text-lg text-right">USD ${{ formatPrice(ord_deli_price,2) }}</p>
<!--                                    <p class="text-[#4f65ae] text-sm text-right">{{ fee_shipping[0].text }}</p>-->
                                </div>
                            </div>
                            <div class="flex justify-between mt-10 mb-5"  v-if="false && insurance_yn == 'Y' && deli_kind !='1'">
                              <label class="font-semibold uppercase py-1 w-1/3">Insurance Fee</label>
                              <div class="font-semibold w-2/3">
                                <p class="text-lg text-right">USD ${{ formatPrice(insurance_price,2) }}</p>
                              </div>
                            </div>
                            <div v-if="deli_kind !='1' " v-show="is_giftcard == 0">
                              <div class="flex flex-row ups_kind2" v-if='this.shipping_info["country"] == "USA"'>
                                <input name="ups_kind" id="ups_kind2" type="checkbox" class="mt-0.5" true-value="2" false_value="1" v-model="ups_kind" @click="changeUps('2')">
                                <label for="ups_kind2" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">UPS Signature(+$5.25)</label>
                              </div>
                            </div>
                            <div v-if="deli_kind !='1'" v-show="is_giftcard == 0">
                              <div class="flex flex-row">
                                <input type="checkbox" id="ups_kind3" class="mt-0.5" v-model="insurance_yn" true-value="Y" false_value="N">
                                <label for="ups_kind3" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                  UPS Insurance
                                </label>
                                  <VTooltip class="inline-block">
                                      <i class="fal fa-question-circle cursor-pointer" aria-hidden="true"></i>
                                      <template #popper>
                                          Members of GCOOP USA can select UPS’s insurance service when ordering.
                                          This service offers financial reimbursement if a package is lost, stolen, or damaged during the transit process.
                                          If one chooses to opt out of insurance, GCOOP USA will not take responsibility for damaged, lost or stolen products.
                                      </template>
                                  </VTooltip>
                              </div>
                            </div>
                            <div class="border-t mt-8" v-show="is_giftcard == 0 && isSavePoint===true">
                              <div class="flex justify-between py-6">
                                <label class="font-semibold uppercase py-1 w-1/3">Earned<br>Points</label>
                                <div class="font-semibold w-2/3">
                                  <p class="text-lg text-right">{{ formatPrice(point,2) }}</p>
                                </div>
                              </div>
                            </div>
                        </div>
                        <button v-show="gift_card == false" @click="order" class="bg-[#2c80c4] hover:bg-[#2c80c4] font-semibold py-3 text-sm text-white uppercase w-full mt-4">PROCEED TO PAYMENT</button>
                        <button v-show="gift_card == true && gift_card_pay == true" @click="order" class="bg-[#2c80c4] hover:bg-[#2c80c4] font-semibold py-3 text-sm text-white uppercase w-full mt-4">PROCEED TO PAYMENT</button>
                        <div v-show="gift_card == true && gift_card_pay == false">
                            카드는 다른상품과 같이 결제 할 수 없습니다.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
/* javascript numberFormat library */
import numeral from 'numeral';
import 'numeral/locales';
import vSelect from "vue-select";
import alert from "vue3-simple-alert";
import modalView from "../modalView";
import VueSimpleAlert from "vue3-simple-alert";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import Swal from 'sweetalert2'
import { Tooltip } from "floating-vue";
import datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


export default {
    name: "Cart",
    components : {
        datepicker,
        // address_book,
        vSelect,
        alert,
        modalView,
        Loading,
        Swal,
        VTooltip : Tooltip
    },
    props: ['orderer','fail_message','mem_center','last_order_ceter','is_giftcard'],
    setup() {
        return {
            // products,
        }
    },
    watch: {
      insurance_yn : function (val){
        if(val == 'Y'){
          this.insurance_yn = "Y";
        }else{
          this.insurance_yn = "N";
        }
      },
      ups_kind : function (val){
        if(val == '1'){
          this.ord_deli_price = this.ground_price;
        }else if(val == '2'){
          this.ord_deli_price = this.signature_price;
        }
      },
        pickup_cd: function(val){
            if(val != '0'){
                this.pickup_list.forEach((value, index) => {
                    if(value.pickup_cd == val){
                        this.shipping_info["d_state"] = value.state;
                        this.shipping_info["d_city"] = value.city;
                        this.shipping_info["d_post"] = value.zip_code;
                        this.shipping_info["d_address"] = value.addressLine1;
                        this.shipping_info["d_address2"] = value.addressLine2;
                        this.shipping_info["country"] = "USA";

                    }
                });

            }
        }
    },
    data: () => ({
        title: [],
        products: [],
        max_qty: 99,
        sum_item: [],
        total_cost: 0,
        total_weight: 0,
        fee_shipping: [],
        shipping_selected: 0,
        fee_tax: [],
        tax_selected: 0,
        sub_delipay:0,
        isEnterShipping: true,
        isEnterProduct: true,
        shipping_info: [],
        csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
        selectedItems: [],
        state_list: [],
        city_list: [],
        street_list: [],
        post_list: [],
        center_list: [],
        msg_post:'',
        address_book_open: Boolean,  // 주소록 레이어 오픈
        loading:false,
        address_search_open:false,
        tax_detail_open : false,
        gold_mall_categories : window.Laravel.gold_mall_categories,
        gold_check : window.Laravel.gold_check,
        is_order_possible : window.Laravel.is_order_possible,
        isSavePoint : window.Laravel.isSavePoint,
        other_products : [],
        commition_cnt_cd : "",
        radioCondition : "1",
        ups_kind : "1",
        insurance_yn : "",
        insurance_price : 0,
        point : 0,
        o_firstname : "",
        o_lastname : "",
        o_mobile : "",
        o_post : "",
        o_city : "",
        o_state : "",
        o_addr2_1 : "",
        o_addr2_2 : "",
        o_country : "",
        address_kind : "M",
        deli_kind : "",
        ord_deli_price : "",
        signature_price : 0,
        ground_price : 0,
        gel_price : 0,
        pickup_list : [
            {"pickup_cd" : "0","pickup_name" : "Select Pickup"},
            {"pickup_cd" : "2","pickup_name" : "LA OFFICE","zip_code" : "90010","city" : "Los Angeles","state" : "CA","addressLine1" : "3435 Wilshire Blvd","addressLine2" : "Ste 2125"},
            //{"pickup_cd" : "3","pickup_name" : "GSC","zip_code" : "90040","city" : "COMMERCE","state" : "CA","addressLine1" : "5835 E 61st st","addressLine2" : ""}
        ],
        pickup_time_list : [
            {"pickup_cd" : "0","pickup_name" : "Select Pickup Time"},
            {"pickup_cd" : "13:00~13:20","pickup_name" : "13:00~13:20"},
            {"pickup_cd" : "13:20~13:40","pickup_name" : "13:20~13:40"},
            {"pickup_cd" : "13:40~14:00","pickup_name" : "13:40~14:00"},
            {"pickup_cd" : "14:00~14:20","pickup_name" : "14:00~14:20"},
            {"pickup_cd" : "14:20~14:40","pickup_name" : "14:20~14:40"},
            {"pickup_cd" : "14:40~15:00","pickup_name" : "14:40~15:00"},
            {"pickup_cd" : "15:00~15:20","pickup_name" : "15:00~15:20"},
            {"pickup_cd" : "15:20~15:40","pickup_name" : "15:20~15:40"},
            {"pickup_cd" : "15:40~16:00","pickup_name" : "15:40~16:00"},
        ],
        gift_card : false,
        gift_card_pay : false,
        pickup_cd : "0",
        pickup_time_cd : "0",
        format : "MM/dd/yyyy",
        pickup_date : "",
        start_date : date_min_week,
        end_date : date_max_week,
        configure_yn : 'N',
        config_mst_list : [],
        config_pdt_info : [],
    }),
    created() {
        this.loading = true;
        this.address_book_open = false;
        this.change_shipping('');
        this.change_billing('');
        this.sum_item.price = 0;
        this.sum_item.vat = 0;
        this.sum_item.amt = 0;
        this.sum_item.bv = 0;
        this.sum_item.deliveryFee = 0;
        // 장바구니 가져오기
        this.getCart();
        // this.getCenterList();
        if(this.fail_message != ""){
            alert.alert(this.fail_message,"","",{returnFocus : false,  didClose: () => {
                    this.$refs.d_firstname.focus();
                }, didDestroy: ()=>{
                    this.$refs.d_firstname.focus();
                }
            }).then((result) => {
                if(result){
                    this.$refs.d_firstname.focus();
                }
            });
        }
    },
    mounted() {

    },
    methods:{
        regText(colum){
            var reg = /'/gi;
            var text = this.shipping_info[colum];
            text = text.replaceAll( reg, "");
            this.shipping_info[colum] = text;
        },
        regNumber(colum){
            var reg = /'/gi;
            var text = this.shipping_info[colum];
            text = text.replaceAll(/[^0-9]/g,'');
            this.shipping_info[colum] = text;
        },
        // 장바구니 가져오기
        getCart(){
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL+'/api/cart',{
                bz_cd: window.Laravel.bz_cd,
                is_giftcard : this.is_giftcard,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.products = response.data.cart_info;
                    if(response.data.config_mst_list !=null || response.data.config_mst_list !='undefined'){
                      this.config_mst_list = response.data.config_mst_list; //자율구성상품 mst
                      this.config_pdt_info = response.data.config_pdt_info; //자율구성상품 detail + product info
                    }
                    this.gift_card_pay = response.data.gift_card_pay;
                    this.gift_card = response.data.gift_card;
                    if(this.products != []) { // 상품이 있으면 배송비, 택스 처리
                        this.total_weight = parseFloat(response.data.weight_info.result).toFixed(3);
                        if(this.deli_kind != "1"){
                            this.loading = false;
                        }
                    }
                    this.sum_item.price = this.sumPrice(this.products);
                    this.sum_item.bv = this.sumBV(this.products);
                    if(this.gift_card == true){
                        this.point = 0;
                    }else{
                        this.point = this.sum_item.bv * 0.05;
                    }
                    this.sum_item.amt = this.sumAmt(this.products);

                })
                .catch(e => {
                });
        },
        getCenterList(){
            axios.post(process.env.MIX_BACK_URL+'/api/getCenter',{
                bz_cd: window.Laravel.bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.center_list = response.data;
                })
                .catch(e => {
                });

        },
        get_info_address_book(id)
        {
          this.loading = true;
          axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_item',{
            seq:id
          },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
            .then(response => {
                if(this.address_kind == "B"){
                  this.o_firstname = response.data.r_firstname;
                  this.o_lastname = response.data.r_lastname;
                  this.o_state = (response.data.r_addr1).split("|@|")[1];
                  this.o_city = (response.data.r_addr1).split("|@|")[0];
                  this.o_post = response.data.r_post;
                  this.o_mobile = response.data.r_mobile;
                  this.o_addr2_1 = (response.data.r_addr2).split("|@|")[0];
                  this.o_addr2_2 = (response.data.r_addr2).split("|@|")[1];
                  this.o_country = response.data.r_country;
                  this.address_book_open = false;
                  this.loading = false;
                }else{
                  this.shipping_info["d_firstname"] = response.data.r_firstname;
                  this.shipping_info["d_lastname"] = response.data.r_lastname;
                  this.shipping_info["d_state"] = (response.data.r_addr1).split("|@|")[1];
                  this.shipping_info["d_city"] = (response.data.r_addr1).split("|@|")[0];
                  this.shipping_info["d_post"] = response.data.r_post;
                  this.shipping_info["d_telephone"] = response.data.r_tel;
                  this.shipping_info["d_mobile"] = response.data.r_mobile;
                  this.shipping_info["d_address"] = (response.data.r_addr2).split("|@|")[0];
                  this.shipping_info["d_address2"] = (response.data.r_addr2).split("|@|")[1];
                  this.shipping_info["country"] = response.data.r_country;
                  this.address_book_open = false;
                    this.loading = false;

                  //this.getShippingFee();
                }
            })
        },
        // 수량 변경
        decrement(e,i){
            this.loading = true;
            if(this.products[i].qty > 1) {
                this.products[i].qty--;
                this.setChangeQty(this.products[i].seq_pd,this.products[i].qty);
                //this.getCart();
            }else{
              this.loading = false;
            }
        },
        increment(e,i){
          //231106 구매수량제한에 따른 alert 창 추가
          //sum_sales_qty = 해당 제품 현재까지 판매수량
          if(this.products[i].product.total_chk_buy == 'Y' && (Number(this.products[i].product.total_limit_buy) < (Number(this.products[i].product.sum_sales_qty[0].total_sales) + Number(this.products[i].qty))+1)){
            VueSimpleAlert.alert("You can only purchase "+ (Number(this.products[i].product.total_limit_buy) - Number(this.products[i].product.sum_sales_qty[0].total_sales)) +" units of "+this.products[i].product.pdt_name+"", "", "", { }).then((result) => {
              if (result) {
                return;
              }
            });
          }else{
                this.loading = true;
                if(this.products[i].qty < this.max_qty) {
                    this.products[i].qty++;
                    this.setChangeQty(this.products[i].seq_pd,this.products[i].qty);
                    //this.getCart();
                }else{
                  this.loading = false;
                }
            }
        },
        changeQty(e,i){
          //231106 구매수량제한에 따른 alert 창 추가
          //sum_sales_qty = 해당 제품 현재까지 판매수량
          if(this.products[i].product.total_chk_buy == 'Y' && (Number(this.products[i].product.total_limit_buy) < (Number(this.products[i].product.sum_sales_qty[0].total_sales) + Number(this.products[i].qty)))){
            VueSimpleAlert.alert("You can only purchase "+ (Number(this.products[i].product.total_limit_buy) - Number(this.products[i].product.sum_sales_qty[0].total_sales)) +" units of "+this.products[i].product.pdt_name+"", "", "", { }).then((result) => {
              if (result) {
                this.products[i].qty = (Number(this.products[i].product.total_limit_buy) - Number(this.products[i].product.sum_sales_qty[0].total_sales));
                return;
              }
            });
          }else{
            this.loading = true;
            if(this.products[i].qty < 1) {
                this.products[i].qty = 1;
                //this.totalCost(this.products);
            }else if(this.products[i].qty > this.max_qty) {
                this.products[i].qty = this.max_qty;
                //this.totalCost(this.products);
            }
          this.setChangeQty(this.products[i].seq_pd,this.products[i].qty);
          //this.getCart();
          }
        },
        setChangeQty(item, qty){
            axios.post(process.env.MIX_BACK_URL+'/api/change_qty',{
                item: item,
                qty: qty,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.getCart();
                    if(response.data.msg){
                        alert(response.data.msg);
                    }
                });
        },
        // 장바구니 아이템 삭제
        removeItem(e,i, config_yn = null){
            var item = (config_yn == 'Y') ? i : this.products[i].seq_pd;
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL+'/api/remove_cart',{
                item: item,
                config_yn : config_yn
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.loading = true;
                    if(response.data.msg){
                        alert(response.data.msg);
                    }
                    this.getCart(window.Laravel.bz_cd);
                    this.emitter.emit('getCountCartItems', '')
                });
        },
        // 선택 아이템 삭제
        removeSelect(){
            if(this.selectedItems.length === 0){
                alert.alert("No selected items");
                return false;
            }
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL+'/api/remove_cart',{
                item: this.selectedItems,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.loading = false;
                    if(response.data.msg){
                        alert(response.data.msg);
                    }
                    this.selectedItems = [];
                    this.getCart(window.Laravel.bz_cd);
                    this.emitter.emit('getCountCartItems', '')

                });
        },
        // 쇼핑 계속하기
        prevUrl(){
            window.history.go(-1);//
        },
        // 주문하기
        async order(){
            if(!this.isEnterShipping){
                this.isEnterShipping = true;
                this.isEnterProduct = false;

                alert.alert('Please enter your shipping information',"","",{returnFocus:false,focusConfirm:false,
                    didOpen: () => {
                    },
                    didDestroy: (toast) => {
                        this.$refs.d_firstname.focus();
                    }
                }).then((result) => {
                });
                return true;
            }

            if(this.sum_item.amt == 0){
                alert.alert('The configuration for the shopping basket cannot be found.');
                return false;
            }

            if(!this.shipping_info["d_firstname"]){
                alert.alert('Please enter reciever name');
                return false;
            }
            if(!this.shipping_info["d_lastname"]){
              alert.alert('Please enter reciever name');
              return false;
            }
            if(!this.shipping_info["d_mobile"]){
                alert.alert('Please enter mobile No.');
                return false;
            }
            if(!this.shipping_info["d_state"]){
                alert.alert('Please select shipping State information');
                return false;
            }
            if(!this.shipping_info["d_city"]){
                alert.alert('Please select shipping City information');
                return false;
            }
            if(!this.shipping_info["d_address"]){
                alert.alert('Please enter detail address');
                $("#address").focus();
                return false;
            }

            if(!this.o_firstname){
              alert.alert('Please enter reciever name');
              return false;
            }
            if(!this.o_lastname){
              alert.alert('Please enter reciever name');
              return false;
            }
            if(!this.o_mobile){
              alert.alert('Please enter mobile No.');
              return false;
            }
            if(!this.o_state){
              alert.alert('Please select shipping State information');
              return false;
            }
            if(!this.o_city){
              alert.alert('Please select shipping City information');
              return false;
            }
            if(!this.o_addr2_1){
              alert.alert('Please enter detail address');
              return false;
            }

            if(this.deli_kind == "1"){
                if(this.pickup_cd == "0"){
                    alert.alert('Please select pickup');
                    return false;
                }
                if(this.pickup_time_cd == "0"){
                    alert.alert('Please select pickup time');
                    return false;
                }
                if(this.pickup_date == ""){
                    alert.alert('Please select pickup_date');
                    return false;
                }
            }

            // 골드몰 제품 및 골드 회원 여부 확인
            if ( this.isGoldMallProductStatus() === false){
                VueSimpleAlert.alert("Gold Mall products are only available to Gold qualifiers.", "", "", {}).then((result) => {
                });
                return ;
            }


            this.loading = true;
            //await this.sleep(5000);
            let formdata = new FormData();
            formdata.append("_token", this.csrf);
            formdata.append("bz_cd", window.Laravel.bz_cd);
            formdata.append("client_ip", window.Laravel.clientIp);
            formdata.append("orderHost", location.host);
            formdata.append("orderNation", window.Laravel.bz_cd);
            formdata.append("orderPath", '일반주문');
            formdata.append("sumPrice", this.sum_item.price);
            formdata.append("sumBv", this.sum_item.bv);
            formdata.append("shippingFee", this.shipping_selected);
            formdata.append("taxFee", this.tax_selected);
            formdata.append("firstName", this.shipping_info["d_firstname"]);
            formdata.append("lastName", this.shipping_info["d_lastname"]);
            formdata.append("mobile", this.shipping_info.d_mobile);
            formdata.append("address", this.shipping_info.d_address+'|@|'+this.shipping_info.d_address2);
            formdata.append("post", this.shipping_info.d_post);
            formdata.append("city", this.shipping_info.d_city);
            formdata.append("state", this.shipping_info.d_state);
            formdata.append("note", document.shipping_info.note.value);
            formdata.append("ups_kind", this.ups_kind);
            formdata.append("insurance_yn", this.insurance_yn);
            formdata.append("country", this.shipping_info["country"]);

            formdata.append("o_firstname", this.o_firstname);
            formdata.append("o_lastname", this.o_lastname);
            formdata.append("o_mobile", this.o_mobile);
            formdata.append("o_addr2_1", this.o_addr2_1);
            formdata.append("o_addr2_2", this.o_addr2_2);
            formdata.append("o_city", this.o_city);
            formdata.append("o_state", this.o_state);
            formdata.append("o_post", this.o_post);
            formdata.append("o_country", this.o_country);
            formdata.append("deli_kind", this.deli_kind);

            formdata.append("tax_rate", 0);
            formdata.append("pdt_tax_total", 0);

            formdata.append("pickup_cd", this.pickup_cd);
            formdata.append("pickup_time_cd", this.pickup_time_cd);
            formdata.append("pickup_date", this.pickup_date);

            this.loading = true;
            axios.post(process.env.MIX_BACK_URL+'/api/getShippingFee',{
                bz_cd: window.Laravel.bz_cd,
                products: this.products,
                state: this.shipping_info["d_state"],
                postcode: this.shipping_info["d_post"],
                ups_kind : this.ups_kind,
                insurance_yn : this.insurance_yn,
                country : this.shipping_info["country"],
                deli_kind : this.deli_kind
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.insurance_price = response.data.insurance_price;
                    this.signature_price = response.data.signature_price;
                    this.ground_price = response.data.ground_price;
                    this.ord_deli_price = response.data.ground_price;
                    this.gel_price = response.data.gel_price;

                    if(this.gift_card == true){
                        this.insurance_price = 0;
                        this.signature_price = 0;
                        this.ground_price = 0;
                        this.ord_deli_price = 0;
                        this.gel_price = 0;

                    }

                    formdata.append("insurance_price", this.insurance_price);
                    formdata.append("ord_deli_price", this.ord_deli_price);
                    formdata.append("signature_price", this.signature_price);
                    formdata.append("ground_price", this.ground_price);
                    formdata.append("gel_price", this.gel_price);
                    formdata.append("gift_card", this.gift_card);
                    formdata.append("is_giftcard", this.is_giftcard);



                    formdata.append("_method", "PUT");
                    axios.post(process.env.MIX_BACK_URL+'/api/order', formdata,
                        {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                        .then(response => {
                            if(response.data.msg){
                                alert.alert(response.data.msg);
                                this.loading = false;
                            }
                            if(response.data.result == 'Success'){
                                let form = document.shipping_info;
                                form.action = '/order';
                                form.order_seq.value = response.data.order_seq;
                                form.shipping_fee.value = this.shipping_selected;
                                form.tax_fee.value = this.tax_selected;
                                form.pay_total_amt.value = this.total_cost;
                                form.address2_1.value = this.shipping_info["d_address"];
                                form.address2_2.value = this.shipping_info["d_address2"];
                                this.loading = false;
                                form.submit();
                            }
                        });
                })
                .catch(e => {
                    this.loading = false;
                });
        },

        changeUps(ups_kind){
            if(this.ups_kind == 1) this.ups_kind = "2";
            else if(this.ups_kind == 2) this.ups_kind = "1";

            if(ups_kind == "2") this.ord_deli_price = this.signature_price;
            else if(ups_kind == "1") this.ord_deli_price = this.ground_price;
        },
        getShippingFee(){//배송비계산
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL+'/api/getShippingFee',{
                bz_cd: window.Laravel.bz_cd,
                products: this.products,
                state: this.shipping_info["d_state"],
                postcode: this.shipping_info["d_post"],
                ups_kind : this.ups_kind,
                insurance_yn : this.insurance_yn,
                country : this.shipping_info["country"],
                deli_kind : this.deli_kind
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.insurance_price = response.data.insurance_price;
                    this.signature_price = response.data.signature_price;
                    this.ground_price = response.data.ground_price;
                    this.ord_deli_price = response.data.ground_price;
                    this.loading = false;
                })
                .catch(e => {
                  this.loading = false;
                });
        },
        sumPrice(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.prices.price*el.qty), 0);
        },
        sumVat(data) { // 상
            // 품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.prices.vat*el.qty), 0);
        },
        sumAmt(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.prices.amt*el.qty), 0);
        },
        sumBV(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.prices.pv*el.qty), 0);
        },
        change_billing(data){  // 배송지 변경
          this.msg_post = "";
          if(data=='Same' || data == '') {
            this.o_firstname = this.orderer["o_firstname"];
            this.o_lastname = this.orderer["o_lastname"];
            this.o_state = this.orderer["o_state"];
            this.o_city = this.orderer["o_city"];
            this.o_post = this.orderer["o_post"];
            this.o_addr2_1 = this.orderer["o_address"].split("|@|")[0];
            this.o_addr2_2 = this.orderer["o_address"].split("|@|")[1] ?? "";
            this.o_mobile = this.orderer["o_mobile"];
            this.o_country = this.orderer["o_country"] ?? "USA";
          }else if(data=='Above' ) {
            this.o_firstname = this.shipping_info["d_firstname"];
            this.o_lastname = this.shipping_info["d_lastname"];
            this.o_state = this.shipping_info["d_state"];
            this.o_city = this.shipping_info["d_city"];
            this.o_post = this.shipping_info["d_post"];
            this.o_addr2_1 = this.shipping_info["d_address"];
            this.o_addr2_2 = this.shipping_info["d_address2"];
            this.o_mobile = this.shipping_info["d_mobile"];
            this.o_country = this.shipping_info["country"] ;
        }else{
            this.o_firstname = '';
            this.o_lastname = '';
            this.o_state = '';
            this.o_city = '';
            this.o_post = '';
            this.o_addr2_1 = '';
            this.o_addr2_2 = '';
            this.o_mobile = '';
            this.o_country = '';
          }
          if (data=='Manage'){
            this.address_kind = "B";
            this.address_book_open = true;
          }else{
            // this.address_book_open = false;
          }
        },
        change_shipping(data){  // 배송지 변경
            this.msg_post = "";
            this.ups_kind = "1";
            this.insurance_yn = "N";
            this.insurance_price = 0;
            this.pickup_cd = "0";
            this.pickup_time_cd = "0";
            if(data=='Same' || data == '') {
                this.shipping_info["d_firstname"] = this.orderer["o_firstname"];
                this.shipping_info["d_lastname"] = this.orderer["o_lastname"];
                this.shipping_info["d_state"] = this.orderer["o_state"];
                this.shipping_info["d_city"] = this.orderer["o_city"];
                this.shipping_info["d_post"] = this.orderer["o_post"];
                this.shipping_info["d_address"] = this.orderer["o_address"].split("|@|")[0];
                this.shipping_info["d_address2"] = this.orderer["o_address"].split("|@|")[1] ?? "";
                this.shipping_info["d_telephone"] = this.orderer["o_telephone"];
                this.shipping_info["d_mobile"] = this.orderer["o_mobile"];
                this.shipping_info["country"] = this.orderer["o_country"] ?? "USA";
                if(this.shipping_info["d_post"].length >= 6 && (this.orderer["o_country"] === undefined || this.orderer["o_country"] == "" || this.orderer["o_country"] == null)){
                  this.shipping_info["country"] = "CAN";
                }
                this.deli_kind = "2";
                if(data == 'Same'){
                  //this.getShippingFee()
                }
            }else if(data=='pickup') {
                this.shipping_info["d_firstname"] = this.orderer["o_firstname"];
                this.shipping_info["d_lastname"] = this.orderer["o_lastname"];
                this.shipping_info["d_state"] = 'CA';
                this.shipping_info["d_city"] = 'LOS ANGELES';
                this.shipping_info["d_post"] = '90001';
                this.shipping_info["d_address"] = '6710 Wilson Ave';
                this.shipping_info["d_address2"] = '';
                this.shipping_info["country"] = 'USA';
                this.shipping_info["d_mobile"] = this.orderer["o_mobile"];
                this.deli_kind = "1";
            }else{
                this.shipping_info["d_firstname"] = '';
                this.shipping_info["d_lastname"] = '';
                this.shipping_info["d_state"] = '';
                this.shipping_info["d_city"] = '';
                this.shipping_info["d_street"] = '';
                this.shipping_info["d_post"] = '';
                this.shipping_info["d_address"] = '';
                this.shipping_info["d_address2"] = '';
                this.shipping_info["d_telephone"] = '';
                this.shipping_info["d_mobile"] = '';
                this.shipping_info["country"] = '';
                this.deli_kind = "2";
            }

            if (data=='Manage'){
              this.address_kind = "M";
                this.address_book_open = true;
            }else{
                // this.address_book_open = false;
            }
        },
        // 주소록 레이어 오픈
        close_address_book(){
            this.address_book_open = false;
        },
        // 숫자 포멧
        formatPrice(value,digit) {
            let val = val = (value/1).toFixed(digit).replace('.', '.')
            val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            //val = (value/1).toFixed(digit).replace(/(.?0+$)/, '')// 소수점 끝자리 0 제거
            return val;
        },
        search_address(addr_kind){
            this.address_kind = addr_kind;
            if(this.deli_kind !='1'){
                this.address_search_open = true;
            }
        },

        tax_detail(){
          this.tax_detail_open = true;
        },
        set_address(value){
            if(this.address_kind == "B"){
              this.address_search_open = false;
              this.o_state =value.state;
              this.o_city = value.district;
              this.o_post = value.postcode;
              this.o_country =value.country;
            }else{
              this.address_search_open = false;
              this.shipping_info["d_state"] =value.state;
              this.shipping_info["d_city"] = value.district;
              this.shipping_info["d_post"] = value.postcode;
              this.shipping_info["country"] =value.country;
              //this.getShippingFee();
            }
        },
        close_address_search(){
            this.address_search_open = false;
        },
        close_tax_detail(){
          this.tax_detail_open = false;
        },
        link(value){
            location.href = value;
        },
        allSelect(){
            if(this.selectedItems.length > 0){
                this.selectedItems = [];
            }else{
                this.products.forEach((value, index) => {this.selectedItems.push(value.seq_pd);});
                Object.entries(this.config_mst_list).forEach(([key, value]) => {
                  this.selectedItems.push(value.seq_pd);
                });
            }
        },
        isGoldMallProductStatus() {
            // 카트에 골드몰 제품이 있으며, 골드 직급 이상인 회원이 아닌 경우 체크
            if ( this.products.some(product => this.gold_mall_categories.includes(String(product.group_id))) === true && this.gold_check === false){
                return false;
            }
            return true;
        }
    }
}
</script>
<style>
.vs__search {
    font-size: 1.5em;
    font-weight: 500;
    padding: 0.05rem;
    border: none;
    /* margin-top: 35px; */
}
.badge_box{position: absolute; top: 0; right: 0; width: 100%; }
.badge_box>img {width: 25% !important; top:8px; right: 8px; position: absolute; z-index: 1; background:transparent !important;}

.coming_box{position: absolute; top: 0; right: 0; width: 100%; }
.coming_box>img{width:25% !important;  position: absolute; right:8px; top: 8px;  z-index: 1; background:transparent !important;}

.sold-out-box2{position: absolute; top: 0; right: 0; width: 100%; }
.sold-out-box2>img {width: 100% !important; top:0px; right: 0px; position: absolute;  z-index: 1; background:transparent !important;}

.padding-pdt-name {  padding: 0 25px 15px 15px;}
.item-amount { width:72px !important; }
</style>
