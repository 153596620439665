<template>
    <!-- 직급 현황 -->
    <div class=" rounded-xl bg-white mb-6 shadow-zinc-500/40 shadow-md p-5 xl:w-4/6 lg:w-3/6 w-full flex flex-col">

        <div class="py-3 flex flex-row justify-between">
            <h3 class="font-semibold text-[#172e59] text-lg min-h-1/3">Promotion Status
                <!--                                    <span class="text-sm font-normal xl:block lg:block hidden"><br>&nbsp;</span>-->
            </h3> <!--승급 진행상황-->
        </div>
        <!-- 여기에 프로그래스 바 추가 -->
        <div class="min-h-2/3 flex flex-col justify-around xl:mt-6 lg:mt-6" v-if="dash_d_val.RANK_NEW">
            <div class="flex flex-row items-center w-full">

                <div class="w-1-9 text-center lg:text-base mx-1">
                    <div class="flex flex-row justify-center items-center">
                        <div v-if="rank_name != 'Manager'"
                             class="xl:border-4 lg:border-4 border-2 border-gray-400 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-gray-400">1</span></div>
                        <div v-if="rank_name == 'Manager'"
                             class="xl:border-4 lg:border-4 border-2 border-red-500 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-red-500">1</span></div>
                    </div>
                </div>
                <div class="w-1-9 align-center items-center align-middle content-center flex">
                    <div class="w-full bg-grey-light rounded items-center align-middle align-center">
                        <div v-if="rank_name == 'Manager'"
                             class="bg-red-500 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                        <div v-if="rank_name != 'Manager'"
                             class="bg-gray-400 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                    </div>
                </div>

                <div class="w-1-9 text-center lg:text-base mx-1">
                    <div class="flex flex-row justify-center items-center">
                        <div v-if="rank_name != 'General Manager'"
                             class="xl:border-4 lg:border-4 border-2 border-gray-400 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-gray-400">2</span></div>
                        <div v-if="rank_name == 'General Manager'"
                             class="xl:border-4 lg:border-4 border-2 border-red-500 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-red-500">2</span></div>
                    </div>
                </div>
                <div class="w-1-9 align-center items-center align-middle content-center flex">
                    <div class="w-full bg-grey-light rounded items-center align-middle align-center">
                        <div v-if="rank_name == 'General Manager'"
                             class="bg-red-500 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                        <div v-if="rank_name != 'General Manager'"
                             class="bg-gray-400 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                    </div>
                </div>

                <div class="w-1-9 text-center lg:text-base mx-1">
                    <div class="flex flex-row justify-center items-center">
                        <div v-if="rank_name != 'Director'"
                             class="xl:border-4 lg:border-4 border-2 border-gray-400 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-gray-400">3</span></div>
                        <div v-if="rank_name == 'Director'"
                             class="xl:border-4 lg:border-4 border-2 border-red-500 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-red-500">3</span></div>
                    </div>
                </div>
                <div class="w-1-9 align-center items-center align-middle content-center flex">
                    <div class="w-full bg-grey-light rounded items-center align-middle align-center">
                        <div v-if="rank_name == 'Director'"
                             class="bg-red-500 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                        <div v-if="rank_name != 'Director'"
                             class="bg-gray-400 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                    </div>
                </div>

                <div class="w-1-9 text-center lg:text-base mx-1">
                    <div class="flex flex-row justify-center items-center">
                        <div v-if="rank_name != 'Vice President'"
                             class="xl:border-4 lg:border-4 border-2 border-gray-400 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-gray-400">4</span></div>
                        <div v-if="rank_name == 'Vice President'"
                             class="xl:border-4 lg:border-4 border-2 border-red-500 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-red-500">4</span></div>
                    </div>
                </div>
                <div class="w-1-9 align-center items-center align-middle content-center flex">
                    <div class="w-full bg-grey-light rounded items-center align-middle align-center">
                        <div v-if="rank_name == 'Vice President'"
                             class="bg-red-500 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                        <div v-if="rank_name != 'Vice President'"
                             class="bg-gray-400 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                    </div>
                </div>

                <div class="w-1-9 text-center lg:text-base mx-1">
                    <div class="flex flex-row justify-center items-center">
                        <div v-if="rank_name != 'President'"
                             class="xl:border-4 lg:border-4 border-2 border-gray-400 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-gray-400">5</span></div>
                        <div v-if="rank_name == 'President'"
                             class="xl:border-4 lg:border-4 border-2 border-red-500 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-red-500">5</span></div>
                    </div>
                </div>
            </div>

            <div class="flex flex-row items-center w-full">
                <p v-if="rank_name != 'Manager'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep">Manager</p>
                <p v-if="rank_name == 'Manager'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep text-red-500 font-semibold">
                    Manager</p>
                <p class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep">&nbsp;</p>

                <!-- General Manager-->
                <p v-if="rank_name != 'General Manager'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep">General Manager</p>
                <p v-if="rank_name == 'General Manager'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep text-red-500 font-semibold">
                    General Manager</p>
                <p class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep">&nbsp;</p>


                <p v-if="rank_name != 'Director'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep">Director</p>
                <p v-if="rank_name == 'Director'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep text-red-500 font-semibold">
                    Director</p>
                <p class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep">&nbsp;</p>
                <p v-if="rank_name != 'Vice President'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep">Vice President</p>
                <p v-if="rank_name == 'Vice President'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep text-red-500 font-semibold">
                    Vice President</p>
                <p class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep">&nbsp;</p>
                <p v-if="rank_name != 'President'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep">President</p>
                <p v-if="rank_name == 'President'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep text-red-500 font-semibold">
                    President</p>
            </div>
            <!--현재 직급은 이며,
        President 승급을 위해 직추천산하 총 3명의 Vice President가 필요하며,
        현재 1명 달성했습니다.-->

            <div v-if="memberData[dash_d_val.RANK_CURR]['type'] === 'A'" class="py-5 px-3 text-sm xl:mt-5 lg:mt-3">
                <div>
                    <ul>
                        <li class="text-center">
                            <p class="font-semibold text-sm mb-3 ">
                                Your current qualification is
                                <span class="text-red-500">{{ dash_d_val.RANK_CURR }}.</span>
                            </p>
                        </li>
                        <li>
                            <span class="text-sm font-semibold">
                                To reach the next qualification of
                            </span>
                            <span class="text-red-500 font-semibold text-sm">
                                {{ dash_d_val.RANK_NEXT }}
                            </span>,
                            you must make a single purchase of {{ numberFormat(memberData[dash_d_val.RANK_CURR]['singlePurchasePv']) }}
                            PV or more or have cumulative sales of {{ numberFormat(memberData[dash_d_val.RANK_CURR]['cumulativeSalesPv']) }} PV or more.
                        </li>
                    </ul>
                </div>
                <div>
                    <ul >
                        <li class="mt-6 mb-3">
                            <p class="font-semibold text-sm ">
                                Upon reaching <span class="text-red-500">{{ dash_d_val.RANK_NEXT }}</span> receive:
                            </p>
                        </li>
                        <li v-for="(arr, idx) in memberData[dash_d_val.RANK_CURR].receiveMessages" :key="idx" class="mb-2">
                            <span v-if="arr.msgType==='1'"  class="inline-block">
                            · {{ arr.msg }}
                            </span>
                            <span v-else-if="arr.msgType==='2'" class="ml-4 inline-block">
                            {{ arr.msg }}
                            </span>
                            <span v-else-if="arr.msgType==='3'" class="mt-4 inline-block">
                            {{ arr.msg }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="memberData[dash_d_val.RANK_CURR]['type'] === 'B'" class="py-5 px-3 text-sm xl:mt-5 lg:mt-3">
                <div>
                    <ul>
                        <li class="text-center">
                            <p class="font-semibold text-sm mb-5 ">
                                Your current rank is
                                <span class="text-red-500">{{ dash_d_val.RANK_CURR }}.</span>
                            </p>
                        </li>
                        <li>
                            <span class="font-semibold text-sm ">
                                To reach the rank of
                            </span>
                            <span class="text-red-500 font-semibold text-sm">

                                <!-- Gold 에 한해서만 예외 처리 //-->
<!--                                <span v-if="dash_d_val.RANK_CURR === 'Gold' && false ">-->
<!--                                    Manager-->
<!--                                </span>-->
<!--                                <span v-else>-->
                                {{ dash_d_val.RANK_NEXT }}
<!--                                </span>-->
                            </span>,
                            you must have at least three immediate downline legs (1st gen) and your entire downline must have {{ numberFormat(memberData[dash_d_val.RANK_CURR]['entireDownlinePv']) }} PV or more in total sales. Two of your downline legs must each have a minimum of {{ numberFormat(memberData[dash_d_val.RANK_CURR]['minimumSalesPv']) }} PV in sales.
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li class="mt-6 mb-3">
                            <p class="font-semibold text-sm ">
                                Upon reaching <span class="text-red-500">{{ dash_d_val.RANK_NEXT }}</span>
                                receive:
                            </p>
                        </li>
                        <li v-for="(arr, idx) in memberData[dash_d_val.RANK_CURR].receiveMessages" :key="idx" class="mb-2">
                            <span v-if="arr.msgType==='1'"  class="inline-block">
                            · {{ arr.msg }}
                            </span>
                            <span v-else-if="arr.msgType==='2'" class="ml-4 inline-block">
                            {{ arr.msg }}
                            </span>
                            <span v-else-if="arr.msgType==='3'" class="mt-4 inline-block">
                            {{ arr.msg }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="memberData[dash_d_val.RANK_CURR]['type'] === 'C'" class="py-5 px-3 text-sm xl:mt-5 lg:mt-3"  style="min-height: 245px;">
                <div>
                    <ul>
                        <li class="text-center">
                            <p class="font-semibold text-sm mb-3 ">
                                Your current rank is
                                <span class="text-red-500">{{ dash_d_val.RANK_CURR }}.</span>
                            </p>
                        </li>
                        <li class="mt-6 mb-3 text-center">
                            Congratulations on your achievement.
                        </li>
                        <li>
                            <span class="font-semibold text-sm ">
                                To achieve the special rank of 2President
                                <span class="text-red-500">2President</span>
                                , you must have a President in at least two of your downline legs.
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="font-semibold pl-3">
                {{  i18n.myoffice.dashboard.promotionStatusMsg }}
            </div>
        </div>
    </div><!--// -->
</template>

<script>


import numeral from "numeral";

export default {
    name: "PromotionStatus",
    props: ['dash_d_val', 'rank_name', 'rank_ord_pv'],
    components: {},
    data: () => ({
        i18n: window.i18n,
        memberData: {
            'Member': {
                type: 'A',
                singlePurchasePv: 1000,
                cumulativeSalesPv: 1000,
                receiveMessages: [
                    {
                        msgType: '1',
                        msg: 'Sales Bonus: 5%'
                    },
                    {
                        msgType: '1',
                        msg: 'Management Bonus: Up to 30% of your  downline’s  (Gen 1-5) Sales Bonus (2% - 10%)'
                    },
                    {
                        msgType: '2',
                        msg: '*Downliners must be Bronze',
                    }
                ]
            },
            'Bronze': {
                type: 'A',
                singlePurchasePv: 2000,
                cumulativeSalesPv: 5000,
                receiveMessages: [
                    {
                        msgType: '1',
                        msg: 'Sales Bonus: 10%'
                    },
                    {
                        msgType: '1',
                        msg: 'Management Bonus: Up to 30% of your  downline’s  (Gen 1-5) Sales Bonus (2% - 10%)'
                    },
                    {
                        msgType: '2',
                        msg: '*Downliners must be Silver or Bronze'
                    }
                ]
            },
            'Silver': {
                type: 'A',
                singlePurchasePv: 3000,
                cumulativeSalesPv: 10000,
                receiveMessages: [
                    {
                        msgType: '1',
                        msg: 'Sales Bonus: 20%'
                    },
                    {
                        msgType: '1',
                        msg: 'Management Bonus: Up to 30% of your  downline’s  (Gen 1-5) Sales Bonus (2% - 10%)'
                    },
                    {
                        msgType: '2',
                        msg: '*Downliners must be Silver or Bronze'
                    },
                    {
                        msgType: '1',
                        msg: 'Purchase Bonus: 10% of purchase PV on single purchases of 3,000PV+'
                    }
                ]
            },
            'Gold': {
                type: 'B',
                entireDownlinePv: 10000,
                minimumSalesPv: 3000,
                receiveMessages: [
                    {
                        msgType: '1',
                        msg: 'Sales Bonus: 20%'
                    },
                    {
                        msgType: '1',
                        msg: 'Management Bonus: Up to 30% of your  downline’s  (Gen 1-5) Sales Bonus (2% - 10%)'
                    },
                    {
                        msgType: '2',
                        msg: '*Downliners must be Gold, Silver, or Bronze'
                    },
                    {
                        msgType: '1',
                        msg: 'Purchase Bonus: 10% of purchase PV on single purchases of 3,000PV+'
                    },
                    {
                        msgType: '1',
                        msg: 'Rank Bonus: 3% of immediate downline leg’s monthly PV sales'
                    }
                ]
            },
            'Manager': {
                type: 'B',
                entireDownlinePv: 20000,
                minimumSalesPv: 6000,
                receiveMessages: [
                    {
                        msgType: '1',
                        msg: 'Sales Bonus: 20%'
                    },
                    {
                        msgType: '1',
                        msg: 'Management Bonus: Up to 30% of your  downline’s  (Gen 1-5) Sales Bonus (2% - 10%)'
                    },
                    {
                        msgType: '2',
                        msg: '*Downliners must be Gold, Silver, or Bronze'
                    },
                    {
                        msgType: '1',
                        msg: 'Purchase Bonus: 10% of purchase PV on single purchases of 3,000PV+'
                    },
                    {
                        msgType: '1',
                        msg: 'Rank Bonus: Up to 5% (2% - 5%) of immediate downline leg’s monthly PV sales'
                    }
                ]
            },
            'General Manager': {
                type: 'B',
                entireDownlinePv: 50000,
                minimumSalesPv: 10000,
                receiveMessages: [
                    {
                        msgType: '1',
                        msg: 'Sales Bonus: 20%'
                    },
                    {
                        msgType: '1',
                        msg: 'Management Bonus: Up to 30% of your  downline’s  (Gen 1-5) Sales Bonus (2% - 10%)'
                    },
                    {
                        msgType: '2',
                        msg: '*Downliners must be Gold, Silver, or Bronze'
                    },
                    {
                        msgType: '1',
                        msg: 'Purchase Bonus: 10% of purchase PV on single purchases of 3,000PV+'
                    },
                    {
                        msgType: '1',
                        msg: 'Rank Bonus: Up to 10% (5% - 10%) of immediate downline leg’s monthly PV sales'
                    },
                    {
                        msgType: '1',
                        msg: 'Shared Rank Bonus: Up to 20% (Gen1, 10% / Gen2 -3, 5% each) of equally ranked downliners’ rank bonus'
                    }
                ]
            },
            'Director': {
                type: 'B',
                entireDownlinePv: 200000,
                minimumSalesPv: 50000,
                receiveMessages: [
                    {
                        msgType: '1',
                        msg: 'Sales Bonus: 20%'
                    },
                    {
                        msgType: '1',
                        msg: 'Management Bonus: Up to 30% of your  downline’s  (Gen 1-5) Sales Bonus (2% - 10%)'
                    },
                    {
                        msgType: '2',
                        msg: '*Downliners must be Gold, Silver, or Bronze'
                    },
                    {
                        msgType: '1',
                        msg: 'Purchase Bonus: 10% of purchase PV on single purchases of 3,000PV+'
                    },
                    {
                        msgType: '1',
                        msg: 'Rank Bonus: Up to 15% (5% - 15%) of immediate downline leg’s monthly PV sales'
                    },
                    {
                        msgType: '1',
                        msg: 'Shared Rank Bonus: Up to 25% (Gen1, 10% / Gen2 -4, 5% each) of equally ranked downliners’ rank'
                    }
                ]
            },
            'Vice President': {
                type: 'B',
                entireDownlinePv: 500000,
                minimumSalesPv: 150000,
                receiveMessages: [
                    {
                        msgType: '1',
                        msg: 'Sales Bonus: 20%'
                    },
                    {
                        msgType: '1',
                        msg: 'Management Bonus: Up to 30% of your  downline’s  (Gen 1-5) Sales Bonus (2% - 10%)'
                    },
                    {
                        msgType: '2',
                        msg: '*Downliners must be Gold, Silver, or Bronze'
                    },
                    {
                        msgType: '1',
                        msg: 'Purchase Bonus: 10% of purchase PV on single purchases of 3,000PV+'
                    },
                    {
                        msgType: '1',
                        msg: 'Rank Bonus: Up to 20% (5% - 20%) of immediate downline leg’s monthly PV sales'
                    },
                    {
                        msgType: '1',
                        msg: 'Shared Rank Bonus: Up to 30% (Gen1, 10% / Gen2-5, 5% each) of equally ranked downliners’ rank bonus'
                    }
                ]
            },
            'President': {
                type: 'C'
            },
        },
    }),
    created() {
    },
    mounted() {
    },
    methods: {
        numberFormat(val, type) {
            if (type === 'dollar') {
                return numeral(val).format('$0,0.00')
            }else if ( type === 'decimalPoint'){
                return numeral(val).format('0,0.00')
            }
            return String(val).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        },
    },
}
</script>

<style scoped>
.w-1-7 {
    width: 14.28%;
}

.w-1-9 {
    width: 11.11%;
}
</style>
