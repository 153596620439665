<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>

  <section class="container mx-auto lg:py-6 md:p-0" >
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 px-6 lg:max-w-7xl lg:px-8 bg-white">
        <div class="w-full lg:flex lg:justify-start">
          <div class="w-full">
            <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start flex-col">
                  <div class="w-full border-t-2 border-black divide-y bg-white lg:p-2" id="infomodify" >
                    <div class="py-3">
                      <label class="cursor-pointer xl:text-xl lg:text-xl text-base" >
                        <input type="checkbox" id="agreeAll" name="agreeAll" v-model="allSelected">&nbsp;Agree All<!-- 약관 전체 동의하기 -->
                      </label>
                    </div>
                    <div class="py-2 border-b-2 border-black">
                      <div class="w-full mb-10" v-for="(item, index) in checkList">
<!--                        <div class="w-full mb-10" v-for="(Value,index) in policy_data">-->
                        <div class="w-full text-base lg:text-lg xl:text-lg font-semibold my-3 pl-4 border-l-8 border-black">{{ contentsList.at(index)?contentsList.at(index).menu_name:'' }}</div>
                        <div class="w-full">
                          <div class="w-full h-80 border overflow-scroll text-sm p-3 border-gray-800" v-html="contentsList.at(index)?contentsList.at(index).content:''">
                          </div>
                          <div class="my-2">
                            <label class="cursor-pointer xl:text-base lg:text-base text-sm" >
                              <input type="checkbox" :value="item" :id="item" :key="index" v-model="selectList">&nbsp;I have read and agree to the {{ contentsList.at(index)?contentsList.at(index).menu_name:'' }} <!-- 약관동의하기 -->
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex space-x-2">
                      <button type="button" v-on:click="send_join"  class="my-2 py-2 px-4 leading-6 text-white bg-[#2c80c4] hover:bg-[#24679f] w-full md:w-1/6 reset_btn rounded-md"> Disagree</button> <!-- 동의하기 -->
                      <button type="button" v-on:click="agree"  class="my-2 py-2 px-4 leading-6 text-white bg-[#2c80c4] hover:bg-[#24679f] w-full md:w-1/6 search_btn rounded-md"> Agree</button> <!-- 동의하기 -->
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>
    </div>
  </section>


</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import MypageTop from './mypage/MypageTop.vue';
import SideMenu from './mypage/SideMenu.vue';
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import {createPopper} from "@popperjs/core";
import modalView from "./modalView";
import vSelect from "vue-select";
import dayjs from "dayjs";
import MyRegister from "./MyRegister";

export default {
  name: "MyRegister_agree",
  components : {
    MyRegister,
    VueSimpleAlert,
    ShoppingCartIcon,
    MypageTop,
    SideMenu,
    Loading,
    modalView,
    vSelect
  },
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
    no_search_messsage: '',
    isLoggedIn: false,
    loading : false,
    mem: "",
    myconfig:"",

    is_login: false,
    is_agree : false,
    allSelected : false,
    checkList: [],
    selectList: [],
    contentsList: [],
  }),
  props: [
    'register_type',
  ],
  beforeCreate() {
    axios.post(window.Laravel.back_url + '/api/getMyConfig', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
      this.myconfig = response.data;

    });

  },
  created() {
    this.getPolicies(this.register_type);
  },
  computed: {
    allSelected: {
      get: function () {
        return this.checkList.length === this.selectList.length;
      },
      set: function (e) { //모두 동의
        this.selectList = e ? this.checkList : [];
      },
    },
  },
  mounted() {
    if (window.Laravel.isLoggedin) {
      this.isLoggedIn = true;
      this.user = window.Laravel.user;
    }
  },

  methods:{
    send_join : function (){
      this.$emit("send_join");
    },
    get_agree(data) {
      this.is_agree = !this.is_agree;
    },

    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "top",
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
    // 숫자 포멧
    formatPrice(value,digit) {
      let val = (value/1).toFixed(digit).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    agree(){
      if(this.selectList.length != this.checkList.length){
        alert.alert('Give your consent to the all policies'); //이용약관에 동의해 주세요.
        return;
      }
      this.is_agree= true;
      location.href='./RegisterInfo?register_type='+this.register_type;

    },
    getPolicies(register_type){
      this.loading = true;
      axios.post(process.env.MIX_BACK_URL+'/api/getPolicy',{
        register_type: register_type
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          // this.checkList = response.data;
          response.data.forEach((value, index) => {
            const parser = new DOMParser();
            const elem = parser.parseFromString(value.content, 'text/html');
            this.checkList.push(value.menu_cd);
            this.contentsList.push({
              menu_cd:value.menu_cd, content: elem.body.innerText, menu_name:value.menu_name
            });
          });
          this.loading = false;
        })
        .catch(e => {
        });

    },
  },

}


</script>

<style >
.v-select{ min-width: 100px; z-index:0 }
.m_menu{display:none;}
</style>

