<template>
    <div class="w-full p-1 justify-between ">
        <div class="text-right"><span @click=" close_address_search "  class="cursor-pointer p-2 font-semibold text-gray-800">X</span></div>
        <h2 class="w-full text-lg xl:text-xl lg:text-xl font-semibold text-gray-800 border-l-[10px] border-[#4493d5] pl-3">
          Delivery Address
          <div class="text-[#2c7fc3] text-xs font-semibold">( Search the address with Zip code. )</div>
        </h2>
    </div>
    <div class="w-full p-2 mb-24 md:mb-4">
        <ul class="flex py-2">
            <li class="flex-1 mr-2 items-center border-blue-500 py-2 px-4" @click="currentTab = 1" :class="currentTab === 1 ? 'border-blue-500  bg-blue-500 hover:bg-blue-700 text-white border rounded' : 'border-white border-gray-200 text-gray-500 hover:bg-gray-200 border rounded'">
                <a class="text-center block xl:text-base lg:text-base text-sm" href="#">Search by Zip code</a>
            </li>
            <li class="flex-1 mr-2" v-show="false" @click=" currentTab = 2 ">
                <a class="text-center block border rounded py-2 px-4"
                   :class="currentTab === 2 ? 'border-blue-500  bg-blue-500 hover:bg-blue-700 text-white' : 'border-white hover:border-gray-200 text-blue-500 hover:bg-gray-200'"  href="#">
                    스트리트로 검색하기
                </a>
            </li>
        </ul>

        <div class="" v-if="currentTab === 1">
          <div class="w-full px-2 py-4 justify-between border-b">
              <form v-on:submit.prevent=" post_Search ">
                  <label class="block mb-3 text-sm font-semibold text-gray-500">Zip code (Minimum 5 characters)</label>
                  <div class="flex">
                      <input type="text" v-model="post" class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="7" oninput="this.value = this.value.replace(' ','')">
                      <input type="submit" value="Search" class="bg-blue-500 font-semibold hover:bg-[#2468a0] py-3 text-sm text-white uppercase w-full cursor-pointer">
                  </div>
              </form>
            </div>
            <div class="w-full p-4 overflow-auto	">
                <label class="block mb-3 text-sm font-semibold text-gray-500">{{ post_label }}</label>
<!--                <vSelect name="state" ref="d_state" :options="post_list" :reduce="value => value.id" v-model='post_select' :clearable="false"-->
<!--                @option:selected="onChangePost" class="block w-full text-gray-600 text-sm"></vSelect>-->
                <table class="w-full">
                    <tr class="p-2 border border-gray-200 w-full hover:bg-slate-100	cursor-pointer" v-for="(value,index) in post_list">
                        <td class="p-2" @click="onChangePost(value.id)">{{ value.label }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "addressSearch",
    components : {
        // address_book,
        vSelect,
    },
    props:['nt_cd'],

    data: () => ({
        title: [],
        products: [],
        currentTab: 1,
        post : "",
        street : "",
        post_list : [],
        steet_list : [],
        steet_select : "",
        //post_select : "",
        post_label : "",
        street_label : "",
    }),
    methods:{
        post_Search(){
            if(this.post.length >= 5){
                axios.post(process.env.MIX_BACK_URL+'/api/getPostSearchList',{
                    post: this.post,
                    nt_cd : this.nt_cd ?? "",
                },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                    .then(response => {
                        this.post_label = "";
                        if(response.data.result == "0"){
                            VueSimpleAlert.alert(response.data.msg,"","",{allowOutsideClick : false}).then((result) => {});
                            return;
                        }

                        if(response.data.address.length === 0){
                            this.post_label = "No addresses found";
                        }else{
                            this.post_label = "Please select your address";
                        }

                        this.post_list = [];

                        response.data.address.forEach((value, index) => {
                            this.post_list.push({
                                label :value.city+ " "+value.state + " "+this.post,
                                id : {"district" : value.city,"postcode" : this.post , "state" : value.state, "country" : value.country}
                            });
                        });

                    })
                    .catch(e => {
                    });
            }else{
                this.post_label = "No addresses found";
            }
        },
        onChangePost(post_select){
            if(post_select.state == "GU"){
              this.post = "";
              VueSimpleAlert.alert("GCOOP is currently unable to provide shipping services to Guam. \nWe apologize for the inconvenience and thank you for your understanding.","","",{});
              return;
            }
            this.$emit("set_address",post_select);
        },
        close_address_search(){
            this.$emit("close_address_search");
        },

    }
}
</script>

<style scoped>

</style>
