<template>
  <section v-show="event_show_type" class="container xl:py-8 lg:py-8 py-4 mx-auto">
    <div class="bg-slate-100 lg:bg-white min-h-[300] lg:min-h-[400]">
      <div class="max-w-8xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 bg-white" :class="event_show_type === 1 ? 'lg:flex': ''">
        <!-- S : Gold Mall Tab -->
        <div v-if="gold_mall_list.length > 0" :class="event_show_type === 1 ? 'lg:grid-cols-2 lg:pr-8': 'lg:grid-cols-4 lg:py-8 py-4'">
          <h2 class="xl:text-2xl lg:text-2xl text-lg font-extrabold tracking-normal text-gray-900 mt-1 mb-1">Gold Mall</h2>
          <div class="grid grid-cols-2 sm:grid-cols-3 xl:gap-x-8 lg:gap-x-6 gap-x-1 gap-y-3" :class="event_show_type === 1 ? 'lg:flex': 'lg:grid-cols-4'">
            <div v-for="(goldMall, i) in gold_mall_list" class="group relative bg-gray-100 border-black m-1.5 rounded-lg border-slate-900 xl:py-2 lg:py-2 py-2 xl:px-3 lg:px-3 px-2 transition duration-300 ease-in-out hover:bg-black/20">
              <div class="w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden relative h-244">
                <a :href="goldMall.go_link" class="block w-full">
                  <img :src="goldMall.file_url + goldMall.file_path + goldMall.file_name" :alt="goldMall.file_ori_name" class="w-full object-center object-cover max-w-none lg:max-w-[244px] lg:max-h-[244px]">
                </a>
              </div>
              <div class="xl:mt-8 lg:mt-8 mt-3 xl:mb-8 lg:mb-8 mb-3 justify-between">
                <div class="w-full">
                  <p class="ell text-[#0e293e] text-sm lg:text-base sm:text-sm font-semibold">{{ goldMall.desc_text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- E : Gold Mall Tab -->
          <!-- S : Event Tab -->
          <div v-if="event_list.length > 0" :class="event_show_type === 1 ? 'lg:grid-cols-2': 'lg:grid-cols-4 lg:py-8 py-4'">
            <h2 class="xl:text-2xl lg:text-2xl text-lg font-extrabold tracking-normal text-gray-900 mt-1 mb-1">Events</h2>
            <div class="grid grid-cols-2 sm:grid-cols-3 xl:gap-x-8 lg:gap-x-6 gap-x-1 gap-y-3" :class="event_show_type === 1 ? 'lg:flex': 'lg:grid-cols-4'">
              <div v-for="(events, i) in event_list" class="group relative bg-gray-100 border-black m-1.5 rounded-lg border-slate-900 xl:py-2 lg:py-2 py-2 xl:px-3 lg:px-3 px-2 transition duration-300 ease-in-out hover:bg-black/20">
                <div class="w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden relative h-244">
                  <a :href="events.go_link" class="block w-full">
                    <img :src="events.file_url + events.file_path + events.file_name" :alt="events.file_ori_name" class="w-full object-center object-cover max-w-none lg:max-w-[244px] lg:max-h-[244px]">
                  </a>
                </div>
                <div class="xl:mt-8 lg:mt-8 mt-3 xl:mb-8 lg:mb-8 mb-3 justify-between">
                  <div class="w-full">
                    <p class="ell text-[#0e293e] text-sm lg:text-base sm:text-sm font-semibold">{{ events.desc_text }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- E : Event Tab -->
          <!-- S : Special Tab -->
        </div>
        <div v-if="special_list.length > 0" class="max-w-8xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 bg-white">
          <div class="lg:grid-cols-4 lg:py-8 py-4">
            <h2 class="xl:text-2xl lg:text-2xl text-lg font-extrabold tracking-normal text-gray-900 mt-1 mb-1">Special</h2>
            <div class="grid grid-cols-2 sm:grid-cols-3 xl:gap-x-8 lg:gap-x-6 gap-x-1 gap-y-3 lg:grid-cols-4">
              <div v-for="(special, j) in special_list" class="group relative bg-gray-100 border-black m-1.5 rounded-lg border-slate-900 xl:py-2 lg:py-2 py-2 xl:px-3 lg:px-3 px-2 transition duration-300 ease-in-out hover:bg-black/20">
                <div class="w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden relative h-244">
                  <a :href="special.go_link" class="block w-full">
                    <img :src="special.file_url + special.file_path + special.file_name" :alt="special.file_ori_name" class="w-full object-center object-cover max-w-none lg:max-w-[244px] lg:max-h-[244px]">
                  </a>
                </div>
                <div class="xl:mt-8 lg:mt-8 mt-3 xl:mb-8 lg:mb-8 mb-3 justify-between">
                  <div class="w-full">
                    <p class="ell text-[#0e293e] text-sm lg:text-base sm:text-sm font-semibold">{{ special.desc_text }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- E : Special Tab -->
      </div>
    </div>
  </section>
</template>


<script>
/* javascript numberFormat library */
import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {ShoppingCartIcon} from '@heroicons/vue/outline'

export default {
  name: "eventProductList",
  components: {
    VueSimpleAlert,
    ShoppingCartIcon
  },
  data: () => ({
    no_search_messsage : "There are no results",
    gold_check : window.Laravel.gold_check,
    event_show_type    : 0,
    event_list         : [],
    special_list       : [],
    gold_mall_list     : []
  }),
  created() {
      this.getEventSpecialList();
  },
  methods: {
    getEventSpecialList() {
        axios.post(process.env.MIX_BACK_URL+'/api/product/eventAndSpecial', {
          bz_cd: window.Laravel.bz_cd,
          gold_check : this.gold_check
        }).then(response => {
            this.event_show_type = response.data.event_show_type;
            this.event_list = response.data.events_sepcial.events;
            this.special_list = response.data.events_sepcial.special;
            this.gold_mall_list = response.data.events_sepcial.goldMall;
        });
    },
  }
}

</script>
<style scoped>
.ell { overflow: hidden; text-overflow: ellipsis; word-wrap: break-word; display: -webkit-box;
  -webkit-line-clamp: 3; /* ellipsis line */
  -webkit-box-orient: vertical;
  line-height: 1.2em;
  height: 3.6em;
}
.h-244 {
  height: 244px;
  display: flex;
  align-items: center;
  //background-color: #F8F8F7;
  //border-radius: 5px;
}
</style>
