<template>
    <div id="signup_info">
        <section class="accordion_notice_box">
            <div class="accordion_notice_item">
                <a class="accordion_notice_tit" href="#n">
                    <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                    {{ i18n.myoffice.myoSearchForm.notice }}
                    <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></div>
                </a>
                <div class="accordion_notice_cont">
                    <div class="myo_search_notice">
                        <p>{{ i18n.resetpw.userDel.notice2 }}</p>
                        <p>{{ i18n.resetpw.userDel.notice3 }}</p>
                        <p>{{ i18n.resetpw.userDel.notice_consumer }}</p>
                    </div>
                </div>
            </div>
        </section>

        <h2 class="myo_table_tit">{{ i18n.myoffice.withdrawal }}</h2>
        <ul id="myo_form">
            <li class="item">
                <span class="item_title">{{ i18n.resetpw.reset.certinum }}</span>
                <span class="item_content">
                    <input type="text" v-model="aproval">
                  <button type="button" v-on:click="send_data" id="send_btn" v-show="flag == 0" class="withdrawal_btn ml-2 ">{{ i18n.resetpw.reset.button1 }}</button>
                </span>

            </li>
        </ul>

        <div class="myo_condition_search2" id="submit_area">

            <button type="button" v-on:click="apro_userDel" id="send_btn1" v-show="flag == 1" class="withdrawal_btn">{{ i18n.resetpw.userDel.button2 }}</button>

        </div>

    </div>

</template>

<script>
export default {
    name: "MyMemberWithdrawal",
    data: () => ({
        i18n: window.i18n,
        aproval: "",
        flag: 0,
    }),
    methods: {
        send_data() {

            axios.post(window.Laravel.back_url + '/api/sendUserDelAproval',
                { "aproval" : this.aproval },
                {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
                    if(response.data=="1") {
                        alert(i18n.resetpw.reset.alert1);
                        this.flag = 1;
                        return;
                    }
                    if(response.data=="-2") {
                        alert(i18n.resetpw.reset.alert2);
                        return;
                    }
                    if(response.data=="-1") {
                        alert(i18n.resetpw.reset.alert3);
                        return;
                    }
                    if(response.data=="-9") {
                        alert(i18n.resetpw.reset.alert4);
                        return;
                    }
                    alert(i18n.resetpw.reset.alert12+" : "+response.data.msgCode+" "+response.data.statusMsg);
            });
        },
        apro_userDel() {
            axios.post(window.Laravel.back_url + '/api/apro_userDel',
                { "aproval" : this.aproval },
                {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
                if(response.data.output=="1") {
                    alert(i18n.resetpw.userDel.alert1);
                    location.href = "/sso/logout";
                    return;
                }
                if(response.data.output=="-2") {
                    alert(i18n.resetpw.reset.alert7);
                    return;
                }
                if(response.data.output=="-4") {
                    alert(i18n.resetpw.reset.alert8);
                    return;
                }
                if(response.data.output=="-5") {
                    alert(i18n.resetpw.reset.alert9);
                    return;
                }
                if(response.data.output=="-6") {
                    alert(i18n.resetpw.reset.alert10);
                    return;
                }
                if(response.data.output=="-9") {
                    alert(i18n.resetpw.reset.alert11);
                    return;
                }
                alert(i18n.resetpw.reset.alert12);
                return;
            });
        }
    }
}
</script>

<style scoped>

</style>
