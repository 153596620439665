<!--  현재 페이지의 메뉴 : hover와 동일하게 적용한 상태 -->
<style>
   #back_office_left_menu > li > ul.on > li > a { background: #4b5460; }
</style>
<template>
    <div id="sidebar-menu" class=" ">
        <div class="navi">
            <div class="tit">MY OFFICE</div>
            <nav>
                <transition name="fade">
                    <ul id="back_office_left_menu"  class="">
                        <li v-for="Value in navigations" v-on:click="NavChange(Value.no)" :class="{'on' : Value.url === thisPage}" :data-id="Value.no">
                            <a :href="(Value.url==='') ? null : Value.url" v-show="Value.no != 11">
                                <i :class="Value.icon"></i>
                                <span>{{ Value.title }}</span><i v-if="Value.child" class="fal fa-angle-down" aria-hidden="true"></i>
                            </a>
                            <a :href="(Value.url==='') ? null : Value.url" v-show="Value.no == 11">
                                <i ></i>
                                <img src="/images/autoship_icon_pink.png" class="h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-500">
                                <span>{{ Value.title }}</span><i v-if="Value.child" class="fal fa-angle-down" aria-hidden="true"></i>
                            </a>

                            <ul v-for="sub in Value.child" v-if="Value.child" :class="{'on' : sub.url === thisPage}" :data-id="sub.no">
                                <li v-show="navId == Value.no  && sub.rank_cd  && rank_cd >= 60">
                                    <a :href="sub.url">{{ sub.title }}</a>
                                </li>
                                <li v-show="navId == Value.no && !sub.rank_cd"  v-if="sub.gourl" style="display: none;">
                                    <a :href="sub.url" @click="goAutoLogin(sub.urlLink, 'tools/main.php')">{{ sub.title }}</a>
                                </li>
                                <li v-show="navId == Value.no  && !sub.rank_cd"  v-else style="display: none;">
                                  <a :href="sub.url">{{ sub.title }}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </transition>

            </nav>
        </div>
    </div>
</template>
<script>
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyofficeNav",
    // props : ['my_office_menu'],
    data: () => ({
        product_categorys: [],
        isLoggedIn: false,
        i18n: window.i18n,
        rank_cd : window.Laravel.rank_cd,
        isVcMember : window.Laravel.isVcMember,
        thisPage : document.location.pathname,
        navigations : [],
        all_navigations : [
        {
          "url": "/MyofficeMain",
          "no": 1,
          title: i18n.menu.MyofficeMain,
          isVcmShow : true,
          icon: "fal fa-home-lg-alt",
        },
          {
            "url": '#',
            "no": 7,
            title: i18n.menu.MyRegister,
            icon: "fal fa-user-plus",
            gourl: 'Y',
            isVcmShow : false,
            urlLink: "register"
          },
          {
            "no": 8,
            title: i18n.menu.MyPersonInfo,
            icon: "fal fa-user",
            isVcmShow : true,
            child: [{
              "url": "/MyPersonInfo",
              "no": 8,
              isVcmShow : true,
              title: i18n.menu.MyPersonInfo,
            },
              {
                "url": "/MyPoint",
                "no": 8,
                isVcmShow : false,
                title: i18n.menu.MyPoint
              },
              {
                "url": "/MyMemberUpChange",
                "no": 8,
                isVcmShow : false,
                title: i18n.menu.MyMemberUpChange
              },
            ]
          },

          {
            "no": 2,
            title: i18n.menu.MyPersonInfos,
            icon: "fal fa-user-cog",
            isVcmShow : false,
            child: [{
              "url": "/MyMemberFavorites",
              "no": 2,
              isVcmShow : false,
              title: i18n.menu.MyMemberFavorites
            },
              {
                "url": "/MyMember",
                "no": 2,
                isVcmShow : false,
                title: i18n.menu.MyMember
              },
              {
                "url": "/MyMemberLegBox",
                "no": 2,
                isVcmShow : false,
                title: i18n.menu.MyMemberLegBox
              },
              {
                "url": "/MyMemberLegLine",
                "no": 2,
                isVcmShow : false,
                title: i18n.menu.MyMemberLegLine
              },
              {
                "url": "/MyMemberLegBoxCompression",
                "no": 2,
                isVcmShow : false,
                title: i18n.menu.MyMemberLegBoxCompression
              },
              // {"url" : "/MyMemberRank","no" : 2,title : i18n.menu.MyMemberRank},

              // {"url" : "/MemberReport","no" : 2,title : i18n.menu.MemberReport},
              //{"url" : "/underMemberInfo","no" : 2,title : i18n.menu.underMemberInfo}
            ]
          },
          {
            "no": 3,
            title: i18n.menu.MyOrders,
            icon: "fal fa-tasks",
            isVcmShow : true,
            child: [
              // {"url" : "/MyOrderList","no" : 3,title : i18n.menu.MyOrderList},
              {
                "url": "/MyOrderDown",
                "no": 3,
                isVcmShow : true,
                title: i18n.menu.MyOrderList
              },
              // {"url" : "/MyOrderDirDown","no" : 3,title : i18n.menu.MyOrderDirDown},
              {
                "url": "/MyOrderLegs",
                "no": 3,
                isVcmShow : false,
                title: i18n.menu.MyOrderLegs
              },
              {
                "url": "/MyOrderCancel",
                "no": 3,
                isVcmShow : true,
                title: i18n.menu.MyOrderCancel
              },
            ]
          },
          {
            "no": 11,
            title: i18n.menu.autoshiplist,
            icon: "fa fa-user-circle",
            isVcmShow : false,
            child: [{
              "url": "/MyAutoshipCardChange",
              "no": 9,
              isVcmShow : false,
              title: i18n.menu.MyAutoshipCardChange
            },
              {
                "url": "/MyAutoshipEdit",
                "no": 9,
                isVcmShow : false,
                title: i18n.menu.MyAutoshipEdit
              },
              {
                "url": "/MyAutoshipWithdrawal",
                "no": 9,
                isVcmShow : false,
                title: i18n.menu.MyAutoshipWithdrawal
              },
            ]
          },
          /* 아직 사용 안 해서 주석처리 */
          {
            "no": 4,
            title: i18n.menu.MyBenefit,
            icon: "fal fa-money-check",
            isVcmShow : true,
            child: [{
              "url": "/MyBenefitSearch",
              "no": 4,
              isVcmShow : true,
              title: i18n.menu.MyBenefitSearch
            },
              //{"url" : "/MyBenefitTotalSearch","no" : 4,title : i18n.menu.MyBenefitTotalSearch},
              {
                "url": "/MyBenefitDetailHistory",
                "no": 4,
                isVcmShow : true,
                title: i18n.menu.MyBenefitDetailHistory
              },
              {
                "url": "/MyBenefitList",
                "no": 4,
                isVcmShow : true,
                title: i18n.menu.MyBenefitList
              }, // Commission Statement
              //{"url" : "/MyBenefitTranslHistory","no" : 4,title : i18n.menu.MyBenefitTranslHistory}, // Commission Remittance
              //{"url" : "/MyBenefitTaxBreakHistory","no" : 4,title : i18n.menu.MyBenefitTaxBreakHistory},
              //{"url" : "/MyBenefitCalendar","no" : 4,title : i18n.menu.MyBenefitCalendar},
              //{"url" : "/MyBenefitAnalysis","no" : 4,title : i18n.menu.MyBenefitAnalysis},
              {
                "url": "/MyBenefitAllowanceReturn",
                "no": 4,
                isVcmShow : true,
                title: i18n.menu.MyBenefitAllowanceReturn
              } // Commission Reduction
            ]
          },
          {
            "no": 5,
            title: i18n.menu.Omnitrition,
            icon: "fal fa-comments",
            isVcmShow : false,
            child: [{
              "url": "/MyOrderItems",
              "no": 5,
              isVcmShow : false,
              title: i18n.menu.MyOrderItems
            },
              {
                "url": "/MyCustomer",
                "no": 5,
                isVcmShow : false,
                title: i18n.menu.MyCustomer
              },
              {
                "url": "/MyCustomerOrderRegister",
                "no": 5,
                isVcmShow : false,
                title: i18n.menu.MyCustomerOrderRegister
              },
              {
                "url": "/MyCustomerOrder",
                "no": 5,
                isVcmShow : false,
                title: i18n.menu.MyCustomerOrder
              },
              {
                "url": "/MyOmnitrition",
                "no": 5,
                isVcmShow : false,
                title: i18n.menu.MyOmnitrition
              }
            ]
          },
          {
            "no": 6,
            title: i18n.menu.MyCommunity,
            icon: "fal fa-comments",
            isVcmShow : true,
            child: [{
              "url": "/MyInQuriy",
              "no": 6,
              isVcmShow : true,
              title: i18n.menu.MyInQuriy
            },
              {
                "url": "/MyNotice",
                "no": 6,
                isVcmShow : true,
                title: i18n.menu.MyNotice
              },
              {
                "url": "/MyData",
                "no": 6,
                isVcmShow : true,
                title: i18n.menu.MyData
              },
              /*{"url" : "#","no" : 6,title : i18n.menu.MyEducation, gourl:'Y',urlLink : "brand"},*/
              /*{"url" : "/MyFAQ","no" : 6,title : i18n.menu.MyFAQ},*/
              {
                "url": "/MyVPData",
                "no": 6,
                isVcmShow : false,
                title: i18n.menu.MyVPData,
                rank_cd: 60
              },
              {
                "url": "https://gcoopdrive.com",
                "no": 6,
                isVcmShow : false,
                title: i18n.menu.GCOOP_drive
              },
            ]
          },
          {
            "url": "/ActivateCard",
            "no": 10,
            title: 'Gift Card',
            icon: "fal fa-gift-card",
            isVcmShow : false,
            child: [{
              "url": "/ActivateCard",
              "no": 10,
              isVcmShow : false,
              title: 'Activate Card'
            },
              {
                "url": "/GiftCardHistory",
                "no": 10,
                isVcmShow : false,
                title: 'Gift Card History'
              },
            ]
          },
          // {
          //   "url": "/HallofFame",
          //   "no": 9,
          //   title: i18n.menu.HallofFame,
          //   isVcmShow : false,
          //   icon: "fal fa-crown"
          // },
          {
            "url": "/",
            "no": 0,
            title: i18n.menu.Shopping,
            isVcmShow : true,
            icon: "fal fa-shopping-cart"
          }

          // {"url" : "/BusinessGuide","no" : 0,title : i18n.menu.BusinessGuide,icon : "fa fa-book"}


        ],
        navId : 1,
        commition_check  : false,
    }),
    created() {
      this.navigations =
        (this.isVcMember === true) ? this.filterNavigations(this.all_navigations) : this.all_navigations;
    },
    beforeCreate() {
        // 카테고리 메뉴
        // axios.post(window.Laravel.back_url+'/api/getMyOfficeMenu', {
        //     bz_cd: window.Laravel.bz_cd
        // }).then(response => {
        //     ///this.navigations = response.data;
        // }).catch(function (error) {console.log(error);});
      axios.post(window.Laravel.back_url + '/api/getjoininfoDocu',{ bz_cd : window.Laravel.bz_cd }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        this.commition_check = response.data.commition_check;
        //dylee 20230324 미주 최치원팀장 요청
        // 수당 발생시 소메뉴 오픈
        if(this.commition_check && this.isVcMember === false){
          this.navigations[2].child[3] = {"url" : "/MyforinerUpload","no" : 8,title : i18n.menu.MyBankInfo};
        }
      });
    },
    mounted() {
        if (window.Laravel.isLoggedin) {
            this.isLoggedIn = true;
            this.user = window.Laravel.user;
            this.rank_cd = window.Laravel.rank_cd;

            if(this.rank_cd == '00' && this.isVcMember === false ){//Comsumer 메뉴 삭제
              this.navigations.splice(1,1);//Member registration
              this.navigations[1].child.splice(2,1);
              this.navigations.splice(2,1);//Team managment
              this.navigations[2].child.splice(1,1);
              this.navigations.splice(4,2);//Commission Management,Consumer
              this.navigations[4].child.splice(2,1);//Library
              this.navigations[2].child[0].url = '/MyOrderList';

            }
        }
      // 좌측 메뉴 현재 페이지 열리도록
        var navOn       = document.querySelector("#back_office_left_menu .on");
        if(navOn) this.navId      = navOn.getAttribute('data-id');
    },
    methods: {
        filterNavigations(navigations) {
          return navigations.reduce((acc, item) => {
            let newItem = {...item};
            if (newItem.isVcmShow) {
              if (newItem.child) {
                newItem.child = this.filterNavigations(newItem.child);
              }
              acc.push(newItem);
            }
            return acc;
          }, []);
        },
        NavChange(id){
            if(id == 7){
              this.goAutoLogin('register','');
            }
            /*
            if(this.navId == id){
                id = 0;
            }
            */
            this.navId = id;
        },
        goAutoLogin(mode, url) {
          if(mode == 'register'){
            if(this.rank_cd != '00'){
              location.href="/Register";
            }else{
              VueSimpleAlert.alert("Impossible","","",{allowOutsideClick : false}).then((result) => {})
            }
          }else{
            let config = {
              headers: {
                Authorization: 'Bearer ' + window.Laravel.access_token
              }
            };

            axios.post(window.Laravel.back_url +'/api/goAutoLogin',{
              mode: mode,
              url: url,
              bz_cd: window.Laravel.bz_cd,
            },config).then(response => {
              if(response.data.href_url != ""){
                window.open(response.data.href_url);
              }
            }).catch(e => {
            });
          }
        }
    },
}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
#back_office_left_menu > li {
  cursor: pointer;
}
</style>
