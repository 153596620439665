<template>
    <div class="member-search">
        <section class="myo_condition_box relative">
            <dl class="myo_search_list">
                <dt>
                    <h3 class="font-semibold text-[#172e59] text-lg min-h-1/3">
                        {{ i18n.myoffice.Commission.DateofBalance }}
                    </h3>
                </dt> <!-- 마감일자 -->
                <dd class="">
                    <div class="flex xl:flex-row lg:flex-row flex-col gap-2 w-full">
                        <datepicker
                            v-model="searchDate"
                            month-picker
                            locale="en"
                            :format="format"
                            :max-date="new Date()"
                            :min-date="minDate"
                            auto-apply
                            style="width:100%"
                        ></datepicker>

                        <!-- :min-date="new Date(2024,5, 1 )" //-->
                    </div>
                </dd>
                <dd>
                    <span class="pb-1 xl:text-sm lg:text-sm text-[10px]break-keep text-red-500 font-semibold">{{ i18n.myoffice.Commission.searchInfo }}</span>
                </dd>
            </dl>
        </section><!-- // myo_condition_box -->
    </div>
</template>

<script>
import vSelect from "vue-select";
import datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: "SearchDate",
    props: ['getYear', 'getMonth', 'dash_d_val', 'isDashboard'],
    components: {
        vSelect,
        datepicker
    },
    data: () => ({
        i18n: window.i18n,
        format: "MM/yyyy",
        currentYear: '',
        currentMonth: '',
        searchDate: '',
        minDate: '',
        isInit: true,
    }),
    watch: {

        searchDate: function (searchDate) {

            // created 에서 값이 반영될 때는 동작하지 않는다.
            if (this.isInit === true) {
                this.isInit = false
                return
            }

            // console.group("=== this.searchDate ===")
            // console.dir(searchDate)
            // console.dir(this.searchDate )
            this.currentYear = String(searchDate.year);
            this.currentMonth = (searchDate.month + 1).toString().padStart(2, '0');
            // console.dir(this.currentYear )
            // console.dir(this.currentMonth )
            // console.groupEnd("=== this.searchDate ===")

            this.$emit('searchMonthDashBoard', this.currentYear, this.currentMonth);
        }
    },
    created() {

        if (this.dash_d_val['PAY_DATE'] !== undefined) {

            this.currentYear = String(this.dash_d_val['PAY_DATE']).substring(0, 4); // 첫 번째에서 네 번째 자리까지 추출 (연도)
            this.currentMonth = String(this.dash_d_val['PAY_DATE']).substring(4, 6); // 다섯 번째에서 여섯 번째 자리까지 추출 (월)

            this.searchDate = {
                month: Number(this.currentMonth) - 1,
                year: this.currentYear
            }
        }

        this.getMinDate();
    },
    mounted() {
    },
    methods: {
        getMinDate() {
            // if (this.isDashboard === true) {
            //     this.minDate = new Date(2024, 3, 1)
            // } else {
            //     this.minDate = new Date(2000, 3, 1)
            // }
            this.minDate = new Date(2024, 3, 1)
        },
        formatPrice(value, digit) {
            let val = (value / 1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        search: function () {

            // console.group("$$$$$$$$$$$$$$$")
            // console.log(this.currentYear)
            // console.log(this.currentMonth)
            // console.groupEnd("$$$$$$$$$$$$$$$")

            this.$emit('searchMonthDashBoard', this.currentYear, this.currentMonth);
            // window.location.href = '/MyofficeMain/' + String(this.currentYear) + String(this.currentMonth)
        }
    },
}
</script>

<style scoped>
.dp__overlay_cell_disabled {
    color: grey !important; /* 글자 색상을 회색으로 변경 */
    background-color: #f0f0f0 !important;; /* 배경 색상을 연한 회색으로 변경 */
    pointer-events: none !important;; /* 클릭 이벤트를 비활성화 */
    cursor: not-allowed !important;; /* 커서를 금지 표시로 변경 */
    border: 1px solid #ff0000 !important;
}

.vuepic-datepicker .is-disabled {
    color: grey; /* 글자 색상을 회색으로 변경 */
    background-color: #f0f0f0; /* 배경 색상을 연한 회색으로 변경 */
    pointer-events: none; /* 클릭 이벤트를 비활성화 */
    cursor: not-allowed; /* 커서를 금지 표시로 변경 */
}
</style>
