<template>
  <loading :active="loading"
           :can-cancel="true"
           :is-full-page="true"></loading>
  <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
    <address_search @set_address="set_address" @close_address_search="close_address_search" :nt_cd="this.nt_cd"/>
  </modalView>

  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 xl:px-6 lg:px-6 px-4 lg:max-w-7xl bg-white">
        <div class="w-full lg:flex lg:justify-start">
          <div class="w-full">
            <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start flex-col">
                  <div class="w-full bg-white lg:p-2" id="infomodify" >
                    <h2 class="xl:text-2xl lg:text-xl text-lg mt-3 mb-6 font-semibold">Registration - Business Entity</h2>
                    <!--START : 국가선택-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>Nation</div>
                      <div class="w-full lg:w-5/6 flex flex-row items-center gap-4">
                        <div class="flex items-center cursor-pointer">
                          <input type="radio" v-model="radioNation" value="Unitedstates" id="us"><label for="us" class="cursor-pointer">&nbsp;United states</label>
                        </div>
                        <div class="flex items-center cursor-pointer">
                          <input type="radio" v-model="radioNation" value="Canada" id="ca"><label for="ca" class="cursor-pointer">&nbsp;Canada</label>
                        </div>
                      </div>
                    </div>
                    <!--END : 국가선택-->
                    <!--START : Federal Employer Identification Number-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>
                        <span v-if="radioNation == 'Unitedstates'">Federal Employer Identification Number</span>
                        <span v-if="radioNation == 'Canada'">Canada Business Number</span>
                      </div>
                      <div class="w-full lg:w-5/6">
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:py-2 lg:items-center mb-4" v-if="false">
                          <div class="w-full flex flex-row items-center gap-4">
                            <div class="flex items-center cursor-pointer">
                              <input type="radio" v-model="SubscriptionCategory" value="C" id="C_Corporation" selected><label for="C_Corporation" class="cursor-pointer">&nbsp;C Corporation</label>
                            </div>
                            <div class="flex items-center cursor-pointer">
                              <input type="radio" v-model="SubscriptionCategory" value="S" id="S_Corporation" ><label for="S_Corporation" class="cursor-pointer">&nbsp;S Corporation</label>
                            </div>
                          </div>
                        </div>
                        <div class="w-full lg:w-5/6 ">
                          <div class="flex xl:flex-row lg:flex-row flex-col">
                            <div class="flex flex-row items-center gap-2">
                              <input name="BusinessNumber1"  class="type_email h-10 xl:w-32 lg:w-32 w-1/2" v-if="radioNation == 'Unitedstates'" v-model="BusinessNumber1" style="ime-mode:disabled;" type="text" :readonly="is_business_num_chk == true" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="2">
                              <input name="BusinessNumber1"  class="type_email h-10 xl:w-48 lg:w-48 w-full" v-if="radioNation == 'Canada'"  v-model="BusinessNumber1" style="ime-mode:disabled;" type="text" :readonly="is_business_num_chk == true" oninput="this.value = this.value.replace(/(\..*)\./g, '$1');" maxlength="15">
                              <span v-if="radioNation == 'Unitedstates'">-</span>
                              <input name="BusinessNumber2"  class="type_email h-10 xl:w-32 lg:w-32 w-1/2" v-if="radioNation == 'Unitedstates'"  v-model="BusinessNumber2" style="ime-mode:disabled;" type="text" :readonly="is_business_num_chk == true" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="7">
                            </div>
                            <input type="button" v-if="businessChkBtn" @click="checkBusinessNum"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] cursor-pointer mt-2 ml-2 xl:ml-2 lg:mt-0 xl:mt-0" value="Check"/>
                          </div>
                          <div id="businessMsg" class="oran text-red-700 text-sm">{{this.businessMsg}}</div>
                        </div>
                      </div>
                    </div>
                    <!--END : Federal Employer Identification Number-->

                    <!--START : Name of Business Entity -->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>Name of Business Entity (to appear on all correspondence)</div>
                      <div class="w-full lg:w-5/6">
                        <input name="sa_name" id="sa_name" class=" add-input1 h-10 w-full xl:w-80 lg:w-80" v-model="sa_name" type="text"> <br>
                      </div>
                    </div>
                    <!--END :  Name of Business Entity -->
                    <!--START : DBA -->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2">DBA(Doing Business As)if different from above</div>
                      <div class="w-full lg:w-5/6">
                        <input name="sa_name" id="sa_dba" class=" add-input1 h-10 w-full xl:w-80 lg:w-80" v-model="sa_dba" type="text"> <br>
                      </div>
                    </div>
                    <!--END :  DBA-->

                    <!--START : 비밀번호-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>{{ i18n.myoffice.Password }}</div>
                      <div class="w-full lg:w-5/6">
                        <div class="flex xl:flex-row lg:flex-row flex-col">
                            <input @blur="checkPassword"  v-if="!show" name="passwd" id="passwd" class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  v-model="passwd" style="ime-mode:disabled;" type="password">
                            <input @blur="checkPassword"  v-if="show" name="passwd" id="passwd" class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  v-model="passwd" style="ime-mode:disabled;" type="text">
                            <input type="button" v-if='!show' @click="showPwd('show')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="View"/>
                            <input type="button" v-if='show' @click="showPwd('show')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="View"/>
                          </div>
                          <div class="w-1/2 text-sm text-red-700">Enter between 8 to 15 characters, containing letters and numbers.</div>
                      </div>
                    </div>
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-3">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>{{ i18n.myoffice.Passwordcheck }}</div>
                      <div class="w-full lg:w-5/6">
                        <div class="flex xl:flex-row lg:flex-row flex-col">
                          <input @blur="checkPassword" v-if="!show_confirm" name="confirmPasswd" id="confirmPasswd" class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  v-model="confirmPasswd" style="ime-mode:disabled;" type="password">
                          <input @blur="checkPassword" v-if="show_confirm" name="confirmPasswd" id="confirmPasswd" class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  v-model="confirmPasswd" style="ime-mode:disabled;" type="text">
                          <input type="button" v-if='!show_confirm' @click="showPwd('show_confirm')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="View"/>
                          <input type="button" v-if='show_confirm' @click="showPwd('show_confirm')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="View"/>
                        </div>
                        <div id="passMsg" class="oran text-red-700 text-sm">Enter between 8 to 15 characters, containing letters and numbers.</div>
                      </div>
                    </div>
                    <!--END : 비밀번호-->
                    <!--START : 이메일-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>{{ i18n.myoffice.email }}</div>
                      <div class="w-full lg:w-5/6 ">
                        <div class="flex xl:flex-row lg:flex-row flex-col">
                          <div class="flex flex-row items-center gap-2">
                            <input name="sa_email1" id="sa_email1" class="type_email h-10 w-1/2 xl:w-60 lg:w-60"  v-model="sa_email1" style="ime-mode:disabled;" type="text" :readonly="is_email_chk == true">
                            @
                            <input name="sa_email2" id="sa_email2" class="type_email h-10 w-1/2 xl:w-60 lg:w-60"  v-model="sa_email2" style="ime-mode:disabled;" type="text" :readonly="is_email_chk == true">
                          </div>
                          <input type="button" v-if="emailChkBtn" @click="checkEmail"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] cursor-pointer mt-2 ml-2 xl:ml-2 lg:mt-0 xl:mt-0" value="Check"/>
                        </div>
                        <div id="emailMsg" class="oran text-red-700 text-sm">{{this.emailMsg}}</div>
                      </div>
                    </div>
                    <!--END : 이메일-->
                    <!--START : 주소-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>Zip Code</div>
                      <div class="w-full lg:w-5/6">
                        <input name="postNoSch" id="postNoSch" class="type_number h-10 w-full lg:w-48" @click="search_address" v-model="postNoSch" maxlength='5' type="text" readonly>
                        <input type="button" @click="search_address"  class="h-10 w-full lg:w-32 leading-6 text-white bg-[#172e59] cursor-pointer mt-2  ml-2 xl:ml-2 lg:mt-0 xl:mt-0" value="Search address"/>
                      </div>
                    </div>

                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>State</div>
                      <div class="w-full lg:w-5/6">
                        <input type='text' name="selectState" id="selectState" v-model='selectState' readonly @click="search_address" maxlength="40" class="h-10 w-full xl:w-60 lg:w-60">
                      </div>
                    </div>
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>City</div>
                      <div class="w-full lg:w-5/6">
                        <input type='text'  name="selectCity" id="selectCity" v-model='selectCity' readonly @click="search_address" maxlength="40" class="h-10 w-full xl:w-60 lg:w-60">
                      </div>
                    </div>
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>Address Line1</div>
                      <div class="w-full lg:w-5/6">
                        <input type='text' name="regionNameSch" id="regionNameSch" v-model='regionNameSch' maxlength="40" class="h-10 w-full">
                      </div>
                    </div>
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2">Address Line2</div>
                      <div class="w-full lg:w-5/6">
                        <input name="regionNamebusiness" id="regionNamebusiness" class=" add-input1 h-10 w-full" v-model="regionNamebusiness" type="text"> <br>
                        <div class="text-red-700 text-sm">Add Apt #, Floor, etc</div>
                      </div>
                    </div>
                    <!--END : 주소-->
                    <!--START : 법인휴대전화-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>Business Phone Number</div>
                      <div class="w-full lg:w-5/6">
                        <div class="flex xl:flex-row lg:flex-row flex-col">
                          <div class="flex flex-row gap-2">
                            <input type="text" v-model="sa_tel1"  id="sa_tel1" :readonly="is_mobile_chk == true" maxlength="3" class="type_number h-10 w-1/3 xl:w-28 lg:w-28"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                            <input type="text" v-model="sa_tel2"  id="sa_tel2" :readonly="is_mobile_chk == true" maxlength="3" class="type_number h-10 w-1/3 xl:w-28 lg:w-28"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                            <input type="text" v-model="sa_tel3"  id="sa_tel3" :readonly="is_mobile_chk == true" maxlength="4" class="type_number h-10 w-1/3 xl:w-28 lg:w-28"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                          </div>
                          <input type="button" v-if='sendBtn'  @click="checkMobile"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] cursor-pointer mt-2 ml-2 xl:ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="Check"/>
                          <input type="button" v-if='duplChkBtn' @click="checkMobile"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 lg:mt-0 xl:mt-0" value="Check"/>
                          <input type="button" v-if='ressetMblChkBtn' @click="resetMobile"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 lg:mt-0 xl:mt-0" value="Reset"/>
                        </div>
                        <div class="flex xl:flex-row lg:flex-row flex-col mt-2">
                          <div class="flex xl:flex-row lg:flex-row flex-col gap-2">
                            <input type="text" v-if="smsChkBtn" v-model="mobile_sms_chk"   maxlength="6" class="type_number h-10 w-full xl:w-60 lg:w-60"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                            <input type="button" v-if="smsChkBtn" @click="checkConfirmMobile"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] cursor-pointer mt-2 ml-2 xl:ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="Confirm"/>
                            <span type="text" v-if="smsChkBtn"  class="border-0 text-red-700 h-10 w-20 xl:w-30 lg:w-30"  >{{this.TimerStr}}</span>
                          </div>
                          <div id="mobileMsg" class="oran text-red-700 text-sm">{{this.mobileMsg}}</div>
                        </div>
                      </div>
                    </div>
                    <!--END : 휴대전화-->




                    <!-- Sponsor -->
                    <h2 class="xl:text-2xl lg:text-xl text-lg mt-3 mb-6 font-semibold">Sponsor</h2>
                    <!--START : 추천인-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:pt-2 lg:items-center mb-4 pb-4 border-b-2 border-zinc-500 ">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>{{ i18n.join.join.EnrollmentSponsorID }}</div>
                      <input type='text' name="rId" id="rId" v-model='rId' readonly  maxlength="40" class="h-10 w-full xl:w-60 lg:w-60">
                      <input type='hidden' name="rrId" id="rrId" v-model='rrId' readonly  maxlength="40" class="h-10 w-full xl:w-60 lg:w-60">
                      <input type='hidden' name="rrName" id="rrName" v-model='rrName' readonly  maxlength="40" class="h-10 w-full xl:w-60 lg:w-60">
                    </div>
                    <!--END : 추천인-->


                    <!-- 회원 정보 -->
                    <div class="flex justify-between text-lg mb-4">
                      <h2 class="xl:text-2xl lg:text-xl text-lg mt-3 mb-6 font-semibold">CEO Information</h2>
                      <div class="flex justify-end">
                        <MinusSmIcon class="h-6 w-6" aria-hidden="true" />
                        <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
                      </div>
                    </div>

                    <!--START : 이름-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>{{ i18n.myoffice.memberName }}</div>
                      <div class="w-full lg:w-5/6 flex xl:flex-row lg:flex-row flex-col gap-2">
                        <input type='text' name="givenName" id="givenName" v-model='givenName'  maxlength="40" class="h-10 w-full xl:w-80 lg:w-80" placeholder="First name" oninput="this.value = this.value.replace(/[^a-z]/gi,'');">
                        <input type='text' name="familyName" id="familyName" v-model='familyName'  maxlength="40" class="h-10 w-full xl:w-80 lg:w-80" placeholder="Last name" oninput="this.value = this.value.replace(/[^a-z]/gi,'');">
                      </div>
                    </div>
                    <!--END : 이름-->

                    <!--START : CEO 휴대전화-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>{{ i18n.myoffice.handphone }}</div>
                      <div class="w-full lg:w-5/6">
                        <div class="flex flex-row gap-2">
                          <input type="text" v-model="ceoMobile1"  id="ceoMobile1" maxlength="3" class="type_number h-10 w-1/3 xl:w-28 lg:w-28"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                          <input type="text" v-model="ceoMobile2"  id="ceoMobile2" maxlength="3" class="type_number h-10 w-1/3 xl:w-28 lg:w-28"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                          <input type="text" v-model="ceoMobile3"  id="ceoMobile3" maxlength="4" class="type_number h-10 w-1/3 xl:w-28 lg:w-28"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                        </div>
                      </div>
                    </div>
                    <!--END : CEO 휴대전화-->
                    <!--START : CEO 이메일-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>CEO {{ i18n.myoffice.email }}</div>
                      <div class="w-full lg:w-5/6 ">
                        <div class="flex xl:flex-row lg:flex-row flex-col">
                          <div class="flex flex-row items-center gap-2">
                            <input name="ceo_email1" id="ceo_email1" class="type_email h-10 w-full xl:w-80 lg:w-80"  v-model="ceo_email1" style="ime-mode:disabled;" type="text" >
                            @
                            <input name="ceo_email2" id="ceo_email2" class="type_email h-10 w-full xl:w-80 lg:w-80"  v-model="ceo_email2" style="ime-mode:disabled;" type="text"  placeholder="ex)google.com">
                          </div>
                          <input type="button" v-if="false" @click="is_ceoMobile_chk"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] cursor-pointer mt-2 xl:ml-2 lg:mt-0 xl:mt-0" value="Check"/>
                        </div>
                        <div id="ceoEmailMsg" class="oran text-red-700 text-sm">{{this.ceoEmailMsg}}</div>
                      </div>
                    </div>
                    <!--END : CEO 이메일-->

                    <!--START : 생년월일-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>Birth Date</div>
                      <div class="w-full lg:w-5/6">
                        <div class="w-full lg:w-5/6 flex gap-2">
                          <select name="strtMonthSch" id="MonthSch" class="xl:w-40 lg:w-40 w-full text-sm md:text-base" v-model=strtMonthSch>
                            <option value="" selected>Month</option>
                            <option value="01">Jan</option>
                            <option value="02">Feb</option>
                            <option value="03">Mar</option>
                            <option value="04">Apr</option>
                            <option value="05">May</option>
                            <option value="06">Jun</option>
                            <option value="07">Jul</option>
                            <option value="08">Aug</option>
                            <option value="09">Sep</option>
                            <option value="10">Oct</option>
                            <option value="11">Nov</option>
                            <option value="12">Dec</option>
                          </select>
                          <select name="strtDaySch" id="DaySch" class="xl:w-40 lg:w-40 w-full text-sm md:text-base" v-model=strtDaySch>
                            <option value="" selected>Day</option>
                            <option v-for="(item, index) in days" :key="item" :value="item">{{item}}</option>
                          </select>
                          <select name="strtYearSch" id="YearSch" class="xl:w-40 lg:w-40 w-full text-sm md:text-base" v-model=strtYearSch>
                            <option value="" selected>Year</option>
                            <option v-for="(item, index) in years" :key="item" :value="item">{{item}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <!--END : 생년월일-->
                  </div><!-- // -->

                  <!-- GCOOP Membership Owners -->
                  <h2 class="xl:text-2xl lg:text-xl text-lg mt-3 mb-6 font-semibold">Business Addendum</h2>
                  <div class="text-right">
                    <button class=""><svg xmlns="http://www.w3.org/2000/svg" class="w-4" viewBox="0 0 384 512" v-on:click="showMessage = !showMessage"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M169.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 274.7 54.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg></button>
                  </div>
                  <div class="w-full xl:text-base lg:text-base text-sm border p-4" v-if="showMessage">
                    All members, managers, shareholders, trustees, partners, or others with any ownership interest in the business entity and each employee, agent, or representative of the business entity (collectively “Owners”) shall be jointly and severally liable for all contracts entered into with GCOOP. Each Owner is individually bound to and must comply with and agree to the GCOOPER Terms and Policies and all documents incorporated by reference into the Independent GCOOPER Application and Agreement (collectively the “Agreement”). These documents are available in My Office. Violation of the Agreement by any Owner or employee of the Business Entity may be jointly and severally imputed to the Entity and all Owners of the Entity. Failure to list all appropriate persons on this Addendum, and/or update this Addendum as ownership changes occur, shall be grounds for disciplinary sanctions as described in the Terms and Policies. Each Owner certifies that neither he/she, nor any household family member, has any ownership, financial, or equitable interest in, or managerial responsibility for, any other GCOOP business, and has not had any such interest or responsibility for at least three calendar months prior to submitting this Addendum.
                  </div>
                  <!--START :  Primary Shareholder-->
                  <div v-for="(sa,key) in sa_data">
                    <div class="w-full bg-gray-300 p-4">
                      <div class="w-full xl:text-base lg:text-base text-sm">
                        List all persons who have any ownership interest in the business entity. (i) primary membership owner and (ii) all other Shareholders that has an interest in the Business Entity (Board Members, Employees, Shareholders) All signatures below affirm that each of the signing parties is an individual with an interest or position in this Business Entity, who has read and will comply with the contents of the GCOOPER Terms and Policies. The (i) primary membership owner is an authorized agent of the business entity and membership and is authorized to sign and execute contracts and business on behalf of the business entity.
                      </div>
                      <div class="w-full mt-4">
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">(i) Primary Shareholder (First Name, Last Name)</div>
                          <div class="w-full lg:w-5/6 flex xl:flex-row lg:flex-row flex-col gap-2">
                            <input type='text' name="sh_first_name[]"  v-model='sa.sa_first_name'  maxlength="40" class="h-10 w-full xl:w-80 lg:w-80" placeholder="First name" oninput="this.value = this.value.replace(/[^a-z]/gi,'');">
                            <input type='text' name="sh_last_name[]"  v-model='sa.sa_last_name'  maxlength="40" class="h-10 w-full xl:w-80 lg:w-80" placeholder="Last name" oninput="this.value = this.value.replace(/[^a-z]/gi,'');">
                          </div>
                        </div>

                        <!--START : Title-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">Title</div>
                          <div class="w-full lg:w-5/6 flex xl:flex-row lg:flex-row flex-col gap-2">
                            <input type='text' name="sh_title[]"  v-model='sa.sa_title'  maxlength="40" class="h-10 w-full xl:w-80 lg:w-80" >
                          </div>
                        </div>
                        <!--END :  Title -->
                        <!--START : SSN / ITIN-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4" >
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">
                            <div v-if="radioNation == 'Unitedstates'">{{ i18n.join.join.memformtext18 }}</div>
                            <div v-if="radioNation == 'Canada'">{{ i18n.join.join.memformtext18_1}}</div>
                          </div>
                          <div class="w-full lg:w-5/6 flex xl:flex-row lg:flex-row flex-col">
                            <select name="sh_ssn_type" class="w-23  h-10 md:w-1/6 sm:w-full text-sm md:text-base" v-model="sa.sa_ssn_type">
                              <option value="S" v-if="radioNation == 'Unitedstates'">SSN</option>
                              <option value="I" v-if="radioNation == 'Unitedstates'">ITIN</option>
                              <option value="SIN" v-if="radioNation == 'Canada'">SIN</option>
                            </select>
                            <div class="flex gap-2 xl:ml-2 lg:ml-2 ml-0 xl:mt-0 lg:mt-0 mt-2">
                              <input v-if="sa.sa_ssn_type == 'S'" name="sh_ssn[]"  maxlength="11"  class="type_email h-10 w-1/3 md:w-4/6 sm:w-full xl:w-60 "  v-model="sa.sa_ssn" style="ime-mode:disabled;" type="text" :readonly="sa.is_jumin_chk == true" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" @blur="this.value = 'dddd'">
                              <input v-if="sa.sa_ssn_type == 'I'" name="sh_ssn[]"  maxlength="9"  class="type_email h-10 w-1/3 md:w-4/6 sm:w-full xl:w-60 "  v-model="sa.sa_ssn" style="ime-mode:disabled;" type="text" :readonly="sa.is_jumin_chk == true" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" @blur="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                              <input v-if="sa.sa_ssn_type == 'SIN'" name="sh_ssn[]"  maxlength="9"  class="type_email h-10 w-1/3 md:w-4/6 sm:w-full xl:w-60 "  v-model="sa.sa_ssn" style="ime-mode:disabled;" type="text" :readonly="sa.is_jumin_chk == true" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" @blur="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                              <input type="button" v-if="ssnChkBtn" @click="chkJumin"  class="h-10 w-full md:w-1/6 lg:w-16 leading-6 text-white bg-[#172e59] cursor-pointer mt-2 xl:ml-2 lg:mt-0 xl:mt-0" value="Check" >
                              <div id="juminMsg" class="oran text-red-700 text-sm">{{this.juminMsg}}</div>
                            </div>
                          </div>

                        </div>

                        <!--END : SSN / ITIN-->
                        <!--START : Email-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">Email Address</div>
                          <div class="w-full lg:w-5/6 flex xl:flex-row lg:flex-row flex-col gap-2">
                            <input type='text' name="sh_email[]"  v-model='sa.sa_email'  maxlength="40" class="h-10 w-full xl:w-80 lg:w-80" >
                          </div>
                        </div>
                        <!--END :  Email -->

                        <!--START : Phone-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">Phone</div>
                          <div class="flex flex-row">
                            <div class="flex flex-row gap-2">
                              <input type='text' name="sa_mobile1"  v-model='sa.sa_mobile1'  maxlength="3" class="h-10 w-1/3 xl:w-28 lg:w-28"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                              <input type='text' name="sa_mobile2"  v-model='sa.sa_mobile2'  maxlength="3" class="h-10 w-1/3 xl:w-28 lg:w-28"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                              <input type='text' name="sa.sa_mobile3"  v-model='sa.sa_mobile3'  maxlength="4" class="h-10 w-1/3 xl:w-28 lg:w-28"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                            </div>
                            <input type="button" v-if='sendPrimaryBtn' @click="checkPrimaryMobile"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="Check"/>
                          </div>
                          <div  class="oran text-red-700 text-sm">{{this.mobilePrimaryMsg}}</div>
                        </div>
                        <!--END :  Phone -->
                      </div>
                    </div>
                  </div>
                  <!--END :  Primary Shareholder-->
                  <div class="w-full  p-4 border-b-2 border-black">
                    <div class="w-full">
                      (ii) Other participants that has an interest in the Business Entity
                    </div>
                    <!-- Start : Other participants that has an interest in the Business Entity -->
                    <div v-for="(sh,key) in sh_data" class="border-b-2 border-black last:border-b-0">
                      <div class="w-full mt-4 border-b-2 border-black last:border-b-0">
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">Name</div>
                          <div class="w-full lg:w-5/6 flex xl:flex-row lg:flex-row flex-col gap-2">
                            <input type='text' name="sh_first_name[]"  v-model='sh.sh_first_name' maxlength="40" class="h-10 w-full xl:w-80 lg:w-80" placeholder="First name" oninput="this.value = this.value.replace(/[^a-z]/gi,'');">
                            <input type='text' name="sh_last_name[]"  v-model='sh.sh_last_name'  maxlength="40" class="h-10 w-full xl:w-80 lg:w-80" placeholder="Last name" oninput="this.value = this.value.replace(/[^a-z]/gi,'');">
                          </div>
                        </div>
                        <!--START : Title-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">Title</div>
                          <div class="w-full lg:w-5/6 flex xl:flex-row lg:flex-row flex-col gap-2">
                            <input type='text' name="sh_title[]"  v-model='sh.sh_title'  maxlength="40" class="h-10 w-full xl:w-80 lg:w-80" >
                          </div>
                        </div>
                        <!--END :  Title -->
                        <!--START : Email-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">Email</div>
                          <div class="w-full lg:w-5/6 flex xl:flex-row lg:flex-row flex-col gap-2">
                            <input type='text' name="sh_email[]"  v-model='sh.sh_email' maxlength="40" class="h-10 w-full xl:w-80 lg:w-80" >
                          </div>
                        </div>
                        <!--END :  Email -->
                        <!--START : SSN / ITIN-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4" >
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">
                            <div v-if="radioNation == 'Unitedstates'">{{ i18n.join.join.memformtext18 }}</div>
                            <div v-if="radioNation == 'Canada'">{{ i18n.join.join.memformtext18_1}}</div>
                          </div>
                          <div class="w-full lg:w-5/6 flex xl:flex-row lg:flex-row flex-col">
                            <select name="sh_ssn_type" class="w-23  h-10 md:w-1/6 sm:w-full text-sm md:text-base" v-model="sh.sh_ssn_type">
                              <option value="S" v-if="radioNation == 'Unitedstates'">SSN</option>
                              <option value="I" v-if="radioNation == 'Unitedstates'">ITIN</option>
                              <option value="SIN" v-if="radioNation == 'Canada'">SIN</option>
                            </select>
                            <div class="flex gap-2 xl:ml-2 lg:ml-2 ml-0 xl:mt-0 lg:mt-0 mt-2">
                              <input v-if="sh.sh_ssn_type == 'S'" name="sh_ssn[]"  maxlength="11"  class="type_email h-10 w-1/3 md:w-4/6 sm:w-full xl:w-60 "  v-model="sh.sh_ssn" style="ime-mode:disabled;" type="text" :readonly="is_jumin_chk == true" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                              <input v-if="sh.sh_ssn_type == 'I'" name="sh_ssn[]"  maxlength="9"  class="type_email h-10 w-1/3 md:w-4/6 sm:w-full xl:w-60 "  v-model="sh.sh_ssn" style="ime-mode:disabled;" type="text" :readonly="is_jumin_chk == true" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                              <input v-if="sh.sh_ssn_type == 'SIN'" name="sh_ssn[]"  maxlength="9"  class="type_email h-10 w-1/3 md:w-4/6 sm:w-full xl:w-60 "  v-model="sh.sh_ssn" style="ime-mode:disabled;" type="text" :readonly="is_jumin_chk == true" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                            </div>
                            <input type="button" @click="chkJumin"  class="h-10 w-full md:w-1/6 lg:w-16 leading-6 text-white bg-[#172e59] cursor-pointer mt-2 xl:ml-2 lg:mt-0 xl:mt-0" value="Check" v-if="sh.is_jumin_chk == false">
                          </div>
                        </div>
                        <!--END : SSN / ITIN-->
                        <!--START : Phone-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">Phone</div>
                          <div class="w-full lg:w-5/6 flex xl:flex-row lg:flex-row flex-col gap-2">
                            <input type='text' name="sh_mobile[]"  v-model='sh.sh_mobile' maxlength="10" class="h-10 w-full xl:w-80 lg:w-80" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                          </div>
                        </div>
                        <div class="flex gap-2 mt-4">
                          <button type="button" v-on:click="remove_sh(key)" class="my-2 py-2 px-4 leading-6 bg-[#cbcfdd] hover:bg-black text-white xl:w-16 lg:w-16 w-1/2 reset_btn rounded-md">Delete</button>
                        </div>
                        <!--END :  Phone -->
                      </div>
                    </div>

                    <!-- END : Other participants that has an interest in the Business Entity -->

                  </div>




                  <div class="flex gap-2 mt-4">
                    <button type="button" v-on:click="Add_form" class="my-2 py-2 px-4 leading-6 text-white bg-[#2c80c4] hover:bg-[#24679f] xl:w-16 lg:w-16 w-1/2 search_btn rounded-md">Add</button>
                  </div>


                  <div class="w-full mt-4">
                    <div class="w-full">According to the GCOOPER Terms & Conditions, GCOOP USA Corp. enforces that GCOOPERs enrolled as a business entity must resell at least 80% of all products purchased. GCOOP USA Corp. reserves the right to request proof of sale at any time. Failure to comply with these terms will result in automatic termination of the GCOOP membership. Do you agree to comply with these terms?</div>
                    <div class="w-full my-3 flex flex-row">
                      <input type="checkbox" value="1" id="agreeAll" name="agreeAll" v-model="isCheckAll" true-value="true" false-value="false"  class="mt-2 cursor-pointer"> <!-- 약관 전체 동의하기 -->
                      <label class="cursor-pointertext-base text-sm ml-2 text-gray-500 cursor-pointer"  for="agreeAll">
                        I agree to comply with the GCOOPER Terms & Conditions and resell at least 80% of all products I purchase through my GCOOP member ship. I acknowledge that I need to provide proof of sale at company's request and failure to comply with these terms will result in automatic termination of my GCOOP membership.
                      </label>
                    </div>
                    <div class="w-full text-sm">
                      In order to verify the Business information you've provided above, please attach a copy of
                      <br>(i) W-9 Form, (ii) Sales Tax License, (iii) Resale Certificate, and (iv) Business Entity Addendum. Submission of these documents are mandatory to complete enrollment as a Business Entity</div>
                  </div>


                  <div class="w-full bg-white lg:p-2" id="optionInfo" v-if="false">
                    <!--START : 기존 아이디-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2">{{ i18n.join.join.OriginUserID }}</div>
                      <input type='text' name="origin_user_id" id="origin_user_id" v-model='origin_user_id'   maxlength="10"  class="h-10 w-full xl:w-60 lg:w-60">
                    </div>
                    <!--END : 기존 아이디-->
                    <!--START : 기존 아이디-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2">{{ i18n.join.join.OriginUserMobile }}</div>
                      <input type='text' name="origin_user_mobile" id="origin_user_mobile" v-model='origin_user_mobile'   maxlength="10" class="h-10 w-full xl:w-60 lg:w-60">
                    </div>
                    <!--END : 기존 아이디-->
                  </div>


                  <div class="flex gap-2 mt-4">
                    <button type="button" v-on:click="send_agree" class="my-2 py-2 px-4 leading-6 bg-[#d5e7f6] hover:bg-[#cbcfdd] text-[#2c7fc3] xl:w-16 lg:w-16 w-1/2 reset_btn rounded-md">Cancel</button>
                    <button type="button" v-on:click="submitForm"  v-if="submitFormBtn" class="my-2 py-2 px-4 leading-6 text-white bg-[#2c80c4] hover:bg-[#24679f] xl:w-16 lg:w-16 w-1/2 search_btn rounded-md">{{ i18n.myoffice.save }}</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class
             ="block lg:hidden xl:hidden mt-4">
        <SideMenu></SideMenu>
      </div>
    </div>

  </section>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import MypageTop from './mypage/MypageTop.vue';
import SideMenu from './mypage/SideMenu.vue';
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import {createPopper} from "@popperjs/core";
import modalView from "./modalView";
import vSelect from "vue-select";
import dayjs from "dayjs";

export default {
  name: "MyRegisterBusiness",
  components : {
    VueSimpleAlert,
    ShoppingCartIcon,
    MypageTop,
    SideMenu,
    Loading,
    modalView,
    vSelect
  },
  data: () => ({
    showMessage: true,

    i18n: window.i18n,
    laravel: window.Laravel,

    no_search_messsage: '',
    isLoggedIn: false,
    loading : false,
    mem: "",
    myconfig:"",



    radioNation : "Unitedstates",
    /* START : 파라미터 변수 선언 */
    SubscriptionCategory : "C",
    BusinessNumber1 : "",
    BusinessNumber2 : "",
    sa_name : "",
    sa_dba : "",
    passwd : "",
    confirmPasswd : "",
    sa_tel1 : "",
    sa_tel2 : "",
    sa_tel3 : "",
    sa_email1 : "",
    sa_email2 : "",
    country : "",
    nt_cd : "001",
    postNoSch : "",
    selectState : "",
    selectCity : "",
    regionNameSch : "",
    regionNamebusiness : "",


    rId : "",
    rrId : "",
    rrName : "",

    givenName : "",
    familyName: "",
    ceoMobile1 : "",
    ceoMobile2 : "",
    ceoMobile3 : "",

    ceo_email1 : "",
    ceo_email2 : "",


    strtDaySch:"",
    strtMonthSch:"",
    strtYearSch:"",

    //(i) Primary Shareholder
    sa_data : [{
      sa_first_name : "",
      sa_last_name : "",
      sa_title : "",
      sa_email : "",
      sa_ssn_type : "S",
      sa_ssn : "",
      sa_mobile1 : "",
      sa_mobile2 : "",
      sa_mobile3 : "",
      is_jumin_chk : "N"
    }],
    //(ii) Other participants that has an interest in the Business Entity
    sh_data : [{
      sh_first_name : "",
      sh_last_name : "",
      sh_title : "",
      sh_email : "",
      sh_ssn_type : "S",
      sh_ssn : "",
      sh_mobile : "",
      is_jumin_chk : "N"
    }],

    /* END : 파라미터 변수 선언 */

    /* 버튼 Show hide 변수*/
    smsChkBtn : false,
    smsChkPrimaryBtn : false,
    sendBtn : false,

    duplChkBtn : true,
    ressetMblChkBtn : false,

    sendPrimaryBtn : true,
    emailChkBtn : true,
    businessChkBtn : true,
    ssnChkBtn : true,
    submitFormBtn : true,


    mobile_sms_chk : "",//sms 코드 인증
    mobile_primary_sms_chk : "",//primary sms 코드 인증
    TimeCounter : 300, //sms 인증 제한 초
    TimerStr : "",//초 -> 분 형식변경


    loginId : "",


    origin_user_id : "",
    origin_user_mobile : "",





    /* 유효성 검사 변수 */
    is_pwd_chk : false,
    /*
      2023-02-14
      MYOFFICE 에서 ITIM / SSN 을 별도로 받기로 하여 유효성검사 is_jumin_chk 강제 true 변경함
    */
    is_jumin_chk : false,
    is_email_chk : false,
    is_mobile_chk : false,
    is_primarymobile_chk : false,
    is_age_chk : false,
    is_business_num_chk : false,

    isCheckAll : false,

    fullPage : true,
    state_list : "",
    city_list : "",
    street_list : "",
    address_search_open:false,
    isMatchStateCity:false,

    /* 기본 선언 변수 */
    days:['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
    years:[],

    is_login: true,

    /*문구*/

    emailMsg : "",
    mobileMsg : "",
    ceoEmailMsg : "",
    mobilePrimaryMsg : "",
    businessMsg : "",
    juminMsg : "",

    //비밀번호 보이기감추기
    show : false,
    show_confirm : false,

  }),

  beforeCreate() {


  },
  created() {

    let year = new Date().getFullYear();
    for(let i=(year-18); i>=(year-100); i--){
      this.years.push(
        i
      );
    }

  },
  watch : {

    //생년월일 체크
    strtDaySch : function(){
      this.chkAge(this.strtDaySch,this.strtMonthSch,this.strtYearSch);
    },
    strtMonthSch : function(){
      this.chkAge(this.strtDaySch,this.strtMonthSch,this.strtYearSch);
    },
    strtYearSch : function(){
      this.chkAge(this.strtDaySch,this.strtMonthSch,this.strtYearSch);
    },
    //연도 데이터 호출
    s_month : function(){
      var total_day = dayjs(this.s_year+this.s_month).daysInMonth();
      var day = 1;
      for(day ; day <= total_day ; day++){
        this.days.push(
          {value: day}
        );
      }
    },
    //국적변경
    radioNation : function(){
      if( this.radioNation == 'Canada'){
        this.BusinessNumber1 = "";
        this.BusinessNumber2 = "";
        this.nt_cd="002";
        this.sa_data[0].sa_ssn_type  = "SIN";
        for(let i =0; i < this.sh_data.length ;i++){
          this.sh_data[i].sh_ssn_type = "SIN";
        }
      }else{
        this.BusinessNumber1 = "";
        this.BusinessNumber2 = "";
        this.nt_cd="001";
        this.sa_data.sa_ssn_type  = "S";
        for(let i; i < this.sh_data.length ;i++){
          this.sh_data[i].sh_ssn_type = "S";
        }
      }
      this.is_mobile_chk = false;
      this.is_jumin_chk = false;
    },
    sh_ssn_type : function(){
      this.sh_ssn = [];
    }
  },
  mounted() {
    if (window.Laravel.registerRid) {
      this.isLoggedIn = true;
      // this.loginId = window.Laravel.user;
      this.rId = window.Laravel.registerRid;
      this.rrId = window.Laravel.registerRid;
      this.rrName = window.Laravel.registerRname;

      this.work_user_id = window.Laravel.user;
    }else{
      location.href='/';
    }
  },
  methods:{
    showPwd : function(select){
      //비밀번호 감추기 보이기
      if(select == 'show'){
        this.show = !this.show;
      }else if(select == 'show_confirm'){
        this.show_confirm = !this.show_confirm;
      }

    },
    updateArray (key) {
      this.$set(this.sa_data, 'key', 'value')
    },
    Add_form(){
      this.sh_data.push ({
        sh_first_name : "",
        sh_last_name : "",
        sh_title : "",
        sh_email : "",
        sh_ssn_type : "S",
        sh_ssn : "",
        sh_mobile : "",
        is_jumin_chk : "N"});
    },
    remove_sh(key){
      this.sh_data.splice(key, 1);
    },
    send_agree : function (){//agree 페이지로 이동
      location.href= './Register';
    },
    submitForm: function (event) {
      this.submitFormBtn = false;
      this.loading = true;
      let formData = new FormData();


      if(this.is_email_chk && this.sa_data[0].is_jumin_chk && this.is_mobile_chk && this.is_business_num_chk && this.is_primarymobile_chk && this.is_pwd_chk && this.is_age_chk && this.isCheckAll
        && this.regionNameSch != ""
      ) {//Submit

        /* START : Business Entity Membership Enrollment */
        formData.append('radioNation', this.radioNation);
        formData.append('SubscriptionCategory', this.SubscriptionCategory);
        formData.append('BusinessNumber1', this.BusinessNumber1);
        formData.append('BusinessNumber2', this.BusinessNumber2);
        formData.append('sa_name', this.sa_name);
        formData.append('sa_dba', this.sa_dba);
        formData.append('passwd', this.passwd);
        formData.append('confirmPasswd', this.confirmPasswd);
        formData.append('sa_email1', this.sa_email1);
        formData.append('sa_email2', this.sa_email2);
        formData.append('postNoSch', this.postNoSch);
        formData.append('country', this.country );
        formData.append('selectState', this.selectState);
        formData.append('selectCity', this.selectCity);
        formData.append('regionNameSch', this.regionNameSch);
        formData.append('regionNamebusiness', this.regionNamebusiness);
        formData.append('sa_tel1', this.sa_tel1);
        formData.append('sa_tel2', this.sa_tel2);
        formData.append('sa_tel3', this.sa_tel3);
        /* END : Business Entity Membership Enrollment */

        /* START : Sponsor */
        formData.append('rrId', this.rrId);
        formData.append('rrName', this.rrName);
        /* END : Sponsor */

        /* START : CEO Information */
        formData.append('givenName', this.givenName);
        formData.append('familyName', this.familyName);

        formData.append('ceoMobile1', this.ceoMobile1);
        formData.append('ceoMobile2', this.ceoMobile2);
        formData.append('ceoMobile3', this.ceoMobile3);

        formData.append('ceo_email1', this.ceo_email1);
        formData.append('ceo_email2', this.ceo_email2);

        formData.append('strtDaySch', this.strtDaySch);
        formData.append('strtMonthSch', this.strtMonthSch);
        formData.append('strtYearSch', this.strtYearSch);
        /* END : CEO Information */

        formData.append('sa_data', JSON.stringify(this.sa_data));
        formData.append('sh_data',JSON.stringify(this.sh_data));

        formData.append('type', "N");
        formData.append('stype', "addMember");
        formData.append('agreeMail', "Y");
        formData.append('agreeSms', "Y");
        formData.append('selectBank', "999");
        formData.append('memberKind', "1");
        formData.append('rankCd', "01");


        formData.append('workUserId', this.work_user_id);


        formData.append('origin_user_id', this.origin_user_id);
        formData.append('origin_user_mobile', this.origin_user_mobile);


        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + window.Laravel.access_token
          }
        }


        axios.post(window.Laravel.back_url+'/api/insertSaveBusinessMemberUs',
          formData
          , config).then(response => {
          if(response.data.status == "1"){
            VueSimpleAlert.alert("Registration complete.","","",{allowOutsideClick : false}).then((result) => { //회원가입이 완료 되었습니다.
              if(result){
                location.replace("/RegisterOk?userid="+response.data.SP_KEYVALUE+"&xid="+response.data.xid);
              }
            });
            this.loading = false;
          }
          else if(response.data.result != "1"){
            VueSimpleAlert.alert(response.data.message,"","",{allowOutsideClick : false}).then((result) => {
              if(result){
              }
            });
          }
          this.loading = false;

        });

      }else{// 가입 조건 미충족
        this.submitFormBtn = true;
        if(!this.is_email_chk){
          VueSimpleAlert.alert("Check your email","","",{allowOutsideClick : false}).then((result) => {});
        }
        // 2023-02-15 dylee SSN ITIN MYOFFICE 입력
        if(!this.sa_data[0].is_jumin_chk){
          VueSimpleAlert.alert("Check your SSN/ITIN","","",{allowOutsideClick : false}).then((result) => {});
        }
        if(!this.is_mobile_chk){
          VueSimpleAlert.alert("Check your sa_tel","","",{allowOutsideClick : false}).then((result) => {});
        }
        if(!this.is_pwd_chk){
          VueSimpleAlert.alert("Check your password","","",{allowOutsideClick : false}).then((result) => {});
        }
        if(!this.is_age_chk){
          VueSimpleAlert.alert("Check your age","","",{allowOutsideClick : false}).then((result) => {});
        }

        if(!this.is_business_num_chk){
          VueSimpleAlert.alert("Check the number of corp.","","",{allowOutsideClick : false}).then((result) => {});
        }
        if(!this.is_primarymobile_chk){
          VueSimpleAlert.alert("Check primary phone number","","",{allowOutsideClick : false}).then((result) => {});
        }
        if(!this.isCheckAll){
          VueSimpleAlert.alert("Check the checkbox","","",{allowOutsideClick : false}).then((result) => {});
        }
        if(this.regionNameSch == ""){
          VueSimpleAlert.alert("Check your address","","",{allowOutsideClick : false}).then((result) => {});
        }

      }
      this.loading = false;
    },

    checkBusinessNum(){
      this.loading = true;
      axios.post(process.env.MIX_BACK_URL+'/api/chkBusinessNumber', {
        businessNumber : (this.BusinessNumber1) +  (this.BusinessNumber2 == ""?"":"-"+ this.BusinessNumber2),
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        if (response.data.cnt > 0) {
          //중복 사업자 존재
          this.businessMsg = i18n.join.join.businessduplicate;/* 중복된 사업자 번호가 있습니다.*/
          this.is_email_chk = false;
        } else {
          //중복 사업자 없음
          this.businessMsg = "Confirmed";/* 중복된 사업자 번호가 없습니다. */
          this.is_business_num_chk = true;
          this.businessChkBtn = false;//버튼 가리기
        }
        this.loading = false;
      })

    },
    checkEmail(){
       this.loading = true;
      //이메일 미입력시
      if(this.sa_email1.length < 1 || this.sa_email2.length < 1) {
        this.emailMsg = i18n.join.twalert.emailId;
        $("#sa_email1").focus();
        this.loading = false;
        return;
      }else{
        this.emailMsg = "";
      }
      //이메일 유효성체크
      this.email = this.sa_email1 + '@' + this.sa_email2;

      let regex =/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
      if(regex.test(this.email) == false){
        this.emailMsg = i18n.join.twalert.emailId2;
        this.loading = false;
        return false;
      }else{
        this.emailMsg = "";
      }
      axios.post(process.env.MIX_BACK_URL+'/api/getComparisonEmail', {
        email : this.email,
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        if(response.data.chk.checker == "N"){
          //중복 이메일 존재
          this.emailMsg = i18n.join.join.alert_emailchk1;/* 사용할 수 없는 핸드폰 번호입니다. */
          this.is_email_chk = false;
        }else{
          //중복 이메일 없음
          this.emailMsg = i18n.join.join.alert_emailchk4;/* 사용할 수 있는 핸드폰 번호입니다. */
          this.is_email_chk = true;
          this.emailChkBtn = false;
        }
        this.loading = false;


      });
    },

    resetMobile(){
      this.loading = true;
      this.mobile1 = "";
      this.mobile2 = "";
      this.mobile3 = "";

      this.is_mobile_chk = false;
      this.duplChkBtn =true;
      this.ressetMblChkBtn = false;

      this.loading = false;
    },

    checkMobile(){
      this.loading = true;
      this.mobile_sms_chk = "";
      //this.smsChkBtn = true;

      //모바일 미입력시
      if(this.sa_tel1.length < 1 || this.sa_tel2.length < 1 || this.sa_tel3.length < 1  ) {
        this.mobileMsg = i18n.join.twalert.en_phone;
        this.loading = false;
        return;
      }else if(this.sa_tel1.length != 3 ){
        this.mobileMsg = i18n.join.twalert.en_phone;
        this.loading = false;
        return;
      }
      else{
        this.mobileMsg = '';
      }


      //모바일 10자리 이하 입력시
      if((this.sa_tel1.length+this.sa_tel2.length+this.sa_tel3.length) < 10) {
        this.mobileMsg = i18n.join.join.phonechk;
        $("#mobile").focus();
        this.loading = false;
        return false;

      }else{
        this.mobileMsg = '';
      }


      //모바일 유효성 검사
      axios.post(process.env.MIX_BACK_URL+'/api/getComparisonMobile', {
        mobile : this.sa_tel1+"-"+this.sa_tel2+"-"+this.sa_tel3,
      }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {

        if (response.data.chk.checker == "0") {
          VueSimpleAlert.alert("Confirmed","","",{allowOutsideClick : false}).then((result) => {})
          //문자인증 비활성화 20230425 최치원 팀장 요청
          /*this.timerStart();//인증문자 제한시간 시작

          axios.post(process.env.MIX_BACK_URL+'/api/sendRegisterAproval', {
            nt_cd : this.nt_cd,
            mobile : this.sa_tel1+"-"+this.sa_tel2+"-"+this.sa_tel3,
          }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {

          });*/
          this.is_mobile_chk = true;
          this.duplChkBtn =false;
          this.ressetMblChkBtn = true;
        } else {
          this.smsChkBtn = false;
          this.is_mobile_chk = false;
          this.mobileMsg = i18n.join.myalert.notAvailPhoneNotice1; /!* 사용할 수 없는 핸드폰 번호입니다. *!/
        }
        this.loading = false;
      });



    },
    //문자 확인코드
    checkConfirmMobile(){
      this.loading = true;
      axios.post(process.env.MIX_BACK_URL+'/api/sendRegisterAprovalCode', {
        code : this.mobile_sms_chk,
        nt_cd : this.nt_cd,
        mobile : this.sa_tel1+"-"+this.sa_tel2+"-"+this.sa_tel3,
      }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {

        if(response.data == 1 ){//성공
          this.mobileMsg = "Confirmed";

          this.is_mobile_chk = true;
          this.sendBtn =false;
          this.smsChkBtn = false;
        }else if(response.data == 1){
          this.mobileMsg = "Code is not available. Please retry again";
          this.is_mobile_chk = false;
          this.smsChkBtn = true;
        }else{
          this.mobileMsg = "Please retry again";
          this.is_mobile_chk = false;
          this.smsChkBtn = true;
        }
        this.loading = false;
      });

    },

    checkPrimaryMobile(){
      this.loading = true;
      this.mobile_primary_sms_chk = "";
      this.smsChkPrimaryBtn = true;

      //모바일 미입력시
      if(this.sa_data[0].sa_mobile1.length < 1 || this.sa_data[0].sa_mobile2.length < 1 || this.sa_data[0].sa_mobile3.length < 1  ) {
        this.mobilePrimaryMsg = i18n.join.twalert.en_phone;
        return;
      }else if(this.sa_data[0].sa_mobile1.length != 3 ){
        this.mobilePrimaryMsg = i18n.join.twalert.en_phone;
        return;
      }
      else{
        this.mobilePrimaryMsg = '';
      }


      //모바일 10자리 이하 입력시
      if((this.sa_data[0].sa_mobile1.length+this.sa_data[0].sa_mobile2.length+this.sa_data[0].sa_mobile3.length) < 10) {
        this.mobilePrimaryMsg = i18n.join.join.phonechk;
        $("#mobile").focus();
        return false;

      }else{
        this.mobilePrimaryMsg = '';
      }

      //모바일 유효성 검사
      axios.post(process.env.MIX_BACK_URL+'/api/getComparisonMobile', {
        mobile : this.sa_data[0].sa_mobile1+"-"+this.sa_data[0].sa_mobile2+"-"+this.sa_data[0].sa_mobile3,
      }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {

        if (response.data.chk.checker == "0") {
          this.is_primarymobile_chk = true;
          this.mobilePrimaryMsg = i18n.myoffice.myofficeAlert.alert20; /!* 사용할 수 없는 핸드폰 번호입니다. *!/
          this.sendPrimaryBtn = false;
        } else {
          this.is_primarymobile_chk = false;
          this.mobilePrimaryMsg = i18n.myoffice.myofficeAlert.alert21; /!* 사용할 수 없는 핸드폰 번호입니다. *!/
        }
        this.loading = false;
      });



    },

    /* START : SMS 인증 시간 함수*/
    timerStart: function() {
      // SMS 인증 1초에 한번씩 start 호출
      this.TimeCounter = 300;
      var interval = setInterval(() => {
        if(this.TimeCounter > 0){
          this.TimeCounter--; //1초씩 감소
          this.TimerStr = this.prettyTime();
          if (this.TimeCounter <= 0) this.timerStop(interval);
        }
      }, 1000);
      return interval;
    },
    prettyTime: function() {
      // 시간 형식으로 변환 리턴
      let time = this.TimeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return (
        minutes.toString().padStart(2, "0") +
        ":" +
        secondes.toString().padStart(2, "0")
      );
    },
    timerStop: function(Timer) {
      clearInterval(Timer);
      this.TimeCounter = 0;
      if(!this.is_mobile_chk){
        this.mobileMsg = "Code has expired";
      }
      this.smsChkBtn = false;
    },
    /* END : SMS 인증 시간 함수*/

    //비밀번호, 비밀번호 확인 동일 확인
    checkPassword : function(e){
      //비밀번호 6자리 이하
      if(this.passwd.length < 6) {
        $("#passMsg").text(i18n.join.twalert.passchk3);//영문 및 숫자를 조합하여, 6~8자리로 입력 해 주시기 바랍니다
        this.is_pwd_chk = false;
        return;
      }
      //비밀번호 확인 미입력시
      if(this.passwd.length < 1 || this.confirmPasswd.length < 1) {
        $("#passMsg").text(i18n.join.twalert.password1);//영문 및 숫자를 조합하여, 6~8자리로 입력 해 주시기 바랍니다
        this.is_pwd_chk = false;
        return;
      }

      //비밀번호, 비밀번호 확인 일치여부
      if(this.passwd != this.confirmPasswd) {
        $("#passMsg").text(i18n.join.twalert.password2);
        this.is_pwd_chk = false;
        return;
      }else{
        $("#passMsg").text('');
        this.is_pwd_chk = true;
      }

    },

    chkJumin(){
      this.loading = true;
      axios.post(process.env.MIX_BACK_URL+'/api/businessChkjumin', {
        ssn : this.sa_data[0].sa_ssn,
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        if( response.data.data.cnt == "0" ){ // 가입가능
          this.sa_data[0].is_jumin_chk = "Y";
          this.juminMsg = "Available";
          this.ssnChkBtn = false;
        }else {
          this.sa_data[0].is_jumin_chk = "N";
          this.juminMsg = i18n.join.join.ssn_notice;
        }
        this.loading = false;
      });

    },
    chkAge(year,mon,day){
      // 미국 미성년자 기준은 만 18세
      // 기준확인을 하기 위한 참고한 사이트 : http://m.blog.naver.com/woongjooseok/220711033579

      if(year == "" || mon == "" || day == ""){
        return;
      }
      //비교를위해 숫자타입으로 변경.
      year = Number(this.strtYearSch);
      mon  = Number(this.strtMonthSch);
      day  = Number(this.strtDaySch);

      //성년기준 나이
      let cut = 18;

      //체크용 날짜형식
      let set = new Date();
      let setYear =  Number(set.getFullYear());
      let setMon = Number((set.getMonth() + 1));
      let setDay = Number(set.getDate());

      // 현재나이
      let age = (setYear-1) - year;

      // 자신의 생일이 지난 만18세가 되면 허용
      if(age >= cut){
        this.is_age_chk = true;
        return;
      }else{
        this.is_age_chk = false;
      }

      // 월체크
      if(setMon > mon){
        this.is_age_chk = true;
        return;
      }else{
        this.is_age_chk = false;
      }

      //일체크
      if(setDay > day){
        this.is_age_chk = true;
      }else{
        this.is_age_chk = false;
      }
    },
    // 우편번호  가져오기
    getPostState(){   // 주(State) 목록 가져오기
      this.loading = true;
      axios.post(process.env.MIX_BACK_URL+'/api/getState',{

      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          // this.shipping_info["d_state"] = value;
          this.state_list = response.data;

          // console.log(this.state_list);
        })
        .catch(e => {
        });
      this.loading = false;
    },
    onChangeState(value) {   // 주(State) 선택

      axios.post(process.env.MIX_BACK_URL+'/api/getCity',{
        selectState: value,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          this.selectCity = "";
          this.Street = "";
          this.postNoSch = "";
          this.city_list = response.data;
          // console.log(this.city_list);
        })
        .catch(e => {
        });
    },
    onChangeCity(value) {  // 도시(City) 선택
      // console.log(event.target.value)
      axios.post(process.env.MIX_BACK_URL+'/api/getStreet',{
        selectState: this.selectState,
        selectCity: value,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          this.Street = "";
          this.postNoSch = "";
          this.street_list = response.data;
          // console.log(response.data);
        })
        .catch(e => {
        });
    },
    onChangeStreet(value) {  // 도로(Street) 선택
      axios.post(process.env.MIX_BACK_URL+'/api/getPostCodeForDelivery',{
        selectState: this.selectState,
        selectCity: this.selectCity,
        street: value,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          this.postNoSch =  response.data.postcode;
          this.post_list = response.data;
        })
        .catch(e => {
        });
    },
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "top",
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ selectState }) {
              component.$el.classList.toggle(
                'drop-up',
                selectState.placement === 'top'
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
    // 숫자 포멧
    formatPrice(value,digit) {
      let val = (value/1).toFixed(digit).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    // 숫자 포멧
    funcNumber(type,val) {
      let value = val;
      // 숫자만 남김
      var pattern_kor = /[^0-9]/gi;

      if(type =='jumin_1'){
        this.jumin_1 = val.replace(pattern_kor, "");
      }else if(type =='jumin_2'){
        this.jumin_2 = val.replace(pattern_kor, "");
      }else if(type =='jumin_3'){
        this.jumin_3 = val.replace(pattern_kor, "");
      }

    },
    search_address(){
      this.address_search_open = true;
    },
    set_address(value){
      this.address_search_open = false;
      this.selectState =value.state;
      this.selectCity = value.district;
      this.Street = "";
      this.postNoSch = value.postcode;
      this.country = value.country;
    },
    close_address_search(){
      this.address_search_open = false;
    },



    /* 이름 특수문자 자동 삭제 */
    funcUserName(e) {
      var tmp =  e.target.value;
      var regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;

      if(regExp.test(tmp)){
        var t = tmp.replace(regExp, "");
        tmp = t;
        if(e.target.id == "familyName"){
          this.familyName = tmp;
        }else if(e.target.id == "givenName"){
          this.givenName = tmp;
        }

      }
    },

  },

}


</script>

<style >
.v-select{ min-width: 100px; z-index:0 }
.m_menu{display:none;}
</style>
