<template>
  <loading :active="loading"
           :can-cancel="true"
           :is-full-page="true"></loading>
  <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
    <address_search @set_address="set_address" @close_address_search="close_address_search" :nt_cd="this.nt_cd"/>
  </modalView>
  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 xl:px-6 lg:px-6 px-4 lg:max-w-7xl bg-white">
        <div class="w-full lg:flex lg:justify-start">
          <div class="w-full">
            <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start flex-col">
                  <div class="w-full bg-white lg:p-2" id="infomodify" >
                    <div class="py-2">
                      <div class="flex justify-between text-lg mb-4">
                        <h2 class="xl:text-2xl lg:text-xl text-lg mt-3 mb-6 font-semibold">{{i18n.myoffice.Registration}} - Member</h2>
                        <div class="flex justify-end">
                          <MinusSmIcon class="h-6 w-6" aria-hidden="true" />
                          <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
                        </div>
                      </div>
                      <!--START : 국가선택-->
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                        <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>Nationality</div>
                        <div class="w-full lg:w-5/6 flex flex-row items-center gap-4">
                          <div class="flex items-center cursor-pointer">
                            <input type="radio" v-model="radioNation" value="Unitedstates" id="us"><label for="us" class="cursor-pointer">&nbsp;United States</label>
                          </div>
                          <div class="flex items-center cursor-pointer">
                            <input type="radio" v-model="radioNation" value="Canada" id="ca"><label for="ca" class="cursor-pointer">&nbsp;Canada</label>
                          </div>
                        </div>
                      </div>
                      <!--END : 국가선택-->
                      <!--START : 이름-->
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                        <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>{{ i18n.myoffice.memberName }}</div>
                        <div class="w-full lg:w-5/6 flex xl:flex-row lg:flex-row flex-col gap-2">
                          <input type='text' name="username_first" id="username_first" v-model='username_first'  maxlength="40" class="h-10 w-full xl:w-60 lg:w-60" placeholder="First name" oninput="this.value = this.value.replace(/[^a-z]/gi,'');">
                          <input type='text' name="username_last" id="username_last" v-model='username_last'  maxlength="40" class="h-10 w-full xl:w-60 lg:w-60" placeholder="Last name" oninput="this.value = this.value.replace(/[^a-z]/gi,'');">
                        </div>
                      </div>
                      <!--END : 이름-->
                      <!--START : 생년월일-->
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                        <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>Birth Date</div>
                        <div class="w-full lg:w-5/6">
                          <div class="w-full lg:w-5/6 flex gap-2">
                            <select name="strtMonthSch" id="MonthSch" class="xl:w-40 lg:w-40 w-full text-sm md:text-base" v-model=strtMonthSch>
                              <option value="" selected>Month</option>
                              <option value="01">Jan</option>
                              <option value="02">Feb</option>
                              <option value="03">Mar</option>
                              <option value="04">Apr</option>
                              <option value="05">May</option>
                              <option value="06">Jun</option>
                              <option value="07">Jul</option>
                              <option value="08">Aug</option>
                              <option value="09">Sep</option>
                              <option value="10">Oct</option>
                              <option value="11">Nov</option>
                              <option value="12">Dec</option>
                            </select>
                            <select name="strtDaySch" id="DaySch" class="xl:w-40 lg:w-40 w-full text-sm md:text-base" v-model=strtDaySch>
                              <option value="" selected>Day</option>
                              <option v-for="(item, index) in days" :key="item" :value="item">{{item}}</option>
                            </select>
                            <select name="strtYearSch" id="YearSch" class="xl:w-40 lg:w-40 w-full text-sm md:text-base" v-model=strtYearSch>
                              <option value="" selected>Year</option>
                              <option v-for="(item, index) in years" :key="item" :value="item">{{item}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <!--END : 생년월일-->
                      <!--START : 비밀번호-->
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                        <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>{{ i18n.myoffice.Password }}</div>
                        <div class="w-full lg:w-5/6">
                          <div class="flex xl:flex-row lg:flex-row flex-col">
                            <input v-if="!show" @blur="checkPassword" name="pwd" id="pwd" class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  v-model="pwd" style="ime-mode:disabled;" type="password">
                            <input v-if="show" @blur="checkPassword" name="pwd" id="pwd" class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  v-model="pwd" style="ime-mode:disabled;" type="text">
                            <input type="button" v-if='!show' @click="showPwd('show')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="View"/>
                            <input type="button" v-if='show' @click="showPwd('show')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="View"/>
                          </div>
                          <div class="w-fil text-sm text-red-500">Enter between 8 to 15 characters, containing letters and numbers.</div>
                        </div>
                      </div>

                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-3">
                        <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>{{ i18n.myoffice.Passwordcheck }}</div>
                        <div class="w-full lg:w-5/6">
                          <div class="flex xl:flex-row lg:flex-row flex-col">
                            <input v-if="!show_confirm" @blur="checkPassword" name="pwd_chk" id="pwd_chk"  class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  v-model="pwd_chk" style="ime-mode:disabled;" type="password">
                            <input v-if="show_confirm" @blur="checkPassword" name="pwd_chk" id="pwd_chk"   class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  v-model="pwd_chk" style="ime-mode:disabled;" type="text">
                            <input type="button" v-if='!show_confirm' @click="showPwd('show_confirm')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="View"/>
                            <input type="button" v-if='show_confirm' @click="showPwd('show_confirm')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="View"/>
                          </div>
                          <div id="passMsg" class="oran text-red-500 text-sm">Enter between 8 to 15 characters, containing letters and numbers.</div>
                        </div>
                      </div>
                      <!--END : 비밀번호-->

                      <!--START : 휴대전화-->
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                        <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>{{ i18n.myoffice.handphone }}</div>
                          <div class="w-full lg:w-5/6">
                            <div class="flex xl:flex-row lg:flex-row flex-col gap-2">
                              <div class="flex flex-row gap-2">
                                <input type="text" name="mobile1" v-model="mobile1" :readonly="is_mobile_chk == true" maxlength="3" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                <input type="text" name="mobile2" v-model="mobile2" :readonly="is_mobile_chk == true" maxlength="3" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                <input type="text" name="mobile3" v-model="mobile3" :readonly="is_mobile_chk == true" maxlength="4" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                              </div>
                              <input type="button" v-if='sendBtn' @click="checkMobile"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="Send"/>
                              <input type="button" v-if='duplChkBtn' @click="checkMobile"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 lg:mt-0 xl:mt-0" value="Check"/>
                              <input type="button" v-if='ressetMblChkBtn' @click="resetMobile"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 lg:mt-0 xl:mt-0" value="Reset"/>
                            </div>
                            <div class="flex flex-col">
                              <div class="flex xl:flex-row lg:flex-row flex-col gap-2">
                                <input type="text" v-if="smsChkBtn" v-model="mobile_sms_chk"   maxlength="6" class="type_number h-10 w-full xl:w-60 lg:w-60 mt-4"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                <input type="button" v-if="smsChkBtn" @click="checkConfirmMobile"  class="mt-4 h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-4 btn_mobileChk" value="Confirm"/>
                              </div>
                            <div id="mobileMsg" class="oran text-red-500 text-sm">{{this.mobileMsg}}</div>
                            <div v-if="smsChkBtn" class="border-0 text-red-500 h-10 w-20 xl:w-30 lg:w-30">{{this.TimerStr}}</div>
                          </div>
                        </div>
                      </div>
                      <!--END : 휴대전화-->
                      <!--START : 이메일-->
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                        <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>{{ i18n.myoffice.email }}</div>
                        <div class="w-full lg:w-5/6 ">
                          <div class="flex xl:flex-row lg:flex-row flex-col">
                              <div class="flex flex-row items-center gap-2">
                                <input name="email_1" id="email_1" class="type_email h-10 w-full xl:w-60 lg:w-60"  v-model="email_1" style="ime-mode:disabled;" type="text" :readonly="is_email_chk == true">
                                @
                                <input name="email_2" id="email_2" class="type_email h-10 w-full xl:w-60 lg:w-60"  v-model="email_2" style="ime-mode:disabled;" type="text" :readonly="is_email_chk == true">
                              </div>
                              <input type="button" v-if="emailChkBtn" @click="checkEmail"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 xl:ml-2 lg:mt-0 xl:mt-0" value="Check"/>
                          </div>
                          <div id="emailMsg" class="oran text-red-500 text-sm">{{this.emailMsg}}</div>
                        </div>
                      </div>
                      <!--END : 이메일-->


                        <!--START : SSN / ITIN-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4" v-if="false">
                            <div class="w-full lg:w-1/6 text-base font-semibold mb-2">
                              <div v-if="radioNation == 'United States'"><span class="text-red-500">*</span>{{ i18n.join.join.memformtext18 }}</div>
                              <div v-if="radioNation == 'Canada'"><span class="text-red-500" >*</span>{{ i18n.join.join.memformtext18_1}}</div>
                            </div>
                            <div class="w-full lg:w-5/6 flex xl:flex-row lg:flex-row flex-col">
                              <select name="jumin_type" id="jumin_type" class="w-23  h-10 md:w-1/6 sm:w-full text-sm md:text-base" v-model="jumin_type">
                                <option value="S" selected v-if="radioNation == 'United States'">SSN</option>
                                <option value="I" >ITIN</option>
                              </select>
                              <div class="flex gap-2 xl:ml-2 lg:ml-2 ml-0 xl:mt-0 lg:mt-0 mt-2">
                                <input v-if="jumin_type == 'S'" name="jumin_1" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="3"  class="type_email h-10 w-1/3 md:w-4/6 sm:w-full xl:w-60 "  v-model="jumin_1" style="ime-mode:disabled;" type="text" :readonly="is_jumin_chk == true">
                                <input v-if="jumin_type == 'I'" name="jumin_1" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="2"  class="type_email h-10 w-1/3 md:w-4/6 sm:w-full xl:w-60 "  v-model="jumin_1" style="ime-mode:disabled;" type="text" :readonly="is_jumin_chk == true">

                                <input v-if="jumin_type == 'S'" name="jumin_2" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="2"  class="type_email h-10 w-1/3 md:w-4/6 sm:w-full xl:w-60 "  v-model="jumin_2" style="ime-mode:disabled;" type="text" :readonly="is_jumin_chk == true">
                                <input v-if="jumin_type == 'I'" name="jumin_2" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="7"  class="type_email h-10 w-1/3 md:w-4/6 sm:w-full xl:w-60 "  v-model="jumin_2" style="ime-mode:disabled;" type="text" :readonly="is_jumin_chk == true">

                                <input v-if="jumin_type == 'S'" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="4" name="jumin_3"  class="type_email h-10 w-1/3 md:w-4/6 sm:w-full xl:w-60 "  v-model="jumin_3" style="ime-mode:disabled;" type="text" :readonly="is_jumin_chk == true">
                              </div>
                              <input type="button" @click="chkJumin"  class="h-10 w-full md:w-1/6 lg:w-16 leading-6 text-[#4f65ae] bg-[#d5e7f6] cursor-pointer mt-2 xl:ml-2 lg:mt-0 xl:mt-0" value="Check" v-if="is_jumin_chk == false">
                            </div>
                          </div>
                        </div>
                        <!--END : SSN / ITIN-->

                        <!--START : 주소-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>Zip Code</div>
                          <div class="w-full lg:w-5/6">
                            <input name="post" id="post" class="type_number h-10 xl:w-60 lg:w-60 w-full" @click="search_address" v-model="post" maxlength='5' type="text" readonly>
                            <input type="button" @click="search_address"  class="h-10 xl:w-36 lg:w-36 w-full leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 xl:ml-2 lg:mt-0 xl:mt-0" value="Search address"/>
                          </div>
                        </div>

                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>State</div>
                          <div class="w-full lg:w-5/6">
                            <input type='text' name="state" id="state" v-model='state' readonly @click="search_address" maxlength="40" class="h-10 w-full xl:w-60 lg:w-60">
                          </div>
                        </div>
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>City</div>
                          <div class="w-full lg:w-5/6">
                            <input type='text'  name="city" id="city" v-model='city' readonly @click="search_address" maxlength="40" class="h-10 w-full xl:w-60 lg:w-60">
                          </div>
                        </div>
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>Address Line1</div>
                          <div class="w-full lg:w-5/6">
                            <input type='text' name="addr1" id="addr1" v-model='addr1' maxlength="40" class="h-10 xl:w-9/12 lg:w-9/12 w-full">
                          </div>
                        </div>
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">Address Line2</div>
                          <div class="w-full lg:w-5/6">
                            <input name="addr2" id="addr2" class=" add-input1 h-10 xl:w-9/12 lg:w-9/12 w-full" v-model="addr2" type="text"> <br>
                            <div class="text-red-500 text-sm">Add Apt #, Floor, etc</div>
                          </div>
                        </div>
                        <!--END : 주소-->

                      <!--START : 추천인-->
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:pt-2 lg:items-center mb-4 pb-4">
                        <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>{{ i18n.join.join.EnrollmentSponsorID }}</div>
                        <input type='text' name="recommUserId" id="recommUserId" v-model='recommUserId' readonly  maxlength="40" class="h-10 w-full xl:w-60 lg:w-60">
                      </div>
                      <!--END : 추천인-->

                    </div>
                    <div class="w-full bg-white lg:p-2" id="optionInfo" >
                      <!--START : 기존 아이디-->
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                        <div class="w-full lg:w-1/6 text-base font-semibold mb-2">{{ i18n.join.join.OriginUserID }}</div>
                        <input type='text' name="origin_user_id" id="origin_user_id" v-model='origin_user_id'   maxlength="10"  class="h-10 w-full xl:w-60 lg:w-60" oninput="this.value = this.value.replace(/[^a-z0-9]/gi,'');">
                      </div>
                      <!--END : 기존 아이디-->
                      <!--START : 기존 아이디-->
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                        <div class="w-full lg:w-1/6 text-base font-semibold mb-2">{{ i18n.join.join.OriginUserMobile }}</div>
                        <input type='text' name="origin_user_mobile" id="origin_user_mobile" v-model='origin_user_mobile'   maxlength="10" class="h-10 w-full xl:w-60 lg:w-60" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                      </div>
                      <!--END : 기존 아이디-->
                    </div>

                    <div class="flex gap-2">
                      <button type="button" v-on:click="send_agree" class="my-2 py-2 px-4 leading-6 bg-[#d5e7f6] hover:bg-[#cbcfdd] transition duration-200 text-[#2c7fc3] xl:w-16 lg:w-16 w-1/2 reset_btn rounded-md">Cancel</button>
                      <button type="button" v-on:click="submitForm" v-if="submitFormBtn"  class="my-2 py-2 px-4 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 xl:w-16 lg:w-16 w-1/2 search_btn rounded-md">{{ i18n.myoffice.save }}</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>

  </section>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import MypageTop from './mypage/MypageTop.vue';
import SideMenu from './mypage/SideMenu.vue';
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import {createPopper} from "@popperjs/core";
import modalView from "./modalView";
import vSelect from "vue-select";
import dayjs from "dayjs";

export default {
  name: "MyRegisterMember",
  components : {
    VueSimpleAlert,
    ShoppingCartIcon,
    MypageTop,
    SideMenu,
    Loading,
    modalView,
    vSelect
  },
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,

    no_search_messsage: '',
    isLoggedIn: false,
    loading : false,
    mem: "",
    myconfig:"",

    userid : "",
    /* 파라미터 변수 선언 */
    radioNation : "Unitedstates",
    nt_cd : "001",
    username : "",
    username_first : "",
    username_last: "",
    strtDaySch:"",
    strtMonthSch:"",
    strtYearSch:"",
    mobile1 : "",
    mobile2 : "",
    mobile3 : "",
    email_1 : "",
    email_2 : "",
    pwd : "",
    pwd_chk : "",
    jumin_type : "S", //SSN ITIN

    jumin_1 : "",
    jumin_2 : "",
    jumin_3 : "",
    country : "",
    post : "",
    state : "",
    city : "",
    addr1 : "",
    addr2 : "",
    mobile_sms_chk : "", //sns 코드
    TimeCounter : 300, //sms 인증 제한 초
    TimerStr : "",//초 -> 분 형식변경


    loginId : "",
    recommUserId : "",
    recommUserName : "",
    origin_user_id : "",
    origin_user_mobile : "",

    rankCd : "01",
    memberKind : "1",




    /* 유효성 검사 변수 */
    is_pwd_chk : false,
    /*
      2023-02-14
      MYOFFICE 에서 ITIM / SSN 을 별도로 받기로 하여 유효성검사 is_jumin_chk 강제 true 변경함
    */
    is_jumin_chk : true,
    is_email_chk : false,
    is_mobile_chk : false,
    is_age_chk : false,


    /* 버튼 Show hide 변수*/
    smsChkBtn : false,
    sendBtn : false,

    duplChkBtn : true,
    ressetMblChkBtn : false,

    emailChkBtn : true,
    submitFormBtn : true,




    selectCity : "",
    fullPage : true,
    state_list : "",
    city_list : "",
    street_list : "",
    address_search_open:false,
    isMatchStateCity:false,

    /* 기본 선언 변수 */
    days:['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
    years:[],

    is_login: true,

    /*문구*/
    juminMsg : "",
    emailMsg : "",
    mobileMsg : "",

    show : false,
    show_confirm : false,
  }),

  beforeCreate() {


  },
  created() {
    let year = new Date().getFullYear();
    for(let i=(year-18); i>=(year-100); i--){
      this.years.push(
        i
      );
    }

  },
  watch : {
    //생년월일 체크
    strtDaySch : function(){
      this.chkAge(this.strtDaySch,this.strtMonthSch,this.strtYearSch);
    },
    strtMonthSch : function(){
      this.chkAge(this.strtDaySch,this.strtMonthSch,this.strtYearSch);
    },
    strtYearSch : function(){
      this.chkAge(this.strtDaySch,this.strtMonthSch,this.strtYearSch);
    },
    //연도 데이터 호출
    s_month : function(){
      var total_day = dayjs(this.s_year+this.s_month).daysInMonth();
      var day = 1;
      for(day ; day <= total_day ; day++){
        this.days.push(
          {value: day}
        );
      }
    },
    radioNation : function(){
      if( this.radioNation == 'Canada'){
        this.nt_cd="002";
        this.jumin_type = "I";
        this.jumin_1 = "";
        this.jumin_2 = "";
        this.jumin_3 = "";
      }else{
        this.nt_cd="001";
        this.jumin_type = "S";
        this.jumin_1 = "";
        this.jumin_2 = "";
        this.jumin_3 = "";
      }
      this.is_jumin_chk = false;
    },
    jumin_type : function(){
      this.jumin_1 = "";
      this.jumin_2 = "";
      this.jumin_3 = "";
    }
  },
  mounted() {
    if (window.Laravel.registerRid) {
      this.isLoggedIn = true;
      // this.loginId = window.Laravel.user;
      this.recommUserId = window.Laravel.registerRid;
      this.recommUserName = window.Laravel.registerRname;
    }else{
      location.href='/';
    }
  },
  methods:{
    showPwd : function(select){
      if(select == 'show'){
        this.show = !this.show;
      }else if(select == 'show_confirm'){
        this.show_confirm = !this.show_confirm;
      }

    },
    send_agree : function (){//agree 페이지로 이동
      location.href= './Register';
    },
    submitForm: function (event) {
      if(this.is_email_chk && this.is_mobile_chk && this.is_pwd_chk && this.is_age_chk && this.username_first.trim() && this.username_last.trim() && this.strtDaySch && this.strtMonthSch && this.strtYearSch
          && this.recommUserId.trim()) {//Submit
        if(this.post == ""){
          VueSimpleAlert.alert("Please check your zip code","","",{allowOutsideClick : false}).then((result) => {})
          this.loading = false; return;
        }
        if(this.city == ""){
          VueSimpleAlert.alert("Please check your city","","",{allowOutsideClick : false}).then((result) => {})
          this.loading = false; return;
        }
        if(this.state == ""){
          VueSimpleAlert.alert("Please check your state","","",{allowOutsideClick : false}).then((result) => {})
          this.loading = false; return;
        }
        if((this.addr1.trim() == "")){
          VueSimpleAlert.alert("Please check your address","","",{allowOutsideClick : false}).then((result) => {})
          this.loading =false;
          return ;
        }
        this.loading       = true;
        this.submitFormBtn = false;
        let formData       = new FormData();

        formData.append('userid', this.userid);
        formData.append('radioNation', this.radioNation);
        formData.append('username_first', this.username_first);
        formData.append('username_last', this.username_last);
        formData.append('strtDaySch', this.strtDaySch);
        formData.append('strtMonthSch', this.strtMonthSch);
        formData.append('strtYearSch', this.strtYearSch);
        formData.append('pwd', this.pwd);
        formData.append('pwd_chk', this.pwd_chk);
        formData.append('mobile1', this.mobile1);
        formData.append('mobile2', this.mobile2);
        formData.append('mobile3', this.mobile3);
        formData.append('email_1', this.email_1);
        formData.append('email_2', this.email_2);

        formData.append('jumin_type', this.jumin_type);
        formData.append('jumin_1', this.jumin_1);
        formData.append('jumin_2', this.jumin_2);
        formData.append('jumin_3', this.jumin_3);
        formData.append('post', this.post);
        formData.append('country', this.country );
        formData.append('state', this.state);
        formData.append('city', this.city);
        formData.append('addr1', this.addr1);
        formData.append('addr2', this.addr2);

        // formData.append('loginId', this.loginId);
        formData.append('recommUserId', this.recommUserId);
        formData.append('recommUserName', this.recommUserName);

        formData.append('origin_user_id', this.origin_user_id);
        formData.append('origin_user_mobile', this.origin_user_mobile);


        formData.append('rankCd', this.rankCd);
        formData.append('memberKind', this.memberKind);
        formData.append('workUserId', this.recommUserId);

        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + window.Laravel.access_token
          }
        }

        this.loading = true;
        axios.post(window.Laravel.back_url+'/api/insertSaveMemberUs',
          formData
          , config).then(response => {
          if(response.data.status == "1"){
            VueSimpleAlert.alert("Registration complete.","","",{allowOutsideClick : false}).then((result) => { //회원가입이 완료 되었습니다.
              if(result){
                location.replace("/RegisterOk?userid="+response.data.SP_KEYVALUE+"&xid="+response.data.xid);
              }
            });

          }
          else if(response.data.result != "1"){
            this.loading = false;
            VueSimpleAlert.alert(response.data.message,"","",{allowOutsideClick : false}).then((result) => {
              if(result){

              }
            });
          }
          this.loading = false;

        });

      }else{// 가입 조건 미충족
        this.submitFormBtn = true;
          if(this.username_first.trim() == ""){
            VueSimpleAlert.alert("Please check your first name","","",{allowOutsideClick : false}).then((result) => {})
            this.loading = false; return;
          }
          if(this.username_last.trim() == ""){
            VueSimpleAlert.alert("Please check your last name","","",{allowOutsideClick : false}).then((result) => {})
            this.loading = false; return;
          }
          if(!this.strtDaySch || !this.strtMonthSch || !this.strtYearSch){
            VueSimpleAlert.alert("Please check your birth date","","",{allowOutsideClick : false}).then((result) => {})
            this.loading = false; return;
          }
          if(!this.is_pwd_chk){
            VueSimpleAlert.alert("Check your password","","",{allowOutsideClick : false}).then((result) => {});
            this.loading = false; return;
          }
          if(!this.is_mobile_chk){
            VueSimpleAlert.alert("Check your mobile","","",{allowOutsideClick : false}).then((result) => {});
            this.loading = false; return;
          }
          if(!this.is_email_chk){
            VueSimpleAlert.alert("Check your email","","",{allowOutsideClick : false}).then((result) => {});
            this.loading = false; return;
          }
          if(!this.recommUserId.trim()){
            VueSimpleAlert.alert("Please check your sponsor ID","","",{allowOutsideClick : false}).then((result) => {})
            this.loading = false; return;
          }
          if(!this.addr1.trim()){
            VueSimpleAlert.alert("Please check your address","","",{allowOutsideClick : false}).then((result) => {})
            this.loading = false; return;
          }
          /* 2023-02-15 dylee SSN ITIN MYOFFICE 입력
          if(!this.is_jumin_chk){
            VueSimpleAlert.alert("Check your SSN/ITIN","","",{allowOutsideClick : false}).then((result) => {});
          }*/
          if(!this.is_age_chk){
            VueSimpleAlert.alert("Check your age","","",{allowOutsideClick : false}).then((result) => {});
          }
      }
      this.loading = false;
      },



    checkEmail(){
      this.loading = true;
      //이메일 미입력시
      if(this.email_1.length < 1 || this.email_2.length < 1) {
        this.emailMsg = i18n.join.twalert.emailId;
        $("#email_1").focus();
        this.loading = false;
        return;
      }else{
        this.emailMsg = "";
      }
      //이메일 유효성체크
      this.email = this.email_1 + '@' + this.email_2;

      let regex =/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
      if(regex.test(this.email) == false){
        this.emailMsg = i18n.join.twalert.emailId2;
        this.loading = false;
        return false;
      }else{
        this.emailMsg = "";
      }

      axios.post(window.Laravel.back_url+'/api/getComparisonEmail', {
        email : this.email,
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        if(response.data.chk.checker == "N"){
          //중복 이메일 존재
          this.emailMsg = i18n.join.join.alert_emailchk1;/* 사용할 수 없는 핸드폰 번호입니다. */
          this.is_email_chk = false;
        }else{
          //중복 이메일 없음
          this.emailMsg = i18n.join.join.alert_emailchk4;/* 사용할 수 있는 핸드폰 번호입니다. */
          this.is_email_chk = true;
          this.emailChkBtn = false; // 이메일 버튼 hide
        }
        this.loading = false;


      });
    },

    resetMobile(){
      this.loading = true;
      this.mobile1 = "";
      this.mobile2 = "";
      this.mobile3 = "";

      this.is_mobile_chk = false;
      this.duplChkBtn =true;
      this.ressetMblChkBtn = false;

      this.loading = false;
    },

    checkMobile(){
      this.loading = true;
      this.mobile_sms_chk = "";
      //this.smsChkBtn = true;

      //모바일 미입력시
      if(this.mobile1.length < 1 || this.mobile2.length < 1 || this.mobile3.length < 1  ) {
        this.mobileMsg = i18n.join.twalert.enPhone;
        this.loading = false;
        return;
      }else if(this.mobile1.length != 3 ){
        this.mobileMsg = i18n.join.twalert.enPhone;
        this.loading = false;
        return;
      }
      else{
        this.mobileMsg = '';
      }


      //모바일 10자리 이하 입력시
      if((this.mobile1.length+this.mobile2.length+this.mobile3.length) < 10) {
        this.mobileMsg = i18n.join.join.phonechk;
        $("#mobile").focus();
        this.loading = false;
        return false;

      }else{
        this.mobileMsg = '';
      }
      //모바일 유효성 검사(중복)
      axios.post(window.Laravel.back_url+'/api/getComparisonMobile', {
        mobile : this.mobile1+"-"+this.mobile2+"-"+this.mobile3,
      }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        if (response.data.chk.checker == "0") {
          VueSimpleAlert.alert("Confirmed","","",{allowOutsideClick : false}).then((result) => {})
          //문자인증 비활성화 20230425 최치원 팀장 요청
          /*this.timerStart();//인증문자 제한시간 시작
          //모바일 인증코드 발송
          axios.post(process.env.MIX_BACK_URL+'/api/sendRegisterAproval', {
            nt_cd : this.nt_cd,
            mobile : this.mobile1+"-"+this.mobile2+"-"+this.mobile3,
          }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
          });*/
          this.is_mobile_chk = true;
          this.duplChkBtn =false;
          this.ressetMblChkBtn = true;
        } else {
          this.is_mobile_chk = false;
          this.mobileMsg = i18n.join.myalert.notAvailPhoneNotice1; /!* 사용할 수 없는 핸드폰 번호입니다. *!/
        }
        this.loading = false;
      });



    },
    //문자 확인코드
    checkConfirmMobile(){
      this.loading = true;
      axios.post(process.env.MIX_BACK_URL+'/api/sendRegisterAprovalCode', {
        code : this.mobile_sms_chk,
        nt_cd : this.nt_cd,
        mobile : this.mobile1+"-"+this.mobile2+"-"+this.mobile3,
      }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {

          if(response.data == 1 ){//성공
            this.mobileMsg = "Confirmed";

            this.is_mobile_chk = true;
            this.sendBtn =false;
            this.smsChkBtn = false;
          }else if(response.data == 1){
            this.mobileMsg = "Code is not available. Please retry again";
            this.is_mobile_chk = false;
            this.smsChkBtn = true;
          }else{
            this.mobileMsg = "Please retry again";
            this.is_mobile_chk = false;
            this.smsChkBtn = true;
          }
        this.loading = false;
      });

    },
    /* START : SMS 인증 시간 함수*/
    timerStart: function() {
      // SMS 인증 1초에 한번씩 start 호출
      this.TimeCounter = 300;
      var interval = setInterval(() => {
        if(this.TimeCounter > 0){
          this.TimeCounter--; //1초씩 감소
          this.TimerStr = this.prettyTime();
          if (this.TimeCounter <= 0) this.timerStop(interval);
        }
      }, 1000);
      return interval;
    },
    prettyTime: function() {
      // 시간 형식으로 변환 리턴
      let time = this.TimeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return (
        minutes.toString().padStart(2, "0") +
        ":" +
        secondes.toString().padStart(2, "0")
      );
    },
    timerStop: function(Timer) {
      clearInterval(Timer);
      this.TimeCounter = 0;
      if(!this.is_mobile_chk){
        this.mobileMsg = "Code has expired";
      }
      this.smsChkBtn = false;
    },
    /* END : SMS 인증 시간 함수*/
    //비밀번호, 비밀번호 확인 동일 확인
    checkPassword : function(e){
      //비밀번호 6자리 이하
      if(this.pwd.length < 6) {
        $("#passMsg").text(i18n.join.twalert.passchk3);//영문 및 숫자를 조합하여, 6~8자리로 입력 해 주시기 바랍니다
        this.is_pwd_chk = false;
        return;
      }
      //비밀번호 확인 미입력시
      if(this.pwd.length < 1 || this.pwd_chk.length < 1) {
        $("#passMsg").text(i18n.join.twalert.password1);//영문 및 숫자를 조합하여, 6~8자리로 입력 해 주시기 바랍니다
        this.is_pwd_chk = false;
        return;
      }

      //비밀번호, 비밀번호 확인 일치여부
      if(this.pwd != this.pwd_chk) {
        $("#passMsg").text(i18n.join.twalert.password2);
        this.is_pwd_chk = false;
        return;
      }else{
        $("#passMsg").text('');
        this.is_pwd_chk = true;
      }

    },

    chkJumin(){
      this.loading = true;
      axios.post(window.Laravel.back_url+'/api/chkjumin', {
        jumin_no : this.jumin_1+this.jumin_2+this.jumin_3,
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        if( response.data == 0 ){ // 가입가능
            this.is_jumin_chk = true;
            this.juminMsg = "Available";
        }else {
          this.is_jumin_chk = false;
          this.juminMsg = i18n.join.join.ssn_notice;
        }
        this.loading = false;
      });

    },
    chkAge(year,mon,day){
        // 미국 미성년자 기준은 만 18세
        // 기준확인을 하기 위한 참고한 사이트 : http://m.blog.naver.com/woongjooseok/220711033579

        if(year == "" || mon == "" || day == ""){
          return;
        }
        //비교를위해 숫자타입으로 변경.
        year = Number(this.strtYearSch);
        mon  = Number(this.strtMonthSch);
        day  = Number(this.strtDaySch);

        //성년기준 나이
        let cut = 18;

        //체크용 날짜형식
        let set = new Date();
        let setYear =  Number(set.getFullYear());
        let setMon = Number((set.getMonth() + 1));
        let setDay = Number(set.getDate());

        // 현재나이
        let age = (setYear-1) - year;

        // 자신의 생일이 지난 만18세가 되면 허용
        if(age >= cut){
          this.is_age_chk = true;
          return;
        }else{
          this.is_age_chk = false;
        }

        // 월체크
        if(setMon >= mon){
          if(setMon == mon){
            //일체크
            if(setDay >= day){
              this.is_age_chk = true;
            }else{
              this.is_age_chk = false;
            }
          }else{
            this.is_age_chk = false;
            return;
          }
        }else{
          this.is_age_chk = false;
        }
    },
    // 우편번호  가져오기
    getPostState(){   // 주(State) 목록 가져오기
      this.loading = true;
      axios.post(process.env.MIX_BACK_URL+'/api/getState',{

      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          // this.shipping_info["d_state"] = value;
          this.state_list = response.data;

          // console.log(this.state_list);
        })
        .catch(e => {
        });
      this.loading = false;
    },
    onChangeState(value) {   // 주(State) 선택

      axios.post(process.env.MIX_BACK_URL+'/api/getCity',{
        state: value,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          this.city = "";
          this.Street = "";
          this.post = "";
          this.city_list = response.data;
          // console.log(this.city_list);
        })
        .catch(e => {
        });
    },
    onChangeCity(value) {  // 도시(City) 선택
      // console.log(event.target.value)
      axios.post(process.env.MIX_BACK_URL+'/api/getStreet',{
        state: this.state,
        city: value,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          this.Street = "";
          this.post = "";
          this.street_list = response.data;
          // console.log(response.data);
        })
        .catch(e => {
        });
    },
    onChangeStreet(value) {  // 도로(Street) 선택
      axios.post(process.env.MIX_BACK_URL+'/api/getPostCodeForDelivery',{
        state: this.state,
        city: this.city,
        street: value,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          this.post =  response.data.postcode;
          this.post_list = response.data;
          // console.log(response.data);
        })
        .catch(e => {
        });
    },
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "top",
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
    // 숫자 포멧
    formatPrice(value,digit) {
      let val = (value/1).toFixed(digit).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    // 숫자 포멧
    funcNumber(type,val) {
      let value = val;
      // 숫자만 남김
      var pattern_kor = /[^0-9]/gi;

      if(type =='jumin_1'){
        this.jumin_1 = val.replace(pattern_kor, "");
      }else if(type =='jumin_2'){
        this.jumin_2 = val.replace(pattern_kor, "");
      }else if(type =='jumin_3'){
        this.jumin_3 = val.replace(pattern_kor, "");
      }

    },
    search_address(){
      this.address_search_open = true;
    },
    set_address(value){
      this.address_search_open = false;
      this.state =value.state;
      this.city = value.district;
      this.Street = "";
      this.post = value.postcode;
      this.country = value.country;

    },
    close_address_search(){
      this.address_search_open = false;
    },



    /* 이름 특수문자 자동 삭제 */
    funcUserName(e) {
      var tmp =  e.target.value;
      var regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;

      if(regExp.test(tmp)){
        var t = tmp.replace(regExp, "");
        tmp = t;
        if(e.target.id == "username_last"){
          this.username_last = tmp;
        }else if(e.target.id == "username_first"){
          this.username_first = tmp;
        }

      }
    },

  },

}


</script>

<style >
.v-select{ min-width: 100px; z-index:0 }
.m_menu{display:none;}
</style>
