<!--
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
-->
<style>
input[type=search]:focus {outline:none;
    outline-offset: 0px !important;
    box-shadow: none !important;}
</style>
<style>

#google_translate_element > div {
  color:transparent;
}
#google_translate_element > div > div {
  position: relative;
  height: 26px;
}
#google_translate_element > div > span {
  display: none;
}
#google_translate_element > div > div > select::-ms-expand {
  display: none;
}

#google_translate_element > div > div:after {
  /*content: '<>'; !* 목록 펼침 아이콘 *!*/
  font: 17px "Consolas", monospace;
  color: #333;
  transform: rotate(90deg);
  right: 11px;
  top: 18px;
  padding: 0 0 2px;
  border-bottom: 1px solid #999;
  position: absolute;
  pointer-events: none;
  text-align:center;
}

#google_translate_element > div > div > select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 105px;
  max-width: 105px;
  height: 26px;
  float: right;
  margin: 0;
  padding: 0px;
  font-size: 13px;
  text-align:center;
  color: #fff;
  border: 0px solid #cccccc;
  -ms-word-break: normal;
  word-break: normal;
  background: #b4b4b4;
}

</style>
<template>

    <div id="google_translate_element" class="relative"></div>

</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems,
    Dialog,
    DialogPanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    TransitionChild,
    TransitionRoot } from '@headlessui/vue'
import { SearchIcon, GlobeIcon, BellIcon, ShoppingCartIcon, ChartSquareBarIcon, LoginIcon,LogoutIcon, UserIcon, MenuIcon, XIcon, UserCircleIcon, ArrowCircleLeftIcon } from '@heroicons/vue/outline'
import VueSimpleAlert from "vue3-simple-alert";
import {eventBus} from "../../app";

export default {
    name: "GoogleTranslate",
    components: {
    },
    setup() {
        return {
            // navigation,
        }
    },
    data: () => ({
        loopCnt: 0,
        deviceInfo: false,
    }),
    created() {

    },
    mounted() {
        this.googleTranslateElementInit();
    },
    methods: {
        googleTranslateElementInit() { // 구글 번역
          if ( this.loopCnt <= 5 ) {
            if (typeof window.google.translate.TranslateElement !== 'undefined') {
              new window.google.translate.TranslateElement(
                {pageLanguage: "en", includedLanguages: 'en,ko,es,zh-CN', autoDisplay: false},
                "google_translate_element"
              );

            } else {
              this.loopCnt++
              setTimeout(() => this.googleTranslateElementInit(), 3000);
            }
          }else{
             throw new Error("=== window.google.translate Error ===")
          }

          var $googleDiv = $("#google_translate_element .skiptranslate");
          var $googleDivChild = $("#google_translate_element .skiptranslate div");
          $googleDivChild.next().remove();

          $googleDiv.contents().filter(function(){
            return this.nodeType === 3 && $.trim(this.nodeValue) !== '';
          }).remove();
        },
  },
}
</script>

