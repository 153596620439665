<template>
    <dl class="myo_search_list">
        <dt>{{ i18n.myoffice.Rank }}</dt>
        <dd>
            <vSelect multiple :options="rank" v-model="rank_cds" @option:selected="setSelected" @option:deselected ="setSelected"  ></vSelect>
        </dd>
        <span class="mini_notice2">{{ i18n.myoffice.myoSearchForm.rankNotice }}</span>
    </dl>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "rankForm",
    components : {
        vSelect
    },
    data: () => ({
        i18n: window.i18n,
        rank: [],
        rank_cd : "",
        // rank_cds : [ { "id": 40, "label": "BM" }, { "id": 50, "label": "DR" }, { "id": 60, "label": "VP" }, { "id": 70, "label": "PR" } ]
        rank_cds : [ { "id": 42, "label": "M" }, { "id": 45, "label": "GM" }, { "id": 50, "label": "D" }, { "id": 60, "label": "VP" }, { "id": 70, "label": "P" } ]
    }),
    beforeCreate() {
        axios.post(window.Laravel.back_url+'/api/getRankData',{
        }).then(response => {
            response.data.forEach((value, index) => {
                if(value.rank_cd >= 25){
                    this.rank.push({
                        id: value.rank_cd,
                        label: value.rank_name,
                    });
                }
            });
        });
    },
    methods : {
        setSelected(value){
            this.$emit("setSelected",this.rank_cds);
        }
    }
}
</script>

<style scoped>

</style>
